import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Paper,
  Box,
} from "@mui/material";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function SeriesModal({ open, handleClose, product, updateSelectedSeries }) {
  const [tooltip, setTooltip] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  useEffect(() => {
    if (product && product.selectedSeries) {
      setSelectedSeries(product.selectedSeries);
    }
  }, [product]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") setIsShiftPressed(true);
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") setIsShiftPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleSelectSeries = (series, index) => {
    let newSelected = [...selectedSeries];

    if (isShiftPressed && lastSelectedIndex !== null) {
      // Get all indices between last selected and current selected
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);

      const rangeSeries = product.all_series
        .slice(start, end + 1)
        .map((s) => s.series);

      newSelected = Array.from(new Set([...newSelected, ...rangeSeries]));
    } else {
      // Toggle selection state
      const seriesName = series.series;
      if (newSelected.includes(seriesName)) {
        newSelected = newSelected.filter((name) => name !== seriesName);
      } else {
        newSelected.push(seriesName);
      }
    }

    setSelectedSeries(newSelected);
    setLastSelectedIndex(index); // Update last selected index
  };

  const handleSaveAndClose = () => {
    updateSelectedSeries(product.sku, selectedSeries);
    handleClose();
  };

  const style = {
    boxShadow: 24,
    borderRadius: "8px",
    bgcolor: "background.paper",
    overflow: "auto",
    width: { xs: "90%", sm: "80%", md: "550px" },
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <Box sx={style}>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {product.product_name}
          </DialogTitle>
          <DialogContent dividers>
            <div className="text-center row">
              <div className="col-md-6">
                <strong>Selected : </strong> {selectedSeries.length || 0}
              </div>
              <div className="col-md-6">
                <strong>Remaining : </strong>{" "}
                {product.all_series.length - selectedSeries.length}
              </div>
            </div>
            <div
              className="row my-3"
              style={{ maxHeight: "200px", overflow: "auto" }}
            >
              {product.all_series.map((series, index) => (
                <div
                  className="col-md-1 p-1 position-relative"
                  key={series.series}
                >
                  <div
                    className="d-flex align-items-center justify-content-center border p-2 position-relative"
                    onClick={() => handleSelectSeries(series, index)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: selectedSeries.includes(series.series)
                        ? "#4c8cc2"
                        : "#ededed",
                      color: selectedSeries.includes(series.series)
                        ? "white"
                        : "",
                    }}
                  >
                    <span>{series.series}</span>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Button
                className="float-end d-block ms-2"
                onClick={handleSaveAndClose}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                className="d-block float-end"
                onClick={handleClose}
                variant="outlined"
                color="error"
              >
                Close
              </Button>
            </div>
            {tooltip && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#ffffff",
                  border: "1px solid #ccc",
                  padding: "5px",
                  zIndex: "1000",
                  borderRadius: "5px",
                }}
              >
                <div>
                  {tooltip.series}, {tooltip.ware_house__ware_house},{" "}
                  {tooltip.rack__row__row_name || "N/A"},{" "}
                  {tooltip.rack__rack_name || "N/A"}
                </div>
              </div>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
}

export default SeriesModal;
