import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetch_sale,
  apiKey,
  fetch_sale_detail,
  DispatchHistory,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Delete from "../../Messages/CancelSale";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Pagination, Icon } from "semantic-ui-react";
import FilterDateRangeModal from "../../Components/FilterModal/FilterDateRangeModal";
import { useScreenWidth } from "../../useScreenWidth";

function AllDispatch() {
  const isWideScreen = useScreenWidth();
  const [allDispatchHistory, setAllDispatchHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  // date Filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel, startDate, endDate]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      filters: filterModel.items || [],
      starting_date: startDate || "",
      ending_date: endDate || "",
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(DispatchHistory, data, { headers })
      .then((response) => {
        // console.log("response.data.data : ", response.data.data);

        setAllDispatchHistory(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (id) => {
    navigate(`/update-sale/${id}`);
  };

  const handleViewClick = (id) => {
    navigate(`/dispatch-details/${id}`);
  };

  const [pks, setPks] = useState([]);

  const handleDeleteClick = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(
      ".MuiDataGrid-main.css-3eek4p-MuiDataGrid-main"
    );
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(parseInt(selected_pk));
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      setError("No sale selected, please choose sale to continue!");
      setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }

    // Perform the delete action
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Delete
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Dispatch</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Dispatch History
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              ></div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1 ">
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>Sale ID</th>
                      <th>
                        <FilterDateRangeModal
                          title="Dispatch Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>Dispatcher</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDispatchHistory &&
                      allDispatchHistory.map((dispatch, index) => (
                        <tr key={index}>
                          <td>{dispatch.id}</td>
                          <td>
                            {new Date(
                              dispatch.dispatched_date
                            ).toLocaleDateString()}
                          </td>{" "}
                          <td>{dispatch.dispatcher_name}</td>
                          <td>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleViewClick(dispatch.id)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {allDispatchHistory.length > 0 ? (
                  <>
                    {allDispatchHistory &&
                      allDispatchHistory.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleViewClick(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.dispatcher_name}
                              </h5>
                              <h5 className="warehouse-card-address">
                                {new Date(
                                  data.dispatched_date
                                ).toLocaleDateString()}
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDispatch;
