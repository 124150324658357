import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'

const style = () => {
    const width = window.innerWidth;
  
    if (width <= 480) {
      return { width: "90%" };
    } else if (width <= 768) {
      return { width: 450 };
    } else {
      return { width: 550 };
    }
  };

function RemoveSkipProduct({selectedRemoveSkipProductDetail ,handleCloseOpenRemoveSkipProductModal ,scannedProducts , setScannedProducts , openRemoveSkipProductModal}) {

    const handleRemoveProduct = () => {
        // Destructure the series and sku from the selectedRemoveSkipProductDetail
        const { product_sku } = selectedRemoveSkipProductDetail.existingProduct;
        const { cur_series } = selectedRemoveSkipProductDetail;
        console.log("cur_series : ", cur_series);
        console.log("product_sku : ", product_sku );
        console.log("selectedRemoveSkipProductDetail : ",selectedRemoveSkipProductDetail)
    
        // Clone scannedProducts to avoid direct state mutation
        const updatedScannedProducts = scannedProducts.map(product => {
            if (product.product_sku  === product_sku) {
                // Filter out the cur_series from selectedSeries
                const updatedSelectedSeries = product.selectedSeries.filter(series => series !== cur_series);

                console.log("hehehehehhehe ",updatedSelectedSeries)
    
                // Return the updated product with the filtered selectedSeries
                return {
                    ...product,
                    selectedSeries: updatedSelectedSeries
                };
            }
            // If the product sku doesn't match, return the product as is
            return product;
        });
    
        // Update the state with the new scannedProducts array
        setScannedProducts(updatedScannedProducts);
    
        // Optionally, close the modal
        handleCloseOpenRemoveSkipProductModal();
    
    };
    
    return (
        <>
            <Dialog
                open={openRemoveSkipProductModal}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseOpenRemoveSkipProductModal();
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={style}>
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
                    >
                        {"Product Already Scanned!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Do you want to remove this product?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={handleRemoveProduct}
                        >
                            Remove Product
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={handleCloseOpenRemoveSkipProductModal}
                            color="error"
                        >
                            Skip
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default RemoveSkipProduct
