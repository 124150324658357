import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_warehouse, fetchProductInven } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AssignWare from "./ProductInvenAdjust";
import AssignRackModal from "./AssignRackModal";
import { useScreenWidth } from "../../../useScreenWidth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";



function Inventory_ProductsModal({
  setOpenModal,
  openModal,
  inventory,
  p_sku,
  setInven,
  fetchData,
  unallocated,
  setUnallocated,
  sendedToInventoryProductTypeModal,
}) {
  const isWideScreen = useScreenWidth();
  const [error, setError] = useState("");
  const [inven_id, setID] = useState("");
  const [quantity, setQty] = useState("");
  const [openModal2, setOpenModal2] = useState(false);
  const [openUnallocatedModal, setOpenUnallocatedModal] = useState(false);
  const [openError, setOpenError] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const handleClose = () => {
    setOpenModal(false);
    setWarehouse(userDetails.primary_warehouse_pk);
    setError(""); // Clear error on close
  };

  useEffect(() => {
    fetchWarehouse();
    fetchDataa();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
      });
  };

  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);
  const [warehouses, setWarehouses] = useState([]);

  const fetchDataa = () => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_pk: warehouse,
          product_sku: p_sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setInven(response.data.product_inventory.inventory);
        setUnallocated(response.data.product_inventory.unallocated);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleWarehouseChange = (e) => {
    const selectedWarehouse = e.target.value; // Use the selected value directly
    setWarehouse(selectedWarehouse);

    axios
      .get(fetchProductInven, {
        params: {
          warehouse_pk: selectedWarehouse, // Use the selected warehouse value here
          product_sku: p_sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setInven(response.data.product_inventory.inventory);
        setUnallocated(response.data.product_inventory.unallocated);
        setOpenModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditClick = (id, qty) => {
    setOpenModal2(true);
    setID(id);
    setQty(qty);
  };

  const handleUnallocated = () => {
    setOpenUnallocatedModal(true);
  };

  const groupedInventory = inventory.reduce((acc, row) => {
    const key = `${row.rack__row__row_name}-${row.rack__rack_name}`;
    if (!acc[key]) {
      acc[key] = {
        rack__row__row_name: row.rack__row__row_name,
        rack__rack_name: row.rack__rack_name,
        quantity: row.quantity,
        seriesCount: 0,
      };
    }
    acc[key].seriesCount += 1; // Increment the count for each series entry
    return acc;
  }, {});
  const groupedInventoryArray = Object.values(groupedInventory);
  // console.log(p_sku, "sku");
  return (
    <>
      <AssignWare
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        inven={inven_id}
        qty={quantity}
        setQty={setQty}
        fetchDataa={fetchDataa}
        setWarehouse={setWarehouse}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <AssignRackModal
        openModal={openUnallocatedModal}
        setOpenModal={setOpenUnallocatedModal}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
        warehouseId={warehouse}
        p_sku={p_sku}
        fetchDataa={fetchDataa}
      />

      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <div className="d-flex justify-content-between">
          <DialogTitle >
            <b>Inventory</b> / <small>#{p_sku}</small>
          </DialogTitle>
          {isWideScreen ? (
            <FormControl
              style={{
                minWidth: "150px",
                marginTop: "15px",
                marginRight: "20px",
              }}
            >
              <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
              <Select
                size="small"
                value={warehouse}
                label="Warehouse"
                onChange={handleWarehouseChange}
              >
                {warehouses.map((ware) => (
                  <MenuItem key={ware.id} value={ware.id}>
                    {ware.ware_house}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>

        <DialogContent dividers>
          <Box>
            {isWideScreen ? (
              <>
                <div
                  className="responsive-table1"
                  style={{ maxHeight: "300px" }}
                >
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        {sendedToInventoryProductTypeModal === "Child Parts" ? (
                          ""
                        ) : (
                          <>
                            <th align="left">Row</th>
                            <th align="left">Rack</th>
                          </>
                        )}

                        <th align="left">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedInventoryArray.length > 0 ? (
                        <>
                          {groupedInventoryArray &&
                            groupedInventoryArray.map((row, index) => (
                              <tr
                                key={`${row.rack__row__row_name}-${row.rack__rack_name}`}
                              >
                                <td component="th" scope="row">
                                  {index + 1}
                                </td>
                                {sendedToInventoryProductTypeModal !==
                                  "Child Parts" && (
                                  <>
                                    <td>{row.rack__row__row_name}</td>
                                    <td>{row.rack__rack_name}</td>
                                  </>
                                )}
                                <td>
                                  {row.quantity
                                    ? row.quantity
                                    : row.seriesCount}
                                </td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td
                              colSpan="6"
                              style={{ textAlign: "center", color: "red" }}
                            >
                              No allocated products found
                            </td>
                          </tr>
                        </>
                      )}

                      {sendedToInventoryProductTypeModal === "Child Parts" ||
                      sendedToInventoryProductTypeModal === "CH" ? (
                        ""
                      ) : (
                        <>
                          {unallocated && unallocated > 0 ? (
                            <>
                              <tr>
                                <td
                                  align="left"
                                  colSpan={3}
                                  style={{ color: "red" }}
                                >
                                  UnAllocated Inventory:
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {unallocated} UnAllocated Products
                                  </span>
                                </td>

                                <td align="left"></td>
                              </tr>
                            </>
                          ) : null}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                {isWideScreen ? null : (
                  <FormControl fullWidth className="mb-2">
                    <InputLabel id="demo-simple-select-label">
                      Warehouse
                    </InputLabel>
                    <Select
                      size="small"
                      value={warehouse}
                      label="Warehouse"
                      onChange={handleWarehouseChange}
                    >
                      {warehouses.map((ware) => (
                        <MenuItem key={ware.id} value={ware.id}>
                          {ware.ware_house}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div className="card">
                  {groupedInventoryArray.length > 0 ? (
                    <>
                      {groupedInventoryArray &&
                        groupedInventoryArray.map((row, index) => (
                          <>
                            <div
                              className="product-card p-2"
                              key={`${row.rack__row__row_name}-${row.rack__rack_name}`}
                            >
                              <div className="d-flex gap-2 justify-content-between">
                                <h5>
                                  <span style={{ color: "#c9c9c9" }}>
                                    {index + 1})
                                  </span>{" "}
                                </h5>
                                {sendedToInventoryProductTypeModal !==
                                  "Child Parts" && (
                                  <>
                                    <h5 className="product-card-name">
                                      <span style={{ color: "#c9c9c9" }}>
                                        Row:
                                      </span>{" "}
                                      {row.rack__row__row_name}
                                    </h5>
                                    <h5 className="product-card-name">
                                      <span style={{ color: "#c9c9c9" }}>
                                        Rack:
                                      </span>{" "}
                                      {row.rack__rack_name}
                                    </h5>
                                    <h5 className="product-card-name">
                                      <span style={{ color: "#c9c9c9" }}>
                                        Quantity:
                                      </span>{" "}
                                      {row.quantity
                                        ? row.quantity
                                        : row.seriesCount}
                                    </h5>
                                  </>
                                )}
                              </div>
                              {/*  */}
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          color: "red",
                          border: "1px solid #ffe3e3",
                          padding: "20px",
                        }}
                      >
                        No allocated products found
                      </p>
                    </>
                  )}

                  {sendedToInventoryProductTypeModal === "Child Parts" ||
                  sendedToInventoryProductTypeModal === "CH" ? (
                    ""
                  ) : (
                    <>
                      {unallocated && unallocated > 0 ? (
                        <>
                          <b align="left" colSpan={3} style={{ color: "red" }}>
                            UnAllocated Inventory:
                            <span style={{ color: "black" }}>
                              {" "}
                              {unallocated} Products
                            </span>
                          </b>

                          <td align="left"></td>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Inventory_ProductsModal;
