import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiKey,
  packed_details,
  confirm_requirement,
  recieve_requirement_package,
} from "../../../Api";
import { useScreenWidth } from "../../../useScreenWidth";
import { FaCheck, FaTruck } from "react-icons/fa";

function PackedDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [data, setData] = useState([]);
  const [shippedStatus, setShippedStatus] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    const data = {
      requirement_pk: parseInt(id),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(packed_details, data, { headers })
      .then((response) => {
        // console.log(response.data.data.requirement_details.shiped_status);
        if (response.data.status === 1) {
          setData(response.data.data.packings);
          setShippedStatus(
            response.data.data.requirement_details.shiped_status
          );

          // setProducts(response.data.packings[0].packing_items);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setLoading(false);
      });
  };

  const handleConfirmPacking = () => {
    const data = {
      requirement_pk: parseInt(id),
      user_name: userDetails.name || "",
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(confirm_requirement, data, { headers })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Packing Shipped Successfully!");
          fetchData();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setLoading(false);
      });
  };

  const handleRecievePackage = (pk) => {
    if (!pk) {
      return;
    }
    const data = {
      packing_pk: parseInt(pk),
      user_name: userDetails.name || "",
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(recieve_requirement_package, data, { headers })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Received Package Successfully!");
          fetchData();
          // setProducts(response.data.packings[0].packing_items);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setLoading(false);
      });
  };

  const handleRedirectView = (id) => {
    // console.log(data[id].packing_items)
    navigate("/view-requirement-items", {
      state: { productData: data[id].packing_items },
    });
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">Requirement</Typography>
                <Typography sx={{ color: "text.primary" }}>
                  Packed Details
                </Typography>
              </Breadcrumbs>
            </div>

            {isWideScreen ? (
              <>
                {["Megha", "Karan", "Tech", "Ankur"].some((name) =>
                  userDetails.name?.includes(name)
                ) ? (
                  <>
                    {shippedStatus && shippedStatus == 1 ? (
                      <Button variant="outlined" size="small" disabled>
                        <FaTruck className="me-1" /> Packaged Shipped
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={handleConfirmPacking}
                        >
                          <FaTruck className="me-1" /> Confirm Shipment
                        </Button>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          {isWideScreen ? (
            <div className="responsive-table1 mt-1">
              <table className="ui table celled" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th>ID</th>
                    <th style={{ width: "110px" }}>Date</th>
                    <th>Note</th>
                    <th>Transfer To</th>
                    <th>Transfered By</th>
                    <th>Received By</th>
                    <th className="text-center">Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((data, index) => (
                      <tr key={data.id}>
                        <td style={{ width: "50px" }}>#{data.id}</td>
                        <td>{data.date}</td>
                        <td
                          className="product_name_class"
                          style={{ maxWidth: "180px" }}
                        >
                          {data.note}
                        </td>
                        <td>{data.warehouse}</td>
                        <td>{data.user_name}</td>
                        <td>{data.recieved_by || "-"}</td>
                        <td className="text-center">
                          {(data.packing_items && data.packing_items.length) ||
                            0}{" "}
                          QTY
                        </td>

                        <td style={{ maxWidth: "160px", overflow: "auto" }}>
                          <Button
                            size="small"
                            onClick={() => handleRedirectView(index)}
                            color="primary"
                            variant="outlined"
                          >
                            View
                          </Button>
                          {data.recieved_status === 1 ? (
                            <>
                              <Button
                                size="small"
                                variant="contained"
                                color="success"
                                style={{ cursor: "default" }}
                                className="ms-2"
                              >
                                <FaCheck className="me-1" /> Package Recieved
                              </Button>
                            </>
                          ) : (
                            <>
                              {[
                                "Vipin",
                                "Gunjan",
                                "Karan",
                                "Tech",
                                "Ankur",
                              ].some((name) =>
                                userDetails.name?.includes(name)
                              ) ? (
                                <>
                                  <Button
                                    size="small"
                                    onClick={() =>
                                      handleRecievePackage(data.id)
                                    }
                                    className="ms-2"
                                    disabled={shippedStatus === 0}
                                    color="success"
                                    variant="outlined"
                                  >
                                    {shippedStatus === 0
                                      ? "Package Not Shipped"
                                      : "Receive"}
                                  </Button>
                                </>
                              ) : null}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {["Megha", "Karan", "Tech", "Ankur"].some((name) =>
                userDetails.name?.includes(name)
              ) ? (
                <>
                 <div className="mt-1 d-flex justify-content-end">
                 {shippedStatus && shippedStatus == 1 ? (
                    <Button variant="outlined" size="small" disabled>
                      <FaTruck className="me-1" /> Packaged Shipped
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={handleConfirmPacking}
                      >
                        <FaTruck className="me-1" /> Confirm Shipment
                      </Button>
                    </>
                  )}
                 </div>
                </>
              ) : null}
              <div className="card p-2 responsive-mobile-table mt-1">
                {data.length > 0 ? (
                  <>
                    {data &&
                      data.map((data, index) => (
                        <>
                          <div className="product-card position-relative ">
                            <div className="py-1 px-2 w-100">
                              <h5 className="product-card-sku">{data.date}</h5>
                              <h5 className="product-card-name">
                                {data.note || "-"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span
                                  style={{
                                    color: "grey",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Transfer to:
                                </span>{" "}
                                {data.warehouse || "-"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span
                                  style={{
                                    color: "grey",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Transfer by:
                                </span>{" "}
                                {data.user_name || "-"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span
                                  style={{
                                    color: "grey",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Received by:
                                </span>{" "}
                                {data.recieved_by || "-"}
                              </h5>

                              <div className="mt-2 d-flex gap-1 justify-content-end ">
                                <Button
                                  size="small"
                                  onClick={() => handleRedirectView(index)}
                                  color="primary"
                                  variant="outlined"
                                >
                                  View
                                </Button>

                                {data.recieved_status === 1 ? (
                                  <>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="success"
                                      style={{ cursor: "default" }}
                                      className="ms-2"
                                    >
                                      <FaCheck className="me-1" /> Package
                                      Recieved
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {[
                                      "Vipin",
                                      "Gunjan",
                                      "Karan",
                                      "Tech",
                                      "Ankur",
                                    ].some((name) =>
                                      userDetails.name?.includes(name)
                                    ) ? (
                                      <>
                                        <Button
                                          size="small"
                                          onClick={() =>
                                            handleRecievePackage(data.id)
                                          }
                                          className="ms-2"
                                          disabled={shippedStatus === 0}
                                          color="success"
                                          variant="outlined"
                                        >
                                          {shippedStatus === 0
                                            ? "Package Not Shipped"
                                            : "Receive"}
                                        </Button>
                                      </>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PackedDetails;
