import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import {
  apiKey,
  fetch_all_product,
  fetch_vendor,
  create_purchase,
  create_draft_purchase,
  fetch_draft_purchase_detail,
  fetch_product_detail,
} from "../../Api";
import { MdClear } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FaTrashAlt } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useLocation } from "react-router-dom";
import AddProductModal from "../Products/AddProductModal";
import { MdDoubleArrow } from "react-icons/md";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CreateVendor from "../OurClients/Vendor/CreateVendor";
import toast, { Toaster } from "react-hot-toast";
import { Card, CardDescription, CardMeta, Label } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import CustomLoader from "../../Components/Loader";
import SubmitLoader from "../../Components/SubmitLoader";

function AddPurchase() {
  const isWideScreen = useScreenWidth();
  const location = useLocation();
  const draft = location.state?.draft;
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // const [purchase_date, setP_date] = useState("");
  const [purchase_date, setP_date] = useState(getCurrentDate());
  const [reference_no, setRef_no] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const navigate = useNavigate();
  useEffect(() => {
    fetchVendors();
  }, [searchQuery]);

  const fetchVendors = (query = searchQuery) => {
    axios
      .get(fetch_vendor, {
        params: { search: searchQuery },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        //console.log(response.data.vendors);
        setVendors(response.data.vendors);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleVendorChange = (event, newValue) => {
    const vendorPk = newValue ? newValue.id : "";
    setVendor(vendorPk);
  };

  const handleInputChange = async (event) => {
    const value = event?.target?.value || "";
    setSearchInput(value);

    if (value.trim().length >= 0) {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        // console.log(response.data.data.products);
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else {
      setProducts([]);
      setDisplay(false);
    }
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 0,
      }));
    }
  };

  // const handleQuantityChange = (event, product) => {
  //   const { value } = event.target;
  //   const quantity = parseInt(value, 10);

  //   if (quantity >= 0 || value === "") {
  //     setQuantities((prevQuantities) => ({
  //       ...prevQuantities,
  //       [product.product_sku]: quantity || 0,
  //     }));

  //     // Update quantity in the selectedProducts array as well
  //     setSelectedProducts((prevProducts) =>
  //       prevProducts.map((p) =>
  //         p.product_sku === product.product_sku
  //           ? { ...p, quantity: quantity || 0 }
  //           : p
  //       )
  //     );
  //   }
  // };

  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
    toast.success("Product removed!");
  };

  const [data, setData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setFileSelected(false);
      setFileInfo(null);
      return;
    }

    if (
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls
        "text/csv", // .csv
      ].includes(file.type)
    ) {
      setFileSelected(false);
      setFileInfo(null);
      toast.error("Only .xls or .csv file format are allowed");

      // Reset the file input field
      e.target.value = "";
      return;
    }

    setFileSelected(true);
    setFileInfo({ name: file.name, size: file.size });

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0]; // First row as headers

      // Check if the headers match "sku" and "quantity"
      const lowerCaseHeaders = headers.map(header => header.toLowerCase());

      if (
          !lowerCaseHeaders.includes("sku") || 
          !lowerCaseHeaders.includes("quantity")
      ) {
          setFileSelected(false);
          setFileInfo(null);
          toast.error("The file must contain 'sku' and 'quantity' as headers");
      
          // Reset the file input field
          e.target.value = "";
          return;
      }

      console.log(jsonData)
      
      const products = jsonData.slice(1).map((row) => {
        const product = {};
        lowerCaseHeaders.forEach((header, index) => {
            // Trim and clean the SKU value to remove spaces and tabs
            if (header === "sku") {
                product[header] = row[index]?.toString().trim().replace(/\t/g, "");
            } else {
                product[header] = row[index];
            }
        });
        return product;
    });

      setData(products);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleClearFile = () => {
    setFileSelected(false);
    setFileInfo(null);
    setData([]);
  };

  const [openImport, setOpenImport] = useState(false);
  const [openImportReport, setOpenReport] = useState(false);

  const handleClickOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };

  const handleCloseImportReport = () => {
    setOpenReport(false);
  };

  const handleOpenImportReport = () => {
    setOpenReport(true);
  };

  const [skippedProducts, setSkippedProducts] = useState([]);
  const [notFoundProducts, setNotFoundProducts] = useState([]);
  const [importedReport, setImportedReport] = useState(0);

  const fetchProduct = async () => {
    setSubmitLoader(true);
    // Temporary arrays to hold skipped and not found products
    const tempSkippedProducts = [];
    const tempNotFoundProducts = [];
    const updatedProducts = [...selectedProducts]; // Copy of selectedProducts to update
  
    for (const item of data) {
      // Check if the product SKU already exists in selectedProducts
      const existingProductIndex = updatedProducts.findIndex(
        (p) => p.product_sku == item.sku
      );
  
      if (existingProductIndex !== -1) {
        // If the product exists, sum the quantity
        updatedProducts[existingProductIndex].quantity += item.quantity; // Sum the quantities
        tempSkippedProducts.push(item.sku); // Add SKU to tempSkippedProducts if already exists
      } else {
        try {
          const response = await axios.get(fetch_product_detail, {
            params: { product_sku: item.sku },
            headers: { "API-Key": apiKey },
          });
  
          if (!response.data.product || response.data.product.length === 0) {
            tempNotFoundProducts.push(item.sku); // Add SKU to tempNotFoundProducts if not found
          } else {
            updatedProducts.push({
              ...response.data.product[0],
              quantity: item.quantity,
            });
          }
        } catch (error) {
          console.error(`Error fetching product with SKU: ${item.sku}`, error);
        }
      }
    }
  
    // Update selectedProducts state with the accumulated updatedProducts
    setSelectedProducts(updatedProducts);
  
    setSubmitLoader(false);
    // After the loop, update the state with the accumulated results
    setSkippedProducts(tempSkippedProducts);
    setNotFoundProducts(tempNotFoundProducts);
    setOpenReport(true);
    setImportedReport(1);
  };
  

  const handleConfirmImport = () => {
    fetchProduct();
    setOpenImport(false);
  };

  // Create purchase
  const handleSubmit = async () => {

    if(!vendor){
      toast.error("Please select vendor!")
      return
    }
    let isValid = true;
    const invalidProducts = [];
    setSubmitLoader(true);
    selectedProducts.forEach((product) => {
      if (
        !quantities[product.product_sku] ||
        quantities[product.product_sku] <= 0
      ) {
        isValid = false;
        invalidProducts.push(product.product_sku);
      }
    });

    if (!isValid) {
      toast.error(`Quantity can't be empty for: ${invalidProducts.join(", ")}`);
      setSubmitLoader(false);
      return;
    }

    if (!vendor) {
      toast.error("Please Select the Vendor");
      setSubmitLoader(false);
    }

    const purchaseData = {
      vendor_pk: parseInt(vendor),
      purchase_date: purchase_date,
      reference_number: reference_no || "",
      user_name: userDetails.name || "",
      products_detail: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]) || product.quantity,
      })),
      draft_id: draft || 0,
    };

    axios
      .post(create_purchase, purchaseData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setSelectedProducts([]);
          setProducts([]);
          setSearchInput("");
          setRef_no("");
          setP_date("");
          setVendor("");
          setSubmitLoader(false);
          navigate("/all_purchases");
          toast.success("Purchase created successfully!");
        } else {
          setSubmitLoader(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setSubmitLoader(false);
        toast.error(error.response.data.message);
      });
  };

  // ==== Create Draft ====
  const createDraft = async () => {
    if(!vendor){
      toast.error("Please select vendor!")
      return
    }

    setSubmitLoader(true);
    selectedProducts.forEach((product) => {
      if (
        !quantities[product.product_sku] ||
        quantities[product.product_sku] <= 0
      ) {
        // isValid = false;
        quantities[product.product_sku] = 0;
        // invalidProducts.push(product.product_name);
      }
    });

    const draftPurchaseData = {
      vendor_pk: parseInt(vendor),
      purchase_date: purchase_date,
      reference_number: reference_no || "",
      user_name: userDetails.name || "",
      products_detail: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]) || product.quantity,
      })),
      draft_id: draft || 0,
    };

    try {
      axios
        .post(create_draft_purchase, draftPurchaseData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            setSelectedProducts([]);
            setProducts([]);
            setSearchInput("");
            setRef_no("");
            setP_date("");
            setVendor("");
            setSubmitLoader(false);
            toast.success("Draft created successfully!");
            navigate("/all-draft-purchase");
          } else {
            setSubmitLoader(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitLoader(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      setSubmitLoader(false);
      console.log("error While Creating Draft :", error.response);
    }
  };

  // Add Product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  // Function to open the modal
  const handleAddProduct = () => {
    setAddProductModalOpen(true);
  };

  // Function to close the modal
  const handleCloseProductAddModal = () => {
    setAddProductModalOpen(false);
  };

  useEffect(() => {
    if (draft) {
      const fetchDraftData = async () => {
        try {
          const response = await axios.get(fetch_draft_purchase_detail, {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
            params: {
              draft_purchase_pk: parseInt(draft),
            },
          });

          setSelectedProducts(response.data.purchase_detail.items_detail || []);
          setP_date(response.data.purchase_detail.purchase_date || "");
          setRef_no(response.data.purchase_detail.reference_number || "");

          // setQuantities
          const quantities = response.data.purchase_detail.items_detail.reduce(
            (acc, product) => {
              // console.log("acc : ", acc);
              acc[product.product_sku] = product.quantity;
              return acc;
            },
            {}
          );

          setQuantities(quantities);

          // Set vendor if vendor_ref exists
          const vendorId = response.data.purchase_detail.vendor_ref?.id;
          if (vendorId) {
            setVendor(vendorId);
          }
        } catch (err) {
          console.log("Error : ", err);
        }
      };
      fetchDraftData();
    }
  }, []);

  const handleFocus = () => {
    handleInputChange();
    setDisplay(true);
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  const totalQuantity = selectedProducts.reduce((sum, product) => sum + product.quantity, 0);




  return (
    <>
      <CustomLoader loading={loading} />
      {submitLoader && <SubmitLoader />}
      <Toaster position="top-right" />

      <CreateVendor
        openvendor={openvendor}
        handleOpenVendor={handleOpenVendor}
        handleCloseVendor={handleCloseVendor}
        fetchVendors={fetchVendors}
        setVendor={setVendor}
      />

      <AddProductModal
        open={addProductModalOpen}
        handleClose={handleCloseProductAddModal}
        handleCheckboxChange={handleCheckboxChange}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_purchases")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Add Purchase
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row ">
            <div className="col-lg-9">
              <div className="card p-3 my-1">
                <div className="row">
                  <div className="col-lg-4 my-2" style={{ display: "flex" }}>
                    <FormControl fullWidth size="small" required>
                      <Autocomplete
                        value={vendors.find((ven) => ven.id === vendor) || null}
                        onChange={handleVendorChange}
                        inputValue={searchQuery}
                        onInputChange={(event, newInputValue) => {
                          setSearchQuery(newInputValue);
                        }}
                        options={vendors}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Vendor"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </FormControl>

                    <Button
                      variant="outlined"
                      style={{ marginLeft: "2px", maxHeight: "37px" }}
                      onClick={handleOpenVendor}
                      size="small"
                    >
                      Add
                    </Button>
                  </div>

                  <div className="col-lg-2 my-2">
                    <TextField
                      id="outlined-date"
                      type="date"
                      value={purchase_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setP_date(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-3 my-2">
                    <TextField
                      label="Reference No."
                      id="outlined-ref"
                      value={reference_no}
                      size="small"
                      fullWidth
                      onChange={(e) => setRef_no(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-3 my-2"
                    style={{
                      // display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    <Card
                      style={{
                        padding: "5px 10px",
                        width: "100%",
                      }}
                    >
                      <CardMeta>
                        Bill to:{" "}
                        <b style={{ color: "black" }}>Kuber Turbo Tech</b>
                      </CardMeta>

                      <CardDescription>Delhi, IN</CardDescription>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 order-2 order-lg-1">
              <div className="card p-3 my-1">
                <div className="row">
                  {/* <div className="col-6 my-2">Total Products</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    {selectedProducts.length}
                  </div> */}
                  <div className="container my-3" style={{ display: "flex" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      style={{ width: "40%" }}
                      onClick={createDraft}
                      disabled={selectedProducts.length < 1}
                    >
                      {draft && draft ? "Save Draft" : "Draft"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{ width: "60%", marginLeft: "5px" }}
                      onClick={handleSubmit}
                      disabled={selectedProducts.length < 1}
                    >
                      Confirm Purchase
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 order-1 order-lg-2">
              <div className="card p-3 my-2">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <TextField
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        value={searchInput}
                        fullWidth
                        size="small"
                        id="search-products"
                        label="Search Products..."
                        variant="outlined"
                        autoComplete="off"
                      />
                      {displaySearch ? (
                        <>
                          <div
                            id="searchList"
                            ref={searchListRef}
                            style={{
                              border: "1px solid #dedede",
                              position: "absolute",
                              zIndex: "2",
                              marginTop: "3px",
                              maxHeight: "50vh",
                              overflowY: "scroll",
                              maxWidth: "500px",
                              backgroundColor: "white",
                              width: "92.4%",
                            }}
                          >
                            {products.map((product, index) => (
                              <>
                                <div
                                  className="card p-3"
                                  style={{ display: "flex" }}
                                  key={index}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isProductSelected(product)}
                                        onChange={() =>
                                          handleCheckboxChange(product)
                                        }
                                        name="check"
                                      />
                                    }
                                    label={
                                      product.product_sku +
                                      " : " +
                                      product.product_name
                                    }
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        style={{
                          marginLeft: "2px",
                          width: "100%",
                          height: "37px",
                          minWidth: "100px",
                        }}
                        size="small"
                        onClick={handleAddProduct}
                      >
                        Add Product
                      </Button>
                    </div>
                    <Button
                      variant="outlined"
                      color="success"
                      style={{ marginLeft: "5px", maxHeight: "37px" }}
                      onClick={handleClickOpenImport}
                      size="small"
                    >
                      Import Products
                    </Button>

                    {importedReport === 1 ? (
                      <>
                        <Button
                          variant="outlined"
                          color="error"
                          style={{ marginLeft: "5px", maxHeight: "37px" }}
                          onClick={handleOpenImportReport}
                          size="small"
                        >
                          Report
                        </Button>
                      </>
                    ) : null}
                  </div>
                  <div className="mb_btn_hide">
                     Products: <Label>{selectedProducts.length}</Label> | 
                     Quantity: <Label color="orange">{totalQuantity}</Label>
                  </div>
                </div>
                {isWideScreen ? (
                  <>
                    <div className="products_add_adjustment my-2">
                      <table className="ui table celled " id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>#</th>
                            <th>SKU</th>
                            <th>Product</th>
                            <th>Part No.</th>
                            <th>Price/MRP</th>
                            <th>Quantity</th>
                            <th
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts.length > 0 ? (
                            <>
                              {selectedProducts &&
                                selectedProducts.map((data, index) => (
                                  <>
                                    <tr>
                                      <td style={{ width: "50px" }}>
                                        {index + 1}
                                      </td>
                                      <td style={{ width: "120px" }}>
                                        {data.product_sku}
                                      </td>
                                      <td>{data.product_name || "-"}</td>
                                      <td style={{ maxWidth: "190px" }}>
                                        {data.part_number || "-"}
                                      </td>

                                      <td style={{ maxWidth: "190px" }}>
                                        <b>{formatIndianRupee(data.mrp)}</b>
                                      </td>

                                      <td
                                        style={{
                                          textAlign: "center",
                                          width: "150px",
                                        }}
                                      >
                                        <TextField
                                          label="Qty"
                                          size="small"
                                          value={
                                            quantities[data.product_sku] ||
                                            data.quantity
                                          }
                                          onChange={(e) =>
                                            handleQuantityChange(e, data)
                                          }
                                          variant="outlined"
                                        />
                                      </td>

                                      <td
                                        style={{
                                          textAlign: "center",
                                          width: "60px",
                                        }}
                                      >
                                        <FaTrashAlt
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDeleteProduct(
                                              data.product_sku
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan="7">
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="card p-2 responsive-mobile-table my-2"
                      style={{
                        border: "1px solid #dedede",
                        borderRadius: "5px",
                      }}
                    >
                      {selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts &&
                            selectedProducts.map((data) => (
                              <>
                                <div className="product-card">
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      #{data.product_sku}
                                    </h5>
                                    <h5 className="product-card-name">
                                      {data.product_name}
                                    </h5>
                                    <h5 className="product-card-mrp my-1">
                                      <span style={{ color: "grey" }}>
                                        Part No.:
                                      </span>{" "}
                                      <b>{data.part_number}</b>
                                    </h5>

                                    <TextField
                                      style={{
                                        fontSize: "11px",
                                        width: "90px",
                                      }}
                                      label="Qty"
                                      size="small"
                                      value={quantities[data.product_sku] || ""}
                                      onChange={(e) =>
                                        handleQuantityChange(e, data)
                                      }
                                      variant="outlined"
                                    />

                                    <FaTrashAlt
                                      onClick={() =>
                                        handleDeleteProduct(data.product_sku)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "25px",
                                        marginTop: "10px",
                                        position: "absolute",
                                        right: "15px",
                                        border: "1px solid red",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openImport}
        onClose={handleCloseImport}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Import Products</DialogTitle>

        <DialogContent dividers>
          <Box mb={2}>
            <Typography
              style={{ color: "grey", fontSize: "13px" }}
              sx={{ mb: 2 }}
            >
              Select a file to import products. Only .xls or .csv formats are
              allowed.{" "}
              {/* <a href={DownloadSample} download={DownloadSample}>
                Download Sample
              </a> */}
            </Typography>

            <TextField
              type="file"
              style={{ cursor: "pointer" }}
              onChange={handleFileChange}
              required
              accept=".xls,.csv"
              fullWidth
              variant="outlined"
            />

            {fileInfo && (
              <div className="card p-2">
                <Typography sx={{ mt: 1 }}>
                  File selected: {fileInfo.name} (
                  {(fileInfo.size / 1024).toFixed(2)} KB)
                </Typography>{" "}
                <MdClear
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "20px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFile}
                />
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImport} variant="outlined" color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmImport} variant="outlined">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* ///////////Import Report /////////// */}
      <Dialog
        open={openImportReport}
        onClose={handleCloseImport}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Import Report</DialogTitle>

        <DialogContent dividers>
          <Box mb={2}>
            <Typography
              style={{ color: "grey", fontSize: "14px", color: "red" }}
            >
              <MdDoubleArrow style={{ marginTop: "-3px" }} /> Duplicate
              Products:
            </Typography>

            <h4 className="m-0">
              {skippedProducts && skippedProducts.length > 0 ? (
                skippedProducts.join(", ")
              ) : (
                <span
                  style={{
                    textAlign: "center",
                    color: "grey",
                    fontSize: "0.9rem",
                    fontWeight: "100",
                  }}
                >
                  No Products
                </span>
              )}
            </h4>

            <Typography
              style={{
                color: "grey",
                fontSize: "14px",
                marginTop: "15px",
                color: "red",
              }}
            >
              <MdDoubleArrow style={{ marginTop: "-3px" }} /> No Found Products:
            </Typography>

            <h4 className="m-0">
              {" "}
              {notFoundProducts && notFoundProducts.length > 0 ? (
                notFoundProducts.join(", ")
              ) : (
                <span
                  style={{
                    textAlign: "center",
                    color: "grey",
                    fontSize: "0.9rem",
                    fontWeight: "100",
                  }}
                >
                  No Products
                </span>
              )}
            </h4>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseImportReport}
            variant="outlined"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddPurchase;
