import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Breadcrumbs, CircularProgress, Autocomplete, TextField } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { apiKey, fetch_racks, fetch_rows } from '../../Api';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjust as necessary
    maxWidth: "600px", // Adjust as necessary
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const AllocateProductInRowRack = ({ open, onClose, product, warehouseId }) => {
    const [rows, setRows] = useState([]);
    const [racks, setRacks] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRack, setSelectedRack] = useState(null);
    const [loading, setLoading] = useState({ rows: false, racks: false });




    useEffect(() => {
        if (warehouseId) {
            // fetchRows(warehouseId);
        }
    }, [warehouseId]);


    const fetchRows = async (warehouseId) => {
        setLoading((prev) => ({ ...prev, rows: true }));
        try {
            const response = await axios.get(fetch_rows, {
                params: { warehouse_id: warehouseId },
               headers: { "API-Key": apiKey },
            });
            setRows(response.data.rows);
        } catch (error) {
            toast.error("Failed to fetch rows.");
        } finally {
            setLoading((prev) => ({ ...prev, rows: false }));
        }
    };

    const fetchRacks = async (rowId) => {
        setLoading((prev) => ({ ...prev, racks: true }));
        try {
            const response = await axios.get(fetch_racks, {
                params: { row_pk: rowId },
                 headers: { "API-Key": apiKey },
            });
            setRacks(response.data.racks);
        } catch (error) {
            toast.error("Failed to fetch racks.");
        } finally {
            setLoading((prev) => ({ ...prev, racks: false }));
        }
    };

    const handleAllocate = () => {
        // Implement your allocate logic here
        toast.success("Product allocated successfully!");
        // onClose();
    };

    return (
        <>
            <Toaster position="top-right" />
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="inherit">Allocate Product in Row/Rack</Typography>
                        <Typography color="inherit">{product?.product_sku}</Typography>
                    </Breadcrumbs>
                    <IoClose
                        className="closeModalBtn"
                        onClick={onClose}
                    />

                    <div className="row mt-3">
                        <div className="col-md-6 mb-1">
                            {loading.rows ? (
                                <CircularProgress />
                            ) : (
                                <Autocomplete
                                    value={selectedRow}
                                    onChange={(event, newValue) => {
                                        setSelectedRow(newValue);
                                        fetchRacks(newValue?.id);
                                    }}
                                    options={rows}
                                    getOptionLabel={(option) => option.row_name}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => <TextField {...params} label="Select Row" />}
                                    disabled={rows.length === 0}
                                />
                            )}
                        </div>
                        <div className="col-md-6 mb-1">
                            {loading.racks ? (
                                <CircularProgress />
                            ) : (
                                <Autocomplete
                                    value={selectedRack}
                                    onChange={(event, newValue) => setSelectedRack(newValue)}
                                    options={racks}
                                    getOptionLabel={(option) => option.rack_name}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => <TextField {...params} label="Select Rack" />}
                                    disabled={!selectedRow || racks.length === 0}
                                />
                            )}
                        </div>
                    </div>

                    <Box mt={3} display="flex" justifyContent="flex-end">
                        <Button onClick={onClose} color="error" variant="outlined" sx={{ mr: 2 }}>Cancel</Button>
                        <Button onClick={handleAllocate} color="primary" variant="contained">Allocate</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AllocateProductInRowRack;
