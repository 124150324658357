import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, switchWarehouse } from "../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import toast, { Toaster } from "react-hot-toast";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

function SwitchWarehouse({
  setOpenModalWarehouse,
  openModalWarehouse,
  warehouses,
}) {
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [primarywarehouse, setPrimaryWare] = useState(
    userDetails.primary_warehouse_pk || ""
  );
  const handleClose = () => {
    setOpenModalWarehouse(false);
  };

  const handleChange = (e) => {
    e.preventDefault();

    const formattedData = {
      user_pk: parseInt(userDetails.user_pk),
      warehouse_pk: primarywarehouse,
    };

    axios
      .post(switchWarehouse, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.status === 1) {
          toast.success("Primary Warehouse Changed Successfully!");

          // Update userDetails with the new primary warehouse
          userDetails.primary_warehouse_pk = primarywarehouse;

          // Convert the updated object back to JSON
          const updatedUserDetails = JSON.stringify(userDetails);

          // Save the updated object back to localStorage
          localStorage.setItem("ktt_users", updatedUserDetails);

          setOpenModalWarehouse(false);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
      });
  };

  return (
    <>
      <Toaster position="top-right" />

      <Dialog
        open={openModalWarehouse}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Switch Primary Warehouse
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <FormControl size="small" fullWidth>
              <InputLabel>Warehouse</InputLabel>
              <Select
                value={primarywarehouse}
                label="Warehouse"
                onChange={(e) => setPrimaryWare(e.target.value)}
              >
                {warehouses.map((ware) => (
                  <MenuItem value={ware.id}>{ware.ware_house}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="ms-2"
            variant="outlined"
            color="primary"
            onClick={handleChange}
            // disabled={
            //   Boolean(error) ||
            //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
            // }
          >
           Save Warehouse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SwitchWarehouse;
