import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";
import { apiKey, delete_customers } from "../../../Api";

import toast, { Toaster } from 'react-hot-toast';

const style = {
  width: 550,
};

function DeleteCustomer({ openDelete, setOpenDelete, selectedPks, fetchData }) {
  const handleClose = () => {
    setOpenDelete(false);
  };

  const [existingCustumersListError, setexistingCustumersListError] = useState([]);


  const handleDelete = () => {
    axios
      .delete(delete_customers, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          customer_pks: selectedPks,
        },
      })
      .then(() => {
        toast.success(`Selected Customers Deleted Successfully!`);
        setOpenDelete(false);
        fetchData();
      })
      .catch((error) => {

        if (error.response?.data?.existing_custumers_list) {
          const existingCustomers = error.response.data.existing_custumers_list.map(
            ([id, name]) => `${name} (ID: ${id})`
          ).join(", ");
          toast.error(`These Customers cannot be deleted because they have associated purchase data: ${existingCustomers}`);
          setexistingCustumersListError(existingCustomers);
        } else {
          toast.error(error.response?.data?.message);
          console.log("Else Wala Error : ",error)
        }

        console.error("Failed to delete Customers", error.response);
        console.error("Purchase Error: ", error.response?.data?.existing_custumers_list);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteCustomer;
