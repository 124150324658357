import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_sale_product,
  fetch_customer,
  create_sale,
  getLastPrice,
  fetch_customer_address,
} from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FaTrashAlt } from "react-icons/fa";
import CreateCustomer from "../../OurClients/Clients/CreateCustomer";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";
import Typography from "@mui/material/Typography";
import { Popup, TextArea } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Autocomplete } from "@mui/material";
import AddProductModal from "../../Products/AddProductModal";
import { useScreenWidth } from "../../../useScreenWidth";
import toast, { Toaster } from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaSitemap } from "react-icons/fa";
import CustomLoader from "../../../Components/Loader";

function AddPartySale() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const isWideScreen = useScreenWidth();
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
   const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [last_price, setLast_price] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [customeraddress, setCustomeraddress] = useState([]);
  const [address, setAddress] = useState("");
  const [discount_amount, setDiscount] = useState("");
  const [sale_date, setS_date] = useState(getCurrentDate());
  const [other_charge, setOtherCharge] = useState("");
  const [shipping_charge, setShippingCharge] = useState("");
  const [notes, setNotes] = useState("");

  const [percentage, setPercentage] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [discounts, setDiscounts] = useState({});
  const [numericDiscounts, setNumericDiscounts] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    fetchCustomers();
  }, [searchquery]);

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.post(
          fetch_sale_product,
          { search: value, customer_pk: parseInt(customer) || 0 },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );

        const fetchedProducts = response.data.data || [];
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer] // Dependency array
  );

  // Handle input change, using debounceFetchProducts
  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const handleFocus = () => {
    debounceFetchProducts();
    setDisplay(true);
  };

  const handleCustomerChange = (e, newValue) => {
    const customer_pk = newValue ? newValue.id : "";
    setCustomer(customer_pk);

    // Create an array of SKUs from selected products
    const skus = selectedProducts.map((product) => product.product_sku);

    // Fetch customer address
    axios
      .get(fetch_customer_address, {
        headers: {
          "API-Key": apiKey,
        },
        params: { customer_pk },
      })
      .then((response) => {
        setCustomeraddress(response.data.address);
      })
      .catch((error) =>
        console.error("Error fetching customer address:", error)
      );

    // Fetch updated prices and recommended discounts for the selected products
    axios
      .post(
        getLastPrice,
        { customer_pk, skus },
        {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        // Extract the prices and recommended discounts from response
        const lastMrpsData = response.data.prices;
        const lastMrpsDataObject = lastMrpsData.reduce((acc, item) => {
          acc[item.sku] = {
            last_price: item.last_price,
            recommended_discount: item.recomended_discount,
          };
          return acc;
        }, {});

        // Update scannedProducts with the new last_price and recommended_discount values
        const updatedProducts = selectedProducts.map((product) => {
          const skuData = lastMrpsDataObject[product.product_sku];

          // Calculate the new recommended discount and MRP
          if (skuData) {
            const originalMRP = product.mrp;
            const percentageValue = skuData.recommended_discount;
            const discountValue = (originalMRP * percentageValue) / 100;
            const newMRP = parseFloat(originalMRP - discountValue, 0);

            // Update state with the calculated values
            setDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [product.product_sku]: `${percentageValue}`, // Set the percentage as a string
            }));

            setNumericDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [product.product_sku]: discountValue.toFixed(2), // Set the discount value
            }));
            setMrps((prevMrps) => ({
              ...prevMrps,
              [product.product_sku]: newMRP, // Set the discount value
            }));

            return {
              ...product,
              last_price: skuData.last_price,
            };
          }

          return {
            ...product,
            last_price: product.last_price,
          };
        });

        // Update the selectedProducts state with new prices and discounts
        setSelectedProducts(updatedProducts);
      })
      .catch((error) => console.error("Error fetching updated prices:", error));
  };

  // const handleCheckboxChange = (product) => {
  //   setSelectedProducts((prevSelected) => {
  //     const isSelected = prevSelected.some(
  //       (p) => p.product_sku === product.product_sku
  //     );

  //     if (isSelected) {
  //       // Remove product from selectedProducts
  //       return prevSelected.filter(
  //         (p) => p.product_sku !== product.product_sku
  //       );
  //     } else {
  //       // Add product to selectedProducts
  //       setLast_price((prevLastMrps) => ({
  //         ...prevLastMrps,
  //         [product.product_sku]: product.last_price,
  //       }));

  //       return [...prevSelected, product];

  //     }
  //   });
  // };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (p) => p.product_sku === product.product_sku
      );

      if (isSelected) {
        // Remove product from selectedProducts
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        // Add product to selectedProducts
        setLast_price((prevLastMrps) => ({
          ...prevLastMrps,
          [product.product_sku]: product.last_price,
        }));

        // Automatically set discount if available and original MRP is greater than 0
        const originalMRP = product.mrp;

        if (product.recomended_discount && originalMRP > 0) {
          const percentageValue = product.recomended_discount; // Get the recommended discount
          const discountValue = (originalMRP * percentageValue) / 100; // Calculate discount value
          const newMRP = parseFloat(originalMRP - discountValue, 0); // Calculate new MRP after discount

          // Update state with the calculated values
          setDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: `${percentageValue}`, // Set the percentage as a string
          }));

          setNumericDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: discountValue.toFixed(2), // Set the discount value
          }));

          setMrps((prevMrps) => ({
            ...prevMrps,
            [product.product_sku]: newMRP, // Set the new MRP
          }));
        }

        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 1,
      }));
    }
  };

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  // Add Product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  // Function to open the modal
  const handleAddProductModal = () => {
    setAddProductModalOpen(true);
  };

  // Function to close the modal
  const handleCloseProductAddModal = () => {
    setAddProductModalOpen(false);
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const handleDiscountChange = (e, product, type) => {
    const { value } = e.target;
    const sku = product.product_sku;
    const originalMRP = parseFloat(product.mrp);
    let newMRP;

    const parseValue = (val) => parseFloat(val) || "";

    if (type === "percentage") {
      const percentageValue = parseValue(value);
      if (percentageValue < 0 || percentageValue > 100) {
        return;
      }
      const discountValue = (originalMRP * percentageValue) / 100;
      newMRP = originalMRP - discountValue;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${percentageValue}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: discountValue,
      }));
    } else if (type === "numeric") {
      const numericValue = parseValue(value);
      if (numericValue < 0) {
        return;
      }
      newMRP = originalMRP - numericValue;

      const percentageValue = (numericValue / originalMRP) * 100;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${parseFloat(percentageValue)}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: numericValue,
      }));
    }

    newMRP = parseFloat(newMRP);

    setMrps((prevMrps) => ({
      ...prevMrps,
      [sku]: newMRP,
    }));
  };

  const handleMRPChange = (event, product) => {
    const { value, key } = event.target;
    let updatedMRP = parseFloat(value) || 0;

    // Check if the key pressed is backspace and if the value is empty
    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value === ""
    ) {
      updatedMRP = ""; // Set updated MRP to an empty string
    } else {
      updatedMRP = parseFloat(value) || 0;
    }

    const originalMRP = parseFloat(product.mrp);
    const discount = parseFloat(discounts[product.product_sku]) || 0;
    const newMRP = updatedMRP === "" ? "" : updatedMRP;

    // Update the MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: newMRP,
    }));

    // Handle flat discount
    const flatDiscount = parseFloat(discount) || 0;
    const newNumericDiscount = flatDiscount;
    const discountedPrice =
      newMRP === "" ? 0 : parseFloat(newMRP - newNumericDiscount);

    // Calculate the discount based on the original MRP
    const calculatedPercentageDiscount =
      newMRP === "" ? 0 : ((originalMRP - newMRP) / originalMRP) * 100 || 0;

    let calculatedNumericDiscount = 0;

    if (originalMRP > 0) {
      calculatedNumericDiscount = newMRP === "" ? 0 : originalMRP - newMRP || 0;
    }

    let discountratenumeric = parseFloat(calculatedNumericDiscount);
    let discountrate = parseFloat(calculatedPercentageDiscount);

    discountrate = isNaN(discountrate) ? "0" : discountrate;
    discountratenumeric = isNaN(discountratenumeric) ? 0 : discountratenumeric;

    // Update the state with the recalculated discounts
    setNumericDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: parseFloat(discountratenumeric),
    }));

    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: parseFloat(discountrate),
    }));

    return discountedPrice;
  };

  const calculateProductTotal = (product) => {
    const quantity = quantities[product.product_sku] || 1;
    const mrp = mrps[product.product_sku] ?? product.mrp;

    if (mrp <= 0) {
      return 0;
    }

    // Retrieve the discount value and check if it contains '%'

    return parseFloat(mrp * quantity).toFixed(2);
  };

  const calculateTotalMRP = () => {
    const totalProductMRP = selectedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] ?? product.mrp;
      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const otherCharge = parseFloat(other_charge) || 0;
    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalCharges = otherCharge + shippingCharge;
    const totalMRP = totalProductMRP + totalCharges - discountAmount;

    return totalMRP;
  };

  const calculateTotalChangeMRP = () => {
    const totalProductMRP = selectedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] ?? product.mrp;
      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const totalSubTotal = totalProductMRP;
    return totalSubTotal;
  };

  const [totalMRP, setTotalMRP] = useState(0);

  useEffect(() => {
    const totalMRP = calculateTotalChangeMRP();

    const newOtherCharge = (percentage / 100) * totalMRP;
    setOtherCharge(newOtherCharge);
  }, [
    shipping_charge,
    discount_amount,
    percentage,
    selectedProducts,
    mrps,
    quantities,
  ]);

  useEffect(() => {
    const totalProductMRP = calculateTotalChangeMRP();

    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalMRP = totalProductMRP + shippingCharge - discountAmount;
    const totalMRP2 = totalProductMRP;
    const finalTotalMRP = Math.max(0, parseFloat(totalMRP)); // Ensure the total MRP is not negative
    const finalTotalMRP2 = Math.max(0, parseFloat(totalMRP2)); // Ensure the total MRP is not negative

    if (finalTotalMRP === 0 || finalTotalMRP2 === 0) {
      setPercentage(0);
    }

    setTotalMRP(finalTotalMRP2); // Assuming you have a state for totalMRP
  }, [selectedProducts, mrps, quantities, shipping_charge, discount_amount]);

  useEffect(() => {
    const newOtherCharge = (percentage / 100) * totalMRP;
    setOtherCharge(newOtherCharge);
  }, [percentage, totalMRP]);

  const handleOtherChargeChange = (e) => {
    const newOtherCharge = parseFloat(e.target.value);
    const floatnewotherCharge = newOtherCharge??newOtherCharge.toFixed(2)
    setOtherCharge(floatnewotherCharge);
    setPercentage((floatnewotherCharge / totalMRP) * 100);
  };

  const handlePercentageChange = (e) => {
    let newPercentage = parseFloat(e?.target?.value);
    if (newPercentage > 99) {
      newPercentage = 99;
    }

    let floatnewPercentage = newPercentage??newPercentage.toFixed(2)
    setPercentage(floatnewPercentage);
    const totalCal = (floatnewPercentage / 100) * totalMRP;
    setOtherCharge(totalCal);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(1);
    if (!sale_date || !customer) {
      toast.error("Customer & Sale Date cannot be empty!");
      setLoading(false);
      setDisabled(0);
      return;
    }

    const saleData = {
      customer_pk: parseInt(customer) || 0,
      sale_date: sale_date,
      other_charge: other_charge || 0,
      courier_charge: shipping_charge || 0,
      // shipping_pk: parseInt(address),
      note: notes || "",
      sale_type: "party",
      discount_amount: discount_amount
        ? parseFloat(discount_amount).toFixed(2)
        : 0,
      user_name: userDetails.name || "",
      products: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]) || 1,
        mrp: mrps[product.product_sku] || product.mrp,
        item_discount:
          product.mrp > 0 &&
          !isNaN(parseFloat(numericDiscounts[product.product_sku]))
            ? parseFloat(numericDiscounts[product.product_sku]).toFixed(2)
            : "0.00",
      })),
    };

    console.log(saleData);
    axios
      .post(create_sale, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setSelectedProducts([]);
          setProducts([]);
          setDiscount("0.00");
          setSearchInput("");
          setS_date("");
          setCustomer("");
          navigate("/all_sales");
          setLoading(false);
          setDisabled(0);
          toast.success("Party Sale created successfully!");
        } else {
          toast.error(response.data.message);
          setLoading(false);
          setDisabled(0);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
        setDisabled(0);
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const calculateTotalMRP = () => {
  //   // Replace this with your actual total MRP calculation
  //   return 100; // Example total MRP value
  // };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const handleLastPriceClick = (data) => {
    if (data.last_price !== 0) {
      // Update the mrps state with the last_price
      const updatedMrps = { ...mrps, [data.product_sku]: data.last_price };
      setMrps(updatedMrps);

      // Calculate the discount percentage and value
      const originalMRP = data.mrp;
      const lastPrice = data.last_price;
      const discountPercentage =
        ((originalMRP - lastPrice) / originalMRP) * 100;
      const discountValue = originalMRP - lastPrice;

      // Update discounts and numericDiscounts
      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [data.product_sku]: `${discountPercentage.toFixed(2)}`, // Discount as percentage
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [data.product_sku]: discountValue.toFixed(2), // Discount as a value
      }));
    }
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  return (
    <>
      <Toaster position="top-right" />
      <CustomLoader loading={loading} />
      <AddProductModal
        open={addProductModalOpen}
        handleClose={handleCloseProductAddModal}
        handleCheckboxChange={handleCheckboxChange}
      />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
        setCustomer={setCustomer}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                Sales
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                Create Party Sale
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-3">
                <div className="row">
                  <div className="col-lg-4 my-2">
                    <div style={{ display: "flex" }}>
                      <FormControl fullWidth size="small" required>
                        <Autocomplete
                          value={
                            customers.find((cust) => cust.id === customer) ||
                            null
                          }
                          onChange={handleCustomerChange}
                          inputValue={searchquery}
                          onInputChange={(event, newInputValue) => {
                            setSearchQuery(newInputValue);
                          }}
                          options={customers}
                          getOptionLabel={(option) =>
                            option.customer_name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Customer"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </FormControl>
                      <Button
                        variant="outlined"
                        style={{ marginLeft: "5px", height: "37px" }}
                        onClick={handleOpencustomer}
                        size="small"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="col-lg-2 my-2">
                    <TextField
                      type="date"
                      value={sale_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setS_date(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 my-1">
                    <div className="row">
                      <div className="col-lg-9 my-1">
                        <TextField
                          onChange={handleInputChange}
                          onFocus={handleFocus}
                          value={searchInput}
                          fullWidth
                          size="small"
                          id="search-products"
                          label="Search Products"
                          variant="outlined"
                        />
                        {displaySearch ? (
                          <>
                            <div
                              id="searchList"
                              ref={searchListRef}
                              style={{
                                border: "1px solid #dedede",
                                position: "absolute",
                                zIndex: "3",
                                maxHeight: "450px",
                                maxWidth: "430px",
                                overflowY: "scroll",
                                backgroundColor: "white",
                                width: "92%",
                                marginTop: "0.15rem",
                                borderRadius: "5px",
                              }}
                            >
                              {products.map((product, index) => (
                                <>
                                  <div
                                    className="card p-3"
                                    style={{ display: "flex" }}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isProductSelected(product)}
                                          onChange={() =>
                                            handleCheckboxChange(product)
                                          }
                                          name="check"
                                        />
                                      }
                                      label={
                                        product.product_sku +
                                        " : " +
                                        product.product_name
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="col-lg-3 my-1">
                        <Button
                          variant="outlined"
                          style={{
                            marginLeft: "2px",
                            width: "100%",
                            height: "37px",
                          }}
                          size="small"
                          onClick={handleAddProductModal}
                        >
                          Add Product
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isWideScreen ? (
                <>
                  <div className="responsive-table1 mt-2">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th align="left">Product</th>
                          <th align="left">MRP</th>
                          <th align="left">Avail Qty</th>
                          <th align="left">Discount</th>
                          <th align="left">Price</th>
                          <th align="left">Quantity</th>
                          <th align="right">Total</th>
                          <th align="right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td style={{ width: "90px" }}>
                                  {product.product_sku}
                                </td>
                                <td
                                  className="product_name_class"
                                  align="left"
                                  style={{
                                    maxWidth: "540px",
                                  }}
                                >
                                  <b>{product.part_number}</b>
                                  <br />
                                  {product.product_name || ""}
                                </td>

                                <td align="left">
                                  {/* ₹{product.mrp}.00 */}
                                  {formatCurrency(product.mrp)}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    textAlign: "center",
                                    color:
                                      product.in_stock < 1 ? "red" : "green",
                                  }}
                                >
                                  {product.in_stock || 0}
                                </td>

                                <td align="left" style={{ width: "10vw" }}>
                                  <div style={{ display: "flex" }}>
                                    <OutlinedInput
                                      disabled={product.mrp == 0}
                                      size="small"
                                      style={{ width: "5vw" }}
                                      name="discountPercentage"
                                      type="number"
                                      value={
                                        discounts[product.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      disabled={product.mrp == 0}
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      name="discountValue"
                                      type="number"
                                      value={
                                        numericDiscounts[product.product_sku] ||
                                        0
                                      }
                                      // inputProps={{
                                      //   max: product.mrp,
                                      // }}
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "numeric"
                                        )
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                    />
                                  </div>
                                </td>

                                <td align="left" style={{ width: "9vw" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      type="number"
                                      value={
                                        mrps[product.product_sku] ??
                                        product.mrp ??
                                        0
                                      }
                                      // value={
                                      //   mrps[product.product_sku] || product.mrp || 0
                                      // }
                                      onChange={(e) =>
                                        handleMRPChange(e, product)
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      // endAdornment={
                                      //   <InputAdornment position="end">
                                      //     .00
                                      //   </InputAdornment>
                                      // }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {product?.last_price != 0 &&
                                    product.last_price != undefined &&
                                    product?.last_price != product.mrp &&
                                    product?.last_price !==
                                      mrps[product.product_sku] ? (
                                      <>
                                        <Popup
                                          style={{
                                            zIndex: "2",
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleLastPriceClick(product)
                                          }
                                          on="click"
                                          pinned
                                          content={`₹${product.last_price}.00`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </td>

                                <td align="right" style={{ width: "80px" }}>
                                  <TextField
                                    type="number"
                                    size="small"
                                    value={quantities[product.product_sku] || 1}
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    variant="outlined"
                                  />
                                </td>

                                <td
                                  align="right"
                                  style={{ width: "5vw", fontWeight: "bold" }}
                                >
                                  {formatCurrency(
                                    calculateProductTotal(product)
                                  )}
                                </td>
                                <td align="center" style={{ width: "2vw" }}>
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      handleDeleteProduct(product.product_sku)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="9">
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table mt-2">
                    <div
                      style={{
                        position: "sticky",
                        top: "-7px",
                        zIndex: "2",
                        padding: "5px",
                        backgroundColor: "white",
                        borderBottom: "1px",
                      }}
                    >
                      <h5>
                        <FaSitemap id="backbtn" />
                        Sale Products
                      </h5>
                    </div>

                    {selectedProducts.length > 0 ? (
                      <>
                        {selectedProducts &&
                          selectedProducts.map((data) => (
                            <>
                              <div
                                className="product-card"
                                style={{
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_sku || ""}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.product_name || ""}
                                  </h5>
                                  <h5 className="product-card-mrp">
                                    <span>
                                      {formatIndianRupee(data.mrp || "N/A")}
                                    </span>{" "}
                                    |{" "}
                                    <span style={{ color: "grey" }}>
                                      {data.in_stock || 0} AVAIL QTY
                                    </span>{" "}
                                  </h5>

                                  <label
                                    style={{
                                      fontSize: "10px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Discounts
                                  </label>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "-4px",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{ width: "40%" }}
                                      name="discountPercentage"
                                      type="number"
                                      value={discounts[data.product_sku] || 0}
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          data,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "5px",
                                        width: "60%",
                                      }}
                                      name="discountValue"
                                      type="number"
                                      value={
                                        numericDiscounts[data.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(e, data, "numeric")
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        width: "70%",
                                      }}
                                      type="text"
                                      value={mrps[data.product_sku] || data.mrp}
                                      onChange={(e) => handleMRPChange(e, data)}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {data?.last_price != 0 &&
                                    data.last_price != undefined &&
                                    data?.last_price != data.mrp &&
                                    data?.last_price !==
                                      mrps[data.product_sku] ? (
                                      <>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleLastPriceClick(data)
                                          }
                                          on="click"
                                          pinned
                                          content={`${formatIndianRupee(
                                            data.last_price
                                          )}`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        width: "30%",
                                        marginLeft: "5px",
                                      }}
                                      type="number"
                                      onFocus={(e) => e.target.select()}
                                      disabled={data.type === "big"}
                                      value={
                                        data.type === "big"
                                          ? (data.additional_info &&
                                              data.additional_info.length) ||
                                            1
                                          : quantities[data.product_sku] || 1
                                      }
                                      onChange={(e) =>
                                        handleQuantityChange(e, data)
                                      }
                                      inputProps={{
                                        min: 1,
                                        max: data.in_stock,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          Qty
                                        </InputAdornment>
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{
                                      backgroundColor: "#ebf5fc",
                                      marginTop: "5px",
                                      padding: "8px 10px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5>
                                      Total:{" "}
                                      <span style={{ color: "green" }}>
                                        {formatIndianRupee(
                                          calculateProductTotal(data)
                                        )}
                                      </span>
                                    </h5>

                                    {data.type && data.type == "big" ? (
                                      <>
                                        <FaTrashAlt
                                          style={{
                                            color: "grey",
                                            cursor: "not-allowed",
                                            marginTop: "2px",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <FaTrashAlt
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            marginTop: "2px",
                                          }}
                                          onClick={() =>
                                            handleDeleteProduct(
                                              data.product_sku
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-3">
              <div className="card p-3 mt-md-0 mt-2">
                <TextArea
                  className="custom_textareafield"
                  placeholder="Type your message here (if any)"
                  value={notes}
                  rows={5}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="card p-3 mt-2">
                <h3 style={{ marginBottom: "0px" }}>Sale Summary</h3>
                <hr />
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">SubTotal</div>
                  <div
                    className="col-6 my-2"
                    style={{
                      textAlign: "right",

                      fontSize: "1.2rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {" "}
                    {formatIndianRupee(calculateTotalChangeMRP())}{" "}
                  </div>

                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      type="number"
                      placeholder="0.00"
                      value={discount_amount}
                      disabled={calculateTotalChangeMRP() == 0}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setDiscount(e.target.value)}
                      size="small"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">kg</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                  <div className="col-6 my-2">Shipping Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      type="number"
                      placeholder="0.00"
                      disabled={calculateTotalChangeMRP() == 0}
                      value={shipping_charge}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setShippingCharge(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="col-4 my-2">Other Charges</div>
                  <div
                    className="col-8 my-2"
                    style={{ textAlign: "right", display: "flex" }}
                  >
                    <OutlinedInput
                      disabled={calculateTotalChangeMRP() == 0}
                      size="small"
                      fullWidth
                      type="number"
                      style={{ width: "40%" }}
                      name="discountPercent"
                      value={percentage}
                      onChange={handlePercentageChange}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />

                    <OutlinedInput
                      disabled={calculateTotalChangeMRP() == 0}
                      size="small"
                      placeholder="0.00"
                      style={{ marginLeft: "2px", width: "60%" }}
                      fullWidth
                      type="number"
                      name="discountAmount"
                      value={other_charge && other_charge.toFixed(0)}
                      onChange={handleOtherChargeChange}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                  </div>
                  <div
                    style={{
                      height: "0.5px",
                      backgroundColor: "#dbdbdb",
                      marginTop: "5px",
                    }}
                  ></div>
                  <div
                    className="col-6 my-2 mt-3"
                    style={{ fontSize: "1.3rem" }}
                  >
                    Grand Total :
                  </div>
                  <div
                    className="col-6 my-2 mt-3"
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontSize: "1.5rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {formatIndianRupee(calculateTotalMRP())}
                  </div>
                </div>
              </div>
              <div className="card p-3 mt-2">
                {disabled == 1 ? (
                  <>
                    <Button
                      size="small"
                      disabled
                      ss
                      variant="contained"
                      style={{
                        height: "37px",
                      }}
                    >
                      Confirm Sale
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      size="small"
                      disabled={calculateTotalChangeMRP() == 0}
                      ss
                      variant="contained"
                      style={{
                        height: "37px",
                      }}
                      onClick={handleSubmit}
                    >
                      Confirm Sale
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPartySale;
