import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiKey, checkTokenValidity } from "../Api";

const TokenVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const validateUser = async () => {
      let userDetails = [];
      try {
        userDetails = JSON.parse(localStorage.getItem("ktt_users"));
      } catch {
        navigate("/auth");
      }

      if (
        !userDetails ||
        !userDetails.token ||
        !userDetails.email ||
        !userDetails.name
      ) {
        navigate("/auth");

        return;
      }

      try {
        const headers = {
          "content-type": "application/json",
          "API-Key": apiKey,
        };

        const payload = {
          email: userDetails.email,
          token: userDetails.token,
        };

        const response = await axios.post(checkTokenValidity, payload, {
          headers,
        });

        if (response.data.status === 0) {
          navigate("/auth");
        } else {
        }
      } catch (error) {
        // console.error("Error validating user:", error);
        navigate("/auth");
      }
    };

    validateUser();
  }, [navigate]);

  return null;
};

export default TokenVerification;
