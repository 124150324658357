import React from 'react';
import { FaShoppingBag, FaUsers, FaRupeeSign, FaChartLine } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Line, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Button } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

const dummyData = [
    { title: 'Total Products', value: '854', change: '2.56%', icon: <FaShoppingBag />, bgClass: 'bg-primary', changeType: 'increase' },
    { title: 'Total Users', value: '31,876', change: '0.34%', icon: <FaUsers />, bgClass: 'bg-light-pink', changeType: 'increase' },
    { title: 'Total Revenue', value: '₹34,241', change: '7.66%', icon: <FaRupeeSign />, bgClass: 'bg-pink', changeType: 'increase' },
    { title: 'Total Sales', value: '1,76,586', change: '0.74%', icon: <FaChartLine />, bgClass: 'bg-orange', changeType: 'decrease' }
];

const graphData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
        {
            label: 'Growth',
            data: [100, 150, 200, 250, 300, 350],
            borderColor: 'blue',
            backgroundColor: 'rgba(0,0,255,0.2)',
        },
        {
            label: 'Profit',
            data: [80, 120, 180, 220, 280, 300],
            borderColor: 'purple',
            backgroundColor: 'rgba(128,0,128,0.2)',
        },
        {
            label: 'Sales',
            data: [120, 170, 230, 270, 310, 400],
            borderColor: 'pink',
            backgroundColor: 'rgba(255,105,180,0.2)',
        }
    ],
};

const orderStatisticsData = {
    labels: ['Delivered', 'Cancelled', 'Pending', 'Returned'],
    datasets: [
        {
            label: 'Orders',
            data: [2000, 500, 800, 400],
            backgroundColor: ['#5c67f7', 'red', 'pink', '#ff8e6f'],
        }
    ]
};


const topSellingBrands = [
    { name: "None", sales: 1800, gross: "25% Gross", percentage: "0.45%" },
    { name: "TEL", sales: 0, gross: "0%", percentage: "0%" },
    { name: "Turbo Master", sales: 0, gross: "0%", percentage: "0%" },
    { name: "Holset", sales: 0, gross: "0%", percentage: "0%" },
    { name: "E&E", sales: 0, gross: "0%", percentage: "0%" },
    { name: "Suotepower", sales: 0, gross: "0%", percentage: "0%" },
    { name: "BORGWARNER", sales: 0, gross: "0%", percentage: "0%" },
    { name: "JRONE", sales: 0, gross: "0%", percentage: "0%" },
    { name: "GARRETT", sales: 0, gross: "0%", percentage: "0%" },
    { name: "MITSHUBISI", sales: 0, gross: "0%", percentage: "0%" },
    { name: "O.E LOOSE", sales: 0, gross: "0%", percentage: "0%" },
    { name: "MITSUBISHI", sales: 0, gross: "0%", percentage: "0%" },
    { name: "CHINA", sales: 0, gross: "0%", percentage: "0%" }
];



function SalesDashboard() {
    const navigate = useNavigate();
    return (
        <>
            {/* Top Cards */}
            <div className="row">
                <div className="col-xl-8">
                    <div className="row">

                        {dummyData.map((item, index) => (
                            <div className="col-xxl-3 col-xl-6" key={index}>
                                <div className="card custom-card position-relative rounded mb-2">
                                    <div className="card-body p-3 dash-bg-image">
                                        <div className="d-flex align-items-start justify-content-between mb-2 gap-1 flex-xxl-nowrap flex-wrap">
                                            <div>
                                                <span className="text-muted d-block mb-1 text-nowrap">{item.title}</span>
                                                <h4 className="fw-bold mb-0 mt-0 fs-2">{item.value}</h4>
                                            </div>
                                            <div className="dash-absolute-icon">
                                                <span className={`avatar ${item.bgClass}`}>{item.icon}</span>
                                            </div>
                                        </div>
                                        <div className="text-muted fs-13">
                                            {item.changeType === 'increase' ? (
                                                <>Increased By <span className="text-success">{item.change}<i className="ti ti-arrow-narrow-up fs-16"></i></span></>
                                            ) : (
                                                <>Decreased By <span className="text-danger">{item.change}<i className="ti ti-arrow-narrow-down fs-16"></i></span></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Sales Overview Section */}
                        <div className="col-xxl-8 col-xl-6 mt-3">
                            <div className="card custom-card position-relative rounded mb-2">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <div className="card-title mb-0">Sales Overview</div>
                                    <Button variant='outlined' size='small' onClick={() => navigate(`/all_sales`)}>View All </Button>

                                </div>
                                <div className="card-body">
                                    <div style={{ minHeight: "330px" }}>
                                        <Line data={graphData} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Order Statistics Section */}
                        <div className="col-xxl-4 col-xl-6 mt-3">
                            <div className="card custom-card position-relative rounded mb-2">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <div className="card-title mb-0">Order</div>
                                    <Button variant='outlined' size='small' onClick={() => navigate(`/all_purchases`)}>View All </Button>
                                </div>
                                <div className="card-body text-center" >
                                    <Doughnut data={orderStatisticsData} />
                                    <p className="mt-3">Total Orders: 3,736</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="col-xl-4">


                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 ">
                            <div className="card custom-card position-relative rounded mb-2">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <div className="card-title mb-0">Top Selling Brands</div>

                                </div>
                                <div className="card-body p-0" >

                                    <div className="d-flex align-items-center justify-content-between px-2 mt-2 mb-1">
                                        <p className='mb-0'>Overall Sales</p>
                                        <div>
                                            <p className='mb-0'> <small className='text-success'>2.74% 	&uarr;</small> 1,25,990</p>
                                        </div>
                                    </div>

                                    <table className="ui table" id="c_table">
                                        <tbody>
                                            {topSellingBrands.map((brand, index) => (
                                                <tr key={index}>
                                                    <td>{brand.name}</td>
                                                    <td>{brand.sales}</td>
                                                    <td>{brand.gross}</td>
                                                    <td>{brand.percentage}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}



                </div>



            </div>



        </>
    );
}

export default SalesDashboard;
