import React, { useState } from 'react';
import { Typography, Button, Modal, Box } from "@mui/material";
import { Label } from 'semantic-ui-react';
import ViewCustomer from '../../OurClients/Clients/ViewCustomer';
import { useScreenWidth } from '../../../useScreenWidth';
import { TbExternalLink } from 'react-icons/tb';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "80%", md: "850px" },
    maxWidth: "950px",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

function CustomersProductDashboard({ data, onClose }) {

    const isWideScreen = useScreenWidth();

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [openDetailModal, setOpenDetailModal] = useState(false);

    const handleOpenDetailModal = (customer) => {
        console.log(customer);
        setSelectedCustomer(customer);
        setOpenDetailModal(true);
    };

    const handleCloseDetailModal = () => {
        setSelectedCustomer(null);
        setOpenDetailModal(false);
    };


    return (
        <>

            <ViewCustomer
                openViewCustomer={openDetailModal}
                handleCloseCustomer={handleCloseDetailModal}
                selectedCustomer={selectedCustomer}
                fetchCustomers={``}
            />
            <Modal open onClose={onClose}>
                <Box sx={modalStyle} className="bg-white py-3 px-3">
                    <div className="d-flex justify-content-between align-items-center mb-2" >
                        <Typography variant="h5" component="h2">
                            Customers Details
                        </Typography>
                        <div className="">
                            Total Customers : <Label>{data.length}</Label>
                        </div>

                    </div>

                    {isWideScreen ? (
                        <>
                            <div
                                className="responsive-table1"
                                style={{
                                    maxHeight: "300px",
                                    height: "max-content",
                                    width: "100%",
                                }}
                            >
                                <table className="ui table celled" id="c_table">
                                    <thead className="table-head">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleOpenDetailModal(item)} >{item.id}</td>
                                                    <td>{item.customer_name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.total_purchased_quantity}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="card p-2 responsive-mobile-table">
                                {data && data.map((item, idx) => (
                                    <div className="product-card " key={idx} >
                                        <div className="py-1 px-2 w-100"  >
                                        <h5>{item.customer_name}</h5>
                                        <h5 className="sale-card-date">{item.phone} | {item.total_purchased_quantity} QTY  </h5>
                                            <div
                                                onClick={() => handleOpenDetailModal(item)}
                                                className="navigate-other-mobile"
                                            >
                                                <TbExternalLink />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}


                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "20px", float: "right" }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

        </>
    );
}

export default CustomersProductDashboard;
