import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { fetch_purchase, apiKey, barcode_printing } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Delete from "../../Messages/DeletePurchase";
import Chip from "@mui/material/Chip";
import { IoMdArrowRoundBack } from "react-icons/io";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from "react-hot-toast";
import { useScreenWidth } from "../../useScreenWidth";
import { Icon, Label, Pagination } from "semantic-ui-react";
import CustomLoader from "../../Components/Loader";
import PrintBarcode from "./PrintBarcode";
import FilterSelectionModalByNumber from "../../Components/FilterModal/FilterSelectionModalByNumber";
import FilterDateRangeModal from "../../Components/FilterModal/FilterDateRangeModal";
import { format, parseISO } from "date-fns";

function Purchases() {
  const isWideScreen = useScreenWidth();
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // date Filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // For Filter
  const [receivedStatus, setReceivedStatus] = useState([
    { value: 0, label: "Not Received" },
    { value: 1, label: "Received" },
  ]);
  const [selectedReceivedStatus, setSelectedReceivedStatus] = useState([]);

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    // debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchquery,
    startDate,
    endDate,
    selectedReceivedStatus,
  ]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      selectedStatus: selectedReceivedStatus || [],
      starting_date: startDate || "",
      ending_date: endDate || "",
    };

    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    // console.log("formated data : ", data);
    // console.log("selectedReceivedStatus : ", selectedReceivedStatus);

    axios
      .post(fetch_purchase, data, { headers })
      .then((response) => {
        setPurchases(response.data.purchases);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data?.data?.message);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleEditClick = (id) => {
    navigate(`/update-purchase/${id}`);
  };
  const handleViewClick = (id) => {
    navigate(`/view-purchase/${id}`);
  };

  const handleAllocateClick = (id) => {
    navigate(`/allocate-purchase-goods/${id}`);
  };

  const [pks, setPks] = useState([]);
  const handleDeleteClick = () => {
    const selectedPks = selectedRows.map((purchase) => purchase.id);

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No purchase selected!");
      // setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }
  };

  // Selection
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Only include products that are receive_status
      const receive_statusPurchases = purchases.filter(
        (purchase) => purchase.receive_status !== 1
      );
      setSelectedRows(receive_statusPurchases);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (purchases) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.find((item) => item.id === purchases.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== purchases.id);
      } else {
        // Otherwise, add the full product object
        return [...prevSelected, purchases];
      }
    });
  };

  // Print Barcode.
  const [openBarcode, setOpenBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handleBarcodeClick = (id) => {
    // navigate(`/barcode-purchase/${id}`);
    try {
      axios
        .get(barcode_printing, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            purchase_pk: parseInt(id),
          },
        })
        .then((response) => {
          setBarcodes(response.data.products);
        })
        .catch((error) => {
          console.log("Error : ", error);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log("Error : ", error);
    }
    setOpenBarcode(true);
  };

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <Delete
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <PrintBarcode
        openBarcode={openBarcode}
        handleCloseBarcode={handleCloseBarcode}
        barcodes={barcodes}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>

              <Typography sx={{ color: "text.primary" }}>
                Purchase Orders
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-purchase")}
                >
                  Add New
                </Button>
                {selectedRows && selectedRows.length > 0 ? (
                  <>
                    <div className="mb_btn_hide">
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleDeleteClick}
                      >
                        Delete Purchase
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ width: "2px" }}>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length ===
                              purchases.filter(
                                (product) => product.receive_status !== 1
                              ).length &&
                            purchases.some(
                              (purchase) => purchase.receive_status !== 1
                            )
                          }
                          onChange={handleSelectAll}
                          disabled={purchases.every(
                            (purchase) => purchase.receive_status === 1
                          )}
                        />
                      </th>
                      <th>ID</th>
                      <th>
                        <FilterDateRangeModal
                          title="Purchase Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>Reference No.</th>
                      <th>Vendor</th>
                      <th>
                        <FilterSelectionModalByNumber
                          title="Receive Status"
                          options={receivedStatus}
                          selectedOptions={selectedReceivedStatus}
                          onSelect={setSelectedReceivedStatus}
                        />
                      </th>
                      <th style={{ textAlign: "center" }}>Total Products</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchases.length > 0 ? (
                      <>
                        {purchases &&
                          purchases.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "2px" }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.some(
                                      (item) => item.id === data.id
                                    )}
                                    onChange={() => handleRowSelect(data)}
                                    disabled={data.receive_status === 1}
                                  />
                                </td>
                                <td style={{ width: "70px" }}>#{data.id}</td>
                                <td>
                                  <b>
                                    {format(
                                      parseISO(data.purchase_date),
                                      "dd MMMM, yyyy"
                                    )}
                                  </b>
                               
                                </td>
                                <td>{data.reference_number || "-"}</td>
                                <td><b>{data.vendor_ref?.name || "N/A"}</b></td>

                                <td style={{ width: "160px" }}>
                                  <Label
                                    style={{ width: "95px" }}
                                    className={`label ${
                                      data.receive_status === 1
                                        ? "green_label"
                                        : data.receive_status === 0
                                        ? "red_label"
                                        : "blue_label"
                                    }`}
                                  >
                                    {data.receive_status === 1
                                      ? "Received"
                                      : data.receive_status === 0
                                      ? "Not Received"
                                      : "Pending"}
                                  </Label>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "160px",
                                  }}
                                >
                                  <b>{data.total_products}</b> QTY
                                </td>

                                <td style={{ maxWidth: "150px" }}>
                                 

                                  {data.receive_status !== 1 ? (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{ marginRight: "5px" }}
                                      color="primary"
                                      onClick={() => handleViewClick(data.id)}
                                    >
                                      Receive
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      style={{ marginRight: "5px" }}
                                      size="small"
                                      color="info"
                                      onClick={() => handleViewClick(data.id)}
                                    >
                                      History
                                    </Button>
                                  )}

                                  
                                  {/* ) : null} */}

                                  {data.receive_status !== 1 ? (
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                      onClick={() => handleEditClick(data.id)}
                                    >
                                      Edit
                                    </Button>
                                  ) : null}

                                  {data.receive_status !== 1 ? null : (
                                    <Button
                                      disabled={data.print_status === 0}
                                      variant="outlined"
                                      size="small"
                                      color="success"
                                      onClick={() =>
                                        handleBarcodeClick(data.id)
                                      }
                                    >
                                      Print
                                    </Button>
                                  )}

                                  
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="8">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {purchases.length > 0 ? (
                  <>
                    {purchases &&
                      purchases.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleViewClick(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                #{data.id} | {data.reference_number || "-"}
                              </h5>
                              <h5 className="product-card-name">
                                {data.vendor_ref?.name || "N/A"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span style={{ color: "grey" }}>QTY:</span>{" "}
                                {data.total_products} | {data.purchase_date}
                              </h5>

                              <Label
                                style={{
                                  position: "absolute",
                                  marginTop: "-20px",
                                  right: "15px",
                                  fontSize: "11px",
                                }}
                                color={
                                  data.receive_status == 1 ? "green" : "red"
                                }
                                size="small"
                                tag
                              >
                                {data.receive_status == 1
                                  ? "Recieved"
                                  : "Not Recieved"}
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Purchases;
