// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA5wyK9KiOlzKLYbC6Au3QyKhg06yAMmak",
    authDomain: "database-349ba.firebaseapp.com",
    projectId: "database-349ba",
    storageBucket: "database-349ba.appspot.com",
    messagingSenderId: "452342656365",
    appId: "1:452342656365:web:90936ba6990c11ba793e64",
    measurementId: "G-WNB71QFQDL"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };