import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  fetchProductInven,
  fetch_all_product_transfer,
  convert_product,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaPencilAlt, FaTasks, FaTrashAlt } from "react-icons/fa";
import { MdBallot, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import AssignRackModal from "../Inventory/StockAdjustment/AssignStockModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import Webcam from "react-webcam";
import Quagga from "quagga";
import { IoSearch } from "react-icons/io5";
import { useScreenWidth } from "../../useScreenWidth";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import SeriesModal from "../Inventory/StockAdjustment/SeriesModal";
import RemoveSkipProduct from "../../Messages/StockAdjustment/RemoveSkipProduct";

import soundEffect from "../../Assets/sounds/beep.mp3";
import { BsUpcScan } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import SelectDestinatedProduct from "./SelectDestinatedProduct";
import SelectWarehouseModal from "./SelectWarehouseModal";
import { TextArea } from "semantic-ui-react";
import { LuArrowBigDown } from "react-icons/lu";

function AddConversion() {
  const webcamRef = useRef(null);

  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const isWideScreen = useScreenWidth();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProducts2, setSelectedProducts2] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [productInventories, setProductInventories] = useState({});
  const [unallocated, setUnallocated] = useState({});
  const [inven, setInven] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );
  const [warehouses, setWarehouses] = useState([]);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [date, setDate] = useState(getCurrentDate());

  const navigate = useNavigate();

  useEffect(() => {
    fetchWarehouse();
    fetchInven();
  }, []);

  const fetchInven = (sku) => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const { row_inventory, obj } = response.data;

        setProductInventories((prevInventories) => ({
          ...prevInventories,
          [sku]: row_inventory,
        }));

        // Update unallocated quantities for the specific SKU
        setUnallocated((prev) => ({
          ...prev,
          [sku]: obj.unallocated,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses); // Set all warehouses
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = useCallback(() => {
    if (!apiKey) {
      toast.error("API Key is not available");
    }
    axios
      .post(
        fetch_all_product_transfer,
        {
          search: searchQuery || "",
          warehouse_pk: parseInt(warehouse),
        },
        {
          headers: {
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        // console.log("response.data.data : ",response.data.data)
        const fetchedProducts = response.data.data;
        setProducts(fetchedProducts);
        setDisplay(true);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response?.data?.message);
      });
  }, [searchQuery, warehouse]);

  const [debouncedQuery, setDebouncedQuery] = useState("");

  // Debounce the setting of debouncedQuery
  const debouncedSetQuery = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
    }, 600),
    []
  );

  useEffect(() => {
    if (debouncedQuery) {
      fetchProducts(debouncedQuery);
    }
  }, [debouncedQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    const newEntry = {
      ...product,
      inventoryDetail: "",
      quantity: 0,
    };

    if (product.product_status == 1) {
      // Call addScannedProduct with the appropriate parameters
      addScannedProduct(product.product_sku, product);
      return; // Exit the function after adding the scanned product
    }

    setScannedProducts((prevSelected) => {
      // console.log("prevSelected : ", prevSelected);
      // Fetch inventory details for the newly added product
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        fetchInven(product.product_sku);
        return [...prevSelected, newEntry];
      }
    });
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // =================================== Stock IN ===============================

  const [quantities, setQuantities] = useState({});

  const [stockInModal, setStockInModal] = useState(false);
  const [p_sku, setP_SKU] = useState("");

  const [qty, setQty] = useState("");
  const handleEditClick = (sku) => {
    setP_SKU(sku);
    setStockInModal(true);
    const quantity = quantities[sku] || 0;
    setQty(quantity);
    // console.log("Editing product SKU:", sku, "with quantity:", quantity);
  };

  // ============= Submit Data ===========

  const handleSubmitData = () => {
    if (!scannedProducts || scannedProducts.length === 0) {
      toast.error("Please Select a Product.");
      return;
    }
    let hasError = false; // Flag to track errors
    scannedProducts.forEach((product) => {
      if (!product.target_sku) {
        toast.error(
          `Target SKU is missing for product SKU: ${product.product_sku}`
        );
        hasError = true; // Set error flag to true
      }
    });

    if (hasError) {
      return;
    }

    // validateProducts();

    const formattedProducts = scannedProducts.map((product) => ({
      source_sku: product.product_sku,
      qty:
        product.type === "big"
          ? product.selectedSeries.length || 0
          : parseInt(product.qty) || 0,
      selected_series: product.selectedSeries || [],
      target_sku: product.target_sku,
    }));

    const formattedSubmitData = {
      user_name: userDetails.name,
      remark: notes,
      date: date,
      warehouse_pk: warehouse,
      products: formattedProducts,
    };

    console.log("object");
    axios
      .post(convert_product, formattedSubmitData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status == 1) {
          navigate("/conversions");
          setSelectedProducts([]);
          setDate("");
          setInven([]);
          setScannedProducts([]);
          toast.success(`Conversion created Successfully!`);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        console.log(error);
      });
  };

  // ========== New Stock Out =========

  const inputRef = useRef(null); // Create a ref for the input field
  const [scanData, setScanData] = useState("");
  const [ScanFocus, setScanFocus] = useState(0);
  const [itemDisplay, setItemDisplay] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [customer, setCustomer] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const handleClickSmallItems = () => {
    setItemDisplay((prevValue) => (prevValue === 1 ? 0 : 1));
    // setScanData("");
    setSearchInput("");
  };

  const handleScanFocus = () => {
    setScanFocus((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [ScanFocus]);

  // === fetch_all_product_transfer ===

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const body = {
          search: value,
          warehouse_pk: parseInt(warehouse),
        };
        const response = await axios.post(
          fetch_all_product_transfer,
          // { search: value, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        );
        const fetchedProducts = response.data.data || [];
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer, warehouse] // Dependency array
  );

  const handleInputChangeSmall = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length <= 1) {
        setDisplay(false);
      } else if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const isProductSelected = (product) => {
    return scannedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleDeleteProduct = (product_sku) => {
    setScannedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      // console.log("fetchProductData Chala");
      // console.log(sku);
      const body = {
        search: sku,
        warehouse_pk: parseInt(warehouse),
      };
      axios
        .post(
          fetch_all_product_transfer,
          // { search: sku, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          // console.log("Response of Scanned Barcode ", response.data.data);
          // console.log("Response of Data ", response.data);
          if (response.data.status === 1) {
            if (response.data.data.length > 0) {
              const productData = response.data.data[0];
              addScannedProduct(sku, productData);
            } else {
              toast.error("Product Not Found");
              // setOpenError(true);
            }
          } else if (response.data.status === 0) {
            toast.error(response.data.message);
            // setOpenError(true);
          }
          setScanData("");
        })
        .catch((error) => {
          // setOpenError(true);

          toast.error(error.response.data.message);
          setScanData("");
        });
    }, 400),
    [customer, warehouse]
  );

  useEffect(() => {
    handleInputChangeScan(scanData);
  }, [scanData]);

  const handleInputChangeScan = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of scannedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      // const [baseSku, additionalInfoString] = sku.split("_");
      // const additionalInfo = parseInt(additionalInfoString, 10);
      let baseSku;
      let additionalInfoString;
      let additionalInfo;

      if (sku.includes("_")) {
        [baseSku, additionalInfoString] = sku.split("_");
        additionalInfo = parseInt(additionalInfoString, 10);
      } else if (sku.includes("-")) {
        baseSku = sku.split("-")[1];
        additionalInfoString = undefined;
        additionalInfo = undefined; // No additional info
      } else {
        baseSku = sku;
        additionalInfoString = undefined;
        additionalInfo = undefined; // Set to undefined or any default value as needed
      }
      const seriesAvailable = productData.serieses.map(
        (series) => series.series == additionalInfo
      );

      // console.log("productData : ",productData);

      if (additionalInfoString) {
        if (seriesAvailable.some((isAvailable) => isAvailable)) {
          setScannedProducts((prev) => {
            const existingProduct = prev.find(
              (item) =>
                item.sku.slice("-")[1] === baseSku ||
                item.product_sku.slice("-")[1] === baseSku ||
                item.sku === baseSku
            );

            if (existingProduct) {
              // Check if the cur_series is already in the selectedSeries array
              if (
                existingProduct.selectedSeries.includes(
                  productData.cur_series || parseInt(additionalInfoString)
                )
              ) {
                setOpenRemoveSkipProductModal(true);
                setSelectedRemoveSkipProductDetail({
                  existingProduct,
                  cur_series:
                    productData.cur_series || parseInt(additionalInfoString),
                });
                return prev; // Do not add the series if it is already selected
              }

              return prev.map((item) => {
                if (item.sku === baseSku) {
                  const newSeries = {
                    series:
                      productData.cur_series || parseInt(additionalInfoString),
                    ware_house__ware_house: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.ware_house__ware_house,
                    rack__rack_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__rack_name,
                    rack__row__row_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__row__row_name,
                  };

                  return {
                    ...item,
                    selectedSeries: [
                      ...item.selectedSeries,
                      ...(productData.cur_series
                        ? [productData.cur_series]
                        : []),
                      ...(additionalInfoString
                        ? [parseInt(additionalInfoString)]
                        : []),
                    ],
                  };
                }
                toast.success("Product Added !");
                return item;
              });
            }

            const newProduct = {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              type: "big",
              cur_series: productData.cur_series,
              all_series: productData.serieses.map((productSeries) => ({
                series: productSeries.series,
                ware_house__ware_house: productSeries.ware_house__ware_house,
                rack__rack_name: productSeries.rack__rack_name,
                rack__row__row_name: productSeries.rack__row__row_name,
              })),
              selectedSeries: productData.cur_series
                ? [productData.cur_series]
                : additionalInfo
                ? [additionalInfo]
                : [],
            };

            toast.success("Product Added!");

            return [...prev, newProduct];
          });
        } else {
          // console.log("Series is Not available.");
          const warehouseName = warehouses
            .filter((ware) => ware.id == warehouse)
            .map((ware) => ware.ware_house);
          toast.error(`Product is Not available in ${warehouseName} `);
        }
      } else {
        setScannedProducts((prev) => {
          const existingProduct = prev.find((item) => {
            const sku = item.sku || ""; // Default to empty string if sku is undefined
            const productSku = item.product_sku || ""; // Default to empty string if product_sku is undefined

            return (
              sku.slice("-")[1] === baseSku ||
              productSku.slice("-")[1] === baseSku ||
              sku === baseSku
            );
          });

          if (existingProduct) {
            return prev.filter(
              (item) => item.product_sku !== existingProduct.product_sku
            );
          }

          const newProduct = {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            type: "big",
            cur_series: productData.cur_series,
            all_series: productData.serieses.map((productSeries) => ({
              series: productSeries.series,
              ware_house__ware_house: productSeries.ware_house__ware_house,
              rack__rack_name: productSeries.rack__rack_name,
              rack__row__row_name: productSeries.rack__row__row_name,
            })),
            selectedSeries: productData.cur_series
              ? [productData.cur_series]
              : additionalInfo
              ? [additionalInfo]
              : [],
          };
          toast.success("Product Added !");
          return [...prev, newProduct];
        });
      }
    }, 300),
    [warehouse] // dependencies
  );

  // Series Modal Open
  const [openSeriesModal, setOpenSeriesModal] = useState(false);
  const [selectedSeriesProduct, setSelectedSeriesProduct] = useState(null);

  const handleOpenSeriesModal = (product) => {
    setSelectedSeriesProduct(product); // Set the selected product to pass to the modal
    setOpenSeriesModal(true); // Open the modal
  };

  const handleCloseSeriesModal = () => {
    setOpenSeriesModal(false); // Close the modal
    setSelectedSeriesProduct(null); // Clear the selected product
  };

  // For Big product update Series.
  const [openRemoveSkipProductModal, setOpenRemoveSkipProductModal] =
    useState(false);
  const [selectedRemoveSkipProductDetail, setSelectedRemoveSkipProductDetail] =
    useState([]);

  const handleCloseOpenRemoveSkipProductModal = () => {
    setOpenRemoveSkipProductModal(false);
  };

  const updateSelectedSeries = (sku, selectedSeries, cur_series) => {
    setScannedProducts((prev) => {
      return prev.map((product) => {
        if (product.sku === sku) {
          // Check if the cur_series is already in the selectedSeries array
          if (selectedSeries.includes(cur_series)) {
            setOpenRemoveSkipProductModal(true);
            setSelectedRemoveSkipProductDetail({
              product,
              cur_series: cur_series,
            });
            return product; // Do not update the product if the series is already selected
          }
          return { ...product, selectedSeries };
        }
        return product;
      });
    });
  };

  const handleSamllProductQuantityChange = (e, product, index) => {
    const value = Math.max(
      0,
      Math.min(Number(e.target.value), product.in_stock)
    );
    const updatedProducts = [...scannedProducts];
    updatedProducts[index].qty = value;
    setScannedProducts(updatedProducts);
  };

  // Warehouse check

  const [openWarehouseModalChange, setOpenWarehouseModalChange] =
    useState(false);
  const [pendingWarehouse, setPendingWarehouse] = useState("");

  const handleWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;

    // Check if selectedWarehouseId is valid
    if (selectedWarehouseId) {
      const selectedWarehouse = warehouses.find(
        (wh) => wh.id === selectedWarehouseId
      );

      if (scannedProducts && scannedProducts.length > 0) {
        setPendingWarehouse(selectedWarehouse.id);
        setOpenWarehouseModalChange(true);
      } else {
        setPendingWarehouse(selectedWarehouse.id);
        setWarehouse(selectedWarehouse.id);
      }
    }
  };

  const handleConfirmWarehouseChange = () => {
    setWarehouse(pendingWarehouse);
    setOpenWarehouseModalChange(false);
    setScannedProducts([]);
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleCancelWarehouseChange = () => {
    setOpenWarehouseModalChange(false);
    setPendingWarehouse("");
  };

  // Web Cam
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [isScanned, setIsScanned] = useState(false); // State to track if a barcode has been scanned

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
    setIsScanned(false); // Reset scanning state when opening the camera
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleDetected = useCallback(
    (result) => {
      const scannedCode = result.codeResult.code;
      // console.log("Scanned Code: ", scannedCode);

      // Only set scan data and play sound if a barcode has not already been scanned
      if (!isScanned) {
        playSound();
        setIsScanned(true); // Mark that a barcode has been scanned
        setIsCameraOpen(false);
      }
    },
    [isScanned]
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc && !isScanned) {
          // Only scan if not already scanned
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"], // Adjust according to your needs
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected, isScanned]);

  // Add these two functions above your component return statement
  const handleFocus = () => {
    fetchProducts();
    setDisplay(true);
  };

  // Dialog box of selecting Target Product

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const handleOpenDialog = (index) => {
    setSelectedProductIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleProductSelect = (product) => {
    console.log("pro : ", product);
    const updatedProducts = [...scannedProducts];

    if (selectedProductIndex !== null) {
      if (
        updatedProducts[selectedProductIndex].product_sku ===
        product.product_sku
      ) {
        toast.error(`Source and target product cannot be same`);
        return;
      }
      updatedProducts[selectedProductIndex].target_sku = product.product_sku;
      updatedProducts[selectedProductIndex].target_product_name =
        product.product_name;
      setScannedProducts(updatedProducts);
      toast.success(`${product.product_sku} added as target product`);
    }
    handleCloseDialog();
  };

  const [openModalWarehouse, setOpenModalWarehouse] = useState(true);

  return (
    <>
      <SelectDestinatedProduct
        open={openDialog}
        onClose={handleCloseDialog}
        onProductSelect={handleProductSelect}
        warehouse={warehouse}
        defaultSelectedProduct={
          selectedProductIndex !== null
            ? scannedProducts[selectedProductIndex]?.target_sku
            : ""
        }
      />

      <SelectWarehouseModal
        setOpenModalWarehouse={setOpenModalWarehouse}
        openModalWarehouse={openModalWarehouse}
        setWarehouse={setWarehouse}
      />

      <Toaster position="top-right" />

      <RemoveSkipProduct
        selectedRemoveSkipProductDetail={selectedRemoveSkipProductDetail}
        handleCloseOpenRemoveSkipProductModal={
          handleCloseOpenRemoveSkipProductModal
        }
        scannedProducts={scannedProducts}
        setScannedProducts={setScannedProducts}
        openRemoveSkipProductModal={openRemoveSkipProductModal}
      />

      {/* // Out Stock Big Type Size Product Series Selection Modal  */}
      {openSeriesModal && (
        <SeriesModal
          open={openSeriesModal}
          handleClose={handleCloseSeriesModal}
          product={selectedSeriesProduct}
          updateSelectedSeries={updateSelectedSeries}
        />
      )}

      <AssignRackModal
        openModal={stockInModal}
        setOpenModal={setStockInModal}
        warehouseId={warehouse}
        p_sku={p_sku}
        setInven={setInven}
        inven={inven}
        qty={qty}
      />

      {/* Confirmation Modal */}
      <Dialog
        open={openWarehouseModalChange}
        onClose={handleCancelWarehouseChange}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <p>
            Changing the warehouse will remove all data. Do you want to proceed?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelWarehouseChange}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmWarehouseChange}
            color="primary"
            variant="outlined"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/conversions")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/conversions")}
              >
                Conversion
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                Add Conversion
              </Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <div className="scannerDiv">
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",
                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseCamera}
              >
                Close
              </Button>
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-9 my-2 order-2 order-lg-1">
              <div className="card p-3">
                <div style={{ display: "flex", gap: "2px" }}>
                  <TextField
                    inputRef={inputRef} // Assign the ref to the TextField
                    // onFocus={handleFocus}
                    onFocus={
                      itemDisplay && itemDisplay === 1 ? handleFocus : ""
                    }
                    onChange={
                      itemDisplay && itemDisplay === 1
                        ? handleInputChangeSmall
                        : (e) => setScanData(e.target.value)
                    }
                    value={
                      itemDisplay && itemDisplay === 1 ? searchInput : scanData
                    }
                    // value={searchInput}

                    size="small"
                    fullWidth
                    style={{ maxWidth: "300px" }}
                    label={
                      itemDisplay && itemDisplay === 1
                        ? "Search Products"
                        : "Scan Product..."
                    }
                    variant="outlined"
                  />

                  {displaySearch ? (
                    <>
                      <div
                        id="searchList"
                        ref={searchListRef}
                        style={{
                          border: "1px solid #dedede",
                          position: "absolute",
                          zIndex: "2",
                          maxHeight: "450px",
                          maxWidth: "430px",
                          overflowY: "scroll",
                          backgroundColor: "white",
                          width: "92%",
                          marginTop: "2.8rem",
                          borderRadius: "5px",
                        }}
                      >
                        {products.map((product, index) => (
                          <>
                            <div
                              className="card  p-3"
                              style={{ display: "flex" }}
                              key={index}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isProductSelected(product)}
                                    onChange={() =>
                                      handleCheckboxChange(product)
                                    }
                                    name="check"
                                  />
                                }
                                label={
                                  product.product_sku +
                                  " : " +
                                  product.product_name
                                }
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}

                  {itemDisplay && itemDisplay === 1 ? (
                    <>
                      <BsUpcScan
                        onClick={() => {
                          handleCloseCamera();
                          handleScanFocus();
                          handleClickSmallItems();
                        }}
                        style={{
                          fontSize: "30px",
                          padding: "8px",
                          height: "37px",
                          width: "38px",
                          cursor: "pointer",
                          border: "1px solid #1565c0",
                          borderRadius: "5px",
                          marginLeft: "5px",
                          color: "#1565c0",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {isWideScreen ? (
                        <>
                          <BsUpcScan
                            onClick={() => {
                              handleScanFocus();
                            }}
                            style={{
                              fontSize: "30px",
                              padding: "8px",
                              height: "37px",
                              width: "38px",
                              cursor: "pointer",
                              border: "1px solid #1565c0",
                              borderRadius: "5px",
                              marginLeft: "5px",
                              color: "#1565c0",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <BsUpcScan
                            onClick={() => {
                              handleOpenCamera();
                            }}
                            style={{
                              fontSize: "30px",
                              padding: "8px",
                              height: "37px",
                              width: "38px",
                              cursor: "pointer",
                              border: "1px solid #1565c0",
                              borderRadius: "5px",
                              marginLeft: "5px",
                              color: "#1565c0",
                            }}
                          />
                        </>
                      )}
                      <IoSearch
                        onClick={() => {
                          handleCloseCamera();
                          handleScanFocus();
                          handleClickSmallItems();
                        }}
                        style={{
                          fontSize: "30px",
                          padding: "8px",
                          height: "37px",
                          width: "38px",
                          cursor: "pointer",
                          border: "1px solid red",
                          borderRadius: "5px",
                          marginLeft: "5px",
                          color: "red",
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              {isWideScreen ? (
                <>
                  <div className="responsive-table1 mt-2">
                    <table className="ui table celled " id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>Source Product </th>
                          <th style={{ textAlign: "center" }}>Series </th>
                          <th>Quantity </th>
                          <th>In Stock </th>
                          <th style={{ width: "25%" }}> Target Product </th>
                          <th align="right"> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {scannedProducts && scannedProducts.length > 0 ? (
                          <>
                            {scannedProducts.map((product, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    product.type !== "big"
                                      ? "#f5faff"
                                      : "transparent",
                                }}
                              >
                                <td align="left">
                                  #{product.product_sku || ""}
                                  <p style={{ marginTop: "5px" }}>
                                    {product.product_name || ""}
                                  </p>
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                    color: "#00528a",
                                  }}
                                >
                                  {product.type == "big" ? (
                                    <FaTasks
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOpenSeriesModal(product)
                                      }
                                    />
                                  ) : null}
                                </td>

                                {product.type == "big" ? (
                                  <>
                                    <td
                                      align="left"
                                      style={{ maxWidth: "80px" }}
                                    >
                                      <TextField
                                        className="p-0 m-0"
                                        size="small"
                                        type="number"
                                        value={
                                          product.selectedSeries
                                            ? product.selectedSeries.length
                                            : 0
                                        }
                                        disabled={true}
                                      />
                                    </td>
                                    <td>
                                      {product.all_series
                                        ? product.all_series.length
                                        : 0}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td
                                      align="left"
                                      style={{ maxWidth: "80px" }}
                                    >
                                      <TextField
                                        className="p-0 m-0"
                                        size="small"
                                        type="number"
                                        value={product.qty || ""}
                                        onChange={(e) =>
                                          handleSamllProductQuantityChange(
                                            e,
                                            product,
                                            index
                                          )
                                        }
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                          },
                                        }}
                                        placeholder="Qty"
                                        autoComplete="off"
                                      />
                                    </td>

                                    <td>
                                      {product.in_stock ? product.in_stock : 0}
                                    </td>
                                  </>
                                )}

                                {/* Target Product  */}

                                <td style={{ width: "25%" }}>
                                  {product.target_sku ? (
                                    <>
                                      <span>#{product.target_sku}</span>
                                      <IconButton
                                        onClick={() => handleOpenDialog(index)}
                                      >
                                        <FaPencilAlt
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          fontSize="small"
                                        />
                                      </IconButton>

                                      <p style={{ marginTop: "0px" }}>
                                        {product.target_product_name}
                                      </p>
                                    </>
                                  ) : (
                                    <Button
                                      onClick={() => handleOpenDialog(index)}
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                    >
                                      Select Target Product
                                    </Button>
                                  )}
                                </td>

                                <td align="right">
                                  {product.type == "big" ? (
                                    <FaTrashAlt
                                      style={{
                                        color:
                                          product.type !== "big" ||
                                          (product.type === "big" &&
                                            product.selectedSeries.length === 0)
                                            ? "red"
                                            : "gray",
                                        cursor:
                                          product.type !== "big" ||
                                          (product.type === "big" &&
                                            product.selectedSeries.length === 0)
                                            ? "pointer"
                                            : "not-allowed",
                                      }}
                                      onClick={() => {
                                        if (
                                          product.type !== "big" ||
                                          (product.type === "big" &&
                                            product.selectedSeries.length === 0)
                                        ) {
                                          handleDeleteProduct(
                                            product.product_sku
                                          );
                                        }
                                      }}
                                    />
                                  ) : (
                                    <FaTrashAlt
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleDeleteProduct(product.product_sku)
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="6">
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table mt-2">
                    {scannedProducts.length > 0 ? (
                      <>
                        {scannedProducts &&
                          scannedProducts.map((data, index) => (
                            <>
                              <div
                                className="product-card"
                                style={{
                                  backgroundColor:
                                    data.type !== "big"
                                      ? "#f5faff"
                                      : "transparent",
                                }}
                              >
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_sku || ""}
                                  </h5>
                                  <h5 className="product-card-name my-1">
                                    {data.product_name || ""}
                                  </h5>

                                  <h5
                                    className="product-card-mrp mt-1"
                                    style={{ color: "grey" }}
                                  >
                                    {data.type == "big" ? (
                                      <>
                                        AVAIL QTY:{" "}
                                        {data.all_series
                                          ? data.all_series.length
                                          : 0}
                                      </>
                                    ) : (
                                      <>AVAIL QTY: {data.in_stock || 0}</>
                                    )}
                                  </h5>

                                  <div
                                    style={{
                                      marginTop: "5px",
                                      display: "flex",
                                      color: "red",
                                    }}
                                  >
                                    <LuArrowBigDown
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                    <p>Converting To</p>
                                  </div>

                                  {data.target_sku ? (
                                    <>
                                      <h5 className="product-card-sku mt-2">
                                        #{data.target_sku || ""}
                                      </h5>
                                      <h5
                                        style={{ color: "green" }}
                                        className="product-card-name my-1"
                                      >
                                        {data.target_product_name || ""}
                                      </h5>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="mt-2"
                                        onClick={() => handleOpenDialog(index)}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                      >
                                        Select Target Product
                                      </Button>
                                    </>
                                  )}

                                  <div
                                    style={{
                                      display: "flex",
                                      borderTop: "1px solid #dbdbdb",
                                      marginTop: "7px",
                                    }}
                                  >
                                    {data.type == "big" ? (
                                      <>
                                        {/* <TextField
                                          className="mt-2"
                                          style={{ maxWidth: "150px" }}
                                          size="small"
                                          type="number"
                                          value={
                                            data.selectedSeries
                                              ? data.selectedSeries.length
                                              : 0
                                          }
                                          disabled={true}
                                        /> */}

                                        <OutlinedInput
                                          style={{ maxWidth: "120px" }}
                                          className="mt-2"
                                          size="small"
                                          type="number"
                                          disabled={true}
                                          value={
                                            data.selectedSeries
                                              ? data.selectedSeries.length
                                              : 0
                                          }
                                          startAdornment={
                                            <InputAdornment position="start">
                                              Qty
                                            </InputAdornment>
                                          }
                                        />

                                        <FaTasks
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "37px",
                                            color: "#00528a",
                                            marginLeft: "5px",
                                            border: "1px solid #00528a",
                                            borderRadius: "4px",
                                            padding: "8px",
                                            marginTop: "7.5px",
                                          }}
                                          onClick={() =>
                                            handleOpenSeriesModal(data)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <OutlinedInput
                                          style={{ maxWidth: "120px" }}
                                          className="mt-2"
                                          size="small"
                                          type="number"
                                          value={data.qty || ""}
                                          onChange={(e) =>
                                            handleSamllProductQuantityChange(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          InputProps={{
                                            inputProps: {
                                              min: 0,
                                            },
                                          }}
                                          autoComplete="off"
                                          startAdornment={
                                            <InputAdornment position="start">
                                              Qty
                                            </InputAdornment>
                                          }
                                        />

                                        {/* <TextField
                                          style={{ maxWidth: "150px" }}
                                          className="mt-2"
                                          size="small"
                                          type="number"
                                          value={data.qty || ""}
                                          onChange={(e) =>
                                            handleSamllProductQuantityChange(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          InputProps={{
                                            inputProps: {
                                              min: 0,
                                            },
                                          }}
                                          placeholder="Qty"
                                          autoComplete="off"
                                        /> */}
                                      </>
                                    )}

                                    <FaTrashAlt
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "37px",
                                        color: "red",

                                        position: "absolute",
                                        right: "20px",
                                        border: "1px solid red",
                                        borderRadius: "4px",
                                        padding: "8px",
                                        marginTop: "7.5px",
                                      }}
                                      onClick={() =>
                                        handleDeleteProduct(data.product_sku)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {isWideScreen ? null : (
                <>
                  <div className="card p-3 mt-2">
                    <Button
                      disabled={scannedProducts && scannedProducts.length < 1}
                      style={{ height: "37px" }}
                      onClick={handleSubmitData}
                      variant="contained"
                    >
                      Confirm Conversion
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 my-2 order-1 order-lg-2">
              <div className="card p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    value={warehouse} // Ensure this is controlled by state
                    onChange={handleWarehouseChange}
                    label="Warehouse"
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  className="mt-2"
                  type="date"
                  value={date}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => setDate(e.target.value)}
                />

                <TextArea
                  className="custom_textareafield mt-2"
                  placeholder="Type your message here (if any)"
                  size="small"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>

              {isWideScreen ? (
                <>
                  <div className="card p-3 mt-2">
                    <Button
                      disabled={scannedProducts && scannedProducts.length < 1}
                      style={{ height: "37px" }}
                      onClick={handleSubmitData}
                      variant="contained"
                    >
                      Confirm Conversion
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddConversion;
