import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import PrintBarcode from "../Purchase/PrintBarcode";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FilterSelectionModal from "../../Components/FilterModal/FilterSelectionModal";
import { useScreenWidth } from "../../useScreenWidth";
import { Label } from "semantic-ui-react";

function SeriesModal({
  open,
  setOpen,
  pseriesname,
  pseriessku,
  serieses,
  sendData,
}) {
  const isWideScreen = useScreenWidth();
  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const [selectedFilterWarehouse, setSelectedFilterWarehouse] = useState("");

  const [filteredSeries, setFilteredSeries] = useState(serieses); // Initialize with all serieses

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintBarCode = (detail) => {
    // Prepare the data for printing
    const barcodeData = {
      sku: sendData.sku, // Format SKU with series
      series: detail.series, // Use the selected series
      part_number: sendData.part_number,
      name: sendData.name,
      brand: sendData.brand__product_brand,
    };

    // console.log("barcodeData: ", barcodeData);

    // Wrap in an array to maintain consistency
    setBarcodes([barcodeData]);
    setOpenModalBarcode(true);

    // console.log("setOpenModalBarcode TRUE HO GYAS");
  };

  const [selectedSeries, setSelectedSeries] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectSeries = (data) => {
    setSelectedSeries((prev) =>
      prev.some((s) => s.series === data.series)
        ? prev.filter((s) => s.series !== data.series)
        : [...prev, data]
    );
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedSeries([]);
    } else {
      setSelectedSeries(filteredSeries);
    }
    setAllSelected(!allSelected);
  };

  // Automatically update `allSelected` based on `selectedSeries`
  useEffect(() => {
    setAllSelected(
      filteredSeries.length > 0 &&
        selectedSeries.length === filteredSeries.length
    );
  }, [selectedSeries, filteredSeries]);

  const handlePrintAll = () => {
    const formatedData = selectedSeries.map((item) => ({
      sku: sendData.sku,
      series: item.series,
      part_number: sendData.part_number,
      name: sendData.name,
      brand: sendData.brand__product_brand,
    }));
    setBarcodes(formatedData);
    setOpenModalBarcode(true);
  };

  // Effect to update filteredSeries when selectedFilterWarehouse or serieses change
  useEffect(() => {
    if (selectedFilterWarehouse && selectedFilterWarehouse.length > 0) {
      setFilteredSeries(
        serieses.filter((series) =>
          selectedFilterWarehouse.includes(series.ware_house__ware_house)
        )
      );
    } else {
      setFilteredSeries(serieses); // Show all data if no filter is selected
    }
  }, [selectedFilterWarehouse, serieses]);

  // Extract unique warehouses
  const uniqueWarehouses = Array.from(
    new Set(serieses.map((item) => item.ware_house__ware_house))
  ).map((warehouse) => ({
    value: warehouse,
    label: warehouse,
  }));

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              {pseriessku}
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              {pseriesname}
            </Typography>
          </Breadcrumbs>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            {isWideScreen ? (
              <>
                <div
                  className="responsive-table1"
                  style={{ maxHeight: "60vh" }}
                >
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleSelectAll}
                            indeterminate={
                              selectedSeries.length > 0 &&
                              selectedSeries.length < serieses.length
                            }
                          />
                        </th>
                        <th>SKU</th>
                        <th>
                          <FilterSelectionModal
                            title="Warehouse"
                            options={uniqueWarehouses}
                            selectedOptions={selectedFilterWarehouse}
                            onSelect={setSelectedFilterWarehouse}
                          />
                        </th>
                        <th>Row</th>
                        <th>Rack</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSeries.length > 0 ? (
                        filteredSeries
                          .sort((a, b) => Number(a.series) - Number(b.series)) // Sort numerically
                          .map((data, index) => {
                            const isSelected = selectedSeries.some(
                              (s) => s.series === data.series
                            );
                            return (
                              <tr
                                key={index}
                                style={
                                  isSelected ? { background: "#e1ebfd" } : {}
                                }
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={() => handleSelectSeries(data)}
                                  />
                                </td>
                                <td className="text-nowrap">
                                  <span
                                    style={{ color: "grey" }}
                                  >{`${pseriessku}_`}</span>
                                  <b style={{ color: "red" }}>{data.series}</b>
                                </td>
                                <td>{data.ware_house__ware_house}</td>
                                <td>
                                  {data.rack__row__row_name
                                    ? data.rack__row__row_name
                                    : "-"}
                                </td>
                                <td>
                                  {data.rack__rack_name
                                    ? data.rack__rack_name
                                    : "-"}
                                </td>
                                <td align="right">
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handlePrintBarCode(data)}
                                  >
                                    Print
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={6} align="center">
                            No series available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="responsive-mobile-table">
                  {filteredSeries.length > 0 ? (
                    filteredSeries
                      .sort((a, b) => Number(a.series) - Number(b.series)) // Sort numerically
                      .map((data, index) => {
                        const isSelected = selectedSeries.some(
                          (s) => s.series === data.series
                        );
                        return (
                          <div
                            className="product-card"
                            key={index}
                            style={isSelected ? { background: "#e1ebfd" } : {}}
                          >
                            <div className="py-1 px-2 w-100 ">
                              <div>
                                <h5 className="product-card-sku">
                                  <span
                                    style={{ color: "grey" }}
                                  >{`${pseriessku}_`}</span>
                                  <b style={{ color: "red" }}>{data.series}</b>
                                </h5>
                                <h5 className="product-card-name">
                                  {data.ware_house__ware_house}
                                </h5>
                                <h5 className="product-card-mrp">
                                  <span
                                    style={{
                                      color: "grey",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Rack:{" "}
                                  </span>{" "}
                                  <Label size="tiny">
                                    {data.rack__row__row_name
                                      ? data.rack__row__row_name
                                      : "-"}{" "}
                                  </Label>
                                  <Label size="tiny" color="error">
                                    {data.rack__rack_name
                                      ? data.rack__rack_name
                                      : "-"}
                                  </Label>
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={6} align="center">
                        No series available
                      </td>
                    </tr>
                  )}
                </div>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {selectedSeries && selectedSeries.length > 0 && (
            <Button variant="outlined" onClick={handlePrintAll} color="primary">
              Print All
            </Button>
          )}

          <Button variant="outlined" onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* PrintRackBarcode Modal */}
      <PrintBarcode
        handleCloseBarcode={setOpenModalBarcode}
        openBarcode={openModalBarcode}
        barcodes={barcodes}
      />
    </>
  );
}

export default SeriesModal;
