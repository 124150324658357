import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useScreenWidth } from "../../../useScreenWidth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "900px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function InvenModal({ setOpenModal, openModal, data }) {
  const isWideScreen = useScreenWidth();

  const handleClose = () => {
    setOpenModal(false);
  };

  // console.log(data.obj_send, "is data");
  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Transfer Inventory Details
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            {isWideScreen ? (
              <div className="responsive-table1" style={{ maxHeight: "30vh" }}>
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th align="left">Warehouse</th>
                      <th align="left">Row</th>
                      <th align="left">Rack</th>
                      <th align="left">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      <>
                        {data.map((dataa, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{dataa.obj_send.ware_house}</td>
                            <td>
                              {dataa.obj_send.row ? dataa.obj_send.row : "-"}
                            </td>
                            <td>
                              {dataa.obj_send.rack ? dataa.obj_send.rack : "-"}
                            </td>
                            <td>
                              {dataa.obj_send.quantity
                                ? dataa.obj_send.quantity
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <div className="card p-2 responsive-mobile-table">
                  {data &&
                    data.map((dataa, index) => (
                      <>
                        <div className="product-card">
                          <div className="py-1 px-2 w-100">
                            <h5 className="product-card-name">
                              {dataa.obj_send.ware_house || "N/A"}
                            </h5>
                            <h5>
                              Row :{" "}
                              {dataa.obj_send.row ? dataa.obj_send.row : "-"} ||
                              Rack :{" "}
                              {dataa.obj_send.rack ? dataa.obj_send.rack : "-"}{" "}
                              || QTY :{" "}
                              {dataa.obj_send.quantity
                                ? dataa.obj_send.quantity
                                : "-"}
                            </h5>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InvenModal;
