import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { MdInventory } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
import SaleTypeModal from "../Pages/Sales/SelectSaleTypeModal";
import { FaThList, FaTruck, FaUsers } from "react-icons/fa"; // Dispatch icon
import { FaShoppingCart } from "react-icons/fa"; // Purchase icon
import { FaChartLine } from "react-icons/fa"; // Sales icon
import { FaWarehouse } from "react-icons/fa"; // Inventory icon
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useScreenWidth } from "../useScreenWidth";

function Sidebar({ isActive, toggleSidebar }) {
  const isWideScreen = useScreenWidth();

  const [openSaleType, setOpenSaleType] = useState(false);
  const handleSaleType = () => {
    setOpenSaleType(true);
  };

  const initialMenuItems = [
    {
      title: "Dashboard",
      path: "/",
      icon: <MdSpaceDashboard />,
    },
    {
      title: "Products",
      icon: <AiOutlineProduct />,
      subItems: [
        { path: "/all_products", label: "All Products" },
        { path: "/add_product", label: "Add Products" },
        { path: "/unallocated-products", label: "Unallocated Products" },

      ],
    },
    {
      title: "Inventory",
      icon: <MdInventory />,
      subItems: [
        { path: "/warehouses", label: "Warehouses" },
        { path: "/stock_adjustments", label: "Adjustments" },
        { path: "/conversions", label: "Conversion" },
        { path: "/stock_transfer", label: "Stock Transfer" },
        { path: "/stock-ledger", label: "Stock Ledger" },
      ],
    },
    {
      title: "Purchases",
      icon: <FaShoppingCart />,
      subItems: [
        { path: "/all_purchases", label: "Purchase Orders" },
        { path: "/all-draft-purchase", label: "Draft Purchases" },
      ],
    },
    {
      title: "Sales",
      icon: <FaChartLine />,
      subItems: [
        { path: "/all_sales", label: "Sales" },
        { label: "Create Sale", onClick: handleSaleType },
      ],
    },
    {
      title: "Cust / Vend",
      icon: <FaUsers />,
      subItems: [
        { path: "/all-vendors", label: " Vendors" },
        { path: "/all-customers", label: " Customers" },
      ],
    },
    {
      title: "Requirements",
      icon: <FaThList />,
      subItems: [{ path: "/requirements-list", label: "Requirement List" }],
    },
    {
      title: "Dispatch",
      icon: <FaTruck />,
      subItems: [{ path: "/dispatch-history", label: "Dispatch History" }],
    },
  ];

  const [menuItems, setMenuItems] = useState(initialMenuItems);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(menuItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setMenuItems(reorderedItems);
  };

  const location = useLocation();

  const isActiveSubItem = (subItems) => {
    return subItems.some((subItem) =>
      location.pathname.startsWith(subItem.path)
    );
  };

  return (
    <>
      {/* isWideScreen */}
      <SaleTypeModal
        openSaleType={openSaleType}
        setOpenSaleType={setOpenSaleType}
        toggleSidebar={toggleSidebar}
      />

      <nav
        className={`sidebar sidebar-offcanvas ${isActive ? "active" : ""}`}
        id="sidebar"
        style={{ backgroundColor: "##00265c" }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="menuItems">
            {(provided) => (
              <ul
                className="nav scrollNav"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {menuItems.map((item, index) => (
                  <Draggable
                    key={item.title}
                    draggableId={item.title}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        className={`nav-item ${
                          item.subItems ? "has-subItems" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.path ? (
                          <NavLink className="nav-link" to={item.path}>
                            <span className="icon-bg">{item.icon}</span>
                            <span className="menu-title">{item.title}</span>
                          </NavLink>
                        ) : (
                          <>
                            <a
                              className="nav-link"
                              data-bs-toggle="collapse"
                              href={`#${item.title
                                .toLowerCase()
                                .replace(/\s/g, "-")}`}
                              aria-expanded="false"
                              aria-controls="forms"
                            >
                              <span className="icon-bg">{item.icon}</span>
                              <span className="menu-title">{item.title}</span>
                              <i className="menu-arrow"></i>
                            </a>
                            {item.subItems && (
                              <div
                                className="collapse"
                                id={item.title
                                  .toLowerCase()
                                  .replace(/\s/g, "-")}
                              >
                                <ul className="nav flex-column sub-menu">
                                  {item.subItems.map((subItem, subIndex) => (
                                    <li className="nav-item" key={subIndex}>
                                      {subItem.onClick ? (
                                        <a
                                          className="nav-link"
                                          style={{ cursor: "pointer" }}
                                          onClick={subItem.onClick}
                                        >
                                          {subItem.label}
                                        </a>
                                      ) : (
                                        <NavLink
                                          className="nav-link"
                                          to={subItem.path}
                                          onClick={
                                            isWideScreen ? null : toggleSidebar
                                          }
                                        >
                                          {subItem.label}
                                        </NavLink>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <ul>
          <li
            style={{
              position: "absolute",
              bottom: "5px",
              left: "20px",
              listStyle: "none",
            }}
          >
            <p style={{ color: "white" }} className="menu-title">
            Version 1.0.11 | Last Update: 17-01-2025
            </p>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;