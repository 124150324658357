import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { remove_requirement_item_from_picking, apiKey } from "../Api";
import toast, { Toaster } from "react-hot-toast";
import { TextField } from "@mui/material";

function RemovePickupModal({
  showRemoveQty,
  setShowRemoveQty,
  fetchData,
  item_pk,
  picked_qty,
}) {
  const [quantity, setQuantity] = useState("");
  const handleClose = () => {
    setShowRemoveQty(false);
  };

  const handleOnChange = (e) => {
    let setQty = e.target.value;

  
    if (setQty <= picked_qty) {
      setQuantity(setQty);
    } else {
      setQuantity("");
      toast.error("Quantity can't exceed Picked Quantity!")
    }
  };

  const handleRemove = () => {
    if (quantity < 1) {
      toast.error("Quantity cannot be empty or 0(zero)!");
      return;
    }
    const data = {
      item_pk: parseInt(item_pk),
      quantity: parseInt(quantity),
    };

    try {
      axios
        .post(remove_requirement_item_from_picking, data, {
          headers: {
            "API-Key": apiKey,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            toast.success(`Product Item Removed Successfully!`);
            setShowRemoveQty(false);
            fetchData();
          } else {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          // console.log(error, "aage");
        });
      // console.log(response.data.data.products);
    } catch (error) {
      // console.log(error, "error aage piche");
      toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog
        open={showRemoveQty}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Remove Product</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            {/* <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText> */}
            <TextField
              type="number"
              autoFocus
              label="Quantity"
              style={{ cursor: "pointer" }}
              value={quantity}
              onChange={handleOnChange}
              required
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={quantity < 1}
            onClick={handleRemove}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RemovePickupModal;
