import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { edit_customer, fetch_customer_category } from "../../../Api";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

function EditCustomer({
  customerEditDetail,
  apiKey,
  closeModal,
  fetchCustomers,
}) {
  const [customerName, setCustomerName] = useState(
    customerEditDetail.customer_name
  );
  const [email, setEmail] = useState(customerEditDetail.email);
  const [phone, setPhone] = useState(customerEditDetail.phone);
  const [alternativePhone, setAlternativePhone] = useState(
    customerEditDetail.alternativ_phone
  );
  const [gstNumber, setGstNumber] = useState(customerEditDetail.gst_number);
  const [selectedCategory, setSelectedCategory] = useState(
    customerEditDetail.category_ref.id
  );

  useEffect(() => {
    setCustomerName(customerEditDetail.customer_name);
    setEmail(customerEditDetail.email);
    setPhone(customerEditDetail.phone);
    setAlternativePhone(customerEditDetail.alternativ_phone);
    setGstNumber(customerEditDetail.gst_number);
  }, [customerEditDetail]);

  const handleSave = (e) => {
    e.preventDefault();
    const customer_data = {
      customer_name: customerName,
      email,
      phone,
      alternativ_phone: alternativePhone,
      gst_number: gstNumber,
      category_pk: selectedCategory || 0,
    };

    const updatedData = {
      customer_pk: customerEditDetail.id,
      customer_data,
    };

    console.log("updatedCustomer : ", updatedData);

    axios
      .post(edit_customer, updatedData, {
        headers: {
          "API-key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          toast.success(response.data.message);
          fetchCustomers();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log("Error Updating Customer Detail:", error);
      });
  };

  // fetch_customer_category
  const [customerCategory, setCustomerCategory] = useState([]);
  const fetchCustomerCategory = () => {
    axios
      .get(fetch_customer_category, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data && response.data.categories) {
          setCustomerCategory(response.data.categories);
        } else {
          console.error("Unexpected response structure", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching customer category:", error);
      });
  };

  useEffect(() => {
    fetchCustomerCategory();
  }, []);

  return (
    <>
      <Toaster position="top-right" />
      <div>
        <h2>Edit Customer</h2>
        <form onSubmit={handleSave}>
          <div className="row" style={{ maxWidth: "700px" }}>
            <div className="col-md-6">
              <TextField
                label="Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                fullWidth
                autoFocus
                size="small"
                margin="normal"
              />
            </div>

            <div className="col-md-6">
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-md-4 mt-3">
              <FormControl fullWidth size="small" required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  label="Category"
                >
                  <MenuItem> Select Category</MenuItem>
                  {customerCategory.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4">
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-md-4">
              <TextField
                label="Alternative Phone"
                value={alternativePhone}
                onChange={(e) => setAlternativePhone(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-md-4">
              <TextField
                label="GST Number"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
            </div>
          </div>
          <Button variant="contained" color="primary" type="submit">
            Save Changes
          </Button>
          <Button
            variant="outlined"
            onClick={closeModal}
            color="error"
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </form>
      </div>
    </>
  );
}

export default EditCustomer;
