import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Typography,
  Button,
  TextField,
  Card,
  Modal,
} from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  apiKey,
  fetch_customer_category,
  fetch_discount_settings,
  set_discount_settings,
} from "../../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function DiscountAdjustment() {
  const navigate = useNavigate();
  const [customerCategory, setCustomerCategory] = useState([]);
  const [brandsData, setBrandsData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchQueries, setSearchQueries] = useState({});
  const [discountRate, setDiscountRate] = useState(0.0);
  const [selectedEditCategoryId, setSelectedEditCategoryId] = useState(0.0);

  const fetchCustomerCategory = () => {
    axios
      .get(fetch_customer_category, { headers: { "API-Key": apiKey } })
      .then((response) => {
        if (response.data && response.data.categories) {
          setCustomerCategory(response.data.categories);
          // Fetch all brands data after fetching categories
          response.data.categories.forEach((category) => {
            fetchBrandsData(category.id); // Fetch brands for each category by default
          });
        }
      })
      .catch((error) =>
        console.error("Error fetching customer categories:", error)
      );
  };

  const fetchBrandsData = (categoryId, query = "") => {
    axios
      .get(fetch_discount_settings, {
        params: { category_pk: categoryId, search: query },
        headers: { "API-Key": apiKey },
      })
      .then((response) => {
        setBrandsData((prevData) => ({
          ...prevData,
          [categoryId]: response.data.data,
        }));
      })
      .catch((error) => console.error("Error fetching brands data:", error));
  };

  useEffect(() => {
    fetchCustomerCategory();
  }, []);

  const handleSearchChange = (categoryId, searchQuery) => {
    setSearchQueries((prev) => ({ ...prev, [categoryId]: searchQuery }));
    fetchBrandsData(categoryId, searchQuery);
  };

  const handleEditClick = (brand, categoryId) => {
    setSelectedBrand(brand);
    setSelectedEditCategoryId(categoryId);
    setDiscountRate(brand.discount_rate);
    setShowModal(true);
  };

  const handleEditDiscount = (e) => {
    e.preventDefault();
    // console.log("Saved new discount rate:", discountRate);

    let formattedData = {
      category_pk: selectedEditCategoryId,
      brand_pk: selectedBrand.id,
      discount_rate: parseFloat(discountRate),
    };

    axios
      .post(set_discount_settings, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Discount rate updated successfully");
          fetchCustomerCategory();
        } else {
          toast.error(response.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setShowModal(false); // Close the modal after saving
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/settings")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Settings</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Discount Adjustment
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="my-2">
            <div className="row">
              {customerCategory.map((category) => (
                <div className="col-md-4 mb-2" key={category.id}>
                  <Card variant="outlined" sx={{ padding: 2 }}>
                    <Typography variant="h6" className="mb-1">
                      Customer category : {category.category}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Search brands..."
                      value={searchQueries[category.id] || ""}
                      onChange={(e) =>
                        handleSearchChange(category.id, e.target.value)
                      }
                      sx={{ marginBottom: 2 }}
                    />
                    <div style={{ maxHeight: "400px", overflow: "auto" }}>
                      {brandsData[category.id] ? (
                        brandsData[category.id].map((brand) => (
                          <div
                            key={brand.id}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div>
                              <Typography variant="body1">
                                <strong>{brand.product_brand}</strong>
                              </Typography>
                              <Typography variant="body2" className="fs-6">
                                Discount Rate: {brand.discount_rate}%
                              </Typography>
                            </div>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                handleEditClick(brand, category.id)
                              }
                            >
                              Edit
                            </Button>
                          </div>
                        ))
                      ) : (
                        <Typography>No brands available</Typography>
                      )}
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Editing Discount Rate */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: 24,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Edit Discount Rate
          </Typography>
          <form onSubmit={handleEditDiscount}>
            {selectedBrand && (
              <div>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {selectedBrand.product_brand}
                </Typography>
                {/* <TextField
                  fullWidth
                  label="Discount Rate (%)"
                  type="integer"
                  value={discountRate || 0}
                  onChange={(e) => setDiscountRate(e.target.value)}
                  sx={{ mt: 2 }}
                  size="small"
                  autoFocus
                /> */}

                <TextField
                  fullWidth
                  label="Discount Rate (%)"
                  type="number"
                  value={discountRate}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,3}$/.test(value)) {
                      setDiscountRate(value);
                    }
                  }}
                  sx={{ mt: 2 }}
                  size="small"
                  autoFocus
                  inputProps={{
                    min: "0",
                    step: "0.001",
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => setShowModal(false)}
                variant="outlined"
                color="error"
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="outlined" color="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default DiscountAdjustment;
