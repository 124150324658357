// utils/dateUtils.js

const formatDate = (dateString) => {
  if (!dateString || dateString === "-") {
    return "-";
  }

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // handle teens
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      <span>
        {day}
        <span style={{ fontSize: "9px", marginTop: "-8px" }}>
          {ordinalSuffix(day)}
        </span>{" "}
        {month}, {year}
      </span>
    </>
  );
};

export { formatDate };
