import React, { useState, useRef } from "react";
import axios from "axios";
import { apiKey, add_product_brand } from "../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from 'react-hot-toast';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddBrand({ openaddbrand, setOpenAddBrand, fetchBrandType , setProductBrand }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [brand, setBrand] = useState("");



  const handleAddBrand = (e) => {
    e.preventDefault()
    if (brand.length < 2) {

      toast.error("Brand Name Required!");

      return;
    }

    let data = {
      product_brand: brand,
    };

    axios
      .post(add_product_brand, data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === 1) {
          // setOpenSuccess(true);
          toast.success(`A new brand ${brand} has been added successfully!`);
          fetchBrandType();
          setBrand("");
          setProductBrand(brand)
          setOpenAddBrand(false);
        } else {
          toast.error(response.data.message);
          // setOpenError(true);
          setBrand("");
          setOpenAddBrand(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        // setBrand("");
        // setOpenAddBrand(false);
        // setOpenError(true);
      });
  };

  const handleClose = (e) => {
    // Close modal only if clicking on the overlay
    setBrand("");
    setOpenAddBrand(false);
  };

  return (
    <>
    <Toaster  position="top-right" />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openaddbrand}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        // onClose={handleClosecustomer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb" className="mb-2">
            <Typography color="inherit">Add Product</Typography>
            <Typography sx={{ color: "text.primary" }}>Add Brand</Typography>
          </Breadcrumbs>

        <form onSubmit={handleAddBrand}>
          <TextField
            type="text"
            value={brand}
            label="Brand"
            size="small"
            fullWidth
            autoFocus
            onChange={(e) => setBrand(e.target.value)}
          />

          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px", marginRight: "5px" }}
              onClick={handleClose}
              color="error"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px" }}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </div>

        </form>
        </Box>
      </Modal>
    </>
  );
}

export default AddBrand;
