import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { fetch_allocation_history, apiKey, fetch_warehouse } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useScreenWidth } from "../../useScreenWidth";
import Select from "@mui/material/Select";
import ViewAllocationHistoryModal from "./ViewAllocationHistoryModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Icon, Label, Pagination } from "semantic-ui-react";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import CustomLoader from "../../Components/Loader";
import SubmitLoader from "../../Components/SubmitLoader";

function AllocationHistory() {
  const isWideScreen = useScreenWidth();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWarehouse(ware);
    setSubmitLoader(true);
  };

  useEffect(() => {
    fetchDataWarehouse();
    fetchData();
  }, [warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };


  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [currentPage, pageSize, searchquery]);

  const fetchData = (page = currentPage, query = searchquery) => {

    if(!warehouse){
      toast("Please choose an warehouse!", {
        icon:'😁'
      })
      return null
    }
    const data = {
      page,
      per_page: pageSize,
      search: query,
      warehouse_pk: warehouse ,
      selectedBrands: [],
      selectedTypes: [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_allocation_history, data, { headers })
      .then((response) => {
        console.log(response.data)
        setHistory(response.data.allocation_history);
        setTotalPages(response.data.num_pages);
        setLoading(false);
        setSubmitLoader(false);
      })
      .catch((error) => {
 
        toast.error(error?.response?.data?.message || "Something went wrong!");
        setLoading(false);
        setSubmitLoader(false);
      });
  };



  // Modal of View Allocation

  const [opendetail, setOpenDetail] = useState(false);
  const [selectedAllocatedDetail, setSelectedAllocatedDetail] = useState();

  const handleViewAllocationHistory = (allocateDetails) => {
    setSelectedAllocatedDetail(allocateDetails);
    setOpenDetail(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd : h:mma");
  };

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <ViewAllocationHistoryModal
        opendetail={opendetail}
        allocateDetails={selectedAllocatedDetail}
        apiKey={apiKey}
        setOpenDetail={setOpenDetail}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/unallocated-products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Allocation History
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8 md-my-0"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  style={{ maxWidth: "200px" }}
                >
                  <Select value={warehouse} onChange={handleWarehouseChange}>
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Allocated By</th>
                      <th>Row</th>
                      <th>Rack</th>
                      <th>Products</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length > 0 ? (
                      <>
                        {history &&
                          history.map((data) => (
                            <>
                              <tr>
                                <td style={{width:'50px'}}>#{data.id}</td>
                                <td>{formatDate(data.date)}</td>
                                <td>{data.user_name}</td>
                                <td><b style={{color:'red'}}>{data.row.rack_number__row__row_name}</b></td>
                                <td><b style={{color:'red'}}>{data.rack.rack_number__rack_name}</b></td>
                                <td>{data.history_detail.length}</td>

                                <td style={{ textAlign: "center" }}>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      handleViewAllocationHistory(data)
                                    }
                                  >
                                    Details
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {history.length > 0 ? (
                  <>
                    {history &&
                      history.map((data) => (
                        <>
                          <div
                            className="product-card position-relative"
                            onClick={() => handleViewAllocationHistory(data)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-mrp my-1">
                                {formatDate(data.date)} |{" "}
                                <span style={{ color: "grey" }}>
                                  {data.user_name || "-"}
                                </span>
                              </h5>
                              <h5>
                                <Label size="tiny">
                                  {data.row.rack_number__row__row_name}
                                </Label>
                                <Label size="tiny" color="error">
                                  {data.rack.rack_number__rack_name}
                                </Label>
                              </h5>

                              <Label
                                color="blue"
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "7px",
                                  fontSize: "11px",
                                }}
                                className="product-card-mrp"
                              >
                                {data.history_detail.length} QTY
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllocationHistory;
