import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetch_rows, apiKey, fetch_racks, delete_row } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./RowRackInvenPopUp";
import ViewRacks from "./UpdateRows/RacksUpdateModal";
import AddNewRow from "./UpdateRows/AddNewRow";
import DeleteRowModal from "../../Messages/DeleteWarehouse/DeleteRow";
import EditRow from "./UpdateRows/EditRowModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import PrintRackBarcode from "./PrintRackBarcode";
import toast from "react-hot-toast";
import { useScreenWidth } from "../../useScreenWidth";
import CustomLoader from "../../Components/Loader";
import { FaWarehouse } from "react-icons/fa";
import { BiTask, BiTaskX } from "react-icons/bi";
import { Label } from "semantic-ui-react";

function RowRackDetails() {
  const isWideScreen = useScreenWidth();
  const [rows, setRows] = useState([]);
  const [inven, setInven] = useState([]);
  const [error, setError] = useState(null);
  const [row, setRow] = useState("");
  const [racks, setRacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openAddRowModal, setOpenAddRowModal] = useState(false);
  const [openEditRowModal, setOpenEditRowModal] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const [allocated, setAllocated] = useState(0);
  const [unallocated, setUnAllocated] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(
        fetch_rows,

        {
          params: {
            warehouse_id: id,
          },
          headers: {
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        setWarehouse(response.data.ware_house);
        console.log(response.data);
        setAllocated(response.data.allocated_qauntity);
        setUnAllocated(response.data.unallocated_quantity);
        setRows(response.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const [row_id, setRowId] = useState("");

  const handleViewClick = (rowid, row) => {
    setRowId(rowid);
    setOpenViewModal(true);

    try {
      axios
        .get(fetch_racks, {
          params: {
            row_pk: parseInt(rowid),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setRacks(response.data.racks);
          setRow(row);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditRow = (rowid, row) => {
    setRowId(rowid);
    setRow(row);

    setOpenEditRowModal(true);
  };

  const handleAddRowClick = () => {
    setOpenAddRowModal(true);
  };

  // Print

  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [racksbar, setRacksData] = useState([]);
  const handlePrintRow = (rowid, row) => {
    let setRowId = rowid;
    let allRacks;
    let setRow;

    try {
      axios
        .get(fetch_racks, {
          params: {
            row_pk: parseInt(rowid),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          allRacks = response.data.racks;
          setRow = row;

          // console.log("all racks : ",allRacks)

          const racksbarData = allRacks.map((selectedRow) => {
            return {
              barcode_value: `W${id}-R${setRowId}-R${selectedRow.id}`,
              row_name: row,
              warehouse_name: warehouse,
              rack_name: selectedRow.rack_name,
            };
          });

          // Open the PrintBarcode modal with the prepared data
          setOpenModalBarcode(true);
          setRacksData(racksbarData);
        })
        .catch((error) => {
          console.log("Error : ", error.response?.data?.message);
        });
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };

  // Selection & Delete Rows
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (row) => {
    if (row.is_deletable === 1) {
      setSelectedRows((prevSelected) => {
        if (prevSelected.some((selectedRow) => selectedRow.id === row.id)) {
          return prevSelected.filter(
            (selectedRow) => selectedRow.id !== row.id
          );
        } else {
          return [...prevSelected, row];
        }
      });
    }
  };

  const handleSelectAllRows = () => {
    const deletableRows = rows.filter((row) => row.is_deletable === 1);
    setSelectedRows(
      selectedRows.length === deletableRows.length ? [] : deletableRows
    );
  };

  const handleDeleteRows = () => {
    if (selectedRows.length === 0) {
      toast.error("No rows selected!");
      return;
    }
    axios
      .delete(delete_row, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          row_pks: selectedRows.map((row) => row.id),
        },
      })
      .then(() => {
        toast.success(`Selected Rows Deleted Successfully!`);
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <PrintRackBarcode
        openModalBarcode={openModalBarcode}
        setOpenModalBarcode={setOpenModalBarcode}
        racksbar={racksbar}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        inven={inven}
        row_name={row}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <AddNewRow
        openModal={openAddRowModal}
        setOpenModal={setOpenAddRowModal}
        ware_id={id}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <DeleteRowModal
        openDeleteRow={openDeleteRow}
        setOpenDeleteRow={setOpenDeleteRow}
        fetchData={fetchData}
        row_id={row_id}
      />

      <EditRow
        openEditRowModal={openEditRowModal}
        setOpenEditRowModal={setOpenEditRowModal}
        row_name={row}
        row_id={row_id}
        fetchData={fetchData}
      />

      <ViewRacks
        openModal2={openViewModal}
        setOpenModal2={setOpenViewModal}
        racks={racks}
        row_name={row}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
        ware_id={id}
        warehouse={warehouse}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/warehouses")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Row Rack</Typography>
              <Typography sx={{ color: "text.primary" }}>
                {warehouse}
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row">
            <div className="col-lg-3 my-2">
              <div className="card p-3">
                <div style={{ display: "flex" }}>
                  <FaWarehouse
                    style={{ fontSize: "18px", marginLeft: "1.5px" }}
                  />
                  <div className="p-0 ps-2">
                    <h4>
                      Warehouse:{" "}
                      <span style={{ color: "black" }}>{warehouse || ""}</span>
                    </h4>
                  </div>
                </div>

                <div style={{ display: "flex" }} className="mt-3">
                  <BiTask style={{ fontSize: "20px", color: "green" }} />
                  <div className="p-0 ps-2">
                    <h4>
                      Allocated:{" "}
                      <span style={{ color: allocated < 1 ? "grey" : "green" }}>
                        {allocated || 0} QTY
                      </span>
                    </h4>
                  </div>
                </div>

                <div style={{ display: "flex" }} className="mt-3">
                  <BiTaskX style={{ fontSize: "20px", color: "red" }} />
                  <div className="p-0 ps-2">
                    <h4>
                      UnAllocated:{" "}
                      <span style={{ color: unallocated < 1 ? "grey" : "red" }}>
                        {unallocated || 0} QTY
                      </span>
                    </h4>
                  </div>
                </div>
              </div>

              <div className="card p-3 my-2">
                <div style={{ display: "flex" }} className="mt-1">
                  <Button
                    onClick={() => handleAddRowClick()}
                    variant="outlined"
                    fullWidth
                    size="small"
                  >
                    Add New Row
                  </Button>
                  {isWideScreen && selectedRows && selectedRows.length > 0 ? (
                    <Button
                      fullWidth
                      onClick={() => handleDeleteRows()}
                      variant="outlined"
                      size="small"
                      color="error"
                      style={{ marginLeft: "5px" }}
                    >
                      Delete Row
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-9 my-2">
              {isWideScreen ? (
                <>
                  <div className="responsive-table1">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ width: "2px" }}>
                            <input
                              type="checkbox"
                              checked={
                                selectedRows.length > 0 &&
                                selectedRows.length ===
                                  rows.filter((row) => row.is_deletable === 1)
                                    .length
                              }
                              indeterminate={
                                selectedRows.length > 0 &&
                                selectedRows.length <
                                  rows.filter((row) => row.is_deletable === 1)
                                    .length
                              }
                              onChange={handleSelectAllRows}
                            />
                          </th>
                          <th>#</th>
                          <th>Row</th>
                          <th>Total Racks</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows &&
                          rows.map((data) => (
                            <tr key={data.id}>
                              <td style={{ width: "2px" }}>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some(
                                    (selectedRow) => selectedRow.id === data.id
                                  )}
                                  onChange={() => handleSelectRow(data)}
                                  disabled={data.is_deletable !== 1}
                                />
                              </td>
                              <td style={{ width: "60px" }}>{data.id}</td>
                              <td>{data.row_name}</td>
                              <td
                                style={{
                                  color: data.rack_count < 1 ? "grey" : "black",
                                }}
                              >
                                <b>{data.rack_count} QTY</b>
                              </td>
                              <td style={{ width: "200px" }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handleViewClick(data.id, data.row_name)
                                  }
                                >
                                  View
                                </Button>
                                <Button
                                  style={{ marginLeft: "5px" }}
                                  variant="outlined"
                                  color="success"
                                  size="small"
                                  // onClick={() => handleInventory(index, data.row_name)}
                                >
                                  Inventory
                                </Button>

                                <Button
                                  style={{ marginLeft: "5px" }}
                                  variant="outlined"
                                  size="small"
                                  color="info"
                                  onClick={() =>
                                    handleEditRow(data.id, data.row_name)
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  disabled={data.rack_count === 0}
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    handlePrintRow(data.id, data.row_name)
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  Print
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table">
                    {rows.length > 0 ? (
                      <>
                        {rows &&
                          rows.map((data) => (
                            <>
                              <div
                                className="product-card"
                                onClick={() =>
                                  handleViewClick(data.id, data.row_name)
                                }
                              >
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.id}
                                  </h5>
                                  <h5 className="product-card-name">
                                    Row {data.row_name || "-"}
                                  </h5>

                                  <Label
                                    color={data.rack_count > 0 ? "green" : null}
                                    style={{
                                      position: "absolute",
                                      marginTop: "-20px",
                                      right: "12px",
                                      fontSize: "11px",
                                    }}
                                    className="product-card-mrp"
                                  >
                                    {data.rack_count} QTY
                                  </Label>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RowRackDetails;
