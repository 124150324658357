import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_customer,
  create_sale,
  getLastPrice,
  fetch_customer_address,
  fetch_sale_product_cashsale,
  fetch_sale_chilproducts,
  fetch_warehouse,
} from "../../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FaSitemap, FaTrashAlt } from "react-icons/fa";
import CreateCustomer from "../../OurClients/Clients/CreateCustomer";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";
import { Popup, TextArea } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { BsUpcScan } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Autocomplete } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import AddProductModal from "../../Products/AddProductModal";
import ConfirmSaleProductTransferModal from "./ConfirmSaleProductTransferModal";
import SmallItemTransfer from "./SmallItemTransfer";
import { useScreenWidth } from "../../../useScreenWidth";
import CustomLoader from "../../../Components/Loader";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AddCashSale() {
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const inputRef = useRef(null); // Create a ref for the input field

  const isWideScreen = useScreenWidth();
  const [openConfirmTransfer, setOpenConfirmTransfer] = useState(false);

  const [products, setProducts] = useState([]);
  const [scannedProducts, setScannedProducts] = useState([]);
  // const [scannedProducts, setScannedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
  const [last_price, setLast_price] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState(6);
  const [customeraddress, setCustomeraddress] = useState([]);
  const [address, setAddress] = useState("");
  const [discount_amount, setDiscount] = useState("");
  const [sale_date, setS_date] = useState(getCurrentDate());
  const [other_charge, setOtherCharge] = useState("");
  const [shipping_charge, setShippingCharge] = useState("");
  const [notes, setNotes] = useState("");
  const [percentage, setPercentage] = useState("");

  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [discounts, setDiscounts] = useState({});
  const [numericDiscounts, setNumericDiscounts] = useState({});

  const [scanData, setScanData] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );

  // Change warehouse
  const [openWarehouseChangeDialog, setOpenWarehouseChangeDialog] =
    useState(false);
  const [newChangeWarehouse, setNewChangeWarehouse] = useState(null); // To store updated Warehouse

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    if (scannedProducts && scannedProducts.length > 0) {
      setNewChangeWarehouse(ware); // Set the new warehouse value
      setOpenWarehouseChangeDialog(true); // Open the confirmation dialog
    } else {
      setWarehouse(ware);
    }
    // console.log(warehouse);
  };
  const handleConfirmWarehouseChange = () => {
    setScannedProducts([]); // Remove all products
    setWarehouse(newChangeWarehouse); // Set the new warehouse
    setOpenWarehouseChangeDialog(false); // Close the dialog
  };

  const handleCancelWarehouseChange = () => {
    setOpenWarehouseChangeDialog(false); // Close the dialog without changing the warehouse
  };

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, [searchquery]);

  useEffect(() => {
    fetchDataWarehouse();
  }, [apiKey]);

  // Set the warehouse state if a warehouse containing "Gate" is found
  useEffect(() => {
    const gateWarehouse = warehouses.find((ware) =>
      ware.ware_house.toLowerCase().includes("gate")
    );

    // If found, set it as the selected warehouse
    if (gateWarehouse) {
      setWarehouse(gateWarehouse.id);
    }
  }, [warehouses]);

  // Function to fetch warehouse data
  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        // console.log(response.data.warehouses);
      })
      .catch((error) => {
        console.error("Error fetching warehouses:", error);
      });
  };

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    clearTimeout(window.timeoutId);
      window.timeoutId = setTimeout(() => {
      if (newValue.length <= 5) {
        setScanData("");
        return;
      }

      if (newValue.length > 5) {
        if (isValidProductSku(newValue)) {
          let seriesExists = false;

          for (const product of scannedProducts) {
            if (
              product.sku === newValue.split("_")[0] &&
              product.additional_info?.includes(
                parseInt(newValue.split("_")[1], 10)
              )
            ) {
              seriesExists = true;
              break;
            }
          }

          if (seriesExists) {
            setSkuToRemove(newValue.split("_")[0]);
            setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
            setOpenRemoveP(true);

            return;
          } else {
            fetchProductData(newValue);
            playSound();
          }
        } else {
          playSound();
        }
      }
      setScanData("");
    }, 400);
  };

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      setScannedProducts((prev) => {
        const existingProduct = prev.find((item) => item.sku === baseSku);

        if (existingProduct) {
          if (existingProduct.additional_info?.includes(additionalInfo)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(additionalInfo);
            setOpenRemoveP(true);
            return prev;
          }

          const updatedProducts = prev.map((item) =>
            item.sku === baseSku
              ? {
                  ...item,
                  qty: item.qty + 1,
                  additional_info: [
                    ...(item.additional_info || []),
                    additionalInfo,
                  ],
                }
              : item
          );

          // Automatically set discount if available and original MRP is greater than 0
          const originalMRP = productData.mrp;

          if (productData.recomended_discount && originalMRP > 0) {
            const percentageValue = productData.recomended_discount; // Get the recommended discount
            const discountValue = (originalMRP * percentageValue) / 100; // Calculate discount value
            const newMRP = parseFloat(originalMRP - discountValue); // Calculate new MRP after discount

            // Update state with the calculated values
            setDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [productData.product_sku]: `${percentageValue}`, // Set the percentage as a string
            }));

            setNumericDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [productData.product_sku]: discountValue.toFixed(2), // Set the discount value
            }));

            setMrps((prevMrps) => ({
              ...prevMrps,
              [productData.product_sku]: newMRP, // Set the new MRP
            }));
          }

          // Update quantities with the length of additional_info array
          setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [baseSku]: existingProduct.additional_info.length + 1,
          }));

          return updatedProducts;
        } else {
          const newProduct = {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            part_number: productData.part_number,
            mrp: productData.mrp,
            in_stock: productData.in_stock,
            type: "big",
            last_price: productData.last_price,
            qty: 1,
            additional_info: [additionalInfo],
          };

          // Automatically set discount if available and original MRP is greater than 0
          const originalMRP = productData.mrp;

          if (productData.recomended_discount && originalMRP > 0) {
            const percentageValue = productData.recomended_discount; // Get the recommended discount
            const discountValue = (originalMRP * percentageValue) / 100; // Calculate discount value
            const newMRP = parseFloat(originalMRP - discountValue); // Calculate new MRP after discount

            // Update state with the calculated values
            setDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [productData.product_sku]: `${percentageValue}`, // Set the percentage as a string
            }));

            setNumericDiscounts((prevDiscounts) => ({
              ...prevDiscounts,
              [productData.product_sku]: discountValue.toFixed(2), // Set the discount value
            }));

            setMrps((prevMrps) => ({
              ...prevMrps,
              [productData.product_sku]: newMRP, // Set the new MRP
            }));
          }

          // Update quantities with the length of additional_info array
          setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [baseSku]: 1, // since it's a new product with a single additional info
          }));

          return [...prev, newProduct];
        }
      });
    }, 300),
    [customer] // dependencies
  );

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    let quantity = parseInt(value, 10);

    if (isNaN(quantity) || quantity <= 0) {
      quantity = 1; // Ensure minimum quantity is 1
    }

    if (quantity > product.in_stock) {
      quantity = product.in_stock;
      toast.error(
        `Quantity can't exceed available stock ${product.in_stock} !`
      );
    }

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };

        const productIndex = scannedProducts.findIndex(
          (p) => p.product_sku === product.product_sku
        );

        if (productIndex !== -1) {
          const additionalInfo = scannedProducts[productIndex].additional_info;

          if (additionalInfo && Array.isArray(additionalInfo)) {
            newQuantities[product.product_sku] = additionalInfo.length;
          } else {
            newQuantities[product.product_sku] = quantity; // or handle as appropriate
          }
        }

        return newQuantities;
      });
    }
  };

  const [setsku, setSKu] = useState("");
  const [productDatatoModal, setProductDatatoModal] = useState([]);
  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(fetch_sale_product_cashsale, {
          params: {
            product_value: sku,
            customer_pk: customer || 0,
            warehouse_pk: parseInt(warehouse),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            if (response.data.product.is_from_another_warehouse === 1) {
              setSKu(sku);
              setOpenConfirmTransfer(true);
              setProductDatatoModal(response.data.product);
            } else {
              const productData = response.data.product;
              addScannedProduct(sku, productData);
            }
          } else if (response.data.status === 0) {
            toast.error(response.data.message);
            // setOpenError(true);
          }
          setScanData("");
        })
        .catch((error) => {
          // setOpenError(true);

          toast.error(error.response.data.message);
          setScanData("");
        });
    }, 400),
    [customer, warehouse]
  );

  const handleRemoveProduct = () => {
    // console.log("skuToRemove:", skuToRemove);
    // console.log("additionalInfoToRemove:", additionalInfoToRemove);

    setScannedProducts((prev) => {
      return prev
        .map((item) => {
          if (item.product_sku === `KTT-${skuToRemove}`) {
            const updatedAdditionalInfo = item.additional_info.filter(
              (info) => info !== additionalInfoToRemove
            );
            if (updatedAdditionalInfo.length === 0) {
              return null;
            } else {
              return {
                ...item,
                additional_info: updatedAdditionalInfo,
                qty: updatedAdditionalInfo.length,
              };
            }
          }
          return item;
        })
        .filter((item) => item !== null); // Remove items marked for removal
    });

    // Reset state values
    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
    setScanData("");
  };

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  //SMall Ityems search
  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.get(fetch_sale_chilproducts, {
          params: {
            search: value,
            customer_pk: parseInt(customer) || 0,
            warehouse_pk: warehouse,
          },
          headers: { "API-Key": apiKey },
        });

        const fetchedProducts = response.data.data || [];
        // console.log(fetchedProducts, ":djsdhsh");

        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer, warehouse] // Dependency array
  );

  const handleInputChangeSmall = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length <= 1) {
        setDisplay(false);
      } else if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const [smallItemArray, setSmallItemArray] = useState([]);
  const [smallItemOpen, setSmallItemOpen] = useState(false);

  const handleCheckboxChange = (product) => {
    if (product.in_stock > 0) {
      setScannedProducts((prevSelected) => {
        const isSelected = prevSelected.some(
          (p) => p.product_sku === product.product_sku
        );

        // Update last price only when adding the product
        if (!isSelected) {
          setLast_price((prevLastMrps) => ({
            ...prevLastMrps,
            [product.product_sku]: product.last_price,
          }));
        }

        // Automatically set discount if available and original MRP is greater than 0
        const originalMRP = product.mrp;

        if (product.recomended_discount && originalMRP > 0) {
          const percentageValue = product.recomended_discount; // Get the recommended discount
          const discountValue = (originalMRP * percentageValue) / 100; // Calculate discount value
          const newMRP = parseFloat(originalMRP - discountValue); // Calculate new MRP after discount

          // Update state with the calculated values
          setDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: `${percentageValue}`, // Set the percentage as a string
          }));

          setNumericDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: discountValue.toFixed(2), // Set the discount value
          }));

          setMrps((prevMrps) => ({
            ...prevMrps,
            [product.product_sku]: newMRP, // Set the new MRP
          }));
        }

        // Toggle product selection
        return isSelected
          ? prevSelected.filter((p) => p.product_sku !== product.product_sku) // Remove
          : [...prevSelected, product]; // Add
      });
    } else if (product.in_stock <= 1 && product.available_in_other.length > 0) {
      // Show available options in a modal
      setSmallItemArray(product.available_in_other);
      setSKu(product.product_sku);
      setSmallItemOpen(true);
      setProductDatatoModal(product);
    } else {
      // Stock not available error
      toast.error(`Stock Not Available!`);
    }
  };

  const isProductSelected = (product) => {
    return scannedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCustomerChange = (e, newValue) => {
    const customer_pk = newValue ? newValue.id : "";
    setCustomer(customer_pk);

    // Create an array of SKUs from selected products
    const skus = scannedProducts.map((product) => product.product_sku);

    // Fetch customer address
    axios
      .get(fetch_customer_address, {
        headers: {
          "API-Key": apiKey,
        },
        params: { customer_pk },
      })
      .then((response) => {
        setCustomeraddress(response.data.address);
      })
      .catch((error) =>
        console.error("Error fetching customer address:", error)
      );

    // Fetch updated prices and recommended discounts for the selected products
    axios
      .post(
        getLastPrice,
        { customer_pk, skus },
        {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        // Extract the prices and recommended discounts from response
        const lastMrpsData = response.data.prices;
        const lastMrpsDataObject = lastMrpsData.reduce((acc, item) => {
          acc[item.sku] = {
            last_price: item.last_price,
            recommended_discount: item.recomended_discount,
          };
          return acc;
        }, {});

        // Update scannedProducts with the new last_price and recommended_discount values
        const updatedProducts = scannedProducts.map((product) => {
          const skuData = lastMrpsDataObject[product.product_sku];

          // Calculate the new recommended discount and MRP
          if (skuData) {
            const originalMRP = product.mrp;
            const percentageValue = skuData.recommended_discount;
            const discountValue = (originalMRP * percentageValue) / 100;
            const newMRP = parseFloat(originalMRP - discountValue);

            // Update state with the calculated values
            // setDiscounts((prevDiscounts) => ({
            //   ...prevDiscounts,
            //   [product.product_sku]: `${percentageValue}`, // Set the percentage as a string
            // }));

            // setNumericDiscounts((prevDiscounts) => ({
            //   ...prevDiscounts,
            //   [product.product_sku]: discountValue.toFixed(2), // Set the discount value
            // }));
            // setMrps((prevMrps) => ({
            //   ...prevMrps,
            //   [product.product_sku]: newMRP, // Set the discount value
            // }));

            return {
              ...product,
              last_price: skuData.last_price,
            };
          }

          return {
            ...product,
            last_price: product.last_price,
          };
        });

        // Update the scannedProducts state with new prices and discounts
        setScannedProducts(updatedProducts);
      })
      .catch((error) => console.error("Error fetching updated prices:", error));
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setScannedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  // Add Product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  // Function to open the modal
  const handleAddProductModal = () => {
    setAddProductModalOpen(true);
  };

  // Function to close the modal
  const handleCloseProductAddModal = () => {
    setAddProductModalOpen(false);
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const [itemDisplay, setItemDisplay] = useState(0);

  const handleClickSmallItems = () => {
    setItemDisplay((prevValue) => (prevValue === 1 ? 0 : 1)); // Toggle between 1 and 0
    setScanData("");
    setSearchInput("");
  };

  const handleDiscountChange = (e, product, type) => {
    const { value } = e.target;
    const sku = product.product_sku;
    const originalMRP = parseFloat(product.mrp);
    let newMRP;

    const parseValue = (val) => parseFloat(val) || 0;

    if (type === "percentage") {
      const percentageValue = parseValue(value);
      if (percentageValue < 0 || percentageValue > 100) {
        return;
      }
      const discountValue = (originalMRP * percentageValue) / 100;
      newMRP = originalMRP - discountValue;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${percentageValue}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: discountValue,
      }));
    } else if (type === "numeric") {
      const numericValue = parseValue(value);
      if (numericValue < 0) {
        return;
      }
      newMRP = originalMRP - numericValue;

      const percentageValue = (numericValue / originalMRP) * 100;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${parseFloat(percentageValue)}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: numericValue,
      }));
    }

    newMRP = parseFloat(newMRP);

    setMrps((prevMrps) => ({
      ...prevMrps,
      [sku]: newMRP,
    }));
  };

  const handleMRPChange = (event, product) => {
    const { value, key } = event.target;
    let updatedMRP = parseFloat(value) || 0;

    // Check if the key pressed is backspace and if the value is empty
    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      value === ""
    ) {
      updatedMRP = ""; // Set updated MRP to an empty string
    } else {
      updatedMRP = parseFloat(value) || 0;
    }

    // Retrieve the original MRP and current discount for the product
    const originalMRP = parseFloat(product.mrp) || 0;

    const discount = parseFloat(discounts[product.product_sku]) || 0;

    // If updated MRP is negative, set it to 0
    const newMRP = updatedMRP === "" ? "" : updatedMRP;

    // Update the MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: newMRP,
    }));

    // Handle flat discount
    const flatDiscount = parseFloat(discount) || 0;
    const newNumericDiscount = flatDiscount;
    const discountedPrice =
      newMRP == "" ? 0 : parseFloat(newMRP - newNumericDiscount);

    // Calculate the discount based on the original MRP
    const calculatedPercentageDiscount =
      newMRP == "" ? 0 : ((originalMRP - newMRP) / originalMRP) * 100 || 0;

    let calculatedNumericDiscount = 0;

    if (originalMRP > 0) {
      calculatedNumericDiscount = newMRP == "" ? 0 : originalMRP - newMRP || 0;
    }

    let discountratenumeric = parseFloat(calculatedNumericDiscount);
    let discountrate = parseFloat(calculatedPercentageDiscount);

    discountrate = isNaN(discountrate) ? "0" : discountrate;
    discountratenumeric = isNaN(discountratenumeric) ? 0 : discountratenumeric;

    // Update the state with the recalculated discounts
    setNumericDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: parseFloat(discountratenumeric),
    }));

    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: parseFloat(discountrate),
    }));

    return discountedPrice;
  };

  const calculateProductTotal = (product) => {
    const quantity =
      product.additional_info && product.additional_info.length
        ? product.additional_info.length
        : quantities[product.product_sku] || 1;

    const mrp = mrps[product.product_sku] ?? product.mrp;

    if (mrp <= 0) {
      return 0;
    }

    return parseFloat(mrp * quantity);
  };

  const calculateTotalMRP = () => {
    const totalProductMRP = scannedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] ?? product.mrp;
      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const otherCharge = parseFloat(other_charge) || 0;
    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalCharges = otherCharge + shippingCharge;
    const totalMRP = totalProductMRP + totalCharges - discountAmount;

    return totalMRP;
  };

  // const calculateTotalChangeMRP = () => {
  //   return scannedProducts.reduce((total, product) => {
  //     const productMRP = mrps[product.product_sku] || 0;
  //     return total + productMRP * (quantities[product.product_sku] || 1);
  //   }, 0);
  // };

  const calculateTotalChangeMRP = () => {
    const totalProductMRP = scannedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] ?? product.mrp;

      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const totalSubTotal = totalProductMRP;
    // console.log('Total Product MRP:', totalProductMRP);

    return totalSubTotal;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [totalMRP, setTotalMRP] = useState(0);

  useEffect(() => {
    const totalProductMRP = calculateTotalChangeMRP();

    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalMRP = totalProductMRP + shippingCharge - discountAmount;
    const totalMRP2 = totalProductMRP;
    const finalTotalMRP = Math.max(0, parseFloat(totalMRP)); // Ensure the total MRP is not negative
    const finalTotalMRP2 = Math.max(0, parseFloat(totalMRP2)); // Ensure the total MRP is not negative

    if (finalTotalMRP === 0 || finalTotalMRP2 === 0) {
      setPercentage(0);
    }

    setTotalMRP(finalTotalMRP2); // Assuming you have a state for totalMRP
  }, [scannedProducts, mrps, quantities, shipping_charge, discount_amount]);

  useEffect(() => {
    const newOtherCharge = (percentage / 100) * totalMRP;
    setOtherCharge(newOtherCharge);
  }, [percentage, totalMRP]);

  const handleOtherChargeChange = (e) => {
    const newOtherCharge = parseFloat(e.target.value);
    const floatnewotherCharge = newOtherCharge??newOtherCharge.toFixed(2)
    setOtherCharge(floatnewotherCharge);
    setPercentage((floatnewotherCharge / totalMRP) * 100);
  };

  const handlePercentageChange = (e) => {
    let newPercentage = parseFloat(e?.target?.value);
    if (newPercentage > 99) {
      newPercentage = 99;
    }

    let floatnewPercentage = newPercentage??newPercentage.toFixed(2)
    setPercentage(floatnewPercentage);
    const totalCal = (floatnewPercentage / 100) * totalMRP;
    setOtherCharge(totalCal);
  };

  const [ScanFocus, setScanFocus] = useState(0);

  const handleScanFocus = () => {
    setScanFocus((prevValue) => (prevValue === 1 ? 0 : 1)); // Toggle between 1 and 0
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0) {
      inputRef.current.focus(); // Autofocus the input field
    }
  }, [ScanFocus]); // Depend on itemDisplay so it runs when itemDisplay changes

  const handleFocus = () => {
    // console.log("handleFocus called");
    debounceFetchProducts();
    setDisplay(true);
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });

    // Format the amount correctly
    return formatter.format(amount);
  }
  const handleLastPriceClick = (data) => {
    if (data.last_price !== 0) {
      // Update the mrps state with the last_price
      const updatedMrps = { ...mrps, [data.product_sku]: data.last_price };
      setMrps(updatedMrps);

      // Calculate the discount percentage and value
      const originalMRP = data.mrp;
      const lastPrice = data.last_price;
      const discountPercentage =
        ((originalMRP - lastPrice) / originalMRP) * 100;
      const discountValue = originalMRP - lastPrice;

      // Update discounts and numericDiscounts
      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [data.product_sku]: `${discountPercentage.toFixed(2)}`, // Discount as percentage
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [data.product_sku]: discountValue.toFixed(2), // Discount as a value
      }));
    }
  };

  // Set default search query to "cash" on component mount
  useEffect(() => {
    setSearchQuery("Cash");
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(1);
    if (!sale_date || !customer) {
      toast.error("Customer cannot be empty!");
      setLoading(false);
      setDisabled(0);
      return;
    }
    // console.log(additional_info,'is additional info')
    const saleData = {
      customer_pk: parseInt(customer) || 0,
      warehouse_pk: parseInt(warehouse),
      sale_date: sale_date,
      other_charge: other_charge || 0,
      courier_charge: shipping_charge || 0,
      // shipping_pk: parseInt(address),
      note: notes || "",
      sale_type: "cash",

      discount_amount: discount_amount
        ? parseFloat(discount_amount).toFixed(2)
        : 0,
      user_name: userDetails.name || "",
      products: scannedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity:
          parseInt(quantities[product.product_sku]) ||
          parseInt(product.additional_info?.length) ||
          1,
        mrp: mrps[product.product_sku] || product.mrp,
        item_discount:
        product.mrp > 0 &&
        !isNaN(parseFloat(numericDiscounts[product.product_sku]))
          ? parseFloat(numericDiscounts[product.product_sku]).toFixed(2)
          : "0.00",
        series: product.additional_info || [],
      })),
    };

    axios
      .post(create_sale, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          navigate("/all_sales");
          setDisabled(0);
          setLoading(false);
          setScannedProducts([]);
          setProducts([]);
          setDiscount("0.00");
          setSearchInput("");
          setS_date("");
          setCustomer("");
          toast.success("Sale created successfully!");
        } else {
          toast.error(response.data.message);
          setLoading(false);
          setDisabled(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDisabled(0);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <AddProductModal
        open={addProductModalOpen}
        handleClose={handleCloseProductAddModal}
      />

      <SmallItemTransfer
        smallItemArray={smallItemArray}
        setSmallItemOpen={setSmallItemOpen}
        smallItemOpen={smallItemOpen}
        setsku={setsku}
        warehouse={warehouse}
        productDatatoModal={productDatatoModal}
        handleCheckboxChange={handleCheckboxChange}
      />

      <ConfirmSaleProductTransferModal
        openConfirmTransfer={openConfirmTransfer}
        setOpenConfirmTransfer={setOpenConfirmTransfer}
        warehouse={warehouse}
        setsku={setsku}
        productDatatoModal={productDatatoModal}
        addScannedProduct={addScannedProduct}
      />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
        setCustomer={setCustomer}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Create Cash Sale
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-3">
                <div className="row">
                  <div className="col-lg-4 my-2">
                    <div style={{ display: "flex" }}>
                      <FormControl fullWidth size="small" required>
                        <Autocomplete
                          value={
                            customers.find((cust) => cust.id === customer) || 6
                          }
                          onChange={handleCustomerChange}
                          inputValue={searchquery}
                          onInputChange={(event, newInputValue) => {
                            if (newInputValue === "") {
                              setCustomer(null);
                            }
                            setSearchQuery(newInputValue);
                          }}
                          options={customers}
                          getOptionLabel={(option) =>
                            option.customer_name || "Cash"
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Customer"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </FormControl>

                      <Button
                        variant="outlined"
                        style={{ marginLeft: "5px", height: "37px" }}
                        onClick={handleOpencustomer}
                        size="small"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="col-lg-2 my-2">
                    <TextField
                      type="date"
                      value={sale_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setS_date(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 my-1">
                    <div className="row">
                      <div
                        className="col-lg-9 my-1"
                        style={{ display: "flex" }}
                      >
                        <TextField
                          inputRef={inputRef} // Assign the ref to the TextField
                          onFocus={itemDisplay === 1 ? handleFocus : ""}
                          onChange={
                            itemDisplay && itemDisplay === 1
                              ? handleInputChangeSmall
                              : (e) => setScanData(e.target.value)
                          }
                          value={
                            itemDisplay && itemDisplay === 1
                              ? searchInput
                              : scanData
                          }
                          // value={searchInput}
                          fullWidth
                          size="small"
                          label={
                            itemDisplay && itemDisplay === 1
                              ? "Search Child Parts..."
                              : "Scan Product..."
                          }
                          variant="outlined"
                        />
                        {displaySearch ? (
                          <>
                            <div
                              id="searchList"
                              ref={searchListRef}
                              style={{
                                border: "1px solid #dedede",
                                position: "absolute",
                                zIndex: "3",
                                maxHeight: "450px",
                                maxWidth: "430px",
                                overflowY: "scroll",
                                backgroundColor: "white",
                                width: "92%",
                                marginTop: "2.8rem",
                                borderRadius: "5px",
                              }}
                            >
                              {products.map((product, index) => (
                                <>
                                  <div
                                    className="card p-3"
                                    style={{ display: "flex" }}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isProductSelected(product)}
                                          onChange={() =>
                                            handleCheckboxChange(product)
                                          }
                                          name="check"
                                        />
                                      }
                                      label={
                                        product.product_sku +
                                        " : " +
                                        product.product_name +
                                        "  (" +
                                        product.in_stock +
                                        ")"
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null}

                        {itemDisplay === 1 ? null : (
                          <>
                            <Button
                              onClick={handleScanFocus}
                              variant="outlined"
                              color="success"
                              style={{ marginLeft: "5px", height: "37px" }}
                            >
                              SCAN
                            </Button>
                          </>
                        )}

                        <div style={{ marginLeft: "5px" }}>
                          {itemDisplay && itemDisplay === 1 ? (
                            <>
                              <BsUpcScan
                                style={{
                                  fontSize: "30px",
                                  padding: "8px",
                                  height: "37px",
                                  width: "38px",
                                  cursor: "pointer",
                                  border: "1px solid cornflowerblue",
                                  borderRadius: "5px",
                                  color: "cornflowerblue",
                                }}
                                onClick={() => {
                                  handleScanFocus();
                                  handleClickSmallItems();
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <IoSearch
                                style={{
                                  fontSize: "30px",
                                  padding: "8px",
                                  height: "37px",
                                  width: "38px",
                                  cursor: "pointer",
                                  border: "1px solid red",
                                  borderRadius: "5px",
                                  color: "red",
                                }}
                                onClick={handleClickSmallItems}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 my-1">
                        <Button
                          variant="outlined"
                          style={{
                            marginLeft: "2px",
                            width: "100%",
                            height: "37px",
                          }}
                          size="small"
                          onClick={handleAddProductModal}
                        >
                          Add Product
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isWideScreen ? (
                <>
                  <div className="responsive-table1 mt-2">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th align="left">Product</th>
                          <th align="left">MRP</th>
                          <th align="left">Avail Qty</th>
                          <th align="left">Discount</th>
                          <th align="left">Price</th>
                          <th align="left">Quantity</th>
                          <th align="right">Total</th>
                          <th align="right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {scannedProducts && scannedProducts.length > 0 ? (
                          <>
                            {scannedProducts.map((product, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    product.type !== "big"
                                      ? "#e1f1fa"
                                      : "transparent",
                                }}
                              >
                                <td style={{ width: "90px" }}>
                                  {product.product_sku || ""}
                                </td>
                                <td
                                  className="product_name_class"
                                  align="left"
                                  style={{
                                    maxWidth: "540px",
                                  }}
                                >
                                  <b>{product.part_number}</b>
                                  <br />
                                  {product.product_name || ""}
                                </td>

                                <td align="left">
                                  {product.mrp < 1
                                    ? "₹" + product.mrp + ".00"
                                    : product.mrp > 0
                                    ? formatIndianRupee(product.mrp)
                                    : 0}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    textAlign: "center",
                                    color:
                                      product.in_stock < 1 ? "red" : "green",
                                  }}
                                >
                                  {product.in_stock || 0}
                                </td>

                                <td align="left" style={{ width: "10vw" }}>
                                  <div style={{ display: "flex" }}>
                                    <OutlinedInput
                                      disabled={product.mrp == 0}
                                      size="small"
                                      type="number"
                                      style={{ width: "5vw" }}
                                      name="discountPercentage"
                                      value={
                                        discounts[product.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      size="small"
                                      type="number"
                                      disabled={product.mrp == 0}
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      name="discountValue"
                                      value={
                                        numericDiscounts[product.product_sku] ||
                                        0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "numeric"
                                        )
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                    />
                                  </div>
                                </td>

                                <td align="left" style={{ width: "9vw" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      type="number"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      value={
                                        mrps[product.product_sku] ??
                                        product.mrp ??
                                        0
                                      }
                                      onChange={(e) =>
                                        handleMRPChange(e, product)
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {product.last_price != 0 &&
                                    product.last_price != product.mrp &&
                                    product?.last_price !==
                                      mrps[product.product_sku] ? (
                                      <>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleLastPriceClick(product)
                                          }
                                          on="click"
                                          pinned
                                          content={`₹${product.last_price}.00`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </td>

                                <td align="right">
                                  <TextField
                                    type="number"
                                    size="small"
                                    disabled={product.type === "big"}
                                    value={
                                      product.type === "big"
                                        ? (product.additional_info &&
                                            product.additional_info.length) ||
                                          1
                                        : quantities[product.product_sku] || 1
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    inputProps={{
                                      min: 1,
                                      max: product.in_stock,
                                    }}
                                    variant="outlined"
                                  />
                                </td>

                                <td
                                  align="right"
                                  style={{ width: "5vw", fontWeight: "bold" }}
                                >
                                  ₹{calculateProductTotal(product).toFixed(2)}
                                </td>
                                <td align="center" style={{ width: "2vw" }}>
                                  {product.type && product.type == "big" ? (
                                    <>
                                      <FaTrashAlt
                                        style={{
                                          color: "grey",
                                          cursor: "not-allowed",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <FaTrashAlt
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteProduct(
                                            product.product_sku
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="9">
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table mt-2">
                    <h5>
                      <FaSitemap id="backbtn" />
                      Sale Products
                    </h5>
                    {scannedProducts.length > 0 ? (
                      <>
                        {scannedProducts &&
                          scannedProducts.map((data) => (
                            <>
                              <div
                                className="product-card"
                                style={{
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_sku || ""}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.product_name || ""}
                                  </h5>
                                  <h5 className="product-card-mrp">
                                    <span>
                                      {formatIndianRupee(data.mrp || "N/A")}
                                    </span>{" "}
                                    |{" "}
                                    <span>{data.in_stock || 0} AVAIL QTY</span>{" "}
                                  </h5>

                                  <label
                                    style={{
                                      fontSize: "10px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Discounts
                                  </label>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "-4px",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      type="number"
                                      style={{ width: "40%" }}
                                      name="discountPercentage"
                                      value={discounts[data.product_sku] || 0}
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          data,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "5px",
                                        width: "60%",
                                      }}
                                      type="number"
                                      name="discountValue"
                                      value={
                                        numericDiscounts[data.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(e, data, "numeric")
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      type="number"
                                      style={{
                                        width: "70%",
                                      }}
                                      value={mrps[data.product_sku] || data.mrp}
                                      onChange={(e) => handleMRPChange(e, data)}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {data?.last_price != 0 &&
                                    data.last_price != undefined &&
                                    data?.last_price != data.mrp &&
                                    data?.last_price !==
                                      mrps[data.product_sku] ? (
                                      <>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleLastPriceClick(data)
                                          }
                                          on="click"
                                          pinned
                                          content={`${formatIndianRupee(
                                            data.last_price
                                          )}`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        width: "30%",
                                        marginLeft: "5px",
                                      }}
                                      type="number"
                                      onFocus={(e) => e.target.select()}
                                      disabled={data.type === "big"}
                                      value={
                                        data.type === "big"
                                          ? (data.additional_info &&
                                              data.additional_info.length) ||
                                            1
                                          : quantities[data.product_sku] || 1
                                      }
                                      onChange={(e) =>
                                        handleQuantityChange(e, data)
                                      }
                                      inputProps={{
                                        min: 1,
                                        max: data.in_stock,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          Qty
                                        </InputAdornment>
                                      }
                                    />

                                    {/* <TextField
                                      style={{
                                        width: "50%",
                                        marginLeft: "5px",
                                      }}
                                      type="number"
                                      size="small"
                                      disabled={data.type === "big"}
                                      value={
                                        data.type === "big"
                                          ? (data.additional_info &&
                                              data.additional_info.length) ||
                                            1
                                          : quantities[data.product_sku] || 1
                                      }
                                      onChange={(e) =>
                                        handleQuantityChange(e, data)
                                      }
                                      inputProps={{
                                        min: 1,
                                        max: data.in_stock,
                                      }}
                                      variant="outlined"
                                    /> */}
                                  </div>

                                  <div
                                    style={{
                                      backgroundColor: "#ebf5fc",
                                      marginTop: "5px",
                                      padding: "8px 10px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5>
                                      Total:{" "}
                                      <span style={{ color: "green" }}>
                                        {formatIndianRupee(
                                          calculateProductTotal(data)
                                        )}
                                      </span>
                                    </h5>

                                    {data.type && data.type == "big" ? (
                                      <>
                                        <FaTrashAlt
                                          style={{
                                            color: "grey",
                                            cursor: "not-allowed",
                                            marginTop: "2px",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <FaTrashAlt
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            marginTop: "2px",
                                          }}
                                          onClick={() =>
                                            handleDeleteProduct(
                                              data.product_sku
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-3">
              <div className="card p-3 mt-md-0 mt-2">
                <FormControl size="small" fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    value={warehouse}
                    label="Select Warehouse"
                    onChange={handleWarehouseChange}
                  >
                    <MenuItem value="">--Select Warehouse--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="card p-3 mt-2">
                <TextArea
                  className="custom_textareafield"
                  placeholder="Type your message here (if any)"
                  value={notes}
                  rows={5}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
              <div className="card p-3 mt-2">
                <h3 style={{ marginBottom: "0px" }}>Sale Summary</h3>
                <hr />
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">SubTotal</div>
                  <div
                    className="col-6 my-2"
                    style={{
                      textAlign: "right",

                      fontSize: "1.2rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {" "}
                    {formatIndianRupee(calculateTotalChangeMRP())}{" "}
                  </div>
                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      disabled={calculateTotalChangeMRP() == 0}
                      placeholder="0.00"
                      type="number"
                      value={discount_amount}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setDiscount(e.target.value)}
                      size="small"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">kg</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                  <div className="col-6 my-2">Shipping Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      placeholder="0.00"
                      type="number"
                      disabled={calculateTotalChangeMRP() == 0}
                      value={shipping_charge}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setShippingCharge(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="col-4 my-2">Other Charges</div>
                  <div
                    className="col-8 my-2"
                    style={{ textAlign: "right", display: "flex" }}
                  >
                    <OutlinedInput
                      size="small"
                      type="number"
                      disabled={calculateTotalChangeMRP() == 0}
                      fullWidth
                      style={{ width: "40%" }}
                      name="discountPercent"
                      value={percentage}
                      onChange={handlePercentageChange}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />

                    <OutlinedInput
                      size="small"
                      type="number"
                      disabled={calculateTotalChangeMRP() == 0}
                      placeholder="0.00"
                      style={{ marginLeft: "2px", width: "60%" }}
                      fullWidth
                      name="discountAmount"
                      value={other_charge}
                      onChange={handleOtherChargeChange}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                  </div>
                  <div
                    style={{
                      height: "0.5px",
                      backgroundColor: "#dbdbdb",
                      marginTop: "5px",
                    }}
                  ></div>
                  <div
                    className="col-6 my-2 mt-3"
                    style={{ fontSize: "1.3rem" }}
                  >
                    Grand Total :
                  </div>
                  <div
                    className="col-6 my-2 mt-3"
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontSize: "1.5rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {formatIndianRupee(calculateTotalMRP())}
                  </div>
                </div>
              </div>
              <div className="card p-3 mt-2">
                {disabled == 1 ? (
                  <>
                    <Button
                      size="small"
                      disabled
                      variant="contained"
                      style={{
                        // marginLeft: "5px",
                        height: "37px",
                      }}
                    >
                      Create Sale
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      size="small"
                      disabled={calculateTotalChangeMRP() == 0}
                      variant="contained"
                      style={{
                        // marginLeft: "5px",
                        height: "37px",
                      }}
                      onClick={handleSubmit}
                    >
                      Create Sale
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Change Warehouse  */}
      <Dialog
        open={openWarehouseChangeDialog}
        onClose={handleCancelWarehouseChange}
      >
        <DialogTitle>Change Warehouse</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing the warehouse will remove all products. Do you want to
            proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelWarehouseChange} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmWarehouseChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddCashSale;
