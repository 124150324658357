import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  validateWarehouse,
  validateProduct,
  inventory_mapping,
} from "../../../Api";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import Quagga from "quagga";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import { BsUpcScan } from "react-icons/bs";
import Error from "../../../Messages/Error";
import soundEffect from "../../../Assets/sounds/beep.mp3";
import { debounce } from "lodash";
import { Label, Popup } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import { useScreenWidth } from "../../../useScreenWidth";
import { FaImage } from "react-icons/fa";
import SubmitLoader from "../../../Components/SubmitLoader";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

const BarcodeScanner = () => {
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [submitLoader, setSubmitLoader] = useState(false);
  const webcamRef = useRef(null);
  const isWideScreen = useScreenWidth();
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);

  const [openReallocate, setOpenReallocate] = useState(false);

  const [openchangeRack, setOpenChangeRack] = useState(false);

  const [openRemoveP, setOpenRemoveP] = useState(false);

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [scanData, setScanData] = useState("");

  const [scanData2, setScanData2] = useState("");

  const [warehouseId, setWarehouseId] = useState("");
  const [rack_pk, setRackPk] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);

  const [rackName, setRackName] = useState("");
  const [sku, setSku] = useState("");
  const [productDataa, setProductDataa] = useState([]);

  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

    const [lastScannedSku, setLastScannedSku] = useState(null);

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      // Call your input change handler with scanData
      handleInputChange(scanData);

      // Immediately blur the input to hide the keyboard
      if (inputRef.current) {
        inputRef.current.blur();
      }
    };

    document.addEventListener("click", handleFocus);

    return () => {
      document.removeEventListener("click", handleFocus);
    };
  }, []);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  const [scannedDataArray, setScannedDataArray] = useState([]);

 // Debounced handler for scanData
 const handleDebouncedInputChange = useCallback(
  debounce((newScanData) => {
    setScannedDataArray((prevData) => [newScanData, ...prevData]);
  }, 500), // Adjust the debounce delay as needed
  []
);

useEffect(() => {
  if (scanData) {
    handleDebouncedInputChange(scanData);
  }
}, [scanData, handleDebouncedInputChange]);

// Optional: Use another effect for any side-effects after scanData is updated
useEffect(() => {
  handleInputChange(scanData);
}, [scanData]);

  const isValidWarehouseId = (value) => {
    const regex = /^W\d+-R\d+-R\d+$/;
    return regex.test(value);
  };

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleInputChange = (scanData) => {
    let newValue = scanData;

    const checkValue = newValue[0];
    
    // Clear previous timeout to avoid multiple simultaneous calls
    clearTimeout(window.timeoutId);
    
    window.timeoutId = setTimeout(() => {
     
  
      if (newValue.length > 5) {
        if (checkValue === "W") {
          // Handle warehouse ID
          if (isValidWarehouseId(newValue)) {
            setScanData2(newValue);
  
            if (warehouseId === newValue) {
              toast.error("Warehouse already selected!");
              setScanData2("");
              setOpenChangeRack(false);
              return;
            }
  
            if (warehouseId && warehouseId !== newValue) {
              setOpenChangeRack(true);
              return;
            }
  
            setWarehouseId(newValue);
            fetchWarehouseData(newValue);
            setIsCameraOpen(false);
            setScanData(""); // Clear the input after warehouse is selected
          } else {
            // If warehouse ID is not valid
            playSound(); // Uncomment to play sound if needed
          }
        } else {

          if (newValue.length <= 5) {
            setScanData("");
            return;
          }
          // Handle product SKU
          if (isValidProductSku(newValue)) {
            let seriesExists = false;

            // Ensure proper splitting and handling of SKU and additional info
            const parts = newValue.split("_");
            const skuPart = parts[0]; // SKU part
            const additionalInfoPart = parts.slice(1).join("_"); // Join the rest of the parts after _ to handle multi-digit series
          
            // Check if the additional info part exists and is a number
            if (additionalInfoPart && !isNaN(additionalInfoPart)) {
              const additionalInfo = parseInt(additionalInfoPart, 10);
  
              for (const product of scannedProducts) {
                if (
                  product.sku === skuPart &&
                  product.additional_info?.includes(additionalInfo)
                ) {
                  seriesExists = true;
                  break;
                }
              }
  
              if (seriesExists) {
                setSkuToRemove(skuPart);
                setAdditionalInfoToRemove(additionalInfo);
                setOpenRemoveP(true);
                return;
              } else {
                fetchProductData(newValue);
                setIsCameraOpen(false);
                setScanData(""); // Clear the input after product is added
                playSound();
              }
            } else {
              playSound();
            }
          } else {
            playSound();
            setScanData2("");
          }
        }
      }
    }, 400); // Adjust delay as needed
};

  
  
  
  

  const fetchWarehouseData = useCallback(
    debounce((pk) => {
      axios
        .get(validateWarehouse, {
          params: { scanned_data: pk },
          headers: { "API-Key": apiKey },
        })
        .then((response) => {
          setRackName(response.data.rack);
          setRackPk(response.data.rack.id);
        })
        .catch((error) => {
          toast.error("Warehouse ID Invalid. Scan Again!");
          setScanData("");
        });
    }, 200),
    []
  );

  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(validateProduct, {
          params: { product_value: sku },
          headers: { "API-Key": apiKey },
        })
        .then((response) => {
          const productData = response.data.product;
          addScannedProduct(sku, productData);
        })
        .catch((error) => {
          let errorstatus = error.response.data.status;
          const productData = error.response.data.product;
          if (errorstatus === 2) {
            setOpenReallocate(true);
            setError(`${error.response.data.message}. Do you want to Re-allocate ?`);
            setError2("Product already allocated!");
            setProductDataa(productData);
            setSku(sku);
          } else {
            toast.error(error.response.data.message);
          }
        });
    }, 150),
    []
  );


  useEffect(() => {
    return () => {
      fetchWarehouseData.cancel();
      fetchProductData.cancel();
    };
  }, [fetchWarehouseData, fetchProductData]);

  const addScannedProduct = useCallback((sku, productData) => {
    const [baseSku, additionalInfoString] = sku.split("_");
    const additionalInfo = parseInt(additionalInfoString, 10);

    if (!productData) {
      toast.error("Product not found for the scanned SKU.");
      return;
    }

    setScannedProducts((prev) => {
      const existingProduct = prev.find((item) => item.sku === baseSku);

      if (existingProduct) {
        if (existingProduct.additional_info?.includes(additionalInfo)) {
          setSkuToRemove(baseSku);
          setAdditionalInfoToRemove(additionalInfo);
          setOpenRemoveP(true);
          return prev;
        }

        return prev.map((item) =>
          item.sku === baseSku
            ? {
                ...item,
                qty: item.qty + 1,
                additional_info: [...(item.additional_info || []), additionalInfo],
              }
            : item
        );
      } else {
        return [
          ...prev,
          {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            qty: 1,
            additional_info: [additionalInfo],
          },
        ];
      }
    });

    setLastScannedSku(baseSku);

    // Remove highlight after 1 second
    setTimeout(() => {
      setLastScannedSku(null);
    }, 500);
  }, []);

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: { size: 800 },
              decoder: { readers: ["code_128_reader"] },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 250);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);
  
  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };


  const handleChangeRack = () => {
    fetchWarehouseData(scanData2);
    setWarehouseId(scanData2);
    setOpenChangeRack(false);
    setScanData2("");
    setScanData("");
  };


  const handleReallocate = () => {
    addScannedProduct(sku, productDataa);
    setOpenReallocate(false);
    setTimeout(() => {
      setSku("");
      setProductDataa([]);
    }, 300);
  };


  const handleCloseAllocate = () => {
    setOpenReallocate(false);
    setScanData("");
    setTimeout(() => {
      setSku("");
      setProductDataa([]);
    }, 1000);
  };


 
  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  const handleCloseChangeRack = () => {
    setOpenChangeRack(false);
    setScanData("");
  };


  const handleRemoveProduct = () => {
    setScannedProducts((prev) => {
      return prev
        .map((item) => {
          if (item.sku === skuToRemove) {
            const updatedInfo = item.additional_info.filter(
              (info) => info !== additionalInfoToRemove
            );
  
            // Set qty to 0 only if additional_info is empty after removal
            const updatedQty = updatedInfo.length === 0 ? 0 : item.qty;
  
            return {
              ...item,
              additional_info: updatedInfo,
              qty: updatedQty,
            };
          }
          return item;
        })
        .filter((item) => item.qty > 0); // Remove items with qty 0
    });
  
    // Reset states
    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
  };
  

  // console.log(scannedProducts);
  const handleSubmit = () => {
    setSubmitLoader(true);
    const finalData = {
      user_name: userDetails.name,
      rack: parseInt(rack_pk),
      products: scannedProducts,
    };

    if (!rack_pk) {
      setSubmitLoader(false);
      toast.error("Please Scan Rack. Rack cannot be empty!");
      return;
    } else if (scannedProducts.length === 0) {
      setSubmitLoader(false);
      toast.error("Please Scan Products. Products cannot be empty!");
      return;
    }

    try {
      axios
        .post(inventory_mapping, finalData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            // setOpenSuccess(true);
            toast.success("Products Allocated Successfully!");
            setScannedProducts([]);

            setWarehouseId("");
            setRackName("");
            navigate("/unallocated-products");
            setSubmitLoader(false);
          } else {
            setSubmitLoader(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitLoader(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      setSubmitLoader(false);
      console.log("error While Creating Draft :", error);
    }
  };

  const handleResetOperation = () => {
    toast.success("Operation Reset Successfully!");
    setScannedProducts([]);
    setWarehouseId("");
    setRackName("");
  };

  const handleCancelOperation = () => {
    setScannedProducts([]);
    setWarehouseId("");
    setRackName("");
    navigate("/unallocated-products");
  };

  const totalAdditionalInfoLength = scannedProducts.reduce((total, product) => {
    return total + product.additional_info.length;
  }, 0);

  return (
    <>
      <Toaster position="top-right" />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/unallocated-products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography color="inherit">Unallocated Products</Typography>
              <Typography sx={{ color: "text.primary" }}>Allocation</Typography>
            </Breadcrumbs>
          </div>

          <div className="row">
            <div className="col-lg-9 order-2 order-lg-1 my-2">
              <div className="card p-3">
                <h5 style={{ marginTop: "6px" }}>Scanned Products</h5>

                <input
                  autoFocus
                  value={scanData}
                  ref={inputRef}
                  style={{
                    border: "1px solid #d1d1d1",
                    borderRadius: "4px",
                    maxWidth: "150px",
                    fontSize: "15px",
                    color: "grey",
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    padding: "5px",
                    display: "none",
                  }}
                  onChange={(e) => setScanData(e.target.value)}
                />

                <input
                  ref={hiddenInputRef}
                  onChange={(e) => setScanData(e.target.value)}
                  value={scanData}
                  style={{
                    border: "1px solid #d1d1d1",
                    borderRadius: "4px",
                    maxWidth: "150px",
                    fontSize: "15px",
                    color: "grey",
                    position: "absolute",
                    right: "50px",
                    top: "10px",
                    padding: "5px",
                  }}
                />

                {isCameraOpen ? (
                  <>
                    <BsUpcScan
                      id="mobile_scan"
                      onClick={handleCloseCamera}
                      style={{
                        fontSize: "25px",
                        margin: "2px",
                        marginRight: "5px",
                        position: "absolute",
                        right: "15px",
                        cursor: "pointer",
                        top: "10px",
                        color: "red",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <BsUpcScan
                      id="mobile_scan"
                      onClick={handleOpenCamera}
                      style={{
                        fontSize: "25px",
                        margin: "2px",
                        marginRight: "5px",
                        position: "absolute",
                        right: "15px",
                        cursor: "pointer",
                        top: "10px",
                        color: "green",
                      }}
                    />
                  </>
                )}

                {isWideScreen ? (
                  <>
                    <div className="responsive-table1">
                      <table className="ui table celled " id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th>Product</th>
                            <th>Part Number</th>
                            <th>Qty</th>
                            <th>Series</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scannedProducts.length > 0 ? (
                            <>
                              {scannedProducts &&
                                scannedProducts.map((product) => (
                                  <>
                                    <tr key={product.product_sku} className={
                  product.sku === lastScannedSku ? "highlighted-row" : ""
                }>
                                      <td>{product.product_sku}</td>
                                      <td>{product.product_name}</td>
                                      <td>{product.part_number}</td>
                                      <td>{product.additional_info.length}</td>
                                      <td>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          on="click"
                                          pinned
                                          content={
                                            product.additional_info &&
                                            product.additional_info.join(", ")
                                          }
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "cornflowerblue",
                                              }}
                                            />
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan={5}>
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <p style={{marginTop:'5px'}}>Total Qty: <b style={{color:'black'}}>{totalAdditionalInfoLength}</b></p>
                  </>
                ) : (
                  <>
                    <div className="card p-2 responsive-mobile-table">
                      {scannedProducts.length > 0 ? (
                        <>
                          {scannedProducts &&
                            scannedProducts.map((product) => (
                              <>
                                <div className="product-card">
                                  <div>
                                    <FaImage className="product-img" />
                                  </div>
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      {product.product_sku}
                                    </h5>
                                    <h5 className="product-card-name">
                                      {product.product_name}
                                    </h5>

                                    <Popup
                                      style={{
                                        backgroundColor: "white",
                                        border: "1px solid grey",
                                        borderRadius: "5px",
                                        color: "black",
                                        padding: "5px",
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                      on="click"
                                      pinned
                                      content={
                                        product.additional_info &&
                                        product.additional_info.join(", ")
                                      }
                                      trigger={
                                        <Label
                                          style={{ float: "right" }}
                                          className="product-card-mrp"
                                        >
                                          QTY {product.additional_info.length}
                                        </Label>
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}


              </div>
            </div>
            <div className="col-lg-3 order-1 order-lg-2 my-2">
              {isCameraOpen ? (
                <div className="scannerDiv">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    width={1920}
                    height={220}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      facingMode: "environment",

                      border: "none",
                    }}
                    style={{
                      marginBottom: "12px",
                      border: "3px dashed green",
                      padding: "0",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCloseCamera}
                  >
                    Close
                  </Button>
                </div>
              ) : null}
              <div className="card">
                <div className="card-body" style={{ padding: "10px" }}>
                  <div className="row">
                    <h4 style={{ color: "grey", fontSize: "14px" }}>
                      SCANNED RACK
                    </h4>

                    <h5
                      style={{
                        color: "grey",
                        fontWeight: "normal",
                        marginBottom: "15px",
                      }}
                    >
                      {rackName ? (
                        <b style={{ color: "red" }}>
                          {rackName.warehouse_name} - {rackName.row_name} -{" "}
                          {rackName.rack_name}
                        </b>
                      ) : (
                        <span style={{ color: "#e3e3e3" }}>--</span>
                      )}
                    </h5>
                    <span style={{ fontSize: "12px" }}>
                      Rack ID:{" "}
                      {warehouseId ? (
                        <b style={{ color: "grey" }}>{warehouseId}</b>
                      ) : (
                        <span style={{ color: "#e3e3e3" }}>--</span>
                      )}
                    </span>
                    {isCameraOpen ? null : (
                      <>
                        <Button
                          size="small"
                          id="mobile_scan"
                          onClick={handleOpenCamera}
                          variant="outlined"
                          style={{
                            marginTop: "5px",
                            maxWidth: "150px",
                            position: "absolute",

                            bottom: "5px",
                            right: "5px",
                          }}
                        >
                          Change Rack
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {isWideScreen ? (
                <>
                  <div className="card p-3 mt-2">
                    <div className="d-flex">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="warning"
                        size="small"
                        onClick={handleResetOperation}
                      >
                        Reset
                      </Button>

                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        style={{ marginLeft: "5px" }}
                        size="small"
                        onClick={handleCancelOperation}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "5px" }}
                        fullWidth
                        size="small"
                        onClick={handleSubmit}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>

                  <div className="card mt-2 p-3">
                    <p>Scanned Logs</p>
                    <div style={{height:'20vh', overflowY:'scroll'}}>
                    <ul>
        {scannedDataArray && scannedDataArray.map((data, index) => (
          <li key={index}>{data}</li>
        ))}
      </ul>
                    </div>
                   
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {isWideScreen ? null : (
            <>
              <div className="card p-3 mt-2">
                <div className="d-flex">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={handleResetOperation}
                  >
                    Reset
                  </Button>

                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: "5px" }}
                    size="small"
                    onClick={handleCancelOperation}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "5px" }}
                    fullWidth
                    size="small"
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          )}
          <audio id="scanSound" src={soundEffect} />
        </div>
      </div>

      {/* 
      //Add allocated Product */}

      <Dialog
        open={openReallocate}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseAllocate();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product already allocated!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseAllocate}
              color="error"
            >
              Cancel
            </Button>
            <Button size="small" variant="outlined" onClick={handleReallocate}>
              Reallocate
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* 
      //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* 
      //Change RAck */}

      <Dialog
        open={openchangeRack}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Want to change Rack?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Are you sure you want to change rack?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleChangeRack}>
              Change Rack
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseChangeRack}
              color="error"
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default BarcodeScanner;
