import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  get_products_for_packing,
  check_product_series_for_packing,
  pack_requirement,
} from "../../Api";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Error from "../../Messages/Error";
import Quagga from "quagga";
import Webcam from "react-webcam";
import soundEffect from "../../Assets/sounds/beep.mp3";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoAlertCircleOutline } from "react-icons/io5";
import { Label, Popup } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { BsUpcScan } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import SubmitLoader from "../../Components/SubmitLoader";
import { useScreenWidth } from "../../useScreenWidth";
import { FaTrashAlt } from "react-icons/fa";
import Inventory_ProductsModal from "../Inventory/StockTransfer/Inventory_ProductsModal";
import SelectPackRequirementSmallQty from "./PackRequirement/SelectPackRequirementSmallQty";
import SelectPackRequirementSeries from "./PackRequirement/SelectPackRequirementSeries";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function PackRequirementProducts() {
  const { id } = useParams();

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isWideScreen = useScreenWidth();

  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");

  const [submitLoader, setSubmitLoader] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [warehouses, setwarehouses] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const [packedDate, setPackedDate] = useState(getCurrentDate());
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const navigate = useNavigate();
  const [warehouse, seswarehouse] = useState("");
  const [swarehouse, setSWarehouse] = useState("");
  const webcamRef = useRef(null);
  const [scanData, setScanData] = useState("");
  const [scanInputData, setScanInputData] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);
  const [curr_series, setCurr_series] = useState("");
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const inputRef = useRef(null);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setwarehouses(response.data.warehouses);
        // const gateWarehouse = warehouses.find(warehouse =>
        //     warehouse.ware_house.toLowerCase().includes('gate')
        // );
        // if (gateWarehouse) {
        //     seswarehouse(gateWarehouse.id)
        //     console.log(gateWarehouse.id)
        // }
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };


    // Set the warehouse state if a warehouse containing "Gate" is found
    useEffect(() => {
      const gateWarehouse = warehouses.find((ware) =>
        ware.ware_house.toLowerCase().includes("gate")
      );
  
      // If found, set it as the selected warehouse
      if (gateWarehouse) {
        seswarehouse(gateWarehouse.id);
      }
    }, [warehouses]);

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
    setIsCameraOpen(false);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 6 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleScanChange = (e) => {
    const scanquery = e.target.value;
    // Clear previous timeout to avoid multiple simultaneous calls
    clearTimeout(window.timeoutId);

    window.timeoutId = setTimeout(() => {
      if (scanquery.length < 8) {
        return;
      }
      if (scanquery.length > 7) {
        if (isValidProductSku(scanquery)) {
          let seriesExists = false;
          const [productSku, series] = scanquery.split("_");

          setScanInputData(scanquery);
          for (const product of selectedProducts) {
            if (
              product.sku === productSku &&
              product.additional_info?.includes(parseInt(series, 10))
            ) {
              seriesExists = true;
              break;
            }
          }

          if (seriesExists) {
            setSkuToRemove(productSku);
            setAdditionalInfoToRemove(parseInt(series, 10));
            setOpenRemoveP(true);
            setScanInputData("");
            inputRef.current.focus();
            return;
          } else {
            fetchProductData(scanquery);
            setScanInputData("");
            inputRef.current.focus();
          }

          if (scanquery.includes("_")) {
            handleSeriesClick(productSku, parseInt(series, 10));
          }
        } else {
          playSound();
        }
      }
    }, 400);
  };

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length < 9) {
      setScanData("");
      return;
    }

    if (newValue.length > 7) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;
        const [productSku, series] = newValue.split("_");

        for (const product of selectedProducts) {
          if (
            product.sku === productSku &&
            product.additional_info?.includes(parseInt(series, 10))
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(productSku);
          setAdditionalInfoToRemove(parseInt(series, 10));
          setOpenRemoveP(true);
          return;
        } else {
          fetchProductData(newValue);
          setIsCameraOpen(false);
          playSound();
        }

        // Call handleSeriesClick if newValue contains "_"
        if (newValue.includes("_")) {
          handleSeriesClick(productSku, parseInt(series, 10));
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      const data = {
        product_value: sku,
        requirement_pk: parseInt(id),
        warehouse_pk: parseInt(warehouse),
      };
      // console.log("data : ", data)
      axios
        .post(check_product_series_for_packing, data, {
          headers: {
            "API-Key": apiKey,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const productData = response.data.product;

          // console.log("fetchProductData : ", productData)

          if (!productData || productData.length === 0) {
            toast.error("No product data available");
            return; // Exit early if there's no data
          }
          addScannedProduct(sku, productData);
          // Clear the scan data
          setScanData("");
          playSound();

          setKhaliKaroKey((prevKey) => prevKey + 1);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          playSound();
          setKhaliKaroKey((prevKey) => prevKey + 1);
          setScanData("");
          setTimeout(() => {
            inputRef.current.focus();
          }, 500);
        });
    }, 400),
    [warehouse, selectedProducts]
  );

  // const addScannedProduct = useCallback(
  //     debounce((sku, productData) => {
  //         const [baseSku, additionalInfoString] = sku.split("_");
  //         const additionalInfo = parseInt(additionalInfoString, 10);

  //         console.log("addScannedProduct : ", productData);

  //         if (!productData) {
  //             toast.error("Product not found for the scanned SKU.");
  //             return;
  //         }

  //         let splitSeries = sku.split("_")[1];
  //         let splittedSeries = parseInt(splitSeries);

  //         setSelectedProducts((prev) => {
  //             const existingProduct = prev.find((item) => item.sku === baseSku);

  //             if (existingProduct) {
  //                 const packedQty = existingProduct.packed_quantity;
  //                 const pickedQty = productData.picked_quantity;

  //                 // Check if packed quantity exceeds picked quantity
  //                 if (existingProduct.selectedSeries.length >= pickedQty) {
  //                     toast.error(
  //                         `${existingProduct.product_sku} can't exceed more than ${pickedQty}`
  //                     );
  //                     inputRef.current.focus();
  //                     return prev;
  //                 }

  //                 // Check if the series already exists
  //                 if (existingProduct.selectedSeries.includes(splittedSeries)) {
  //                     setSkuToRemove(baseSku);
  //                     setAdditionalInfoToRemove(splittedSeries);
  //                     setOpenRemoveP(true);
  //                     inputRef.current.focus();
  //                     return prev;
  //                 } else {
  //                     inputRef.current.focus();
  //                     return prev.map((item) =>
  //                         item.sku === baseSku
  //                             ? {
  //                                 ...item,
  //                                 selectedSeries: [
  //                                     ...(item.selectedSeries || []),
  //                                     splittedSeries,
  //                                 ],

  //                             }
  //                             : item
  //                     );
  //                 }
  //             } else {
  //                 // If no existing product, ensure packed quantity does not exceed picked quantity
  //                 if (productData.picked_quantity < 1) {
  //                     toast.error("Cannot add a product with zero picked quantity.");
  //                     inputRef.current.focus();
  //                     return prev;
  //                 }

  //                 toast.success("Product added successfully!");
  //                 inputRef.current.focus();
  //                 return [
  //                     ...prev,
  //                     {
  //                         sku: baseSku,
  //                         product_name: productData.product_name,
  //                         product_sku: productData.product_sku,
  //                         part_number: productData.part_number,
  //                         available_series: productData.available_series,
  //                         packed_serieses: productData.packed_serieses,
  //                         selectedSeries: [splittedSeries],
  //                         available_stock: productData.available_stock || 0,
  //                         product_status: 1,
  //                         picked_quantity: productData.picked_quantity,
  //                         packed_quantity: productData.packed_quantity,
  //                         required_quantity: productData.required_quantity,
  //                     },
  //                 ];
  //             }
  //         });
  //     }, 300),
  //     [] // dependencies
  // );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const splittedSeries = parseInt(additionalInfoString, 10);

      console.log("addScannedProduct : ", productData);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }

      setSelectedProducts((prev) => {
        const existingProduct = prev.find((item) => item.sku === baseSku);

        const {
          picked_quantity,
          packed_quantity,
          required_quantity,
          available_series,
        } = productData;
        const remainingQty = picked_quantity - packed_quantity;

        // Validate if the scanned series is part of available series
        if (!available_series.includes(splittedSeries)) {
          toast.error(
            `Series ${splittedSeries} is not available for this product.`
          );
          inputRef.current.focus();
          return prev;
        }

        if (existingProduct) {
          // Check if the series already exists
          if (existingProduct.selectedSeries.includes(splittedSeries)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(splittedSeries);
            setOpenRemoveP(true);
            inputRef.current.focus();
            return prev;
          }

          // Check if packed quantity exceeds picked quantity
          if (existingProduct.selectedSeries.length >= remainingQty) {
            toast.error(
              `${existingProduct.product_sku} can't exceed more than the remaining quantity (${remainingQty}).`
            );
            inputRef.current.focus();
            return prev;
          }

          inputRef.current.focus();
          return prev.map((item) =>
            item.sku === baseSku
              ? {
                  ...item,
                  selectedSeries: [...item.selectedSeries, splittedSeries],
                }
              : item
          );
        } else {
          // New product entry - ensure adding one series only
          if (remainingQty <= 0) {
            toast.error(
              `Cannot add a new product as the remaining quantity (${remainingQty}) is zero.`
            );
            inputRef.current.focus();
            return prev;
          }

          toast.success("Product added successfully!");
          inputRef.current.focus();
          return [
            ...prev,
            {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              part_number: productData.part_number,
              available_series: productData.available_series,
              packed_serieses: productData.packed_serieses,
              selectedSeries: [splittedSeries],
              available_stock: productData.available_stock || 0,
              product_status: 1,
              picked_quantity: picked_quantity,
              packed_quantity: packed_quantity,
              required_quantity: required_quantity,
            },
          ];
        }
      });
    }, 300),
    [] // dependencies
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0,
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 300);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const fetchProducts = useCallback(() => {
    const data = {
      search: searchQuery,
      search_with_sku: "",
      requirement_pk: parseInt(id),
      warehouse_pk: parseInt(warehouse),
      page: 1,
      per_page: 25,
    };
    // console.log("data : ", data)
    axios
      .post(get_products_for_packing, data, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const fetchedProducts = response.data.products;

        console.log("fetchedProductsss : ", fetchedProducts);

        // Extract specific keys from the fetched products
        const selectedKeysProducts = fetchedProducts.map((product) => ({
          product_name: product.product_name,
          product_sku: product.product_sku,
          part_number: product.part_number,
          product_type: product.product_type,
          packed_quantity: product.packed_quantity,
          picked_quantity: product.picked_quantity,
          required_quantity: product.required_quantity,
          packed_serieses: product.packed_serieses,
          selectedSeries: [],
          sku: product.product_sku.split("-")[1],
          available_stock: product.available_stock,
          available_inventory: product.available_inventory,
          product_status: product.product_status,
          available_series: product.available_series,
        }));

        setProducts(selectedKeysProducts);
        setDisplay(true);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [searchQuery, warehouse]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    [warehouse, warehouse]
  );

  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    console.log("handleCheckboxChange : ", product);

    if (product.available_stock < 1) {
      toast.error("Stock not available !");
      return;
    }

    // addScannedProduct(sku, productData);

    setSelectedProducts((prevSelected) => {
      const productInSelected = prevSelected.find(
        (p) => p.product_sku === product.product_sku
      );

      if (productInSelected) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        if (product.product_status === 0) {
          return [
            ...prevSelected,
            { ...product, selectedSeries: [], smallQty: 0 },
          ];
        } else {
          return [
            ...prevSelected,
            {
              sku: product.product_sku.replace("KTT-", ""),
              product_name: product.product_name,
              product_sku: product.product_sku,
              part_number: product.part_number,
              product_type: product.product_type,
              packed_quantity: product.packed_quantity,
              picked_quantity: product.picked_quantity,
              required_quantity: product.required_quantity,
              packed_serieses: product.packed_serieses,
              selectedSeries: [],
              available_stock: product.available_stock,
              available_inventory: product.available_inventory,
              product_status: product.product_status,
              available_series: product?.available_series,
            },
          ];
        }
      }
    });
  };

  const handleSeriesClick = (productSku, series) => {
    setSelectedProducts((prevSelected) => {
      return prevSelected.map((product) => {
        if (product.product_sku === productSku) {
          const isSelected = product.selectedSeries.includes(series);
          return {
            ...product,
            selectedSeries: isSelected
              ? product.selectedSeries.filter((s) => s !== series)
              : [...product.selectedSeries, series],
          };
        }
        return product;
      });
    });
  };

  let selectedWarehouse = "";
  const [openConfirmChange, setOpenConfirmChange] = useState(false);
  const handleWarehouseChange = (event) => {
    selectedWarehouse = event.target.value;
    if (selectedProducts.length > 0) {
      // toast.error("Oops! You can't change warehouse");
      setOpenConfirmChange(true);

      return;
    }
    seswarehouse(selectedWarehouse);
  };

  const handleCloseConfirmChange = () => {
    setOpenConfirmChange(false);
  };

  const handleConfirmChange = () => {
    setSelectedProducts([]);
    setOpenConfirmChange(false);
    setSearchQuery("");
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
      setSearchQuery("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
  };

  const handleRemoveProduct = () => {
    let ProductSku = "KTT-" + skuToRemove;
    let seriesToRemoveInt = additionalInfoToRemove;

    setSelectedProducts((prevSelected) => {
      return (
        prevSelected
          .map((product) => {
            if (product.product_sku === ProductSku) {
              const updatedSeries = product.selectedSeries.filter(
                (series) => series !== seriesToRemoveInt
              );

              // If the updated series is empty, return null
              if (updatedSeries.length === 0) {
                return null;
              }

              return {
                ...product,
                selectedSeries: updatedSeries,
              };
            }
            return product;
          })
          // Filter out any products that are null (which indicates they have an empty series)
          .filter((product) => product !== null)
      );
    });

    setOpenRemoveP(false);
  };

  const [p_sku, setSKU] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [inventory, setInven] = useState([]);
  const [unallocated, setUnallocated] = useState(0);

  const [refreshKey, setRefreshKey] = useState(0);

  const handleSubmit = () => {
    if (!packedDate) {
      toast.error("Date Required!");
      return;
    }
    if (!notes) {
      toast.error("Please provide a note!");
      return;
    }

    let formattedData = {
      requirement_pk: parseInt(id),
      note: notes,
      user_name: userDetails.name,
      warehouse_pk: parseInt(warehouse),
      date: packedDate,
      products: selectedProducts.map((product) => {
        let transformedProduct = {
          product_sku: product.product_sku,
          quantity:
            product.product_status === 0
              ? product.smallQty
              : product.selectedSeries.length,
        };

        if (product.selectedSeries && product.selectedSeries.length > 0) {
          transformedProduct.selected_serieses = product.selectedSeries;
        }

        if (
          product.product_status === 0 &&
          product.available_inventory &&
          product.available_inventory.length > 0
        ) {
          transformedProduct.inventory = product.available_inventory.map(
            (inv) => ({
              warehouse_pk: inv.warehouse_pk,
              quantity: inv.picked_warehouse_qty,
            })
          );
        }

        return transformedProduct;
      }),
    };
    console.log("formattedData : ", formattedData);

    setSubmitLoader(true);
    setDisabled(1);

    try {
      axios
        .post(pack_requirement, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setSubmitLoader(false);
            setDisabled(0);
            setProducts([]);
            setDisplay(false);
            setSelectedProducts([]);
            setRefreshKey((prevKey) => prevKey + 1);
            seswarehouse("");
            setNotes("");
            setSubmitLoader(false);
            toast.success(`Requirement Packed Successfully!`);
            navigate("/requirements-list");
          } else {
            toast.error(response.data.message);
            setSubmitLoader(false);
            setDisabled(0);
          }
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message ||
              "Something went wrong, Please try again"
          );
          console.log("Error while packing the requirement : ", error.response);
          setSubmitLoader(false);
          setDisabled(0);
        });
    } catch (error) {
      console.log("Something went wrong!");
      setSubmitLoader(false);
      setDisabled(0);
    }
  };

  const [KhaliKaroKey, setKhaliKaroKey] = useState(0);
  const [inputStatus, setInputStatus] = useState(0);

  const [ScanFocus, setScanFocus] = useState(1);

  const handleScanButtonClick = () => {
    setInputStatus((prevStatus) => (prevStatus === 0 ? 1 : 0));
    setScanFocus((prevValue) => (prevValue === 0 ? 1 : 0)); // Toggle between 1 and 0
    setKhaliKaroKey((prevKey) => prevKey + 1);
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0) {
      inputRef.current.focus(); // Autofocus the input field
    }
  }, [ScanFocus]);

  const handleRemoveItem = (itemToRemove) => {
    const updatedProducts = selectedProducts.filter(
      (item) => item !== itemToRemove
    );
    setSelectedProducts(updatedProducts);
  };

  const handleSmallQtyChange = (index, value) => {
    setSelectedProducts((prevSelected) => {
      return prevSelected.map((product, i) => {
        if (i === index) {
          const newValue = Math.max(
            1,
            Math.min(value, product.available_stock)
          );
          return { ...product, smallQty: newValue };
        }
        return product;
      });
    });
  };

  const handleUpdateQuantity = (sku, { warehouse, quantity }) => {
    setSelectedProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.sku === sku) {
          // Find the matching product by SKU and update the smallQty
          return {
            ...product,
            smallQty: quantity, // Update the smallQty within the product
            available_inventory: product.available_inventory.map((inv) =>
              inv.warehouse_pk === warehouse
                ? { ...inv, picked_warehouse_qty: quantity } // Update picked_warehouse_qty in inventory for the selected warehouse
                : inv
            ),
          };
        }
        return product; // Return other products unchanged
      });
    });

    toast.success(`Updated quantity to: ${quantity}`);
  };

  const [openSelectSmallQTYModal, setOpenSelectSmallQTYModal] = useState(false);
  const [selectedSmallQTYProduct, setSelectedSmallQTYProduct] = useState(null);

  // Function to handle opening the modal
  const handleOpenSelectSmallQTYModal = (product) => {
    setSelectedSmallQTYProduct(product);
    setOpenSelectSmallQTYModal(true);
  };

  // Function to handle closing the modal
  const handleCloseSelectSmallQTYModal = () => {
    setOpenSelectSmallQTYModal(false);
    setSelectedSmallQTYProduct(null);
  };

  // Series Selector
  const [openSelectSeriesModal, setOpenSelectSeriesModal] = useState(false);
  const [selectedSeriesProduct, setSelectedSeriesProduct] = useState(null);

  // Function to handle opening the modal
  const handleOpenSelectSeriesModal = (product) => {
    console.log("product : ", product);
    setSelectedSeriesProduct(product);
    setOpenSelectSeriesModal(true);
  };

  // Function to handle closing the modal
  const handleCloseSelectSeriesModal = () => {
    setOpenSelectSeriesModal(false);
    setSelectedSeriesProduct(null);
  };

  const handleUpdateSelectedSeries = (sku, selectedSeries) => {
    // Find the product by sku
    const updatedProducts = selectedProducts.map((product) => {
      if (product.sku === sku) {
        // Update the selectedSeries for the specific product
        return { ...product, selectedSeries };
      }
      return product;
    });

    // Update the selectedProducts array
    setSelectedProducts(updatedProducts);
  };

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <Toaster position="top-right" />

      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Inventory_ProductsModal
        key={refreshKey}
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        p_sku={p_sku}
        setInven={setInven}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
      />

      {openSelectSmallQTYModal && selectedSmallQTYProduct && (
        <SelectPackRequirementSmallQty
          product={selectedSmallQTYProduct}
          onUpdateQuantity={handleUpdateQuantity}
          handleCloseModal={handleCloseSelectSmallQTYModal}
        />
      )}

      {openSelectSeriesModal && (
        <SelectPackRequirementSeries
          open={openSelectSeriesModal}
          handleClose={handleCloseSelectSeriesModal}
          product={selectedSeriesProduct} // Pass the selected product to the modal
          updateSelectedSeries={handleUpdateSelectedSeries}
        />
      )}

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography color="inherit">Pack Requirement</Typography>

              <Typography sx={{ color: "text.primary" }}>(#{id})</Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <>
              <div className="scannerDiv">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  width={1920}
                  height={220}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "environment",

                    border: "none",
                  }}
                  style={{
                    marginBottom: "12px",
                    border: "3px dashed green",
                    padding: "0",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseCamera}
                >
                  Close
                </Button>
              </div>
            </>
          ) : null}

          <div className="card p-3 my-1">
            <div className="row">
              <div className="col-lg-2 my-2">
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Target Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Target Warehouse"
                    required
                    value={warehouse}
                    onChange={handleWarehouseChange}
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem
                        key={ware.id}
                        value={ware.id}
                        disabled={ware.id === swarehouse}
                      >
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-2 my-2">
                <TextField
                  size="small"
                  type="date"
                  fullWidth
                  value={packedDate}
                  onChange={(e) => setPackedDate(e.target.value)}
                />
              </div>

              <div className="col-lg-4 my-2">
                <TextField
                  size="small"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2 my-2 " style={{ textAlign: "right" }}>
                {disabled == 1 ? (
                  <>
                    <Button disabled variant="contained">
                      Confirm Transfer
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={
                        !warehouse ||
                        !selectedProducts ||
                        selectedProducts.length === 0
                      }
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      Confirm Packing
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-lg-3 my-2">
              <div className="card p-3">
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <TextField
                    key={KhaliKaroKey}
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    disabled={!warehouse}
                    label={
                      inputStatus === 1 ? "Search Products" : "Scan Product"
                    }
                    variant="outlined"
                    color={inputStatus === 1 ? "primary" : "success"}
                    onInput={
                      inputStatus === 1 ? handleSearchChange : handleScanChange
                    }
                    // onFocus={inputStatus === 1 ? fetchProducts : ""} // fetchProducts
                    onFocus={inputStatus === 1 && fetchProducts}
                  />

                  {isWideScreen ? (
                    <>
                      {inputStatus === 1 ? (
                        <BsUpcScan
                          onClick={() => {
                            // handleOpenCamera();
                            handleScanButtonClick();
                          }}
                          style={{
                            fontSize: "30px",
                            padding: "8px",
                            height: "37px",
                            width: "38px",
                            cursor: "pointer",
                            border: "1px solid #1565c0",
                            borderRadius: "5px",
                            marginLeft: "5px",
                            color: "#1565c0",
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {inputStatus === 1 ? (
                        <BsUpcScan
                          onClick={() => {
                            handleOpenCamera();
                            // handleScanButtonClick();
                          }}
                          style={{
                            fontSize: "30px",
                            padding: "8px",
                            height: "37px",
                            width: "38px",
                            cursor: "pointer",
                            border: "1px solid #1565c0",
                            borderRadius: "5px",
                            marginLeft: "5px",
                            color: "#1565c0",
                          }}
                        />
                      ) : null}
                    </>
                  )}

                  {inputStatus === 0 ? (
                    <IoSearch
                      onClick={() => {
                        handleCloseCamera();
                        handleScanButtonClick();
                      }}
                      style={{
                        fontSize: "30px",
                        padding: "8px",
                        height: "37px",
                        width: "38px",
                        cursor: "pointer",
                        border: "1px solid red",
                        borderRadius: "5px",
                        marginLeft: "5px",
                        color: "red",
                      }}
                    />
                  ) : null}
                </div>

                {displaySearch && displaySearch ? (
                  <>
                    <div id="searchList" ref={searchListRef}>
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card  p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProductSelected(product)}
                                  onChange={() => handleCheckboxChange(product)}
                                  name="check"
                                  disabled={
                                    product.packed_quantity ===
                                    product.picked_quantity
                                  }
                                />
                              }
                              label={
                                product.product_sku +
                                " : " +
                                product.product_name
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-lg-9 my-2">
              <div className="card p-3">
                {isWideScreen ? (
                  <>
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>#</th>
                            <th>SKU</th>
                            <th align="left">Product</th>
                            <th align="left">Part No.</th>
                            <th>Packed Qty</th>
                            <th>Picked Qty</th>
                            <th>Required Qty</th>
                            <th align="left">Available Qty</th>
                            <th align="left">Quantity</th>
                            <th align="right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts && selectedProducts.length > 0 ? (
                            <>
                              {selectedProducts.map((product, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      product.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                >
                                  <td>{index + 1}</td>

                                  <td style={{ width: "6.5vw" }}>
                                    {product.product_sku || ""}
                                  </td>
                                  <td align="left">
                                    {product.product_name || ""}
                                  </td>
                                  <td align="left">
                                    {product.part_number || ""}
                                  </td>
                                  <td align="left">
                                    {product.packed_quantity || 0}
                                  </td>
                                  <td align="left">
                                    {product.picked_quantity || 0}
                                  </td>
                                  <td align="left">
                                    {product.required_quantity || 0}
                                  </td>

                                  <td align="left">
                                    {product.available_stock || 0}
                                  </td>

                                  <td align="left">
                                    {product.product_status === 0 ? (
                                      <>
                                        <TextField
                                          disabled={true}
                                          value={product.smallQty}
                                          size="small"
                                          type="number"
                                          style={{ width: "80px" }}
                                          variant="outlined"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {(product.selectedSeries &&
                                          product.selectedSeries.length > 0 &&
                                          product.selectedSeries.length) ||
                                          0}

                                        {product.selectedSeries.length > 0 && (
                                          <Popup
                                            style={{
                                              backgroundColor: "white",
                                              border: "1px solid grey",
                                              borderRadius: "5px",
                                              color: "black",
                                              padding: "5px",
                                              marginLeft: "20px",
                                              marginTop: "-6px",
                                              cursor: "pointer",
                                              position: "absolute",
                                            }}
                                            on="click"
                                            pinned
                                            content={`${product.selectedSeries}`}
                                            trigger={
                                              <IoAlertCircleOutline
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "5px",
                                                  fontSize: "18px",
                                                  color: "red",
                                                }}
                                              />
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </td>

                                  <td align="center" style={{ width: "2vw" }}>
                                    <>
                                      {product.product_status === 0 ? (
                                        <>
                                          <Button
                                            variant="outlined"
                                            size="small"
                                            disabled={
                                              product.packed_quantity ===
                                              product.picked_quantity
                                            }
                                            onClick={() =>
                                              handleOpenSelectSmallQTYModal(
                                                product
                                              )
                                            }
                                          >
                                            Select Quantity
                                          </Button>

                                          <Button
                                            style={{ marginLeft: "5px" }}
                                            onClick={() =>
                                              handleRemoveItem(product)
                                            }
                                            variant="outlined"
                                            color="error"
                                            size="small"
                                          >
                                            Remove
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() =>
                                              handleOpenSelectSeriesModal(
                                                product
                                              )
                                            }
                                          >
                                            Series
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan="10">
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card p-2 responsive-mobile-table">
                      {selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts &&
                            selectedProducts.map((data) => (
                              <>
                                <div
                                  className="product-card"
                                  style={{
                                    backgroundColor:
                                      data.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                  // onClick={() =>
                                  //     data.product_status === 0
                                  //         ? handleWareSmallItem(
                                  //             data.available_warehouses,
                                  //             data.product_sku
                                  //         )
                                  //         : null
                                  // }
                                >
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      #{data.product_sku || ""}
                                    </h5>
                                    <h5 className="product-card-name my-1">
                                      {data.product_name || ""}
                                    </h5>

                                    {data.product_status === 0 ? (
                                      <>
                                        <IoCheckmarkDoneSharp
                                          // onClick={() =>
                                          //     handleWareSmallItem(
                                          //         data.available_warehouses,
                                          //         data.product_sku
                                          //     )
                                          // }
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "25px",
                                            marginTop: "-5px",
                                            position: "absolute",
                                            right: "50px",
                                            border: "1px solid green",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            color: "green",
                                          }}
                                        />

                                        <FaTrashAlt
                                          onClick={() => handleRemoveItem(data)}
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "25px",
                                            marginTop: "-5px",
                                            position: "absolute",
                                            right: "15px",
                                            border: "1px solid red",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            color: "red",
                                          }}
                                        />
                                      </>
                                    ) : null}

                                    <h5 className="product-card-mrp my-1">
                                      In Stock:{" "}
                                      <b style={{ color: "green" }}>
                                        {data.available_stock || ""}
                                      </b>{" "}
                                      | QTY{" "}
                                      {data.product_status === 0 ? (
                                        <>
                                          {data.transferDetails &&
                                          data.transferDetails.length > 0
                                            ? data.transferDetails.reduce(
                                                (sum, detail) =>
                                                  sum + (detail.quantity || 0),
                                                0
                                              )
                                            : 0}
                                        </>
                                      ) : (
                                        <>
                                          {(data.selectedSeries &&
                                            data.selectedSeries.length) ||
                                            0}

                                          <Popup
                                            style={{
                                              backgroundColor: "white",
                                              border: "1px solid grey",
                                              borderRadius: "5px",
                                              color: "black",
                                              padding: "5px",
                                              marginLeft: "20px",
                                              marginTop: "-6px",
                                              cursor: "pointer",
                                              position: "absolute",
                                            }}
                                            on="click"
                                            pinned
                                            content={`${data.selectedSeries}`}
                                            trigger={
                                              <IoAlertCircleOutline
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "5px",
                                                  fontSize: "18px",
                                                  color: "red",
                                                }}
                                              />
                                            }
                                          />
                                        </>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  //Confirm change warehouse */}
      <Dialog
        open={openConfirmChange}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change warehouse?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmChange}>Cancel</Button>
          <Button autoFocus onClick={handleConfirmChange}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/*  //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <audio id="scanSound" src={soundEffect} />
    </>
  );
}

export default PackRequirementProducts;
