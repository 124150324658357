import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { apiKey, updateBrand } from "../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const EditBrandModal = ({
  open,
  handleClose,
  brand,
  onSave,
  fetchBrandType,
}) => {
  const [newBrand, setNewBrand] = useState(brand.product_brand);

  useEffect(() => {
    setNewBrand(brand.product_brand);
  }, [brand]);

  const handleSave = (e) => {
    e.preventDefault();
    if (newBrand === "" || newBrand === null) {
      toast.error("Please add the Brand name");
      return;
    }

    const updatedData = {
      brand_pk: brand.id,
      brand_name: newBrand,
    };

    console.log("updatedBrand : ", updatedData);

    axios
      .post(updateBrand, updatedData, {
        headers: {
          "API-key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          toast.success(response.data?.message);
          fetchBrandType();
          handleClose();
        }
      })
      .catch((error) => {
        toast.error(error.response.data?.message);
        console.log("Error Updating Brand :", error);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modalStyle }}>
          <h2 className="h3 mb-3">Edit Product Brand</h2>
          <form onSubmit={handleSave}>
            <TextField
              fullWidth
              label="Product Brand"
              value={newBrand}
              onChange={(e) => setNewBrand(e.target.value)}
              autoFocus
              size="small"
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default EditBrandModal;
