import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import {
  fetch_all_unallocated_product,
  apiKey,
  fetch_warehouse,
  fetch_unique_stocks,
  getTypeBrand,
  export_unallocated_products,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoAlertCircleOutline } from "react-icons/io5";
import SeriesModal from "./SeriesModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Pagination, Icon, Label, Input } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import { FaImage } from "react-icons/fa6";
import { CiMenuKebab } from "react-icons/ci";
import CustomLoader from "../../Components/Loader";
import SubmitLoader from "../../Components/SubmitLoader";
import FilterSelectionModal from "../../Components/FilterModal/FilterSelectionModal";
import toast, { Toaster } from "react-hot-toast";
import FilterTableColumns from "../../Components/FilterModal/FilterTableColumns";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


function UnallocatedProducts() {
  const isWideScreen = useScreenWidth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);

  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);

  // ========== Filter ===========

  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);

  useEffect(() => {
    fetchBrandType();
    fetchUniqueStocks();
  }, []);

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWarehouse(ware);
    setSubmitLoader(true);
  };

  useEffect(() => {

    fetchData();
  }, [warehouse]);

  useEffect(() => {
    fetchDataWarehouse();
  }, []);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    // debouncedFetchData(1, query);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchData();
  //   }, 180000); // 3 minutes in milliseconds

  //   return () => clearInterval(intervalId);
  // }, [currentPage, pageSize, searchquery]);
  useEffect(() => {
    fetchData();
  }, [selectedBrands, selectedTypes, selectedStocks, currentPage, pageSize , searchquery]);

  const fetchData = (page = currentPage, query = searchquery) => {
    setSubmitLoader(true);
    const data = {
      page,
      per_page: pageSize,
      search: query,
      warehouse_pk: warehouse,
      selectedBrands: selectedBrands || null,
      selectedTypes: selectedTypes || null,
      selectedStocks: selectedStocks || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    // console.log(data);
    axios
      .post(fetch_all_unallocated_product, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setProducts(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
        setSubmitLoader(false);
      })
      .catch((error) => {
        setSubmitLoader(false);
        setLoading(false);
      });
  };

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const types = response.data.product_types.map((type) => ({
          value: type.id,
          label: type.product_type,
        }));
        const brands = response.data.product_brands.map((brand) => ({
          value: brand.id,
          label: brand.product_brand,
        }));
        setTypes(types);
        setBrands(brands);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong");
      });
  };

  const fetchUniqueStocks = () => {
    axios
      .get(fetch_unique_stocks, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const stocks = response.data.data.map((stock, index) => ({
          value: index,
          label: stock,
        }));
        setStocks(stocks);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong");
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  const [open, setOpen] = useState(false);
  const [pseriessku, setPseriessku] = useState("");
  const [pseriesname, setPseriesname] = useState("");
  const [serieses, setSeries] = useState([]);
  const [sendData, setSendData] = useState({});

  const handleClickOpen = (sku, name, data, allData) => {
    setOpen(true);
    setSeries(data);
    setPseriessku(sku);
    setPseriesname(name);

    const newData = {
      brand__product_brand: allData.brand.product_brand,
      part_number: allData.part_number,
      sku: sku,
      series: data,
      name: name,
    };

    setSendData(newData); // Update sendData state
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const openmenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };





  const [exportLoading, setExportLoading] = useState(false);

  const handleExportUnallocatedProducts = () => {
    setExportLoading(true);
    const data = {
      search: searchquery,
      warehouse_pk: warehouse,
      selectedBrands: selectedBrands || null,
      selectedTypes: selectedTypes || null,
      selectedStocks: selectedStocks || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(export_unallocated_products, data, { headers })
      .then((response) => {
        if (response.data.status === 1) {
          const exportedData = response.data.data;
          // console.log("exportedData : ", exportedData)

          // Flatten and format the data
          const formattedData = exportedData.map(item => ({
            SKU: item.product_sku,
            ProductName: item.product_name,
            PartNumber: item.part_number,
            MRP: item.mrp,
            InStock: item.in_stock,
            // Warehouse: item.serieses.map(series => {
            //   const parts = [
            //     series.ware_house__ware_house,
            //     series.rack__row__row_name,
            //     series.rack__rack_name
            //   ].filter(Boolean).join(' | '); // Only include non-null values and join them with a separator
            //   return `Series ${series.series}: ${parts}`;
            // }).join(', ')
          }));

          // Convert data to a worksheet and save as Excel
          const worksheet = XLSX.utils.json_to_sheet(formattedData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Unallocated Products");

          // Save the workbook as an Excel file
          XLSX.writeFile(workbook, 'unallocated_products.xlsx');


          setExportLoading(false);
        } else {
          toast.error(response?.data?.message || "Something went wrong");
          setExportLoading(false);
        }

      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal Server Error");
        setExportLoading(false);
        console.error("Error while exporting Unallocated Products:", error);
      });
  };

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <CustomLoader loading={loading} />

      <Toaster position="top-right" />

      <SeriesModal
        open={open}
        setOpen={setOpen}
        pseriessku={pseriessku}
        pseriesname={pseriesname}
        serieses={serieses}
        sendData={sendData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Unallocated Products
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                {/* <input
                  className="form-control"
                  placeholder="Search product here..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                /> */}

                <Input
                  icon
                  placeholder="Search..."
                  value={searchquery}
                  onChange={handleSearchChange}
                >
                  <input />
                  <Icon name="search" />
                </Input>
              </div>

              <div
                className="col-4 col-lg-8 "
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                {isWideScreen ? (
                  <FilterTableColumns tableId="UnallocatedProducts" />
                ) : null}

                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleExportUnallocatedProducts}
                  disabled={exportLoading}
                  className="mb_btn_hide"
                  size="small"
                >
                  {exportLoading ? "Exporting..." : "Export"}
                </Button>


                <Button
                  className="mb_btn_hide"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => navigate(`/allocate-products-scan`)}
                >
                  Allocate Products
                </Button>
                <Button
                  className="mb_btn_hide"
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => navigate("/allocation-history")}
                >
                  Allocate History
                </Button>
                <FormControl size="small" className="mb_btn_hide">
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    style={{ minWidth: "200px" }}
                    value={warehouse}
                    label="Select Warehouse"
                    onChange={handleWarehouseChange}
                  >
                    <MenuItem value="">--Select Warehouse--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <span className="pc_btn_hide">
                  <Button>
                    <CiMenuKebab
                      style={{ fontSize: "20px", marginRight: "-20px" }}
                      onClick={handleClick}
                    />
                  </Button>
                </span>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openmenu}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => navigate(`/allocate-products-scan`)}>
                    Allocate Products
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/allocation-history")}>
                    Allocate History
                  </MenuItem>
                  <MenuItem>
                    <FormControl size="small">
                      <InputLabel>Select Warehouse</InputLabel>
                      <Select
                        style={{ minWidth: "200px" }}
                        value={warehouse}
                        label="Select Warehouse"
                        onChange={handleWarehouseChange}
                      >
                        <MenuItem value="">--Select Warehouse--</MenuItem>
                        {warehouses.map((ware) => (
                          <MenuItem key={ware.id} value={ware.id}>
                            {ware.ware_house}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table
                  className="ui table celled UnallocatedProducts"
                  id="c_table"
                >
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Part Number</th>
                      <th>Product</th>
                      <th>
                        <FilterSelectionModal
                          title="Type"
                          options={types}
                          selectedOptions={selectedTypes}
                          onSelect={setSelectedTypes}
                        />
                      </th>
                      <th>
                        <FilterSelectionModal
                          title="Brand"
                          options={brands}
                          selectedOptions={selectedBrands}
                          onSelect={setSelectedBrands}
                        />
                      </th>
                      <th style={{ textAlign: "center" }}>
                        <FilterSelectionModal
                          title="Stock"
                          options={stocks}
                          selectedOptions={selectedStocks}
                          onSelect={setSelectedStocks}
                        />
                      </th>
                      <th>MRP</th>
                      <th>OEM Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.length > 0 ? (
                      <>
                        {products &&
                          products.map((data) => (
                            <>
                              <tr>
                                <td>{data.product_sku}</td>
                                <td>{data.part_number}</td>
                                <td className="product_name_class"
                                  style={{
                                    maxWidth: "500px",

                                  }}
                                >
                                  {data.product_name}
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.product_type.product_type == "None"
                                        ? "grey"
                                        : "black",
                                  }}
                                >
                                  {data.product_type.product_type}
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.brand.product_brand == "None"
                                        ? "grey"
                                        : "black",
                                  }}
                                >
                                  {data.brand.product_brand}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    color: data.in_stock <= 2 ? "red" : "green",
                                  }}
                                >
                                  <b>{data.in_stock}</b>
                                </td>

                                <td style={{ color: "red" }}>
                                  <b>{formatIndianRupee(data.mrp)}</b>
                                </td>
                                <td className="hide_table_td">
                                  {data.oem_number}
                                </td>

                                <td style={{ textAlign: "center" }}>
                                  <IoAlertCircleOutline
                                    onClick={() =>
                                      handleClickOpen(
                                        data.product_sku,
                                        data.product_name,
                                        data.serieses,
                                        data
                                      )
                                    }
                                    style={{
                                      fontSize: "16px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {products.length > 0 ? (
                  <>
                    {products &&
                      products.map((data) => (
                        <>
                          <div className="product-card position-relative" >
                            <div>
                              <FaImage className="product-img" />
                            </div>
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                {data.product_sku}
                              </h5>
                              <h5 className="product-card-name">
                                {data.product_name}
                              </h5>
                              <h5 className="product-card-partNo">
                                PN : {data.part_number}
                              </h5>
                              <h5 className="product-card-mrp my-1">
                                {formatIndianRupee(data.mrp)}
                              </h5>

                              <Label
                                color={data.in_stock <= 2 ? "red" : "blue"}
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "7px",

                                  fontSize: "11px",

                                  cursor:
                                    data.product_type__product_type ==
                                      "Child Parts"
                                      ? "default"
                                      : "pointer",
                                }}
                                onClick={
                                  data.product_type__product_type !==
                                    "Child Parts"
                                    ? () =>
                                      handleClickOpen(
                                        data.product_sku,
                                        data.product_name,
                                        data.serieses,
                                        data
                                      )
                                    : undefined
                                }
                                className="product-card-mrp"
                              >
                                {data.in_stock} QTY
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UnallocatedProducts;
