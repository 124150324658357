import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useScreenWidth } from "../../useScreenWidth";
import { Label } from "semantic-ui-react";

function ViewAllocationHistoryModal({
  allocateDetails,
  opendetail,
  setOpenDetail,
}) {
  const isWideScreen = useScreenWidth();
  // Destructure the necessary details from allocateDetails
  const { date, user_name, ware_house__ware_house, rack, row, history_detail } =
    allocateDetails || {};

  const handleClose = () => {
    setOpenDetail(false);
  };



  return (
    <Dialog
      open={opendetail}
      onClose={(reason) => {
        if (reason !== "backdropClick") {
          setOpenDetail();
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>Allocation History</DialogTitle>
      <DialogContent dividers>
        <Box>
          <div className="row mb-1">
            <div className="col-lg-3 my-1">
              <Typography>
                <b style={{ color: "#3b3b3b" }}>
                  {date ? new Date(date).toLocaleString() : "-"}
                </b>
              </Typography>
            </div>
            <div className="col-lg-3 my-1">
              <Typography>
                Alloc. by:{" "}
                <b style={{ color: "#3b3b3b" }}>{user_name || "-"}</b>
              </Typography>
            </div>

            <div className="col-lg-4 my-1">
              <Typography>
                Warehouse:
                <b style={{ color: "#3b3b3b" }}>
                  {ware_house__ware_house || "-"}
                </b>
              </Typography>
            </div>

            <div className="col-lg-2 my-1">
              <Typography>
                Rack:{" "}
                <b style={{ color: "#3b3b3b" }}>
                  {row?.rack_number__row__row_name || "-"}
                </b>
                <b style={{ color: "#3b3b3b" }}>
                  {rack?.rack_number__rack_name || "-"}
                </b>
              </Typography>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1" style={{ maxHeight: "350px" }}>
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history_detail && history_detail.length > 0 ? (
                      history_detail.map((item, index) => (
                        <tr key={index}>
                          <td>{item.product_ref__product_sku}</td>
                          <td>{item.product_ref__product_name}</td>
                          <td>{item.quantity}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          No history details available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="responsive-mobile-table">
                {history_detail && history_detail.length > 0 ? (
                  history_detail.map((item, index) => (
                    <div
                      className="product-card position-relative"
                      style={{ minHeight: "80px" }}
                      key={index}
                    >
                      <div className="py-1 px-2">
                        <h5 className="product-card-sku">
                          #{item.product_ref__product_sku}
                        </h5>

                        <h5 className="product-card-name">
                          {item.product_ref__product_name}
                        </h5>

                        <h5 className="product-card-partNo">
                          PN : {item.part_number || "--"}
                        </h5>

                        <Label
                          color="blue"
                          style={{
                            position: "absolute",
                            bottom: "6px",
                            right: "7px",
                            fontSize: "11px",
                          }}
                          className="product-card-mrp"
                        >
                          QTY {item.quantity}
                        </Label>
                      </div>
                    </div>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      No history details available
                    </td>
                  </tr>
                )}
              </div>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewAllocationHistoryModal;
