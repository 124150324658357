import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_vendor } from "../../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import toast, { Toaster } from 'react-hot-toast';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditVendor({
  openvendor,
  handleCloseVendor,
  selectedEditVendorDetail = {},
}) {

  const [vendor_name, setVendor_name] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [gst, setGST] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [contact_name_phone, setContact_name_phone] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [country_code, setCountryCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const [postOffices, setPostOffices] = useState([]);

  useEffect(() => {
    if (selectedEditVendorDetail) {
      setVendor_name(selectedEditVendorDetail.name || "");
      setEmail(selectedEditVendorDetail.email || "");
      setContact(selectedEditVendorDetail.phone || "");
      setGST(selectedEditVendorDetail.gst_no || "");
      setContact_name(selectedEditVendorDetail.contact_name || "");
      setContact_name_phone(selectedEditVendorDetail.alternative_phone || "");
      setPincode(selectedEditVendorDetail.pincode || "");
      setState(selectedEditVendorDetail.state || "");
      setAddress(selectedEditVendorDetail.address || "");
      setCountryCode(selectedEditVendorDetail.country_code || "");
      setCity(selectedEditVendorDetail.city || ""); // Added city
      setCountry(selectedEditVendorDetail.country || ""); // Added country
    }
  }, [selectedEditVendorDetail,openvendor]);

  useEffect(() => {
    if (pincode.length === 6) {
      const fetchPincodeData = async () => {
        try {
          const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
          if (response.data[0].Status === "Success") {
            setPostOffices(response.data[0].PostOffice);

            setCity(response.data[0].PostOffice[0].District);
            setState(response.data[0].PostOffice[0].State);
            setCountry(response.data[0].PostOffice[0].Country);
            // setAddress(response.data[0].PostOffice[0].Name);

          } else {
            setPostOffices([]);
          }
        } catch (error) {
          console.error("Error fetching pincode data:", error);
          setPostOffices([]);
        }
      };
      fetchPincodeData();
    } else {
      setPostOffices([]); // Clear post offices if pincode is not valid
    }
  }, [pincode]);

  const handleEditVendor = (e) => {
    e.preventDefault()
    const id = selectedEditVendorDetail.id;
    if (!id) {
      toast.error("Vendor ID is missing");
      return;
    }

    let formattedData = {
      vendor_pk: id,
      vendor_data: {
        name: vendor_name,
        email: email,
        phone: contact,
        alternative_phone: contact_name_phone,
        country_code: country_code,
        address: address,
        city: city,
        state: state,
        country: country,
        pincode: pincode,
        contact_name: contact_name,
        gst_no: gst, // Use 'gst_no' as specified
      },
    };

    axios
      .post(edit_vendor, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(`Vendor ${vendor_name} has been updated successfully!`);
          // Clear form after submission
          setVendor_name("");
          setEmail("");
          setContact("");
          setContact_name("");
          setContact_name_phone("");
          setPincode("");
          setState("");
          setGST("");
          setAddress("");
          setCountryCode("");
          setCity("");
          setCountry("");
          handleCloseVendor(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "An error occurred");
      });
  };

  return (
    <>
    <Toaster  position="top-right" />
      <Modal
        open={openvendor}
        onClose={handleCloseVendor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Vendor
          </Typography>
          <form onSubmit={handleEditVendor}>
            <div className="row">
              <div className="col-md-12 my-2">
                <TextField
                  value={vendor_name}
                  label="Vendor"
                  size="small"
                  required
                  fullWidth
                  autoFocus
                  onChange={(e) => setVendor_name(e.target.value)}
                />
              </div>
              <div className="col-md-12 my-1">
                <TextField
                  value={gst}
                  label="GST"
                  size="small"
                  fullWidth
                  onChange={(e) => setGST(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  type="email"
                  value={email}
                  label="Email"
                  size="small"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={contact}
                  label="Contact"
                  size="small"
                  fullWidth
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
            </div>
            <h6 style={{ marginTop: "5px" }}>Contact</h6>
            <div className="row">
              <div className="col-md-6 my-1">
                <TextField
                  value={contact_name}
                  label="Contact name"
                  size="small"
                  fullWidth
                  onChange={(e) => setContact_name(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={contact_name_phone}
                  label="Contact"
                  size="small"
                  fullWidth
                  onChange={(e) => setContact_name_phone(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-1">
                <TextField
                  value={pincode}
                  label="Pincode"
                  size="small"
                  fullWidth
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-1">
                <TextField
                  value={state}
                  label="State"
                  size="small"
                  fullWidth
                  onChange={(e) => setState(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-1">
                <TextField
                  value={country_code}
                  label="Country code"
                  size="small"
                  fullWidth
                  onChange={(e) => setCountryCode(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={city}
                  label="City"
                  size="small"
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={country}
                  label="Country"
                  size="small"
                  fullWidth
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="col-md-12 my-1">
                <TextField
                  multiline
                  rows={3}
                  value={address}
                  label="Address"
                  size="small"
                  fullWidth
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div style={{ float: "right" }}>
              <Button
                style={{ marginTop: "8px" }}
                onClick={handleCloseVendor}
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                style={{ marginTop: "8px", marginLeft: "2px" }}
                type="submit"
                variant="outlined"
              >
                Save
              </Button>
            </div>

          </form>
        </Box>
      </Modal>
    </>
  );
}

export default EditVendor;
