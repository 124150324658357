import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, TextField, Select, MenuItem } from '@mui/material';
import toast from 'react-hot-toast';

function SelectPackRequirementSmallQty({ product, onUpdateQuantity, handleCloseModal }) {
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [isQuantityDisabled, setIsQuantityDisabled] = useState(false);

    // This effect will add picked_warehouse_qty to each warehouse in the available_inventory
    useEffect(() => {
        if (product && product.available_inventory) {
            product.available_inventory.forEach(warehouse => {
                if (warehouse.picked_warehouse_qty === undefined) {
                    warehouse.picked_warehouse_qty = 0; 
                }
            });
        }
    }, [product]);

    const handleWarehouseChange = (event) => {
        const selectedWarehousePk = event.target.value;
        setSelectedWarehouse(selectedWarehousePk);
        setQuantity(0);

        // Get selected warehouse data
        const selectedWarehouseData = product?.available_inventory?.find(
            (inv) => inv.warehouse_pk === selectedWarehousePk
        );
        if (selectedWarehouseData?.picked_warehouse_qty >= selectedWarehouseData?.quantity && (product.picked_quantity - product.packed_quantity <= 0)) {
            setIsQuantityDisabled(true); // Disable quantity input if condition is met
        } else {
            setIsQuantityDisabled(false); // Enable quantity input otherwise
        }
    };

    const handleQuantityChange = (event) => {
        const newQuantity = Number(event.target.value);
        setQuantity(newQuantity);

        // Get selected warehouse data
        const selectedWarehouseData = product?.available_inventory?.find(
            (inv) => inv.warehouse_pk === selectedWarehouse
        );

        // Check if the quantity exceeds available stock or picked quantity
        if (selectedWarehouseData) {
            // Ensure quantity does not exceed available stock
            if (newQuantity > selectedWarehouseData.quantity) {
                toast.error(`Exceeds available stock of ${selectedWarehouseData.quantity}`);
                setQuantity(selectedWarehouseData.quantity);  // Reset to max available stock
            }

            // Ensure quantity does not exceed picked_warehouse_qty
            if (newQuantity > product.picked_quantity - product.packed_quantity) {
                toast.error(`Cannot exceed picked quantity by ${product.picked_quantity - product.packed_quantity}`);
                setQuantity(product.picked_quantity - product.packed_quantity);  // Reset to picked quantity
            }
        }
    };

    const handleSubmit = () => {
        const selectedWarehouseData = product?.available_inventory?.find(
            (inv) => inv.warehouse_pk === selectedWarehouse
        );

        if (!selectedWarehouseData) {
            toast.error('Invalid warehouse selected');
            return;
        }


        // Add the picked quantity to the warehouse in available_inventory
        selectedWarehouseData.picked_warehouse_qty = quantity;

        // Update the quantity using the onUpdateQuantity function
        onUpdateQuantity(product.sku, { warehouse: selectedWarehouse, quantity });
        handleCloseModal();  // Close modal when quantity is confirmed
    };

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Select Quantity for {product?.product_name || 'Product'}
                </Typography>

                <Select
                    fullWidth
                    value={selectedWarehouse}
                    onChange={handleWarehouseChange}
                    displayEmpty
                    size='small'
                >
                    <MenuItem value="" disabled>
                        Select Warehouse
                    </MenuItem>
                    {product?.available_inventory?.map((inventory) => (
                        <MenuItem key={inventory.warehouse_pk} value={inventory.warehouse_pk}>
                            {inventory.warehouse_name} (Available: {inventory.quantity})
                        </MenuItem>
                    ))}
                </Select>

                {selectedWarehouse && (
                    <TextField
                        size='small'
                        fullWidth
                        type="number"
                        label="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        inputProps={{
                            min: 0,
                            max:
                                product.available_inventory.find(
                                    (inv) => inv.warehouse_pk === selectedWarehouse
                                )?.quantity || 0,
                        }}
                        disabled={isQuantityDisabled}  // Disable the input based on condition
                        sx={{ mt: 2 }}
                    />
                )}

                <Box sx={{ mt: 2, textAlign: 'right' }}>
                    <Button variant="contained" onClick={handleSubmit}>
                        Confirm
                    </Button>
                    <Button variant="outlined" color='error' onClick={handleCloseModal} sx={{ ml: 1 }}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default SelectPackRequirementSmallQty;
