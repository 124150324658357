import React, { useEffect, useState } from "react";
import {
  allocate_transfer_item,
  apiKey,
  fetch_racks,
  fetch_rows,
  TransferHistoryDetails,
} from "../../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Modal, Box } from "@mui/material";
import { IoClose } from "react-icons/io5";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FaWarehouse } from "react-icons/fa";
import { Label } from "semantic-ui-react";
import { useScreenWidth } from "../../../useScreenWidth";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import { Autocomplete, TextField } from "@mui/material";
import { SiTicktick } from "react-icons/si";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1200px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function TransferProductAllocate({
  open,
  setOpenTransferProductAllocate,
  transferPk,
}) {
  const isWideScreen = useScreenWidth();
  const [products, setProducts] = useState([]);
  const [transferProducts, setTransferProducts] = useState([]);
  const [sourcewarehouse, setSourceWarehouse] = useState("");
  const [date, setDate] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [targetWarehousePk, setTargetWarehousePk] = useState("");
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [loadings, setLoadings] = useState({
    warehouses: false,
    rows: false,
    racks: false,
  });

  const fetchData = () => {
    axios
      .get(TransferHistoryDetails, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          history_pk: transferPk,
        },
      })
      .then((response) => {
        console.log(response.data);
        setProducts(response.data.data);
        setDate(response.data.obj.date);
        setSourceWarehouse(response.data.obj.source_warehouse);
        setWarehouse(response.data.obj.target_warehouse);
        setTargetWarehousePk(response.data.obj?.target_warehouse_pk);
        // fetchRows(response.data.obj?.target_warehouse_pk);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal Server Error");
        console.log(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open && transferPk) {
      fetchData(transferPk);
    }
  }, [open, transferPk]);

  useEffect(() => {
    if (open && products) {
      setTransferProducts(
        products.map((product) => ({
          ...product,
          selectedWarehouse: product.selectedWarehouse || null,
          selectedRow: product.selectedRow || null,
          selectedRack: product.selectedRack || null,
        }))
      );
      fetchRows(targetWarehousePk);
    }
    // Fetch warehouses when the modal is opened
    // fetchWarehouses(); // Uncomment if needed
  }, [open, products]);

  useEffect(() => {
    if (open && transferProducts && targetWarehousePk) {
      // console.log("fetch row chala")
      fetchRows(targetWarehousePk);
    }
    // Fetch warehouses when the modal is opened
    // fetchWarehouses(); // Uncomment if needed
  }, [open, products]);

  const fetchRows = async (warehouseId) => {
    if (!warehouseId) {
      return;
    }
    setLoadings((prev) => ({ ...prev, rows: true }));
    try {
      const response = await axios.get(fetch_rows, {
        params: { warehouse_id: warehouseId },
        headers: { "API-Key": apiKey },
      });
      // console.log(response.data.rows)
      setRows(response.data.rows);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch rows.");
    } finally {
      setLoadings((prev) => ({ ...prev, rows: false }));
    }
  };

  const fetchRacks = async (rowId) => {
    setLoadings((prev) => ({ ...prev, racks: true }));
    try {
      const response = await axios.get(fetch_racks, {
        params: { row_pk: rowId },
        headers: { "API-Key": apiKey },
      });
      setRacks(response.data.racks);
    } catch (error) {
      toast.error("Failed to fetch racks.");
    } finally {
      setLoadings((prev) => ({ ...prev, racks: false }));
    }
  };

  const handleRowChange = (sku, row) => {
    setTransferProducts((prevDetails) =>
      prevDetails.map((detail) =>
        detail.id === sku
          ? { ...detail, selectedRow: row, selectedRack: "" }
          : detail
      )
    );

    if (row) {
      fetchRacks(row.id); // Fetch racks based on selected row
      setRacks([]); // Reset racks
    }
  };

  const handleRackChange = (sku, rack) => {
    setTransferProducts((prevDetails) =>
      prevDetails.map((detail) =>
        detail.id === sku ? { ...detail, selectedRack: rack } : detail
      )
    );
  };

  const handleAllocateProduct = (product) => {
    const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

    if (!product.selectedRack) {
      return toast.error("Please Select Rack");
    }

    const sendData = {
      transfer_item_pk: parseInt(product.id),
      rack_pk: parseInt(product.selectedRack.id),
      user_name: userDetails?.name,
    };

    // console.log(sendData)

    try {
      axios
        .post(allocate_transfer_item, sendData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            toast.success("Allocated successfully!");
            fetchData();
          } else {
            toast.error(response?.data?.message || "Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        });
    } catch (error) {
      console.log("error While Allocating Product :", error?.response);
    }
  };

  return (
    <>
      {/* {console.log("setTransferProducts : ", transferProducts)} */}
      <Toaster position="top-right" />

      <Dialog
        open={open}
        onClose={() => setOpenTransferProductAllocate(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Transfer Details</DialogTitle>

        <DialogContent dividers>
          <Box>
            <div>
              {products && (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <Typography>
                        Target Warehouse:{" "}
                        <b style={{ color: "#3b3b3b" }}>{warehouse}</b>
                      </Typography>
                    </div>

                    <div className="col-md-4">
                      <Typography>
                        Transfer Date:{" "}
                        <b style={{ color: "#3b3b3b" }}>{date}</b>
                      </Typography>
                    </div>

                    <div className="col-md-4">
                      <Typography>
                        Products Quantity:{" "}
                        <b style={{ color: "#3b3b3b" }}>{products.length} QTY</b>
                      </Typography>
                    </div>

                  
                  </div>

                  {isWideScreen ? (
                    <div
                      className="responsive-table1"
                      style={{ maxHeight: "350px" }}
                    >
                      <table className="ui table celled " id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th>Product</th>
                            <th style={{ textAlign: "center" }}>Quantity</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transferProducts.length > 0 ? (
                            <>
                              {transferProducts &&
                                transferProducts.map((data) => (
                                  <>
                                    <tr>
                                      <td>
                                        {data.product_ref.product_sku || "N/A"}
                                      </td>

                                      <td
                                        className="product_name_class"
                                        style={{
                                          maxWidth: "500px",
                                        }}
                                      >
                                        <span style={{ cursor: "pointer" }}>
                                          {data.product_ref.product_name ||
                                            "N/A"}
                                        </span>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.quantity}
                                      </td>

                                      <td style={{ maxWidth: "200px" }}>
                                        {data.is_allocatable == 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              textAlign: "center",
                                            }}
                                          >
                                            Can't Allocate
                                          </p>
                                        ) : (
                                          <div className="d-flex align-items-center justify-content-between gap-1">
                                            <Autocomplete
                                              fullWidth
                                              options={rows}
                                              getOptionLabel={(option) =>
                                                option.row_name
                                              }
                                              value={data.selectedRow || null} // Pass full row object
                                              onChange={(e, newValue) => {
                                                handleRowChange(
                                                  data.id,
                                                  newValue || null
                                                );
                                              }}
                                              style={{ width: "100px" }}
                                              loading={loadings.rows}
                                              renderInput={(params) => (
                                                <TextField
                                                  fullWidth
                                                  {...params}
                                                  label="Row"
                                                  variant="outlined"
                                                  size="small"
                                                />
                                              )}
                                            />

                                            <Autocomplete
                                              fullWidth
                                              style={{ width: "100px" }}
                                              options={racks}
                                              getOptionLabel={(option) =>
                                                option.rack_name
                                              }
                                              value={data.selectedRack || null} // Pass full rack object
                                              onChange={(e, newValue) => {
                                                handleRackChange(
                                                  data.id,
                                                  newValue || null
                                                );
                                              }}
                                              loading={loadings.racks}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Rack"
                                                  variant="outlined"
                                                  size="small"
                                                />
                                              )}
                                            />

                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                handleAllocateProduct(data)
                                              }
                                              style={{ padding: "10px" }}
                                              disabled={
                                                !data.selectedRow ||
                                                !data.selectedRack
                                              }
                                            >
                                              <SiTicktick />
                                            </Button>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan={4}>
                                  {!loading ? (
                                    <div className="notfound_img_div">
                                      <div className="notfound_img"></div>
                                    </div>
                                  ) : (
                                    <div className="notfound_img_div">
                                      <div className="loadingfound_img"></div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="card p-0 responsive-mobile-table"
                      style={{ maxHeight: "50vh" }}
                    >
                      {transferProducts &&
                        transferProducts.map((data) => (
                          <div className="product-card " key={data.id}>
                            <div className="py-1 px-2 w-100">
                              <h5 className="product-card-sku">
                                {" "}
                                #{data.product_ref.product_sku}
                              </h5>
                              <h5 className="product-card-name">
                                {data.product_ref.product_name || "-"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span style={{ color: "grey" }}>QTY:</span>{" "}
                                {data.quantity}
                              </h5>

                              {data.is_allocatable == 0 ? (
                                <>
                                  {/* <p className="text-center text-danger border border-danger mt-2 rounded">Can't Allocate</p> */}
                                  <Label
                                    style={{
                                      position: "absolute",
                                      marginTop: "-20px",
                                      right: "15px",
                                      fontSize: "9px",
                                    }}
                                    color={"green"}
                                    size="small"
                                    tag
                                  >
                                    Allocated
                                  </Label>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center flex-wrap justify-content-between mt-2">
                                    <Autocomplete
                                      fullWidth
                                      style={{ width: "48%" }}
                                      options={rows}
                                      getOptionLabel={(option) =>
                                        option.row_name
                                      }
                                      value={data.selectedRow || null} // Pass full row object
                                      onChange={(e, newValue) => {
                                        handleRowChange(
                                          data.id,
                                          newValue || null
                                        );
                                      }}
                                      loading={loadings.rows}
                                      renderInput={(params) => (
                                        <TextField
                                          fullWidth
                                          {...params}
                                          label="Row"
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    />

                                    <Autocomplete
                                      fullWidth
                                      style={{ width: "48%" }}
                                      options={racks}
                                      getOptionLabel={(option) =>
                                        option.rack_name
                                      }
                                      value={data.selectedRack || null} // Pass full rack object
                                      onChange={(e, newValue) => {
                                        handleRackChange(
                                          data.id,
                                          newValue || null
                                        );
                                      }}
                                      loading={loadings.racks}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Rack"
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    />

                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        handleAllocateProduct(data)
                                      }
                                      style={{ padding: "10px" }}
                                      className="w-100 mt-2"
                                      disabled={
                                        !data.selectedRow || !data.selectedRack
                                      }
                                    >
                                      <SiTicktick />
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenTransferProductAllocate(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TransferProductAllocate;
