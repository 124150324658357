import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, fetchRequirements, stopRequirement } from "../../Api";
import { useScreenWidth } from "../../useScreenWidth";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Pagination, Icon, Label } from "semantic-ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CustomLoader from "../../Components/Loader";
import FilterSelectionModalByNumber from "../../Components/FilterModal/FilterSelectionModalByNumber";

function Requirements() {
  const isWideScreen = useScreenWidth();
  const [allRequriments, setAllRequriments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);

  const [userDetails, setUserDetails] = useState("");

  const [stopStatus, setStopStatus] = useState([
    { value: 0, label: "Not Stopped" },
    { value: 1, label: "Stopped" },
  ]);
  const [selectedStopStatus, setSelectedStopStatus] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    const user = JSON.parse(localStorage.getItem("ktt_users"));
    setUserDetails(user || "");
  }, [currentPage, pageSize, searchquery, filterModel, selectedStopStatus]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      filters: filterModel.items || [],
      stop_status: selectedStopStatus || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    // console.log("data : ", data);
    axios
      .post(fetchRequirements, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setAllRequriments(response.data.data.reverse());
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // To stop the Process of requirement List
  const handleStopClick = (id) => {
    setSelectedRequirementId(id);
    setOpenModal(true);
  };

  const handleCloseStopModal = () => {
    setOpenModal(false);
  };

  const handleConfirmStop = () => {

    setOpenModal(false);
    setSelectedRequirementId(null);

    const data = {
      user_name: userDetails.name || "",
      requirement_pk: parseInt(selectedRequirementId),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(stopRequirement, data, { headers })
      .then((response) => {
        toast.success("Requirement List Stopped Successfully !!");
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "Internal Server Error");
      });
  };

  const handleViewDetails = (id) => {
    navigate(`/requirement-details/${id}`);
  };

  const handlePackingDetails = (id) => {
    navigate(`/requirement-details-dispatch/${id}`);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePackRequirement = (id) => {
    console.log(id);
    navigate(`/pack-requirement-products/${id}`);
  };
  
  const handlePackDetails = (id) => {
    navigate(`/packed-details/${id}`);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement List
              </Typography>
            </Breadcrumbs>
          </div>

          {isWideScreen ? (
            <div className="responsive-table1 my-2">
              <table className="ui table celled" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th>ID</th>
                    <th>Starting Time</th>
                    <th>Ending Time</th>
                    <th>
                      <FilterSelectionModalByNumber
                        title="Stopped Status"
                        options={stopStatus}
                        selectedOptions={selectedStopStatus}
                        onSelect={setSelectedStopStatus}
                      />
                    </th>
                    <th>Stopped By</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allRequriments &&
                    allRequriments.map((requirement, index) => (
                      <tr key={requirement.id || index}>
                        <td>#{requirement.id}</td>
                        <td>
                          {new Date(requirement.starting_time).toLocaleString()}
                        </td>
                        <td>
                          {requirement.ending_time
                            ? new Date(requirement.ending_time).toLocaleString()
                            : "N/A"}
                        </td>
                        <td>{requirement.is_stopped === 1 ? "Yes" : "No"}</td>
                        <td>{requirement.stopped_by || "N/A"}</td>
                        <td style={{ width: "200px" }}>
                          {userDetails && userDetails.role !== "dispatch" ? (
                            <>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handleViewDetails(requirement.id)
                                }
                              >
                                View Details
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handlePackingDetails(requirement.id)
                                }
                              >
                                View Details
                              </Button>
                            </>
                          )}

                          {requirement.can_be_packed === 0 &&
                            requirement.is_packed === 0 &&
                            userDetails.role !== "dispatch" && (
                              <Button
                                size="small"
                                variant="outlined"
                                disabled
                                className="ms-2"
                              >
                                Need to Pack
                              </Button>
                            )}

                          {/* {requirement.can_be_packed !== 0 && requirement.is_packed !== 0 && userDetails.role !== "dispatch" && (
                            <Button
                              size="small"
                              variant="outlined"
                              disabled
                              style={{ marginLeft: "8px" }}
                            >
                              Packed By {requirement.packed_by}
                            </Button>
                          )} */}

                          {requirement.is_stopped !== 1 &&
                            userDetails.role !== "dispatch" && (
                              <>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  onClick={() =>
                                    handleStopClick(requirement.id)
                                  }
                                  className="ms-2"
                                >
                                  Stop
                                </Button>
                              </>
                            )}

                          {requirement.can_be_packed !== 0 &&
                            userDetails.role !== "dispatch" && (
                              <>
                                {requirement.packed_status === 1 ? null : (
                                  <>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      className="ms-2"
                                      onClick={() =>
                                        handlePackRequirement(requirement.id)
                                      }
                                    >
                                      PACK
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          {requirement.packed_status === 1 && (
                            <Button
                              size="small"
                              color="success"
                              variant="outlined"
                              className="ms-2"
                              onClick={() => handlePackDetails(requirement.id)}
                            >
                              PACKED DETAILs
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
             <div className="card p-2 responsive-mobile-table">
                {allRequriments.length > 0 ? (
                  <>
                    {allRequriments &&
                      allRequriments.map((requirement, index) => (
                        <>
                          <div
                          key={index}
                            className="product-card"
                            // onClick={() =>
                            //   userDetails && userDetails.role !== "dispatch"
                            //     ? handleViewDetails(requirement.id)
                            //     : handlePackingDetails(requirement.id)
                            // }
                          >
                            <div className="py-1 px-2 w-100">
                              <h5 className="product-card-sku">
                                #{requirement.id}
                              </h5>
                              <h5
                                className="product-card-mrp"
                                style={{ color: "grey" }}
                              >
                                Start Time:{" "}
                                <b style={{ color: "black" }}>
                                  {new Date(
                                    requirement.starting_time
                                  ).toLocaleString()}
                                </b>
                              </h5>

                              <h5
                                className="product-card-mrp"
                                style={{ color: "grey" }}
                              >
                                End Time:{" "}
                                <b style={{ color: "black" }}>
                                  {requirement.ending_time
                                    ? new Date(
                                      requirement.ending_time
                                    ).toLocaleString()
                                    : "N/A"}
                                </b>
                              </h5>
                              <div
                                style={{
                                  position: "absolute",
                                  marginTop: "-51px",
                                  right: "11px",
                                }}
                              >
                                {requirement.is_stopped === 1 ? (
                                  <>
                                    <Label color="red">Stopped</Label>
                                  </>
                                ) : (
                                  <>
                                    <Label color="green">OnGoing</Label>
                                  </>
                                )}
                              </div>

                              <div className="mt-2 d-flex align-items-center gap-2 justify-content-between w-100">


                                {requirement.packed_status === 1 && (
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    className=" w-50 d-block"
                                    onClick={() => handlePackDetails(requirement.id)}
                                  >
                                    PACKED DETAILs
                                  </Button>
                                )}


                                {requirement.is_stopped !== 1 &&
                                  userDetails.role !== "dispatch" && (
                                    <>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        className=" w-50 d-block"
                                        onClick={() =>
                                          handleStopClick(requirement.id)
                                        }

                                      >
                                        Stop
                                      </Button>
                                    </>
                                  )}

                                {requirement.can_be_packed !== 0 &&
                                  userDetails.role !== "dispatch" && (
                                    <>
                                      {requirement.packed_status === 1 ? null : (
                                        <>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="success"
                                            className=" w-50 d-block"
                                            onClick={() =>
                                              handlePackRequirement(requirement.id)
                                            }
                                          >
                                            PACK
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  )}
                                {userDetails && userDetails.role !== "dispatch" ? (
                                  <>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      className=" w-50 d-block"
                                      onClick={() =>
                                        handleViewDetails(requirement.id)
                                      }
                                    >
                                      View Details
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      className=" w-50 d-block"
                                      onClick={() =>
                                        handlePackingDetails(requirement.id)
                                      }
                                    >
                                      View Details
                                    </Button>
                                  </>
                                )}

                                {requirement.can_be_packed === 0 &&
                                  requirement.is_packed === 0 &&
                                  userDetails.role !== "dispatch" && (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      disabled
                                      className=" w-50 d-block"
                                    >
                                      Need to Pack
                                    </Button>
                                  )}

                              </div>

                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCloseStopModal}>
        <DialogTitle>Confirm Stop</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to stop the process with ID:{" "}
            {selectedRequirementId}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStopModal}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStop}
            color="primary"
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Requirements;
