const prefix = "https://server.kuberturbotech.com";
const websocketip = "wss://server.kuberturbotech.com";
// const prefix = "http://192.168.1.3:8002";
// const websocketip = "ws://192.168.1.3:8004";
// const prefix = "http://212.38.94.126:8002";
// const websocketip = "ws://212.38.94.126:8004";

//Websocket
export const requirement_details = `${websocketip}/ws/requirement-items/`;
export const requirement_pickup = `${websocketip}/ws/requirement-item-pick-up/`;

// URLS //
export const loginURL = `${prefix}/api/login/`;
export const checkTokenValidity = `${prefix}/api/check-token-validity/`;
export const getNextSKU = `${prefix}/api/get-next-sku/`;

export const requirement_pickup_api = `${prefix}/api/pick-up-requirement-item/`;
// Legder
export const stock_ledger = `${prefix}/api/stock-ledger/`;
export const export_ledger = `${prefix}/api/export-ledger/`;

// type and Brands
export const get_product_series_by_warehouse = `${prefix}/api/get-product-series-for-warehouse/`;
export const export_unallocated_products = `${prefix}/api/export-unallocated-products/`;
export const getTypeBrand = `${prefix}/api/get-type-and-brand/`;
export const deleteType = `${prefix}/api/delete-type/`;
export const deleteBrand = `${prefix}/api/delete-brand/`;
export const updateType = `${prefix}/api/edit-type/`;
export const updateBrand = `${prefix}/api/edit-brand/`;

export const fetch_unique_stocks = `${prefix}/api/fetch-unique-stocks/`;

export const fetch_all_product_transfer = `${prefix}/api/get-all-products-for-transfer/`;
export const product_report = `${prefix}/api/product-report/`;
export const add_product_brand = `${prefix}/api/add-brand/`;
export const add_product_type = `${prefix}/api/add-type/`;
export const add_product = `${prefix}/api/add-product/`;
export const import_product = `${prefix}/api/import-products/`;
export const fetch_all_product = `${prefix}/api/get-all-products/`;
export const fetch_all_unallocated_product = `${prefix}/api/fetch-unallocated-products/`;
export const fetch_product_detail = `${prefix}/api/get-product-detail/`;
export const update_product = `${prefix}/api/update-product/`;
export const delete_products = `${prefix}/api/delete-products/`;
export const fetch_allocation_history = `${prefix}/api/fetch-allocation-history/`;
export const printracksBarcode = `${prefix}/api/get-racks-barcode/`;
export const validateWarehouse = `${prefix}/api/check-rack/`;
// export const validateWarehouse2 = `${prefix}/api/get-all-racks-code/`;
export const validateProduct = `${prefix}/api/check-product-series`;
export const add_warehouse = `${prefix}/api/add-warehouse/`;
export const edit_warehouse = `${prefix}/api/edit-warehouse/`;
export const delete_warehouse = `${prefix}/api/delete-warehouse/`;
export const add_row = `${prefix}/api/add-row/`;
export const edit_row = `${prefix}/api/edit-row/`;
export const delete_row = `${prefix}/api/delete-rows/`;
export const add_rack = `${prefix}/api/add-rack/`;
export const edit_rack = `${prefix}/api/edit-rack/`;
export const delete_rack = `${prefix}/api/delete-racks/`;
export const add_bin = `${prefix}/api/add-bin/`;
export const fetch_rows = `${prefix}/api/fetch-rows/`;
export const fetch_racks = `${prefix}/api/fetch-racks/`;
export const fetch_warehouse = `${prefix}/api/fetch-warehouses/`;



// Vendors
export const create_vendor = `${prefix}/api/create-vendor/`;
export const import_vendors = `${prefix}/api/import-vendors/`;
export const fetch_vendor = `${prefix}/api/fetch-vendor/`;
export const delete_vendors = `${prefix}/api/delete-vendors/`;
export const edit_vendor = `${prefix}/api/edit-vendor/`;

// draft purchase
export const create_draft_purchase = `${prefix}/api/create-draft-purchase/`;
export const fetch_draft_purchase = `${prefix}/api/fetch-draft-purchase/`;
export const fetch_draft_purchase_detail = `${prefix}/api/fetch-draft-purchase-detail/`;
export const delete_draft_purchase = `${prefix}/api/delete-draft-purchase/`;

//  purchase
export const create_purchase = `${prefix}/api/create-purchase/`;
export const fetch_purchase = `${prefix}/api/fetch-purchase/`;
export const fetch_purchase_detail = `${prefix}/api/fetch-purchase-detail/`;
export const edit_purchase = `${prefix}/api/edit-purchase/`;
export const delete_purchase = `${prefix}/api/delete-purchase/`;
export const recieve_all = `${prefix}/api/receive-complete-purchase/`;
export const barcode_printing = `${prefix}/api/get-barcode-details/`;

// Inventory
export const check_product_series_for_out = `${prefix}/api/check-product-series-for-out/`;
export const inventory_mapping = `${prefix}/api/inventory-mapping/`;
export const allocate_inventory = `${prefix}/api/allocate-inventory/`;
export const fetch_inventory = `${prefix}/api/fetch-inventory/`;
export const delete_single_inventory = `${prefix}/api/delete-inventory/`;
export const fetchProductsForTransfer = `${prefix}/api/fetch-product-for-transfer/`;
export const TransferInventory = `${prefix}/api/transfer-inventory/`;
export const TransferHistory = `${prefix}/api/fetch-transfer-history/`;
export const TransferHistoryDetails = `${prefix}/api/fetch-transfer-history-items/`;
export const TransferHistoryDetailsInven = `${prefix}/api/fetch-transfer-history-items-inventory/`;
export const stock_in_reasons = `${prefix}/api/fetch-stock-in-reasons/`;
export const allocate_adjustment_item = `${prefix}/api/allocate-adjustment-item/`;
export const allocate_transfer_item = `${prefix}/api/allocate-transfer-item/`;
export const export_transfer = `${prefix}/api/export-transfer/`;
//Convert
export const convertProducts = `${prefix}/api/convert-products/`;

// Clients
export const import_customers = `${prefix}/api/import-customers/`;
export const add_customer = `${prefix}/api/add-customer/`;
export const edit_customer = `${prefix}/api/edit-customer/`;
export const delete_customers = `${prefix}/api/delete-customers/`;
export const fetch_customer = `${prefix}/api/fetch-customers/`;
export const fetch_customer_category = `${prefix}/api/customer-categories/`;
export const fetch_customer_address = `${prefix}/api/fetch-customer-address/`;
export const update_customer_address = `${prefix}/api/fetch-customer-address/`;
export const update_customer_primary_address = `${prefix}/api/switch-customer-primary-address/`;
export const add_customer_address = `${prefix}/api/add-customer-address/`;
export const delete_customer_address = `${prefix}/api/delete-customer-address/`;
export const edit_customer_address = `${prefix}/api/edit-customer-address/`;
export const getLastPrice = `${prefix}/api/refresh-last-price/`;

// Sales
export const transfer_product_immediately = `${prefix}/api/transfer-product-immediately/`;
export const transfer_child_product_immediately = `${prefix}/api/transfer-child-product-immediately/`;
export const fetch_sale_product = `${prefix}/api/products-for-sale/`;
export const fetch_sale_product_cashsale = `${prefix}/api/product-for-cash-sale/`;
export const fetch_sale_chilproducts = `${prefix}/api/child-products/`;
export const create_sale = `${prefix}/api/create-sale/`;
export const fetch_sale = `${prefix}/api/fetch-sale/`;
export const update_sale = `${prefix}/api/update-sale/`;
export const fetch_sale_detail = `${prefix}/api/sale-detail/`;
export const cancel_sale = `${prefix}/api/cancel-sale/`;
export const pending_dispatch = `${prefix}/api/pending-dispatch/`;
export const export_todays_sale = `${prefix}/api/export-todays-sale/`;
export const edit_sale_customer = `${prefix}/api/edit-sale-customer/`;

export const edit_sale_item = `${prefix}/api/edit-sale-item/`;
export const edit_sale_note = `${prefix}/api/edit-sale-note/`;
export const edit_sale_summary = `${prefix}/api/edit-sale-summary/`;

//Dispatch
export const validateProductForDispatch = `${prefix}/api/check-product-series-for-dispatch/`;
export const DispatchHistory = `${prefix}/api/dispatch-history/`;
export const DispatchHistoryItems = `${prefix}/api/dispatch-history-items/`;
export const DispatchSale = `${prefix}/api/dispatch-sale/`;
export const checkAvailableStockQty = `${prefix}/api/check-available-stock-of-sale-items/`;

export const fetchProductInven = `${prefix}/api/fetch-product-inventory/`;
export const inventory_Stock_In = `${prefix}/api/stock-in/`;
export const inventory_Stock_Out = `${prefix}/api/stock-out/`;
export const allocateinventory_Stock_Out = `${prefix}/api/allocate-inventory-from-product/`;
export const adjustmentHostory = `${prefix}/api/adjustment-history/`;
export const adjustment_History_Detail_ById = `${prefix}/api/adjustment-history-detail/`;
export const adjustmentBarcodes = `${prefix}/api/adjustment-barcodes/`;

export const bulk_Stock_In = `${prefix}/api/bulk-stock-in/`;
export const bilk_Stock_Out = `${prefix}/api/bulk-stock-out/`;
export const switchWarehouse = `${prefix}/api/switch-primary-warehouse/`;


// Requirements
export const get_products_for_packing = `${prefix}/api/get-products-for-packing/`;
export const check_product_series_for_packing = `${prefix}/api/check-product-series-for-packing/`;
export const pack_requirement = `${prefix}/api/pack-requirement/`;
export const add_requirement_item = `${prefix}/api/add-requirement-item/`;
export const export_requirement = `${prefix}/api/export-requirement/`;
export const fetchRequirements = `${prefix}/api/fetch-requirements/`;
export const stopRequirement = `${prefix}/api/stop-requirement/`;
export const fetchSingleRequirement = `${prefix}/api/fetch-requirement-items/`;
export const editQtyforRequirement = `${prefix}/api/edit-requirement-item-quantity/`;
export const removefrompickup = `${prefix}/api/remove-requirement-item-from-pickup/`;
export const check_product_transfer = `${prefix}/api/check-product-series-for-transfer/`;
export const transfer_child_product_fetch = `${prefix}/api/child-products-for-transfer/`;
export const convert_product = `${prefix}/api/convert-products/`;
export const conversion_history = `${prefix}/api/conversion-history/`;
export const conversion_history_items = `${prefix}/api/conversion-history-items/`;
export const conversion_barcodes = `${prefix}/api/conversion-barcodes/`;
export const fetch_discount_settings = `${prefix}/api/fetch-disount-settings/`;
export const set_discount_settings = `${prefix}/api/set-discount-settings/`;
export const export_adjustment = `${prefix}/api/export-adjustment-in-excel/`;
export const packed_details = `${prefix}/api/fetch-requirement-packings/`;
export const confirm_requirement = `${prefix}/api/confirm-requirement-shipping/`;
export const recieve_requirement_package = `${prefix}/api/recieve-requirement-packing/`;
export const remove_requirement_item_from_picking = `${prefix}/api/remove-requirement-item-from-picking/`;
export const delete_requirement_item = `${prefix}/api/delete-requirement-item/`;

export const fetch_requirement_descrypancy = `${prefix}/api/fetch-requirement-descrypancy/`;


// API KEY
export const apiKey = "Ankur.XhykdhmepsqXiWbTlMwVQ.kuberturboTech";
