import React, { useState } from "react";
import axios from "axios";
import { apiKey, recieve_all } from "../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomLoader from "../../Components/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RcvAlltoWarehouseModal({
  setOpenModalWarehouse,
  openModalWarehouse,
  warehouses,
  purchase_pk,
}) {
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [primarywarehouse, setPrimaryWare] = useState("");
  const handleClose = () => {
    setOpenModalWarehouse(false);
  };

  const handleReceive = (e) => {
    e.preventDefault();
    setLoading(true);
    const formattedData = {
      purchase_pk: parseInt(purchase_pk),
      warehouse_pk: primarywarehouse,
      user_name: userDetails.name,
    };

    // userDetails.email = primarywarehouse;

    axios
      .post(recieve_all, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          toast.success("Purchase received successfully!");
          setLoading(false);
          setOpenModalWarehouse(false);
          navigate("/all_purchases");
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.data.message ||
            "Something went wrong! Please try again."
        );
      });
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <Dialog open={openModalWarehouse} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Select Warehouse to Receive
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <FormControl fullWidth>
              <InputLabel>Warehouse</InputLabel>
              <Select
                value={primarywarehouse}
                label="Warehouse"
                onChange={(e) => setPrimaryWare(e.target.value)}
              >
                <MenuItem value="">--Select Warehouse--</MenuItem>
                {warehouses.map((ware) => (
                  <MenuItem value={ware.id}>{ware.ware_house}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            color="primary"
            disabled={!primarywarehouse || loading}
            onClick={handleReceive}
          >
            Receive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RcvAlltoWarehouseModal;
