import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import jsBarcode from "jsbarcode";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PrintRackBarcode({ setOpenModalBarcode, openModalBarcode, racksbar }) {
  const generateBarcodeImage = (sku) => {
    const canvas = document.createElement("canvas");
    jsBarcode(canvas, sku, {
      width: 1,
      height: 46,
      fontSize: 0,

      margin: 2,
    });
    return canvas.toDataURL();
  };

  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=1200");

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              .print-warebarcode {
                margin: 15px 10px;
                page-break-inside: avoid;
                page-break-before: always;
              }
              .barcodeName {
                margin: 5px 0;
              }
              @page {
                size: auto;
                margin: 20mm;
              }
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${
            racksbar &&
            racksbar
              .map(
                (data) =>
                  `
                <div class="print-warebarcode" style="font-family:sans-serif">

                
                    <img src="${generateBarcodeImage(
                      data.barcode_value
                    )}" style="width:100%;height:70px"/>
                     <p style="font-size:10px;margin-top:-2px;text-align:center">${
                       data.warehouse_name.split(" ")[0] +
                       "_" +
                       data.row_name +
                       "_" +
                       data.rack_name
                     }</p>
                
                </div>
              `
              )
              .join("")
          }
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleClose = () => {
    setOpenModalBarcode(false);
  };

  return (
    <div>
      <Modal
        open={openModalBarcode}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpenModalBarcode();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Warehouse Barcode</Typography>
            <Typography sx={{ color: "text.primary" }}>
              Printing Preview
            </Typography>
          </Breadcrumbs>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginTop: "2px",
              padding: "20px",
            }}
          >
            {racksbar &&
              racksbar.map((data, index) => (
                <div key={`${index}`} className="wareBarcode print-warebarcode">
                  <img
                    src={generateBarcodeImage(data.barcode_value)}
                    alt="Barcode"
                    style={{ width: "100%", height: "80px" }}
                  />
                  {/* <h6>{data.barcode_value}</h6> */}
                  <p style={{ fontSize: "10px", textAlign: "center" }}>
                    {data.warehouse_name.split(" ")[0]}_{data.row_name}_
                    {data.rack_name}
                  </p>
                </div>
              ))}

              
          </TableContainer>
          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              onClick={handlePrint}
              variant="outlined"
            >
              Confirm Print
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PrintRackBarcode;
