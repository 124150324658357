import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { inventory_Stock_In, inventory_Stock_Out, apiKey } from "../../Api";
import TextField from "@mui/material/TextField";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1100px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RowRackInvenAdjust({
  setOpenModal,
  openModal,
  product,
  qty,
  inven,
  fetchData,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const [success, setSuccess] = useState("");
  const [quantity, setQuantity] = useState("");
  const [type, setType] = useState("");
  const [remark, setRemark] = useState("");
  // console.log(id);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const handleClose = () => {
    setOpenModal(false);
    setError(""); // Clear error on close
  };

  const handleConfirm = () => {
    let formattedData = {
      inventory_pk: parseInt(inven),
      quantity: parseInt(quantity),
      user_name: userDetails.name,
      remark: remark,
    };

    if (type == 1) {
      axios
        .post(inventory_Stock_In, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          setOpenSuccess(true);
          setSuccess(`Stock In Successful!`);
          setOpenModal(false);
          setQuantity("");
          setRemark("");
          setType("");
          fetchData();
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    } else if (type == 2) {
      axios
        .post(inventory_Stock_Out, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          setOpenSuccess(true);
          setSuccess(`Stock Out Successful!`);
          setOpenModal(false);
          setQuantity("");
          setRemark("");
          setType("");
          fetchData();
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    }
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            {product.product_sku}: {product.product_name}
          </Typography>

          <div className="row my-3">
            <div className="col-lg-6">
              <FormControl fullWidth>
                <InputLabel>Adjustment Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  label="Adjustment Type"
                >
                  <MenuItem value="">--Select--</MenuItem>
                  <MenuItem value="1">Stock In</MenuItem>
                  <MenuItem value="2">Stock Out</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-3">
              <TextField
                label="Quantity"
                value={quantity}
                style={{ width: "100%" }}
                onChange={(e) => setQuantity(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="col-lg-3">
              <TextField
                label="Available Quantity"
                style={{ width: "100%" }}
                value={qty}
                disabled
                variant="outlined"
              />
            </div>

            <div className="col-lg-12 my-2">
              <TextField
                label="Remark"
                rows={3}
                style={{ width: "100%" }}
                variant="outlined"
                multiline
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
          <h6 style={{ textAlign: "right" }}>
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "8px" }}
              variant="outlined"
              color="primary"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </h6>
        </Box>
      </Modal>
    </>
  );
}

export default RowRackInvenAdjust;
