import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { apiKey, updateType } from "../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const EditTypeModal = ({ open, handleClose, type, onSave, fetchBrandType }) => {
  const [newType, setNewType] = useState(type.product_type);

  useEffect(() => {
    setNewType(type.product_type);
  }, [type]);

  const handleSave = (e) => {
    e.preventDefault();
    if (newType === "" || newType === null) {
      toast.error("Please add the Type name");
      return;
    }

    const updatedData = {
      type_pk: type.id,
      type_name: newType,
    };

    console.log("updatedType : ", updatedData);

    axios
      .post(updateType, updatedData, {
        headers: {
          "API-key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          toast.success(response.data?.message);
          fetchBrandType();
          handleClose();
        }
      })
      .catch((error) => {
        toast.error(error.response.data?.message);
        console.log("Error Updating Type :", error);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modalStyle }}>
          <h2 className="h3 mb-3">Edit Product Type</h2>
          <form onSubmit={handleSave}>
            <TextField
              fullWidth
              label="Product Type"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              autoFocus
              size="small"
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default EditTypeModal;
