import { Button } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FaCaretDown } from 'react-icons/fa';
import { Checkbox, Input } from 'semantic-ui-react';

function FilterSelectionModalByNumber({ title, options, selectedOptions, onSelect }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [tempSelectedOptions, setTempSelectedOptions] = useState([...selectedOptions]);
    const buttonRef = useRef(null);
    const modalRef = useRef(null); // Ref for the modal container

    useEffect(() => {
        setTempSelectedOptions([...selectedOptions]);
    }, [selectedOptions]);

    useEffect(() => {
        // Close the modal if a click outside is detected
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleSelectAll = () => {
        if (tempSelectedOptions.length === options.length) {
            setTempSelectedOptions([]);
        } else {
            setTempSelectedOptions(options.map(option => option.value));
        }
    };

    const handleOptionChange = (optionValue) => {
        if (tempSelectedOptions.includes(optionValue)) {
            setTempSelectedOptions(tempSelectedOptions.filter(value => value !== optionValue));
        } else {
            setTempSelectedOptions([...tempSelectedOptions, optionValue]);
        }
    };

    const handleClear = () => {
        setTempSelectedOptions([]);
        onSelect([]);
        setIsOpen(false);
    };

    const handleApply = () => {
        // Send the selected IDs as integers
        onSelect(tempSelectedOptions.map(option => parseInt(option, 10)));
        setIsOpen(false);
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const filteredOptions = options.filter(option =>
        option.label && option.label.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getModalPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            return {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            };
        }
        return {};
    };

    const modalContent = (
        <div className="filter-modal" style={getModalPosition()} ref={modalRef}>
            <Input
                type="text"
                placeholder={`Search ${title}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div>
                <Checkbox
                    type="checkbox"
                    checked={tempSelectedOptions.length === options.length}
                    onChange={handleSelectAll}
                    label="Select all"
                />
            </div>
            <div className="filter-options">
                {filteredOptions.map(option => (
                    <div key={option.value}>
                        <Checkbox
                            type="checkbox"
                            checked={tempSelectedOptions.includes(option.value)}
                            onChange={() => handleOptionChange(option.value)}
                            label={option.label}
                        />
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-end mt-2">
                <Button variant="outlined" size="small" color="error" onClick={handleClear}>
                    Clear
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "5px" }}
                    onClick={handleApply}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    return (
        <div ref={buttonRef}>
            <div onClick={handleToggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' , justifyContent:"space-between" }}>
                {title} <FaCaretDown style={{ marginLeft: '5px' }} />
            </div>
            {isOpen && ReactDOM.createPortal(
                modalContent,
                document.body
            )}
        </div>
    );
}

export default FilterSelectionModalByNumber;
