import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { apiKey, conversion_history_items } from "../../Api";
import toast, { Toaster } from "react-hot-toast";
import { TbExternalLink } from "react-icons/tb";
import { useScreenWidth } from "../../useScreenWidth";
import { Label } from "semantic-ui-react";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "950px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function ConversionDetailModal({ open, onClose, conversionId }) {
  const isWideScreen = useScreenWidth();
  const [conversionDetail, setConversionDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  // Other Main Information
  const [conversionOtherDetail, setConversionOtherDetail] = useState();

  useEffect(() => {
    if (open && conversionId) {
      fetchData();
    }
  }, [open, conversionId]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      convert_history_pk: parseInt(conversionId),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(conversion_history_items, data, { headers })
      .then((response) => {
        setConversionDetail(response.data.convert_history);
        setConversionOtherDetail(response.data.history_obj);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data?.message);

        setLoading(false);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className="bg-white py-3 px-3">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <>
              <Typography variant="h5" component="h2">
                Conversion Details <small>(#{conversionId})</small>
                <a
                  href={`/#/conversion-detail/${conversionId}`}
                  target="_blank"
                  className="navigate-other"
                >
                  <TbExternalLink />
                </a>
              </Typography>
              <div className="row my-2">
                <div className="col-md-4">
                  <Typography>
                    <strong>Warehouse: </strong>{" "}
                    {conversionOtherDetail?.warehouse || "N/A"}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Date: </strong>
                    {conversionOtherDetail?.convert_date.toLocaleString() ||
                      "N/A"}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Conversion By: </strong>{" "}
                    {conversionOtherDetail?.user_name || "N/A"}
                  </Typography>
                </div>
              </div>

              {isWideScreen ? (
                <>
                  <div
                    className="responsive-table1 "
                    style={{
                      maxHeight: "300px",
                      height: "max-content",
                      width: "100%",
                    }}
                  >
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>ID</th>
                          <th>Source Product</th>
                          <th>Target Product</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conversionDetail &&
                          conversionDetail.map((conversion, index) => (
                            <tr key={conversion.id || index}>
                              <td>{conversion.id}</td>
                              <td>
                                <span
                                  style={{ color: "grey" }}
                                  className="d-block"
                                >
                                  #{conversion.source_product.product_sku}
                                </span>

                                <span>
                                  {conversion.source_product.product_name}
                                </span>
                              </td>

                              <td>
                                <span
                                  style={{ color: "grey" }}
                                  className="d-block"
                                >
                                  #{conversion.target_product.product_sku}
                                </span>

                                <span>
                                  {conversion.target_product.product_name}
                                </span>
                              </td>

                              <td
                                style={{ width: "90px", textAlign: "center" }}
                              >
                                {conversion.quantity}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="card p-2 responsive-mobile-table"
                    style={{ maxHeight: "250px" }}
                  >
                    {conversionDetail.length > 0 ? (
                      <>
                        {conversionDetail &&
                          conversionDetail.map((data, index) => (
                            <>
                              <div
                                className="product-card"
                                key={data.id || index}
                              >
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.source_product.product_sku || "N/A"}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.source_product.product_name}
                                  </h5>
                                  <h5 style={{ textAlign: "center" }}>
                                    <Label
                                      style={{
                                        margin: "5px 0px",
                                        textAlign: "center",
                                      }}
                                      size="small"
                                    >
                                      <MdKeyboardDoubleArrowDown /> Converted to
                                    </Label>
                                    <Label
                                      color="green"
                                      style={{
                                        margin: "5px 0px",
                                        marginLeft: "5px",
                                      }}
                                      size="small"
                                    >
                                      QTY {data.quantity}
                                    </Label>
                                  </h5>
                                  <h5 className="product-card-sku">
                                    #{data.target_product.product_sku || "N/A"}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.target_product.product_name}
                                  </h5>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {!loading ? (
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        ) : (
                          <div className="notfound_img_div">
                            <div className="loadingfound_img"></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ marginTop: "20px", float: "right" }}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ConversionDetailModal;
