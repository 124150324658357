import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, requirement_pickup, requirement_pickup_api } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Chip, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import soundEffect from "../../Assets/sounds/beep.mp3";
import { Input, Label } from "semantic-ui-react";

function PickUp() {
  const isWideScreen = useScreenWidth();

  const [loading, setLoading] = useState(true);
  const [productSKU, setProductSKU] = useState("");
  const [productName, setProductName] = useState("");
  const [partnumber, setPartNumber] = useState("");
  const [pickedQty, setPickedQty] = useState("");
  const [ischild, setIsChild] = useState(0);
  const [reqQty, setReqQty] = useState("");
  const [suggestedData, setSuggestedData] = useState([]);

  const { pk } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const ws = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_pickup}?requirement_item_pk=${pk}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(response);
      setProductSKU(response.obj.product_sku);
      setProductName(response.obj.product_name);
      setPartNumber(response.obj.part_number);
      setPickedQty(response.obj.picked_up_quantity);
      setReqQty(response.obj.required_quantity);
      Object.values(response.inventory).forEach((data) => {
        setIsChild(data.is_child);
      });
      setSuggestedData(Object.values(response.inventory).map((data) => data));
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [pk, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(pk),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const aggregateInventory = (inventory) => {
    const aggregatedData = {};

    inventory.forEach((inv) => {
      const key = `${inv.rack__row__row_name}-${inv.rack__rack_name}`;
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          rack__row__row_name: inv.rack__row__row_name,
          rack__rack_name: inv.rack__rack_name,
          quantity: 0,
        };
      }
      aggregatedData[key].quantity += 1; // Assuming each item has a quantity of 1
    });

    return Object.values(aggregatedData);
  };

  const [quantity, setQuantity] = useState("");

  const onchangeQuantity = (qty) => {
    let remainingQty = reqQty - pickedQty;

    if (qty > remainingQty) {
      toast.error("Quantity can't exceed remaining quantity!");
      setQuantity("");
      return;
    }
    setQuantity(qty);
  };
  const submitPickedQty = () => {
    const submitdata = {
      item_pk: parseInt(pk),
      quantity: parseInt(quantity),
    };

    axios
      .post(requirement_pickup_api, submitdata, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status === 1) {
          toast.success("Product Picked Successfully!");
          setQuantity("");
          fetchData();
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <audio id="scanSound" src={soundEffect} />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>Pick Up</Typography>
            </Breadcrumbs>
          </div>

          <h5 className="mb-1 mt-3">Product Details:</h5>
          <div className="card p-3">
            <div className="d-flex">
              <div></div>
              <div>
                <p className="mb-0">#{productSKU}</p>
                <p className="mb-0">{productName}</p>
                <p className="mb-0">Part No.: {partnumber}</p>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 mt-2">Required Qty: {reqQty || 0}</h5>
                  <h5 className="mb-0 mt-2 ms-2">
                    | Remaining Qty:{" "}
                    <b style={{ color: "red" }}>{reqQty - pickedQty || 0}</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-2 mt-2 ">
            <div className="d-flex gap-1">
              <div>
                <Input
                  type="number"
                  placeholder="Qty"
                  value={quantity}
                  // onChange={(e) => setQuantity(e.target.value)}
                  onChange={(e) => onchangeQuantity(e.target.value)}
                />
              </div>

              <div>
                <Button
                  variant="contained"
                  style={{ height: "37px" }}
                  disabled={!quantity}
                  onClick={submitPickedQty}
                >
                  Confirm PickUp
                </Button>
              </div>
            </div>
          </div>

          <div className="my-2">
            <div className="row mt-1">
              <div className={ischild == 1 ? "col-md-8" : "col-md-7"}>
                {isWideScreen ? (
                  <div className="responsive-table1">
                    {suggestedData.length > 0 ? (
                      suggestedData.map((req, reqIndex) => {
                        const aggregatedInventory = aggregateInventory(
                          req.inventory
                        );

                        return (
                          <div className="row" key={reqIndex}>
                            <div className="col-md-12">
                              <Label
                                style={{
                                  marginTop: "6px",
                                  maxWidth: "150px",
                                  marginBottom: "2px",
                                  display: ischild === 1 ? "none" : "block",
                                }}
                                as="a"
                                tag
                                color="red"
                              >
                                {req.ware_house}
                              </Label>
                              <table
                                style={{ margin: "0" }}
                                className="ui table celled"
                                id="c_table"
                              >
                                <thead className="table-head">
                                  <tr>
                                    <th>#</th>
                                    {ischild !== 1 ? <th>Row</th> : null}
                                    {ischild !== 1 ? <th>Rack</th> : null}
                                    {ischild === 1 ? <th>Warehouse</th> : null}

                                    <th>Avail Qty</th>
                                    {ischild === 1 ? <th>Quantity</th> : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {req.unallocated && req.unallocated > 0 ? (
                                    <>
                                      <tr>
                                        <td colSpan="3">Unallocated </td>
                                        <td>{req.unallocated}</td>
                                      </tr>
                                    </>
                                  ) : null}

                                  {ischild === 1
                                    ? req.inventory &&
                                      req.inventory.map((inv, invIndex) => (
                                        <tr key={invIndex}>
                                          <td style={{ width: "5%" }}>
                                            {invIndex + 1}
                                          </td>

                                          <td style={{ width: "25%" }}>
                                            {req.ware_house}
                                          </td>

                                          <td style={{ width: "25%" }}>
                                            {inv.quantity} Qty
                                          </td>
                                          <td style={{ width: "35%" }}></td>
                                        </tr>
                                      ))
                                    : aggregatedInventory &&
                                      aggregatedInventory.map(
                                        (inv, invIndex) => (
                                          <>
                                            <tr key={invIndex}>
                                              <td style={{ width: "5%" }}>
                                                {invIndex + 1}
                                              </td>
                                              <td style={{ width: "40%" }}>
                                                {inv.rack__row__row_name || "-"}
                                              </td>
                                              <td style={{ width: "40%" }}>
                                                {inv.rack__rack_name || "-"}
                                              </td>
                                              <td style={{ width: "25%" }}>
                                                {inv.quantity} Qty
                                              </td>
                                              {ischild === 1 ? (
                                                <td
                                                  style={{ width: "35%" }}
                                                ></td>
                                              ) : null}
                                            </tr>
                                          </>
                                        )
                                      )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          {!loading ? (
                            <div className="notfound_img_div">
                              <div className="notfound_img"></div>
                            </div>
                          ) : (
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="responsive">
                      <h5 className="mt-2 mb-1">Available Inventory:</h5>
                      {suggestedData.length > 0 ? (
                        suggestedData.map((req, reqIndex) => {
                          const aggregatedInventory = aggregateInventory(
                            req.inventory
                          );

                          return (
                            <>
                              <div key={reqIndex}>
                                {ischild === 1
                                  ? req.inventory &&
                                    req.inventory.map((inv, invIndex) => (
                                      <>
                                        <div
                                          className="product-card"
                                          key={invIndex}
                                        >
                                          <div className="py-1 px-2">
                                            <p
                                              className="product-card-name mb-0"
                                              style={{ color: "grey" }}
                                            >
                                              {req.ware_house}
                                            </p>
                                            <h5 className="product-card-mrp">
                                              <span
                                                style={{ color: "#c7c7c7" }}
                                              >
                                                Available Qty:
                                              </span>{" "}
                                              <b>{inv.quantity}</b>
                                            </h5>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  : aggregatedInventory &&
                                    aggregatedInventory.map((inv, invIndex) => (
                                      <>
                                        <div
                                          className="product-card"
                                          key={invIndex}
                                        >
                                          <div className="py-1 px-2">
                                            <p
                                              className="product-card-name mb-0"
                                              style={{ color: "grey" }}
                                            >
                                              {req.ware_house}
                                            </p>
                                            <h5 className="product-card-name">
                                              <span style={{ color: "grey" }}>
                                                Rack :
                                              </span>{" "}
                                              {inv.rack__row__row_name || "-"}{" "}
                                              {inv.rack__rack_name || "-"}{" "}
                                            </h5>

                                            <Chip
                                              style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "5px",
                                              }}
                                              label={`QTY: ${inv.quantity}`}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ))}

                                {req.unallocated && req.unallocated > 0 ? (
                                  <>
                                    <div
                                      className="product-card"
                                      style={{ backgroundColor: "#ffd7d4" }}
                                    >
                                      <div className="py-1 px-2">
                                        <h5 className="product-card-name">
                                          Unallocated :{" "}
                                          <b style={{ color: "red" }}>
                                            {req.unallocated}
                                          </b>
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PickUp;
