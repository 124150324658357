import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { apiKey, export_transfer, TransferHistory } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useScreenWidth } from "../../../useScreenWidth.js";
import toast, { Toaster } from "react-hot-toast";
import { Icon, Label, Pagination } from "semantic-ui-react";
import CustomLoader from "../../../Components/Loader";
import FilterDateRangeModal from "../../../Components/FilterModal/FilterDateRangeModal.js";
import FilterSelectionModalByWarehouse from "../../../Components/FilterModal/FilterSelectionModalByWarehouse.js";
import { formatDate } from "../../../Components/DateFormat.js";
import TransferProductAllocate from "./TransferProductAllocate.js";
import * as XLSX from "xlsx";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

function StockTransfer() {
  const isWideScreen = useScreenWidth();
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // Warehouse Filter
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  // date Filter

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchquery,
    startDate,
    endDate,
    selectedWarehouseIds,
  ]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      starting_date: startDate || "",
      ending_date: endDate || "",
      selectedWarehouses: selectedWarehouseIds || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(TransferHistory, data, { headers })
      .then((response) => {
        // console.log(response);
        setTransfers(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleEditClick = (pk) => {
    navigate(`/transfer-history-detail/${pk}`);
  };

  const [openTransferProductAllocate, setOpenTransferProductAllocate] =
    useState(false);
  const [transferAllocateProductPk, setTransferAllocateProductPk] = useState();

  const handleViewProductAllocateModal = (pk) => {
    // console.log(pk);
    setTransferAllocateProductPk(pk);
    setOpenTransferProductAllocate(true);
  };

  // Export Excel Data
  const today = new Date().toISOString().split("T")[0];
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedExportDate, setSelectedExportDate] = useState(today);

  const handleExportExcelFile = () => {
    setShowDatePicker(true);
  };

  const handleExportDateChange = (event) => {
    setSelectedExportDate(event.target.value);
  };

  const handleExport = () => {
    const sendData = {
      transfer_date: selectedExportDate,
    };

    // console.log(sendData)

    try {
      axios
        .post(export_transfer, sendData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            // console.log("excel : ", response.data.products)
            const allItems = response.data.products;

            allItems.sort((a, b) => (a.sku > b.sku ? 1 : -1));

            // Create a worksheet from the data
            const worksheet = XLSX.utils.json_to_sheet(allItems);

            // Create a workbook and add the worksheet to it
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              workbook,
              worksheet,
              `Transfer-${selectedExportDate}`
            );

            // Generate a binary string representation of the workbook
            const wbout = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "binary",
            });

            // Convert the binary string to a Blob object
            const blob = new Blob([s2ab(wbout)], {
              type: "application/octet-stream",
            });

            // Create a link element and trigger a download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Transfer-${selectedExportDate}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            setShowDatePicker(false);
          } else {
            toast.error(response?.data?.message || "Something went wrong");
            setShowDatePicker(false);
          }
        })
        .catch((error) => {
          setShowDatePicker(false);
          console.log(error);
          toast.error(error?.response?.data?.message || "Something went wrong");
        });
    } catch (error) {
      console.log("error While Allocating Product :", error?.response);
    }
  };
  // Utility function to convert a binary string to an array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const handleCloseExportDateModal = () => {
    setShowDatePicker(false);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <TransferProductAllocate
        open={openTransferProductAllocate}
        setOpenTransferProductAllocate={setOpenTransferProductAllocate}
        transferPk={transferAllocateProductPk}
      />

      <Dialog
        open={showDatePicker}
        onClose={handleCloseExportDateModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Select Date for Export</DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Select Date"
            type="date"
            value={selectedExportDate}
            onChange={handleExportDateChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseExportDateModal}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={handleExport} color="primary" variant="outlined">
            Export
          </Button>
        </DialogActions>
      </Dialog>

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>Transfer</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-12  col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-12 col-lg-8 mt-md-0 mt-2 "
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  onClick={() => handleExportExcelFile()}
                >
                  Export Transfer
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-transfer")}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>
                        <FilterDateRangeModal
                          title="Transfer Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>
                        <FilterSelectionModalByWarehouse
                          title="Target Warehouse"
                          selectedWarehouseId={selectedWarehouseIds}
                          onSelect={setSelectedWarehouseIds}
                        />
                      </th>
                      <th>Products</th>
                      <th>Notes</th>
                      <th>Created By</th>
                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfers.length > 0 ? (
                      <>
                        {transfers &&
                          transfers.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "60px" }}>#{data.id}</td>
                                <td style={{ width: "180px" }}>
                                  {formatDate(data.date) || "-"}
                                </td>

                                <td>
                                  {data.target_warehouse
                                    ? data.target_warehouse.ware_house
                                    : "-"}
                                </td>
                                <td>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}{" "}
                                  ({data.total_quantity})
                                </td>
                                <td>{data.note || "N/A"}</td>
                                <td>{data.user_name || "N/A"}</td>
                                <td
                                  style={{
                                    width: "100px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    className="me-2"
                                    onClick={() =>
                                      handleViewProductAllocateModal(data.id)
                                    } // Pass true for Allocate button
                                  >
                                    Allocate
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => handleEditClick(data.id)}
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="7">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {transfers.length > 0 ? (
                  <>
                    {transfers &&
                      transfers.map((data) => (
                        <>
                          <div className="product-card" id="product_card">
                            <div className="py-1 px-2  w-100">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.target_warehouse
                                  ? data.target_warehouse.ware_house
                                  : "-"}
                              </h5>
                              <h5 className="warehouse-card-address">
                                Created By: {data.user_name || "N/A"}
                              </h5>

                              <div className="mt-2 d-flex align-items-center gap-2 justify-content-between w-100">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  className=" w-50 d-block"
                                  onClick={() =>
                                    handleViewProductAllocateModal(data.id)
                                  }
                                >
                                  Allocate
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  className=" w-50 d-block"
                                  color="success"
                                  onClick={() => handleEditClick(data.id)}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                            <div>
                              <h5 className="my-1 warehouse-card-label">
                                <Label color="green">
                                  {data.total_products} | {data.total_quantity}{" "}
                                  QTY
                                </Label>
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StockTransfer;
