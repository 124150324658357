import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { apiKey, fetch_sale_detail, edit_sale_note } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "@mui/material";
import { useScreenWidth } from "../../useScreenWidth";
import { Label, TextArea } from "semantic-ui-react";
import EditSaleDataModal from "./EditSaleDataModal";
import CustomLoader from "../../Components/Loader";
import EditSaleCustomer from "./EditSaleCustomer";
import EditSaleSummaryPrices from "./EditSaleSummaryPrices.js";
import PrintSlipModal from "./PrintSlipModal.js";

function ViewSaleDetail() {
  const isWideScreen = useScreenWidth();
  const { id } = useParams();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerid, setCustomerID] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");

  const [updatednote, setUpdatedNote] = useState("");
  const [sale_date, setSaleDate] = useState("");
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [othercharge, setOtherCharge] = useState(0);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [p_data, setPData] = useState({});

  const handleEditPrice = (product) => {
    setOpenEditModal(true);
    setPData(product);
  };

  // console.log(p_id, "adfhgsdchujvb")

  useEffect(() => {
    fetchSales();
  }, [id]);

  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        const data = response.data.data;
        // console.log(response)
        // Set customer and other details from the API response
        setCustomer(data.billing_customer.customer_name);
        setCustomerID(data.billing_customer.id);
        setNote(data.note);
        setUpdatedNote(data.note);
        setSaleDate(data.sale_date);
        setDiscountedPrice(data.discount_amount);
        setTotalAmount(data.total_amount);
        setOtherCharge(data.other_charge);
        setShipping(data.courier_charge);
        setAddress(
          data.billing_customer.primary_shipping?.address ||
          "No Address Provided"
        );
        setLoading(false);

        // Set selected products based on the API response
        const items = data.items.map((item) => ({
          id: item.id,
          product_sku: item.product_sku,
          part_number: item.part_number,
          product_name: item.product_name,
          mrp: item.mrp,
          price: item.price,
          quantity: item.quantity,
          discount_amount: item.discount_amount,
        }));

        setSelectedProducts(items);
      })
      .catch((error) => {
        console.log(error);
        // setOpenError(true);
        toast.error("Failed to fetch sale details.");
        setLoading(false);
      });
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });

    // Format the amount correctly
    return formatter.format(amount);
  }

  const handleCancelNote = () => {
    setUpdatedNote(note);
  };

  const handleEditNote = () => {
    if (updatednote == note) {
      toast.error("No Changes Found!");
      return;
    }
    const editedNote = {
      sale_pk: parseInt(id),
      note: updatednote ?? note ?? "",
    };

    axios
      .post(edit_sale_note, editedNote, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          fetchSales();

          toast.success("Sale Note Updated Successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const [editCustomer, setOpenEditCustomer] = useState(false);
  const handleEditCustomer = () => {
    setOpenEditCustomer(true);
  };



  //  Edit Sale Summary Prices
  const [isEditSaleSummaryPrices, setIsEditSaleSummaryPrices] = useState(false);

  const handleEditSaleSummaryPrices = () => {
    setIsEditSaleSummaryPrices(true);
  };

  const handleCloseEditSaleSummaryPrices = () => {
    setIsEditSaleSummaryPrices(false);
  };


  // Print Slip 
  const [openModal, setOpenModal] = useState(false);
  const [tqty, setTqty] = useState(0);
  const [addressdata, setAddressData] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);

  const [data, setData] = useState([]);


  const handlePrintSlip = (pk) => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(pk),
        },
      })
      .then((response) => {
        setData(response.data.data);
        setTqty(
          response.data.data.items.reduce((sum, item) => sum + item.quantity, 0)
        );
        setAddressData(response.data.data.billing_customer);
        setSaleProducts(response.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

    setOpenModal(true);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />


      <PrintSlipModal
        tqty={tqty}
        openModal={openModal}
        setOpenModal={setOpenModal}
        // fetchData={fetchData}
        saleProducts={saleProducts}
        data={data}
        addressdata={addressdata}
      />




      <EditSaleCustomer
        customerid={customerid}
        editCustomer={editCustomer}
        sale_pk={id}
        fetchSales={fetchSales}
        setOpenEditCustomer={setOpenEditCustomer}
      />
      <EditSaleDataModal
        sale_pk={id}
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        p_data={p_data}
        fetchSales={fetchSales}
      />


      {isEditSaleSummaryPrices && (
        <EditSaleSummaryPrices
          sale_pk={id}
          totalamount={totalamount}
          discountedprice={discountedprice}
          shipping={shipping}
          othercharge={othercharge}
          onClose={handleCloseEditSaleSummaryPrices}
          fetchSales={fetchSales}
        />
      )}

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">Sales</Typography>
                <Typography color="inherit">Sale Details</Typography>
                <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
              </Breadcrumbs>
            </div>
            {isWideScreen ? (
              <div>

                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => handlePrintSlip(id)}
                >
                  Print
                </Button>
                <Button
                  style={{ marginLeft: "5px" }}
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => navigate(`/dispatch-details/${id}`)}
                >
                  Dispatch History
                </Button>
              </div>
            ) : null}
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-4">
                    Customer:{" "}
                    <span>
                      <b style={{ color: "black" }}>{customer}</b>
                    </span>{" "}
                    <FaPencilAlt
                      style={{ cursor: "pointer" }}
                      onClick={handleEditCustomer}
                    />
                  </div>
                  <div className="col-lg-3">
                    Date: <b style={{ color: "black" }}>{sale_date}</b>
                  </div>
                  <div className="col-lg-5">Bill to: {address}</div>

                  {isWideScreen ? null : (
                    <>

                      <Button
                        style={{ marginTop: "5px" }}
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => navigate(`/dispatch-details/${id}`)}
                      >
                        Dispatch History
                      </Button>
                    </>
                  )}
                </div>
              </div>

              <div className="my-2">
                {isWideScreen ? (
                  <>
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th>Part Number</th>
                            <th>Product</th>
                            <th>MRP</th>
                            <th>Discount</th>
                            <th>Discount Amt</th>
                            <th>Price</th>
                            <th style={{ textAlign: "center" }}>Quantity</th>
                            <th style={{ textAlign: "right" }}>Total</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts.length > 0 ? (
                            selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td component="th" scope="row">
                                  {product.product_sku}
                                </td>
                                <td align="left">{product.part_number}</td>
                                <td
                                  className="product_name_class"
                                  style={{
                                    maxWidth: "350px",
                                  }}
                                >
                                  {product.product_name}
                                </td>
                                <td align="left">
                                  {formatIndianRupee(product.mrp)}
                                </td>
                                <td align="left">
                                  {product.mrp > 0
                                    ? formatIndianRupee(
                                      (product.discount_amount /
                                        product.mrp) *
                                      100
                                    )
                                    : "0.00"}
                                  %
                                </td>
                                <td align="right">
                                  {formatIndianRupee(product.discount_amount)}
                                </td>
                                <td >
                                  {formatIndianRupee(product.price)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {product.quantity}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <b>
                                    {formatIndianRupee(
                                      product.quantity * product.price
                                    )}
                                  </b>
                                </td>
                                <td
                                  align="center"
                                  style={{ textAlign: "center" }}
                                >
                                  <FaPencilAlt
                                    onClick={() => handleEditPrice(product)}
                                    style={{ cursor: "pointer", color: "grey" }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="9"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                {!loading ? (
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                ) : (
                                  <div className="notfound_img_div">
                                    <div className="loadingfound_img"></div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card p-2 responsive-mobile-table">
                      {selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts &&
                            selectedProducts.map((data) => (
                              <>
                                <div className="product-card">
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      #{data.product_sku}
                                    </h5>
                                    <h5 className="product-card-name">
                                      {data.product_name}
                                    </h5>
                                    <small>
                                      ₹{data.quantity * data.price}.00
                                    </small>

                                    <Label
                                      color={"green"}
                                      style={{
                                        position: "absolute",
                                        marginTop: "-17px",
                                        right: "9px",
                                        fontSize: "11px",
                                      }}
                                      className="product-card-mrp"
                                    >
                                      {data.quantity} QTY
                                    </Label>
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          {
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          }
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3" style={{ position: "relative" }}>
                <TextArea
                  className="custom_textareafield"
                  placeholder="Type your note here (if any)"
                  value={updatednote ?? note}
                  rows={5}
                  onChange={(e) => setUpdatedNote(e.target.value)}
                />
                {note == updatednote ? null : (
                  <>
                    <div className="d-flex gap-2 mt-2 justify-content-end">
                      <Button
                        onClick={handleCancelNote}
                        variant="outlined"
                        color="error"
                        size="small"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleEditNote}
                        variant="outlined"
                        color="info"
                        size="small"
                      >
                        Update
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="card p-3 mt-2">
                <div className="d-flex justify-content-between">
                  <h4 className="mb-0">Sale Summary</h4>
                  <FaPencilAlt
                    onClick={handleEditSaleSummaryPrices}
                    style={{ cursor: "pointer", color: "grey" }}
                  />

                </div>
                <hr className="mb-0" />
                <div className="row mt-2">
                  <div className="col-6 my-2">Sub-Total</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <h5 style={{ color: "black" }}>
                      {formatIndianRupee(totalamount)}
                    </h5>
                  </div>
                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <h5
                      style={{ color: discountedprice > 0 ? "black" : "grey" }}
                    >
                      {formatIndianRupee(discountedprice)}
                    </h5>
                  </div>
                  <div className="col-6 my-2">Shipping Charge</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <h5 style={{ color: shipping > 0 ? "black" : "grey" }}>
                      {formatIndianRupee(shipping)}
                    </h5>
                  </div>
                  <div className="col-6 my-2">Other Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <h5 style={{ color: othercharge > 0 ? "black" : "grey" }}>
                      {formatIndianRupee(othercharge)}
                    </h5>
                  </div>

                </div>
                <hr />
                <div className="row">
                  <div className="col-6 my-2">
                    <strong>Total Amount</strong>
                  </div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <strong style={{ color: "red", fontSize: "1.3rem" }}>
                      {formatIndianRupee(
                        totalamount - discountedprice + shipping + othercharge
                      )}
                    </strong>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSaleDetail;
