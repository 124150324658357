import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Button, DialogTitle, DialogContent, Paper, Box } from "@mui/material";
import Draggable from "react-draggable";
import axios from "axios";
import { apiKey, get_product_series_by_warehouse } from "../Api";
import toast from "react-hot-toast";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function ProductSeriesByWarehouseModalSelection({ open, handleClose, productSku, warehouse_pk, maxSelection, updateSelectedSeries, alreadySelectedSeries }) {
    const [tooltip, setTooltip] = useState(null);
    const [allSeries, setAllSeries] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState(alreadySelectedSeries || []);
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            const fetchData =  () => {
                try {
                    const data = { product_sku: productSku, warehouse_pk: warehouse_pk };
                    const headers = { "Content-Type": "application/json", "API-Key": apiKey };
                    axios
                    .post(get_product_series_by_warehouse, data, { headers })
                    .then((response) => {
                    
                      console.log(response.data.serieses)
                      if (response.data.serieses) {
                          setAllSeries(response.data.serieses);
                      }
              
                      setLoading(false);
                    })
                    .catch((error) => {
                      toast.error(error.response?.data?.message);
                      console.log(error.response);
                      setLoading(false);
                    });
                } catch (error) {
                    console.error("Error fetching series data:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [open, productSku, warehouse_pk]);

    useEffect(() => {
        setSelectedSeries(alreadySelectedSeries || []);
    }, [alreadySelectedSeries, open]);

    useEffect(() => {
        const handleKeyDown = (event) => { if (event.key === "Shift") setIsShiftPressed(true); };
        const handleKeyUp = (event) => { if (event.key === "Shift") setIsShiftPressed(false); };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const handleSelectSeries = (series, index) => {
        let newSelected = [...selectedSeries];
    
        if (isShiftPressed && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
            const rangeSeries = allSeries.slice(start, end + 1).map((s) => s.series);
    
            // Check if maxSelection is provided and adding the range of series exceeds the max selection
            if (maxSelection && newSelected.length + rangeSeries.length > maxSelection) {
                toast.error(`You can only select up to ${maxSelection} series.`);
                return;
            }
    
            newSelected = Array.from(new Set([...newSelected, ...rangeSeries]));
        } else {
            const seriesName = series.series;
            if (newSelected.includes(seriesName)) {
                newSelected = newSelected.filter((name) => name !== seriesName);
            } else {
                // Check if maxSelection is provided and adding this series exceeds the max selection
                if (maxSelection && newSelected.length >= maxSelection) {
                    toast.error(`You can only select up to ${maxSelection} series.`);
                    return;
                }
                newSelected.push(seriesName);
            }
        }
    
        setSelectedSeries(newSelected);
        setLastSelectedIndex(index);
    };
    

    const handleSaveAndClose = () => {
        updateSelectedSeries(productSku, selectedSeries);
        handleClose();
    };

    const style = {
        boxShadow: 24,
        borderRadius: "8px",
        bgcolor: "background.paper",
        overflow: "auto",
        width: { xs: "100%", sm: "80%", md: "550px" },
    };

    if(!open){
        return
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                <Box sx={style}>
                    <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                        Product Series Selection / {productSku}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="text-center row">
                            <div className="col-md-6 col-6">
                                <strong>Selected: </strong> {selectedSeries.length}
                            </div>
                            <div className="col-md-6 col-6">
                                <strong>Remaining: </strong> {allSeries.length - selectedSeries.length}
                            </div>
                        </div>
                        <div className="row my-3" style={{ maxHeight: "200px", overflow: "auto" }}>
                            {allSeries.map((series, index) => (
                                <div key={series.series} className="col-md-1 col-3 p-1 position-relative">
                                    <div className="d-flex align-items-center justify-content-center border p-2 position-relative"
                                        onClick={() => handleSelectSeries(series, index)}
                                        style={{ cursor: "pointer", backgroundColor: selectedSeries.includes(series.series) ? "#4c8cc2" : "#ededed", color: selectedSeries.includes(series.series) ? "white" : "black" }}>
                                        <span>{series.series}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <Button className="float-end d-block ms-2" onClick={handleSaveAndClose} variant="contained" color="primary">Save</Button>
                            <Button className="d-block float-end" onClick={handleClose} variant="outlined" color="error">Close</Button>
                        </div>
                        {tooltip && (
                                <div
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#ffffff",
                                        border: "1px solid #ccc",
                                        padding: "5px",
                                        zIndex: "1000",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <div>
                                        {tooltip.series}, 
                                        {tooltip?.warehouse_name},{" "}
                                        {tooltip.rack_number || "N/A"},{" "}
                                        {tooltip.row_name || "N/A"}
                                    </div>
                                </div>
                            )}

                    </DialogContent>
                </Box>
            </Dialog>
        </>
    );
}

export default ProductSeriesByWarehouseModalSelection;
