import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FaHandPointRight } from "react-icons/fa";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function NewCRMUpdateAlert({ openCRMAlert, setOpenAlert, updatemsg }) {
  const handleClose = () => {
    setOpenAlert(false);

    // Create an object with both values
    const data = {
      cancelledstatus: 1,
      updatemsg: updatemsg
    };

    // Store the object in localStorage as a JSON string
    localStorage.setItem("CancelledUpdate", JSON.stringify(data));
  };
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
   

      <Dialog
        open={openCRMAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#ed4928", fontSize: "1.5rem", fontWeight: "bold" }}
          >
            New Update Available
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>Please refresh the CRM to enjoy the new updates!</b>
                  <br />
              {updatemsg ? (
                <>
                 <p style={{marginTop:'13px', fontSize:'1rem'}}> 
                  <i>
                   
                   <FaHandPointRight style={{marginTop:'-2px'}}/>{" "}
                   {updatemsg}
                 </i></p>
                </>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button variant="outlined" size="small" onClick={handleClose} autoFocus>
           Cancel
            </Button>
            <Button variant="outlined" color="error" size="small" onClick={handleRefresh} autoFocus>
             Refresh
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default NewCRMUpdateAlert;
