import React, { useEffect, useState } from "react";
import { Dialog, Button, DialogTitle, DialogContent, Paper, Box } from "@mui/material";
import Draggable from "react-draggable";
import toast from "react-hot-toast";

// Paper Component for dragging functionality
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

// SelectPackRequirementSeries Modal
function SelectPackRequirementSeries({ open, handleClose, product, updateSelectedSeries }) {
    const [tooltip, setTooltip] = useState(null);
    const [allSeries, setAllSeries] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState(product.selectedSeries || []);
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            
            setAllSeries(product.available_series);
            setLoading(false);
        }
    }, [open]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Shift") setIsShiftPressed(true);
        };
        const handleKeyUp = (event) => {
            if (event.key === "Shift") setIsShiftPressed(false);
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    // const handleSelectSeries = (series, index) => {
    //     let newSelected = [...selectedSeries];
    //     const { picked_quantity, packed_quantity } = product;
    //     const remainingQty = picked_quantity - packed_quantity; // Calculate remaining quantity

    //     if (isShiftPressed && lastSelectedIndex !== null) {
    //         const start = Math.min(lastSelectedIndex, index);
    //         const end = Math.max(lastSelectedIndex, index);
    //         const rangeSeries = allSeries.slice(start, end + 1).map(s => s.series);

    //         if (newSelected.length + rangeSeries.length > remainingQty) {
    //             toast.error(`You can only select up to ${remainingQty} series.`);
    //             return;
    //         }
    //         newSelected = Array.from(new Set([...newSelected, ...rangeSeries]));
    //     } else {
    //         const seriesName = series;
    //         if (newSelected.includes(seriesName)) {
    //             newSelected = newSelected.filter((name) => name !== seriesName);
    //         } else {
    //             if (newSelected.length >= remainingQty) {
    //                 toast.error(`You can't select more than the remaining quantity (${remainingQty}).`);
    //                 return;
    //             }
    //             newSelected.push(seriesName);
    //         }
    //     }

    //     setSelectedSeries(newSelected);
    //     setLastSelectedIndex(index);
    // };


    const handleSelectSeries = (series, index) => {
        let newSelected = [...selectedSeries];
        const { picked_quantity, packed_quantity } = product;
        const remainingQty = picked_quantity - packed_quantity;
    
        if (isShiftPressed && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
    
            // Adjusted: Directly slice without mapping for series property
            const rangeSeries = allSeries.slice(start, end + 1);
    
            // Filter out already selected series to avoid double counting
            const filteredRange = rangeSeries.filter(s => !newSelected.includes(s));
    
            if (newSelected.length + filteredRange.length > remainingQty) {
                toast.error(`You can only select up to ${remainingQty} series.`);
                return;
            }
            newSelected = Array.from(new Set([...newSelected, ...filteredRange]));
        } else {
            // Single selection logic
            if (newSelected.includes(series)) {
                newSelected = newSelected.filter((name) => name !== series);
            } else {
                if (newSelected.length >= remainingQty) {
                    toast.error(`You can't select more than the remaining quantity (${remainingQty}).`);
                    return;
                }
                newSelected.push(series);
            }
        }
    
        setSelectedSeries(newSelected);
        setLastSelectedIndex(index);
    };
    

    
    const handleSaveAndClose = () => {
        updateSelectedSeries(product.sku, selectedSeries);
        handleClose();
    };

    const style = {
        boxShadow: 24,
        borderRadius: "8px",
        bgcolor: "background.paper",
        overflow: "auto",
        width: { xs: "100%", sm: "80%", md: "550px" },
    };

    return (
        <>
            {/* {console.log("selectedSeries : ",selectedSeries)} */}
        
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                <Box sx={style}>
                    <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                        Product Series Selection / {product.product_name}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className="text-center row">
                            <div className="col-md-6 col-6">
                                <strong>Selected: </strong> {selectedSeries.length}
                            </div>
                            <div className="col-md-6 col-6">
                                <strong>Remaining: </strong> {product.picked_quantity - product.packed_quantity - selectedSeries.length}
                            </div>
                        </div>
                        <div className="row my-3" style={{ maxHeight: "200px", overflow: "auto" }}>
                            {allSeries.map((series, index) => (
                                <div key={series} className="col-md-1 col-3 p-1 position-relative">
                                    <div
                                        className="d-flex align-items-center justify-content-center border p-2 position-relative"
                                        onClick={() => handleSelectSeries(series, index)}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor: selectedSeries.includes(series) ? "#4c8cc2" : "#ededed",
                                            color: selectedSeries.includes(series) ? "white" : "black",
                                        }}
                                    >
                                        <span>{series}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <Button className="float-end d-block ms-2" onClick={handleSaveAndClose} variant="contained" color="primary">
                                Save
                            </Button>
                            <Button className="d-block float-end" onClick={handleClose} variant="outlined" color="error">
                                Close
                            </Button>
                        </div>
                    </DialogContent>
                </Box>
            </Dialog>
        </>
    );
}

export default SelectPackRequirementSeries;
