import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { apiKey, fetch_customer,edit_sale_customer } from "../../Api";

import {
  Autocomplete,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "500px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditSaleCustomer({ editCustomer, setOpenEditCustomer, customerid,sale_pk,fetchSales }) {
  const handleClose = () => {
    setOpenEditCustomer(false);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(customerid);

  const fetchCustomers = () => {
        const headers = {
          "Content-Type": "application/json",
          "API-Key": apiKey,
          // selectedCategory : selectedCategory || null
        };
        
    axios
      .get(fetch_customer, {
              params: {
            
                per_page: 1000,
             
              
              },
              headers,
            })
      .then((response) => {
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (customerid) {
      const customer = customers.find((cust) => cust.id == customerid);
      setSelectedCustomer(customer ? { label: customer.customer_name, value: customer.id } : null);
    }
  }, [customerid, customers]);

  // if (selectedCustomer) {
  //   handleRowChange(selectedCustomer.value);
  // }



  const handleSubmit = (e) => {
    e.preventDefault();


    const saleData = {
      sale_pk: parseInt(sale_pk),
      customer_pk: parseInt(selectedCustomer.value)
  }


    axios
    .post(edit_sale_customer, saleData, {
      headers: {
        "Content-Type": "application/json",
        "API-Key": apiKey,
      },
    })
    .then((response) => {
      if (response.data.status === 1) {
        // navigate("/all_sales");
        fetchSales()
        setOpenEditCustomer(false)
        toast.success("Customer Updated successfully!");
      } else {
        toast.error(response?.data?.message || "Something Went Wrong");
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Something Went Wrong !!");
    });
  };

  return (
    <Modal open={editCustomer} onClose={handleClose}>
      <div style={modalStyle} className="bg-white py-3 px-3">
        <form onSubmit={handleSubmit}>
          <Typography>Update Customer</Typography>

          <Autocomplete
            className="mt-3"
            disablePortal
            size="small"
            options={customers.map((row) => ({
              label: row.customer_name,
              value: row.id,
            }))}
            value={selectedCustomer}
            onChange={(e, newValue) => setSelectedCustomer(newValue)}
            isOptionEqualToValue={(option, value) => option.value == value.value}
            renderInput={(params) => (
              <TextField
                size="small"
                style={{ width: "100%" }}
                {...params}
                label="Customer"
              />
            )}
          />

          <Button
            type="submit"
            variant="outlined"
            color="primary"
            style={{ marginTop: "20px", float: "right", marginLeft: "5px" }}
          >
            Update
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="error"
            style={{ marginTop: "20px", float: "right" }}
          >
            Close
          </Button>
        </form>
      </div>
    </Modal>
  );
}

export default EditSaleCustomer;
