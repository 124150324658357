import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_all_product,
  fetch_vendor,
  fetch_purchase_detail,
  edit_purchase,
} from "../../Api";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";

import { useParams, useNavigate } from "react-router-dom";
import AddProductModal from "../Products/AddProductModal";
import toast, { Toaster } from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Breadcrumbs, Typography } from "@mui/material";
import CreateVendor from "../OurClients/Vendor/CreateVendor";

function EditPurchase() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState("");
  const [purchase_date, setP_date] = useState("");
  const [reference_no, setRef_no] = useState("");
  const [rcvstatus, setRcvStatus] = useState("");
  const navigate = useNavigate();
  // const [openDelete, setOpenDelete] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const { id } = useParams();

  useEffect(() => {
    fetchVendors();
    fetchPurchases();
  }, []);

  // Add Product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  // Function to open the modal
  const handleAddProduct = () => {
    setAddProductModalOpen(true);
  };

  // Function to close the modal
  const handleCloseProductAddModal = () => {
    setAddProductModalOpen(false);
  };

  const fetchVendors = () => {
    axios
      .get(fetch_vendor, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        //console.log(response.data.vendors);
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPurchases = () => {
    axios
      .post(
        fetch_purchase_detail,
        {
          purchase_pk: parseInt(id),
        },
        {
          headers: {
            "API-Key": apiKey,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response.data.purchase_detail.receive_status);
        setRcvStatus(response.data.purchase_detail.receive_status);
        setP_date(response.data.purchase_detail.purchase_date);
        setRef_no(response.data.purchase_detail.reference_number);
        // console.log(response.data.purchase_detail.reference_number, "dhjhsd");
        setVendor(response.data.purchase_detail.vendor_ref.id);
        setSelectedProducts(response.data.purchase_detail.items_detail);
        const initialQuantities = {};
        response.data.purchase_detail.items_detail.forEach((item) => {
          initialQuantities[item.product_sku] = item.quantity;
        });
        setQuantities(initialQuantities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = async (event) => {
    console.log("handleInputChange Chala");
    const value = event?.target?.value || "";
    setSearchInput(value);

    if (value.trim().length >= 0) {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        // console.log(response.data.data.products);
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else {
      setProducts([]);
      setDisplay(false);
    }
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 0,
      }));
    }
  };

  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[product_sku];
      return updatedQuantities;
    });
  };

  const handleSubmit = async () => {
    let isValid = true;
    const invalidProducts = [];

    selectedProducts.forEach((product) => {
      if (
        !quantities[product.product_sku] ||
        quantities[product.product_sku] <= 0
      ) {
        isValid = false;
        invalidProducts.push(product.product_name);
      }
    });

    if (!isValid) {
      toast.error(
        `Please enter a valid quantity for the following products: ${invalidProducts.join(
          ", "
        )}`
      );

      return;
    }

    const purchaseData = {
      purchase_pk: parseInt(id),
      vendor_pk: parseInt(vendor),
      purchase_date: purchase_date,
      reference_number: reference_no || "",
      user_name: userDetails.name || "",
      products_detail: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]),
      })),
    };

    axios
      .post(edit_purchase, purchaseData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          navigate("/all_purchases");
          toast.success("Purchase updated successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleFocus = () => {
    console.log("handleFocus called");
    handleInputChange();
    setDisplay(true);
  };

  return (
    <>
      <Toaster position="top-right" />
      <CreateVendor
        openvendor={openvendor}
        handleOpenVendor={handleOpenVendor}
        handleCloseVendor={handleCloseVendor}
        setVendor={setVendor}
      />

      <AddProductModal
        open={addProductModalOpen}
        handleClose={handleCloseProductAddModal}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_purchases")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>

              <Typography sx={{ color: "text.primary" }}>
                Update Purchase
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-4 my-2" style={{ display: "flex" }}>
                    <FormControl
                      fullWidth
                      size="small"
                      required
                      disabled={rcvstatus && rcvstatus === 1}
                    >
                      <InputLabel id="vendor_name">Select Vendor</InputLabel>
                      <Select
                        labelId="vendor_name"
                        label="Select Vendor"
                        value={vendor}
                        onChange={(e) => setVendor(e.target.value)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {vendors.map((vendor) => (
                          <MenuItem key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {rcvstatus && rcvstatus === 1 ? null : (
                      <Button
                        variant="outlined"
                        style={{ marginLeft: "2px" }}
                        onClick={handleOpenVendor}
                        size="small"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div className="col-lg-2 my-2">
                    <TextField
                      id="outlined-date"
                      type="date"
                      disabled={rcvstatus && rcvstatus === 1}
                      value={purchase_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setP_date(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-3 my-2">
                    <TextField
                      label="Reference No."
                      id="outlined-ref"
                      value={reference_no}
                      size="small"
                      disabled={rcvstatus && rcvstatus === 1}
                      fullWidth
                      onChange={(e) => setRef_no(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-3 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    <div>Bill to:</div>
                    <div style={{ marginLeft: "5px" }}>
                      <b>Kuber Turbo Tech</b>
                      <br></br>
                      Delhi, IN
                    </div>
                  </div>
                </div>
              </div>

              {rcvstatus && rcvstatus === 1 ? null : (
                <>
                  <div
                    className="card my-2"
                    style={{ padding: "20px 15px 2px 15px" }}
                  >
                    <div className="row">
                      <div className="col-10">
                        <TextField
                          onChange={handleInputChange}
                          onFocus={handleFocus}
                          value={searchInput}
                          fullWidth
                          size="small"
                          id="search-products"
                          label="Search Products"
                          variant="outlined"
                        />
                        {displaySearch ? (
                          <>
                            <div
                              id="searchList"
                              ref={searchListRef}
                              style={{
                                border: "1px solid #dedede",
                                position: "absolute",
                                zIndex: "2",
                                marginTop: "3px",
                                maxHeight: "50vh",
                                overflowY: "scroll",

                                backgroundColor: "white",
                                width: "92.4%",
                              }}
                            >
                              {products.map((product, index) => (
                                <>
                                  <div
                                    className="card p-3"
                                    style={{ display: "flex" }}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isProductSelected(product)}
                                          onChange={() =>
                                            handleCheckboxChange(product)
                                          }
                                          name="check"
                                        />
                                      }
                                      label={
                                        product.product_sku +
                                        " : " +
                                        product.product_name
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="col-2 ">
                        <Button
                          variant="outlined"
                          style={{
                            marginLeft: "2px",
                            width: "100%",
                            height: "40px",
                          }}
                          size="small"
                          onClick={handleAddProduct}
                        >
                          Add Product
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                <h5>Purchase Summary</h5>
                <div className="row" style={{ marginTop: "13px" }}>
                  <div className="col-6 my-2">Total Products</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    {selectedProducts.length}
                  </div>
                  {rcvstatus && rcvstatus === 1 ? null : (
                    <>
                      <div className="container my-3">
                        <Button
                          variant="outlined"
                          style={{ width: "100%" }}
                          onClick={handleSubmit}
                        >
                          Update Purchase
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <div className="products_table_class">
                  <div className="responsive-table1">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th>Product</th>
                          <th>Part No.</th>
                          <th>OEM No.</th>
                          <th>Quantity</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td style={{ width: "6.5vw" }}>
                                  {product.product_sku}
                                </td>
                                <td>{product.product_name}</td>
                                <td>{product.part_number}</td>
                                <td>{product.oem_number}</td>

                                <td style={{ maxWidth: "4vw" }}>
                                  {rcvstatus && rcvstatus === 1 ? (
                                    <>
                                      <h5>
                                        {quantities[product.product_sku] || ""}
                                      </h5>
                                    </>
                                  ) : (
                                    <>
                                      <TextField
                                        label="Qty"
                                        size="small"
                                        type="number"
                                        value={
                                          quantities[product.product_sku] || ""
                                        }
                                        onChange={(e) =>
                                          handleQuantityChange(e, product)
                                        }
                                        variant="outlined"
                                      />
                                    </>
                                  )}
                                </td>

                                <td align="right">
                                  {rcvstatus && rcvstatus === 1 ? null : (
                                    <>
                                      <FaTrashAlt
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteProduct(
                                            product.product_sku
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPurchase;
