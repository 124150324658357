import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import { apiKey, deleteType } from '../../Api';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const DeleteTypeModal = ({ open, handleClose, type, onDelete, fetchBrandType }) => {
    console.log(type)
    const handleDelete = () => {
        onDelete(type.id);
        // handleClose();
        axios
            .delete(deleteType, {
                headers: {
                    "API-Key": apiKey,
                },
                data: {
                    type_pk: type.id,
                },
            }).then((response) => {
                console.log(response)
                if (response.data.status === 1) {
                    toast.success(`Product Type Deleted successfully`);
                    handleClose();
                    fetchBrandType()
                }
                else {
                    toast.error(response.data?.message)
                }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message)
                console.error("Failed to delete Type ", error.response);
                console.error("Type Error: ", error.response?.data?.message);
            });
    };

    return (
        <>

            <Toaster position="top-right" />
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                    <h2 className='h3'>Confirm Deletion</h2>
                    <p>Are you sure you want to delete the product type "{type.product_type}"?</p>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default DeleteTypeModal;
