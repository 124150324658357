import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { apiKey, fetch_warehouse } from "../../Api";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SelectWarehouseModal({
  setOpenModalWarehouse,
  openModalWarehouse,
  setWarehouse,
}) {
  // Destructure props correctly

  const navigate = useNavigate();
  const [ware, setWare] = useState("");
  const [warehouses, setWarehouses] = useState([]);

  const handleClose = () => {
    setOpenModalWarehouse(false);
    navigate("/conversions");
    // toast.error("Sorry You can't make coversion without warehouse");
  };

  const fetchWarehouses = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const handleSubmit = () => {
    setWarehouse(ware);
    setOpenModalWarehouse(false);
  };

  return (
    <>
      <Toaster position="top-right" />

      <Modal
        open={openModalWarehouse}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            style={{ marginBottom: "15px" }}
          >
            Select Warehouse
          </Typography>

          <div style={{ maxHeight: "30vh" }}>
            <FormControl size="small" fullWidth>
              <InputLabel>Warehouse</InputLabel>
              <Select
                value={ware}
                label="Warehouse"
                onChange={(e) => setWare(e.target.value)}
              >
                {warehouses &&
                  warehouses.map((ware) => (
                    <MenuItem key={ware.id} value={ware.id}>
                      {ware.ware_house}
                    </MenuItem> // Add key prop
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              variant="outlined"
              color="primary"
              disabled={!ware}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SelectWarehouseModal;
