import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { apiKey, fetch_draft_purchase } from "../../Api";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteDraftPurchase from "../../Messages/DeleteDraftPurchase";
import toast, { Toaster } from "react-hot-toast";
import { Pagination, Icon, Label } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import CustomLoader from "../../Components/Loader";
import FilterDateRangeModal from "../../Components/FilterModal/FilterDateRangeModal";

function AllDraftPurchase() {
  const isWideScreen = useScreenWidth();
  const [draftPurchaseData, setDraftPurchaseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchquery, setSearchQuery] = useState("");

  // date Filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, startDate, endDate]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      starting_date: startDate || "",
      ending_date: endDate || "",
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_draft_purchase, data, { headers })
      .then((response) => {
        setDraftPurchaseData(response.data.purchases);

        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleEdit = (draft) => {
    navigate("/add-purchase", { state: { draft: draft } }); // Pass the draft data
  };

  // Selection

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedRows(draftPurchaseData);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (purchase) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.find((item) => item.id === purchase.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== purchase.id);
      } else {
        // Otherwise, add the full purchase object
        return [...prevSelected, purchase];
      }
    });
  };

  // delete

  const [pks, setPks] = useState([]);
  const handleDeleteDraftPurchase = () => {
    const selectedPks = selectedRows.map((purchase) => purchase.id);

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No draft purchase selected!");
      // setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <DeleteDraftPurchase
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit"> Purchases</Typography>

            <Typography sx={{ color: "text.primary" }}>
              Draft Purchases
            </Typography>
          </Breadcrumbs>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-purchase")}
                >
                  Add New
                </Button>
                <div className="mb_btn_hide">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleDeleteDraftPurchase}
                  >
                    Delete Draft Purchase
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length === draftPurchaseData.length &&
                            draftPurchaseData.length > 0
                          }
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>ID</th>
                      <th>
                        <FilterDateRangeModal
                          title="Purchase Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>Vendor</th>
                      <th>Total Products</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {draftPurchaseData.length > 0 ? (
                      <>
                        {draftPurchaseData &&
                          draftPurchaseData.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some(
                                    (item) => item.id === data.id
                                  )}
                                  onChange={() => handleRowSelect(data)}
                                />
                              </td>
                              <td>#{data.id}</td>
                              <td>{data.purchase_date}</td>
                              <td>{data.vendor_ref.name}</td>
                              <td>{data.total_products}</td>
                              <td>{data.created_by}</td>
                              <td>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => handleEdit(data.id)}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="6">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {draftPurchaseData.length > 0 ? (
                  <>
                    {draftPurchaseData &&
                      draftPurchaseData.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleEdit(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                #{data.id} | {data.reference_number || "-"}
                              </h5>
                              <h5 className="product-card-name">
                                {data.vendor_ref?.name || "N/A"}
                              </h5>
                              <h5 className="product-card-mrp">
                                <span style={{ color: "grey" }}>QTY:</span>{" "}
                                {data.total_products} | {data.purchase_date}
                              </h5>

                              <Label
                                style={{
                                  position: "absolute",
                                  marginTop: "-20px",
                                  right: "15px",
                                  fontSize: "11px",
                                }}
                                size="small"
                                tag
                              >
                                Draft
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDraftPurchase;
