import React from 'react';
import { Typography, Button, Modal, Box } from "@mui/material";
import { Label } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { BiLinkExternal } from 'react-icons/bi';
import { useScreenWidth } from '../../../useScreenWidth';
import { TbExternalLink } from 'react-icons/tb';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "80%", md: "850px" },
    maxWidth: "950px",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

function PurchasesProductDashboard({ data, onClose }) {

    const navigate = useNavigate();
    const isWideScreen = useScreenWidth();

    return (
        <Modal open onClose={onClose}>
            <Box sx={modalStyle} className="bg-white py-3 px-3">
                <div className="d-flex justify-content-between align-items-center mb-2" >
                    <Typography variant="h5" component="h2">
                        Purchases Details
                    </Typography>
                    <div className="mb_btn_hide">
                        Total : <Label>{data.length}</Label>
                    </div>
                </div>
                {isWideScreen ? (
                    <>
                        <div
                            className="responsive-table1"
                            style={{
                                maxHeight: "300px",
                                height: "max-content",
                                width: "100%",
                            }}
                        >
                            <table className="ui table celled" id="c_table">
                                <thead className="table-head">
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th>Supplier</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ cursor: "pointer" }} onClick={() => navigate(`/view-purchase/${item.id}`)} > <BiLinkExternal style={{ color: "#4183c4" }} />{" "} {item.id}</td>
                                                <td>{item.date}</td>
                                                <td>{item.vendor_name}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card p-2 responsive-mobile-table">

                            {data && data.map((item, idx) => (
                                <div className="product-card " key={idx} >
                                    <div className="py-1 px-2 w-100"  >
                                        <h5>{item.vendor_name}</h5>
                                        <h5 className="sale-card-date">{item.date} |  {item.quantity} QTY </h5>

                                        <div
                                            onClick={() => navigate(`/view-purchase/${item.id}`)}
                                            className="navigate-other-mobile"
                                        >
                                            <TbExternalLink />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "20px", float: "right" }}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default PurchasesProductDashboard;
