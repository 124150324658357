import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useScreenWidth } from "../../../useScreenWidth";
import { FaCheck } from "react-icons/fa";
import { MdOutlineDoubleArrow } from "react-icons/md";

function PackedDetailsProducts() {
  const location = useLocation();
  const { productData } = location.state || {};

  const navigate = useNavigate();
  const { id } = useParams();
  const isWideScreen = useScreenWidth();

  const [shippedStatus, setShippedStatus] = useState(0);
  const [loading, setLoading] = useState(true);

  let dataProducts = [];

  if (!productData) {
    toast.error("Data Not Found!");
  } else {
    dataProducts = productData;
  }

  const groupPackingDetails = (packingDetails) => {
    const grouped = packingDetails.reduce((acc, detail) => {
      const key = `${detail.row}-${detail.rack}`;
      if (!acc[key]) {
        acc[key] = {
          ...detail,
          quantity: 0,
        };
      }
      acc[key].quantity += detail.quantity;
      return acc;
    }, {});
    return Object.values(grouped);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">Requirement</Typography>
                <Typography sx={{ color: "text.primary" }}>
                  Packed Details
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1 mt-1">
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th style={{ width: "110px" }}>Product SKU</th>
                      <th>Product Name</th>
                      <th>Part Number</th>
                      <th className="text-center">Fulfilled Qty</th>
                      <th>Picked From</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataProducts &&
                      dataProducts.map((data, index) => (
                        <tr
                          key={data.id}
                          style={{
                            backgroundColor:
                              data.quantity < data.picked_quantity
                                ? "rgb(253 199 199)"
                                : data.quantity == data.picked_quantity
                                ? "#d1e7dd"
                                : data.quantity > data.picked_quantity &&
                                  data.picked_quantity != 0
                                ? "#fff3cd"
                                : data.picked_quantity == 0
                                ? "transparent"
                                : "transparent",
                          }}
                        >
                          <td style={{ width: "50px" }}>#{data.id}</td>
                          <td>{data.product_sku}</td>
                          <td
                            className="product_name_class"
                            style={{ maxWidth: "100px" }}
                          >
                            {data.product_name}
                          </td>
                          <td
                            style={{
                              maxWidth: "80px",
                              textWrap: "wrap",
                            }}
                          >
                            {data.part_number}
                          </td>
                          <td
                            style={{
                              width: "100px",
                              textAlign: "center",
                            }}
                          >
                            {data.quantity}{" "}
                            <span
                              style={{
                                color: "grey",
                                fontSize: "10px",
                              }}
                            >
                              QTY
                            </span>
                          </td>

                          <td
                            className="text-left"
                            style={{
                              width: "150px",
                              listStyle: "none",
                            }}
                          >
                            <ul>
                              {data.packing_detail &&
                                groupPackingDetails(data.packing_detail).map(
                                  (detail, index) => (
                                    <li key={index}>
                                      {detail.warehouse.length > 20
                                        ? `${detail.warehouse.substring(
                                            0,
                                            20
                                          )}...`
                                        : detail.warehouse}{" "}
                                      <MdOutlineDoubleArrow
                                        style={{ marginTop: "-1px" }}
                                      />{" "}
                                      {detail.row || "-"}
                                      {detail.rack || "-"}{" "}
                                      <MdOutlineDoubleArrow
                                        style={{ marginTop: "-1px" }}
                                      />{" "}
                                      {detail.quantity} QTY
                                    </li>
                                  )
                                )}
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table mt-1">
                {dataProducts.length > 0 ? (
                  <>
                    {dataProducts &&
                      dataProducts.map((data, index) => (
                        <>
                          <div className="product-card position-relative ">
                            <div className="py-1 px-2 w-100">
                              <h5 className="product-card-sku">
                                {data.product_sku}
                              </h5>
                              <h5 className="product-card-name">
                                {data.product_name}
                              </h5>

                              <h5 className="product-card-partNo">
                                PN : {data.part_number}
                              </h5>

                              <h5 className="product-card-mrp">
                                <span style={{ fontWeight: "normal" }}>
                                  Fullfilled Quantity:{" "}
                                </span>
                                <b>{data.quantity} QTY</b>
                              </h5>
                              <hr className="mb-0 mt-2"/>
                              <span style={{ fontWeight: "normal", fontSize:'0.8rem' }}>
                                  Picked From:{" "}
                                </span>
                              <ul className="mb-0">
                                {data.packing_detail &&
                                  groupPackingDetails(data.packing_detail).map(
                                    (detail, index) => (
                                      <li key={index}>
                                        {detail.warehouse.length > 20
                                          ? `${detail.warehouse.substring(
                                              0,
                                              20
                                            )}...`
                                          : detail.warehouse}{" "}
                                        <MdOutlineDoubleArrow
                                          style={{ marginTop: "-1px" }}
                                        />{" "}
                                        {detail.row || "-"}
                                        {detail.rack || "-"}{" "}
                                        <MdOutlineDoubleArrow
                                          style={{ marginTop: "-1px" }}
                                        />{" "}
                                        {detail.quantity} QTY
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PackedDetailsProducts;
