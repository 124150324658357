import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import {
  fetch_all_product,
  apiKey,
  fetchProductInven,
  getTypeBrand,
  fetch_unique_stocks,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ImportProducts from "./ImportProductsModal";
import Delete from "../../Messages/Delete";
import AssignWare from "./ProductAllocations/Inventory_ProductsModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeriesModal from "./SeriesModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import { Pagination, Icon, Label, Input } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import { FaImage } from "react-icons/fa6";
import CustomLoader from "../../Components/Loader";
import FilterSelectionModal from "../../Components/FilterModal/FilterSelectionModal";
import FilterTableColumns from "../../Components/FilterModal/FilterTableColumns";
import { CiMenuKebab } from "react-icons/ci";
import FilterSelectionModalByWarehouse from "../../Components/FilterModal/FilterSelectionModalByWarehouse";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { ContentCopy, ContentCut } from "@mui/icons-material";
import AddProductModal from "./AddProductModal";
import { SiEbox } from "react-icons/si";

function MasterProducts() {
  const isWideScreen = useScreenWidth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [openImport, setOpenImport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    // debouncedFetchData(1, query);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleEditClick = (sku) => {
    navigate(`/update-product/${sku}`);
  };

  const handleViewProduct = (sku) => {
    navigate(`/product-details/${sku}`);
  };

  // Selection

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Only include products that are deletable
      const deletableProducts = products.filter(
        (product) => product.deletable !== 0
      );
      setSelectedRows(deletableProducts);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (product) => {
    setSelectedRows((prevSelected) => {
      if (
        prevSelected.find((item) => item.product_sku === product.product_sku)
      ) {
        // If already selected, remove it
        return prevSelected.filter(
          (item) => item.product_sku !== product.product_sku
        );
      } else {
        // Otherwise, add the full product object
        return [...prevSelected, product];
      }
    });
  };

  const [skus, setSKus] = useState([]);
  const handleDeleteClick = () => {
    const selectedSKUs = selectedRows.map((product) => product.product_sku);

    setSKus(selectedSKUs);

    if (selectedRows.length === 0) {
      toast.error("No products selected!");
      return;
    } else {
      setOpenDelete(true);
    }
  };

  const [inventory, setInven] = useState([]);
  const [p_sku, setSKU] = useState([]);
  const [unallocated, setUnallocated] = useState(0);
  const [
    sendedToInventoryProductTypeModal,
    setSendedToInventoryProductTypeModal,
  ] = useState("");

  const handleInven = (sku, product_type) => {
    setOpenModal(true);
    setSKU(sku);
    setSendedToInventoryProductTypeModal(product_type);

    try {
      axios
        .get(fetchProductInven, {
          params: {
            warehouse_pk: userDetails.primary_warehouse_pk,
            product_sku: sku,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setInven(response.data.product_inventory.inventory);
          setUnallocated(response.data.product_inventory.unallocated);
          setOpenModal(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [open, setOpen] = useState(false);
  const [pseriessku, setPseriessku] = useState("");
  const [pseriesname, setPseriesname] = useState("");
  const [serieses, setSeries] = useState([]);
  const [sendData, setSendData] = useState({}); // New state for sendData

  const handleClickOpen = (sku, name, data, allData) => {
    setOpen(true);
    setSeries(data);
    setPseriessku(sku);
    setPseriesname(name);

    const newData = {
      brand__product_brand: allData.brand__product_brand,
      part_number: allData.part_number,
      sku: sku,
      series: data,
      name: name,
    };

    setSendData(newData); // Update sendData state    
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  const handleNavigate = (data) => {
    navigate("/product-dashboard", {
      state: { productSku: data.product_sku },
    });
  };

  // ========== Filter ===========

  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);

  useEffect(() => {
    fetchBrandType();
    fetchUniqueStocks();
  }, []);

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const types = response.data.product_types.map((type) => ({
          value: type.id,
          label: type.product_type,
        }));
        const brands = response.data.product_brands.map((brand) => ({
          value: brand.id,
          label: brand.product_brand,
        }));
        setTypes(types);

        setBrands(brands);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong");
      });
  };

  const fetchUniqueStocks = () => {
    axios
      .get(fetch_unique_stocks, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const stocks = response.data.data.map((stock, index) => ({
          value: index,
          label: stock,
        }));
        setStocks(stocks);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong");
      });
  };

  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(() => {
    //   fetchData();
    // }, 180000); // 3 minutes in milliseconds

    // return () => clearInterval(intervalId);
  }, [
    currentPage,
    pageSize,
    searchquery,
    selectedBrands,
    selectedTypes,
    selectedStocks,selectedWarehouseIds
  ]);


  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      selectedBrands: selectedBrands || null,
      selectedTypes: selectedTypes || null,
      selectedStocks: selectedStocks || null,
      selectedWarehouse: selectedWarehouseIds || []
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_all_product, data, { headers })
      .then((response) => {
        console.log(response.data.data)
        setProducts(response.data.data.products);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [visibleMenu, setVisibleMenu] = useState(false);
  const [copyProductData, setCopyProductData] = useState("");
  const [openAddProductModal, setOpenAddProductModal] = useState(false);

  const handleCopyProduct = (product) => {
    setOpenAddProductModal(true);
    setCopyProductData(product.product_sku);
  };

  const handleCloseCopyProduct = () => {
    setOpenAddProductModal(false);
    setVisibleMenu(false);
  };

  const menuRef = useRef(false);

  const handleMenuToggle = (productSku) => {
    setVisibleMenu((prev) => (prev === productSku ? false : productSku));
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisibleMenu(false);
    }
  };

  useEffect(() => {
    if (visibleMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleMenu]);



  return (
    <>
      {/* <CustomLoader loading={loading} /> */}

      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <AddProductModal
        copyProductData={copyProductData}
        open={openAddProductModal}
        handleClose={handleCloseCopyProduct}
      />
      <SeriesModal
        open={open}
        setOpen={setOpen}
        pseriessku={pseriessku}
        pseriesname={pseriesname}
        serieses={serieses}
        sendData={sendData} // Pass sendData to SeriesModal
      />

      <Delete
        selectedSKUs={skus}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        p_sku={p_sku}
        setInven={setInven}
        fetchData={fetchData}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
        warehouseId={userDetails.primary_warehouse_pk}
        sendedToInventoryProductTypeModal={sendedToInventoryProductTypeModal}
      />

      <ImportProducts
        openImport={openImport}
        setOpenImport={setOpenImport}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography sx={{ color: "text.primary" }}>
                All Products
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-4">
                <Input
                  icon
                  placeholder="Search..."
                  value={searchquery}
                  className="searchInput"
                  onChange={handleSearchChange}
                >
                  <input />
                  <Icon name="search" />
                </Input>
              </div>

              <div
                className="col-lg-8 mb_btn_hide"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" size="small">
                  <FilterSelectionModalByWarehouse 
                    title="Stock By Warehouse"
                    selectedWarehouseId={selectedWarehouseIds}
                    onSelect={setSelectedWarehouseIds}
                  />
                </Button>

                {isWideScreen ? (
                  <FilterTableColumns tableId="MasterProducts" />
                ) : null}

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add_product")}
                >
                  Add Product
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenImport(true)}
                >
                  Import Products
                </Button>
                {selectedRows && selectedRows.length > 0 ? (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleDeleteClick}
                    >
                      Delete Selected
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled MasterProducts" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ width: "2px" }}>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length ===
                            products.filter(
                                (product) => product.deletable !== 0
                              ).length &&
                            products.some((product) => product.deletable !== 0)
                          }
                          onChange={handleSelectAll}
                          disabled={products.every(
                            (product) => product.deletable === 0
                          )}
                        />
                      </th>
                      <th>SKU</th>
                      <th>Part Number</th>
                      <th>Product</th>
                      <th>
                        <FilterSelectionModal
                          title="Type"
                          options={types}
                          selectedOptions={selectedTypes}
                          onSelect={setSelectedTypes}
                        />
                      </th>
                      <th>
                        <FilterSelectionModal
                          title="Brand"
                          options={brands}
                          selectedOptions={selectedBrands}
                          onSelect={setSelectedBrands}
                        />
                      </th>
                      <th>MRP</th>
                      <th>OEM Number</th>
                      <th style={{ textAlign: "center" }}>
                        <FilterSelectionModal
                          title="Stock"
                          options={stocks}
                          selectedOptions={selectedStocks}
                          onSelect={setSelectedStocks}
                        />
                      </th>
                      <th>Dimensions</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products && products.length > 0 ? (
                      <>
                        {products &&
                          products.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "2px" }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.some(
                                      (item) =>
                                        item.product_sku === data.product_sku
                                    )}
                                    onChange={() => handleRowSelect(data)}
                                    disabled={data.deletable === 0} // Disable if deletable is 0
                                  />
                                </td>

                                <td style={{ position: "relative" }}>
                                  {data.product_sku}{" "}
                                  <CiMenuKebab
                                    onClick={() =>
                                      handleMenuToggle(data.product_sku)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  {visibleMenu === data.product_sku && (
                                    <Paper
                                      ref={menuRef}
                                      sx={{
                                        width: 200,
                                        minWidth: "180px",
                                        maxWidth: "100%",
                                        marginTop: "-50px",
                                        marginLeft: "80%",
                                        position: "absolute",
                                        zIndex: "1000",
                                      }}
                                    >
                                      <MenuList>
                                        <MenuItem
                                          onClick={() =>
                                            handleCopyProduct(data)
                                          }
                                        >
                                          <ListItemIcon>
                                            <ContentCopy fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>Copy</ListItemText>
                                          <Typography
                                            variant="body2"
                                            sx={{ color: "text.secondary" }}
                                          >
                                            ⌘C
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => handleNavigate(data)}
                                        >
                                          <ListItemIcon>
                                            <SiEbox fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>Dashboard</ListItemText>
                                          <Typography
                                            variant="body2"
                                            sx={{ color: "text.secondary" }}
                                          >
                                            ⌘D
                                          </Typography>
                                        </MenuItem>
                                      </MenuList>
                                    </Paper>
                                  )}
                                </td>
                                <td>{data.part_number}</td>
                                <td
                                  className="product_name_class"
                                  style={{
                                    maxWidth: "500px",
                                  }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    // onClick={() =>
                                    //   handleViewProduct(data.product_sku)
                                    // }

                                    // onClick={() => {
                                    //   handleNavigate(data);
                                    // }}
                                  >
                                    {data.product_name}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.product_type__product_type == "None"
                                        ? "grey"
                                        : "black",
                                  }}
                                >
                                  {data.product_type__product_type}
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.brand__product_brand == "None"
                                        ? "grey"
                                        : "black",
                                  }}
                                >
                                  {data.brand__product_brand}
                                </td>

                                <td style={{ color: "red" }}>
                                  <b>{formatIndianRupee(data.mrp)}</b>
                                </td>
                                <td className="hide_table_td">
                                  {data.oem_number}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    color: data.in_stock <= 2 ? "red" : "green",
                                    cursor:
                                      data.product_type__product_type ==
                                        "Child Parts" ||
                                      data.product_type__product_type == "CH"
                                        ? "default"
                                        : "pointer", // Change cursor if it's a child part
                                  }}
                                  onClick={
                                    data.product_type__product_type !==
                                      "Child Parts" &&
                                    data.product_type__product_type !== "CH"
                                      ? () =>
                                          handleClickOpen(
                                            data.product_sku,
                                            data.product_name,
                                            data.serieses,
                                            data
                                          )
                                      : undefined // No action for "Child Parts"
                                  }
                                >
                                  <b>{data.in_stock}</b>
                                </td>
                                <td>
                                  <span style={{ color: "grey" }}>L:</span>{" "}
                                  {data.length}cm;{" "}
                                  <span style={{ color: "grey" }}>B:</span>{" "}
                                  {data.breadth}cm;{" "}
                                  <span style={{ color: "grey" }}>H:</span>{" "}
                                  {data.height}cm;{" "}
                                  <span style={{ color: "grey" }}>W:</span>{" "}
                                  {data.weight}cm;{" "}
                                </td>
                                <td>
                                  <Button
                                    onClick={() =>
                                      handleInven(
                                        data.product_sku,
                                        data.product_type__product_type
                                      )
                                    }
                                    variant="outlined"
                                    size="small"
                                  >
                                    Inventory
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      handleEditClick(data.product_sku)
                                    }
                                    variant="outlined"
                                    color="error"
                                    style={{ marginLeft: "5px" }}
                                    size="small"
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={11}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {products.length > 0 ? (
                  <>
                    {products &&
                      products.map((data) => (
                        <>
                          <div className="product-card position-relative">
                            <div
                              onClick={() =>
                                handleViewProduct(data.product_sku)
                              }
                            >
                              <FaImage className="product-img" />
                            </div>
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                {data.product_sku}
                              </h5>
                              <h5
                                className="product-card-name"
                                onClick={() =>
                                  handleViewProduct(data.product_sku)
                                }
                              >
                                {data.product_name}
                              </h5>
                              <h5 className="product-card-partNo">
                                PN : {data.part_number}
                              </h5>
                              <h5 className="product-card-mrp">
                                {formatIndianRupee(data.mrp)}
                              </h5>

                              <Label
                                color={data.in_stock <= 2 ? "red" : "blue"}
                                style={{
                                  position: "absolute",
                                  bottom: "7px",
                                  right: "7px",
                                  fontSize: "11px",

                                  cursor:
                                    data.product_type__product_type ==
                                    "Child Parts"
                                      ? "default"
                                      : "pointer",
                                }}
                                onClick={() =>
                                  handleInven(
                                    data.product_sku,
                                    data.product_type__product_type
                                  )
                                }
                                // onClick={
                                //   data.product_type__product_type !==
                                //     "Child Parts" &&
                                //   data.product_type__product_type !== "CH"
                                //     ? () =>
                                //         handleClickOpen(
                                //           data.product_sku,
                                //           data.product_name,
                                //           data.serieses,
                                //           data
                                //         )
                                //     : undefined
                                // }
                                className="product-card-mrp"
                              >
                                QTY {data.in_stock}
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterProducts;
