import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, fetch_requirement_descrypancy } from "../../Api";
import { useScreenWidth } from "../../useScreenWidth";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination, Icon, Label } from "semantic-ui-react";
import { Button } from "@mui/material";
import CustomLoader from "../../Components/Loader";

function PendingRequirementProducts() {
  const { id } = useParams();
  const navigate = useNavigate();

  const isWideScreen = useScreenWidth();
  const [allRequriments, setAllRequriments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      requirement_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(fetch_requirement_descrypancy, data, { headers })
      .then((response) => {
        // console.log(response.data.products)
        setAllRequriments(response.data.products);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error while fetching the Descrypancy", error);
        toast.error(error?.response?.data?.message || "Something Went Wrong");
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, id]);

  return (
    <>
      <CustomLoader loading={loading} />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Pending Requirement Products (#{id})
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-12 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <div className="responsive-table1">
              <table
                className="ui table celled PendingRequirementProducts"
                id="c_table"
              >
                <thead className="table-head">
                  <tr>
                    <th>SKU</th>
                    <th>Part Number</th>
                    <th>Product Name</th>
                    <th className="text-center">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {allRequriments.length > 0 ? (
                    <>
                      {allRequriments &&
                        allRequriments.map((data, index) => (
                          <tr key={data.id || index}>
                            <td>{data.product_sku}</td>
                            <td>{data.part_number}</td>
                            <td>{data.product_name}</td>
                            <td className="text-center">{data.quantity}</td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>
                          {!loading ? (
                            <div className="notfound_img_div">
                              <div className="notfound_img"></div>
                            </div>
                          ) : (
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {allRequriments.length > 0 ? (
                  <>
                    {allRequriments &&
                      allRequriments.map((data, index) => (
                        <div className="product-card" key={index}>
                          <div className="py-1 px-2 w-100">
                            <h5 className="product-card-sku">
                              #{data.product_sku}
                            </h5>
                            <h5 className="product-card-name">
                              {data.product_name}
                            </h5>
                            <h5
                              className="product-card-mrp"
                              style={{ color: "grey" }}
                            >
                              <b style={{ color: "black" }}>
                                {data.part_number}
                              </b>
                            </h5>

                            <Label
                              color={"red"}
                              style={{
                                position: "absolute",
                                bottom: "7px",
                                right: "7px",
                                fontSize: "11px",
                              }}
                              className="product-card-mrp"
                            >
                              QTY {data.quantity}
                            </Label>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingRequirementProducts;
