import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TransferHistoryDetails, apiKey } from "../../../Api";
import { TbExternalLink } from "react-icons/tb";
import { useScreenWidth } from "../../../useScreenWidth";
import { Label } from "semantic-ui-react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "950px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function StockTransferModal({ open, onClose, transferId }) {
  const isWideScreen = useScreenWidth();
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [notes, setNotes] = useState("");
  const [sourcewarehouse, setSourceWarehouse] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open && transferId) {
      fetchData();
    }
  }, [open, transferId]);

  const fetchData = () => {
    axios
      .get(TransferHistoryDetails, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          history_pk: transferId,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setProducts(response.data.data);
        setSourceWarehouse(response.data.obj.source_warehouse);
        setWarehouse(response.data.obj.target_warehouse);
        setNotes(response.data.obj.note);
        setLoading(false);
        // setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className="bg-white py-3 px-3">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <>
              <Typography variant="h5" component="h2">
                Transfer Detail <small>(#{transferId})</small>
                <a
                  href={`/#/transfer-history-detail/${transferId}`}
                  target="_blank"
                  className="navigate-other"
                >
                  <TbExternalLink />
                </a>
              </Typography>
              <div className="row my-2">
                <div className="col-md-4">
                  <Typography>
                    <strong>Warehouse: </strong> {warehouse}
                  </Typography>
                </div>
                <div className="col-md-8">
                  <Typography>
                    <strong>Notes: </strong>
                    {notes ? notes : "No notes available for this transfer"}
                  </Typography>
                </div>
              </div>
              {isWideScreen ? (
                <>
                  <div
                    className="responsive-table1 "
                    style={{
                      maxHeight: "300px",
                      height: "max-content",
                      width: "100%",
                    }}
                  >
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th>Product</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.length > 0 ? (
                          <>
                            {products &&
                              products.map((data) => (
                                <>
                                  <tr>
                                    <td>
                                      {data.product_ref.product_sku || "N/A"}
                                    </td>

                                    <td  className="product_name_class"
                                      style={{
                                        maxWidth: "500px",
                                      
                                      }}
                                    >
                                      <span style={{ cursor: "pointer" }}>
                                        {data.product_ref.product_name || "N/A"}
                                      </span>
                                    </td>
                                    <td>{data.quantity}</td>
                                  </tr>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={4}>
                                {!loading ? (
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                ) : (
                                  <div className="notfound_img_div">
                                    <div className="loadingfound_img"></div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="card p-2 responsive-mobile-table"
                    style={{ maxHeight: "250px" }}
                  >
                    {products.length > 0 ? (
                      <>
                        {products &&
                          products.map((data) => (
                            <>
                              <div className="product-card">
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_ref.product_sku}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.product_ref?.product_name}
                                  </h5>
                                  <small>{data.product_ref__part_number}</small>
                                  <Label
                                    color={"green"}
                                    style={{
                                      position: "absolute",
                                      bottom: "5%",
                                      right: "9px",
                                      fontSize: "11px",
                                      cursor: "pointer",
                                    }}
                                    className="product-card-mrp"
                                  >
                                    QTY {data.quantity}
                                  </Label>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {!loading ? (
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        ) : (
                          <div className="notfound_img_div">
                            <div className="loadingfound_img"></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ marginTop: "20px", float: "right" }}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default StockTransferModal;
