import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import eat from "../Assets/sounds/eat.mp3";
import overgame from "../Assets/sounds/gameover.mp3";

const SnakeGame = ({ endGame }) => {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [keyHandled, setKeyHandled] = useState(false);
  const grid = 16;

  const playSoundEat = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const playSoundGameOver = () => {
    const sound = document.getElementById("scanSoundgameover");
    if (sound) {
      sound.play();
    }
  };

  // Snake and apple positions
  const snakeRef = useRef({
    x: 160,
    y: 160,
    dx: grid,
    dy: 0,
    cells: [],
    maxCells: 4,
  });

  const appleRef = useRef({
    x: 320,
    y: 320,
  });

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const getRandomApplePosition = () => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };
    const maxGridX = Math.floor(canvas.width / grid);
    const maxGridY = Math.floor(canvas.height / grid);
    return {
      x: getRandomInt(0, maxGridX) * grid,
      y: getRandomInt(0, maxGridY) * grid,
    };
  };

  // Define the speed (in milliseconds)
  const speed = 100; // Adjust this value for speed (lower = faster)

  const loop = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    // Update snake position
    snakeRef.current.x += snakeRef.current.dx;
    snakeRef.current.y += snakeRef.current.dy;

    // Wrap around the canvas
    if (snakeRef.current.x < 0) {
      snakeRef.current.x = canvas.width - grid;
    } else if (snakeRef.current.x >= canvas.width) {
      snakeRef.current.x = 0;
    }

    if (snakeRef.current.y < 0) {
      snakeRef.current.y = canvas.height - grid;
    } else if (snakeRef.current.y >= canvas.height) {
      snakeRef.current.y = 0;
    }

    // Add new head to the snake
    snakeRef.current.cells.unshift({
      x: snakeRef.current.x,
      y: snakeRef.current.y,
    });

    if (snakeRef.current.cells.length > snakeRef.current.maxCells) {
      snakeRef.current.cells.pop();
    }

    // Draw apple
    context.fillStyle = "red";
    context.fillRect(
      appleRef.current.x,
      appleRef.current.y,
      grid - 1,
      grid - 1
    );

    // Draw snake
    context.fillStyle = "green";
    snakeRef.current.cells.forEach((cell) => {
      context.fillRect(cell.x, cell.y, grid - 1, grid - 1);
    });

    // Check for collision with apple
    if (
      snakeRef.current.cells[0].x === appleRef.current.x &&
      snakeRef.current.cells[0].y === appleRef.current.y
    ) {
      snakeRef.current.maxCells++; // Increase snake length
      setScore((prevScore) => prevScore + 1); // Update score
      playSoundEat();
      appleRef.current = getRandomApplePosition(); // New random apple position
    }

    // Check for collision with itself
    for (let i = 1; i < snakeRef.current.cells.length; i++) {
      if (
        snakeRef.current.cells[0].x === snakeRef.current.cells[i].x &&
        snakeRef.current.cells[0].y === snakeRef.current.cells[i].y
      ) {
        // Game over
        setIsGameOver(true);
        playSoundGameOver();

        return; // Exit the loop
      }
    }

    // Draw the score continuously
    context.fillStyle = "white";
    context.font = "13px Arial";
    context.fillText("Score: " + score, 10, canvas.height - 10); // Display score every frame

    setKeyHandled(false); // Reset the key handled flag after updating the game state
  };

  const handleKeydown = (e) => {
    if (isGameOver || keyHandled) return; // Prevent movement if game is over or key has already been handled

    if (e.key === "ArrowLeft" && snakeRef.current.dx === 0) {
      snakeRef.current.dx = -grid;
      snakeRef.current.dy = 0;
    } else if (e.key === "ArrowUp" && snakeRef.current.dy === 0) {
      snakeRef.current.dy = -grid;
      snakeRef.current.dx = 0;
    } else if (e.key === "ArrowRight" && snakeRef.current.dx === 0) {
      snakeRef.current.dx = grid;
      snakeRef.current.dy = 0;
    } else if (e.key === "ArrowDown" && snakeRef.current.dy === 0) {
      snakeRef.current.dy = grid;
      snakeRef.current.dx = 0;
    }

    setKeyHandled(true); // Set the key handled flag to true
  };

  const resetGame = () => {
    snakeRef.current = {
      x: 160,
      y: 160,
      dx: grid,
      dy: 0,
      cells: [],
      maxCells: 4,
    };
    setScore(0); // Reset score when game resets
    appleRef.current = getRandomApplePosition(); // Reset apple position
    setIsGameOver(false); // Set game status to running
  };

  const handleStartGame = () => {
    resetGame(); // Start a new game
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    const intervalId = setInterval(loop, speed); // Set a fixed interval for the game loop

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      clearInterval(intervalId); // Clear the interval on component unmount
    };
  }, [score, isGameOver]);

  const handleEndGame = () => {
    setIsGameOver(true);
    playSoundGameOver();
    // resetGame()
  };

  return (
    <div>
      {isGameOver ? (
        <div>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "2.3rem",
              color: "red",
              textAlign: "center",
              margin: "30px 0px",
            }}
          >
            GameOver!
          </p>
          <p
            style={{ textAlign: "center", fontSize: "1.1rem", marginTop: "-20px" }}
          >
            Your Score is <b>{score}</b>
          </p>
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "right",
              marginTop: "30px",
            }}
          >
            <Button variant="outlined" color="primary" size="small" onClick={handleStartGame}>
              Replay
            </Button>
            <Button
              onClick={endGame}
              color="success"
              variant="outlined"
              size="small"
            >
              Back to Work
            </Button>
          </div>
        </div>
      ) : (
        <>
          <canvas
            ref={canvasRef}
            width="600"
            height="400"
            style={{ border: "1px solid white", background: "#121212" }}
          />

          <Button variant="outlined" color="error" size="small" onClick={handleEndGame}>
            End Game
          </Button>
        </>
      )}

      <audio id="scanSound" src={eat} />
      <audio id="scanSoundgameover" src={overgame} />
    </div>
  );
};

export default SnakeGame;
