import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Modal, Box } from "@mui/material";
import { IoClose } from "react-icons/io5";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FaWarehouse } from "react-icons/fa";

import { Autocomplete, TextField } from "@mui/material";
import { SiTicktick } from "react-icons/si";
import {
  apiKey,
  fetch_racks,
  fetch_rows,
  allocate_adjustment_item,
  adjustment_History_Detail_ById,
} from "../../../Api";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { format } from "date-fns";
import { useScreenWidth } from "../../../useScreenWidth";
import { Label } from "semantic-ui-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1200px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// function ViewAdjustmentModal({ open, setOpenAdjustmentModal, selectedAdjustmentDetail, }) {
function ViewAdjustmentModal({
  open,
  setOpenAdjustmentModal,
  adjustmentPk,
  selectedAdjustmentDetailShowAllocation,
}) {
  const isWideScreen = useScreenWidth();
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [loading, setLoading] = useState({
    warehouses: false,
    rows: false,
    racks: false,
  });

  const [selectedAdjustmentDetail, setSelectedAdjustmentDetail] =
    useState(null);
  const [adjustmentDetails, setAdjustmentDetails] = useState([]);

  const fetchData = () => {
    const data = {
      adjustment_pk: parseInt(adjustmentPk),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(adjustment_History_Detail_ById, data, { headers })
      .then((response) => {
        // console.log(response.data.data)
        setSelectedAdjustmentDetail(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal Server Error");
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (open && adjustmentPk) {
      fetchData(adjustmentPk);
    }
  }, [open, adjustmentPk, selectedAdjustmentDetailShowAllocation]);

  useEffect(() => {
    if (open && selectedAdjustmentDetail) {
      setAdjustmentDetails(
        selectedAdjustmentDetail.adjustment_history_detail.map((product) => ({
          ...product,
          selectedWarehouse: product.selectedWarehouse || null,
          selectedRow: product.selectedRow || null,
          selectedRack: product.selectedRack || null,
        }))
      );
      fetchRows(selectedAdjustmentDetail?.warehouse?.id); // Assuming fetchRows is defined elsewhere
    }
    // Fetch warehouses when the modal is opened
    // fetchWarehouses(); // Uncomment if needed
  }, [open, selectedAdjustmentDetail]);

  // const [adjustmentDetails, setAdjustmentDetails] = useState(
  //   selectedAdjustmentDetail
  //     ? selectedAdjustmentDetail.adjustment_history_detail.map((product) => ({
  //       ...product,
  //       selectedWarehouse: null,
  //       selectedRow: null,
  //       selectedRack: null,
  //     }))
  //     : []
  // );

  // console.log("selectedAdjustmentDetail : ",selectedAdjustmentDetail);

  // useEffect(() => {
  //   if (open && selectedAdjustmentDetail) {
  //     setAdjustmentDetails(
  //       selectedAdjustmentDetail.adjustment_history_detail.map((product) => ({
  //         ...product,
  //         selectedWarehouse: product.selectedWarehouse || null,
  //         selectedRow: product.selectedRow || null,
  //         selectedRack: product.selectedRack || null,
  //       }))
  //     );
  //     fetchRows(selectedAdjustmentDetail?.warehouse?.id);
  //   }
  //   // Fetch warehouses when the modal is opened
  //   // fetchWarehouses();
  // }, [open, selectedAdjustmentDetail]);

  // console.log("selectedAdjustmentDetail : ",selectedAdjustmentDetail?.warehouse)

  // const fetchWarehouses = async () => {
  //   setLoading((prev) => ({ ...prev, warehouses: true }));
  //   try {
  //     const response = await axios.get(fetch_warehouse, {
  //       headers: { "API-Key": apiKey },
  //     });
  //     setWarehouses(response.data.warehouses);
  //   } catch (error) {
  //     toast.error("Failed to fetch warehouses.");
  //   } finally {
  //     setLoading((prev) => ({ ...prev, warehouses: false }));
  //   }
  // };

  const fetchRows = async (warehouseId) => {
    setLoading((prev) => ({ ...prev, rows: true }));
    try {
      const response = await axios.get(fetch_rows, {
        params: { warehouse_id: warehouseId },
        headers: { "API-Key": apiKey },
      });
      setRows(response.data.rows);
    } catch (error) {
      toast.error("Failed to fetch rows.");
    } finally {
      setLoading((prev) => ({ ...prev, rows: false }));
    }
  };

  const fetchRacks = async (rowId) => {
    setLoading((prev) => ({ ...prev, racks: true }));
    try {
      const response = await axios.get(fetch_racks, {
        params: { row_pk: rowId },
        headers: { "API-Key": apiKey },
      });
      setRacks(response.data.racks);
    } catch (error) {
      toast.error("Failed to fetch racks.");
    } finally {
      setLoading((prev) => ({ ...prev, racks: false }));
    }
  };

  const handleWarehouseChange = (sku, warehouse) => {
    setAdjustmentDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.product_sku === sku
          ? {
              ...detail,
              selectedWarehouse: warehouse, // Set full warehouse object
              selectedRow: "", // Reset row and rack when warehouse changes
              selectedRack: "",
            }
          : detail
      )
    );
    fetchRows(warehouse.id); // Fetch rows based on selected warehouse
    setRows([]); // Reset rows
    setRacks([]); // Reset racks
  };

  // const handleRowChange = (sku, row) => {
  //   setAdjustmentDetails((prevDetails) =>
  //     prevDetails.map((detail) =>
  //       detail.product_sku === sku
  //         ? { ...detail, selectedRow: row, selectedRack: "" }
  //         : detail
  //     )
  //   );
  //   fetchRacks(row.id); // Fetch racks based on selected row
  //   setRacks([]); // Reset racks
  // };

  const handleRowChange = (sku, row) => {
    setAdjustmentDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.product_sku === sku
          ? { ...detail, selectedRow: row, selectedRack: "" }
          : detail
      )
    );

    if (row) {
      fetchRacks(row.id); // Fetch racks based on selected row
      setRacks([]); // Reset racks
    }
  };

  const handleRackChange = (sku, rack) => {
    setAdjustmentDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.product_sku === sku ? { ...detail, selectedRack: rack } : detail
      )
    );
  };

  // console.log(selectedAdjustmentDetail)

  const handleAllocateProduct = (product) => {
    const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

    if (!product.selectedRack) {
      return toast.error("Please Select Rack");
    }

    const sendData = {
      adjustment_item_pk: parseInt(product.id),
      rack_pk: parseInt(product.selectedRack.id),
      user_name: userDetails?.name,
    };

    // console.log(sendData)

    try {
      axios
        .post(allocate_adjustment_item, sendData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            toast.success("Allocated successfully!");
            // fetchData();
            setAdjustmentDetails((prevDetails) =>
              prevDetails.map((p) =>
                p.product_sku === product.product_sku
                  ? { ...p, is_allocatable: 0 }
                  : p
              )
            );
          } else {
            toast.error(response?.data?.message || "Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        });
    } catch (error) {
      console.log("error While Allocating Product :", error?.response);
    }
  };

  return (
    <>
      <Toaster position="top-right" />

      <Dialog
        open={open}
        onClose={() => setOpenAdjustmentModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Stock Adjustment Details
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <div>
              {selectedAdjustmentDetail && (
                <>
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <Typography>
                        Warehouse:{" "}
                        <b style={{ color: "#3b3b3b" }}>
                          {selectedAdjustmentDetail.warehouse.ware_house}
                        </b>
                      </Typography>
                    </div>

                    <div className="col-md-3">
                      <Typography>
                        Type:{" "}
                        <Label
                          color={
                            selectedAdjustmentDetail.action === "stock out"
                              ? "red"
                              : "green"
                          }
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {selectedAdjustmentDetail.action}
                        </Label>
                      </Typography>
                    </div>
                    <div className="col-md-3">
                      <Typography>
                       Date:{" "}
                        <b style={{ color: "#3b3b3b" }}>
                          {format(selectedAdjustmentDetail.date, "yyyy-MM-dd")}
                        </b>
                      </Typography>
                    </div>
                    <div className="col-md-2">
                      <Typography>
                       Products:{" "}
                        <b style={{ color: "#3b3b3b" }}>
                          {selectedAdjustmentDetail.total_products} QTY
                        </b>
                      </Typography>
                    </div>
                    {/* <div className="col-sm-3">
                          <Typography variant="body1">
                            <span style={{ color: "#9dbcf5" }}>Note:</span>{" "}
                            {selectedAdjustmentDetail.note}
                          </Typography>
                        </div> */}
                  </div>

                  {isWideScreen ? (
                    <div
                      className="responsive-table1"
                      style={{ maxHeight: "350px" }}
                    >
                      <table className="ui table celled " id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>Part Number</th>
                            <th>Quantity</th>
                            {selectedAdjustmentDetailShowAllocation && (
                              <th className="text-center">Allocate</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {selectedAdjustmentDetail.adjustment_history_detail.map( */}
                          {adjustmentDetails &&
                            adjustmentDetails.map((product) => (
                              <tr key={product.product_sku}>
                                <td>{product.product_sku}</td>
                                <td>{product.product_name}</td>
                                <td>{product.part_number}</td>
                                <td>{product.quantity}</td>
                                {selectedAdjustmentDetailShowAllocation && (
                                  <td style={{ maxWidth: "200px" }}>
                                    {product.is_allocatable == 0 ? (
                                      <p
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        Already Allocated
                                      </p>
                                    ) : (
                                      <>
                                        <div className="d-flex align-items-center justify-content-between gap-1">
                                          <Autocomplete
                                            disabled={
                                              product.is_allocatable == 0
                                            }
                                            fullWidth
                                            options={rows}
                                            getOptionLabel={(option) =>
                                              option.row_name
                                            }
                                            value={product.selectedRow || null} // Pass full row object
                                            onChange={(e, newValue) => {
                                              handleRowChange(
                                                product.product_sku,
                                                newValue || null
                                              );
                                            }}
                                            style={{ width: "100px" }}
                                            loading={loading.rows}
                                            renderInput={(params) => (
                                              <TextField
                                                fullWidth
                                                {...params}
                                                label="Row"
                                                variant="outlined"
                                                size="small"
                                              />
                                            )}
                                          />

                                          <Autocomplete
                                            disabled={
                                              product.is_allocatable == 0
                                            }
                                            fullWidth
                                            style={{ width: "100px" }}
                                            options={racks}
                                            getOptionLabel={(option) =>
                                              option.rack_name
                                            }
                                            value={product.selectedRack || null} // Pass full rack object
                                            onChange={(e, newValue) => {
                                              handleRackChange(
                                                product.product_sku,
                                                newValue || null
                                              );
                                            }}
                                            loading={loading.racks}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Rack"
                                                variant="outlined"
                                                size="small"
                                              />
                                            )}
                                          />

                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleAllocateProduct(product)
                                            }
                                            style={{ padding: "10px" }}
                                            disabled={
                                              !product.selectedRow ||
                                              !product.selectedRack
                                            }
                                          >
                                            <SiTicktick />
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="responsive-mobile-table"
                      style={{ maxHeight: "50vh", height:'100%' }}
                    >
                      {adjustmentDetails.map((product) => (
                        <div
                          className="product-card position-relative"
                          key={product.product_sku}
                        >
                          <div className="py-1 px-2 w-100">
                            <h5 className="product-card-sku">
                              {" "}
                              #{product.product_sku}
                            </h5>
                            <h5 className="product-card-name">
                              {product.product_name || "-"}
                            </h5>
                            <h5 className="product-card-mrp">
                              <span style={{ color: "grey" }}>QTY:</span>{" "}
                              {product.quantity} | {product.part_number}
                            </h5>

                            {selectedAdjustmentDetailShowAllocation && (
                              <>
                                {product.is_allocatable == 0 ? (
                                  <>
                                    {/* <p className="text-center text-danger border border-danger mt-2 rounded">Already Allocated</p> */}
                                    <Label
                                      style={{
                                        position: "absolute",
                                        bottom: "5px",
                                        right: "7px",
                                        fontSize: "10px",
                                      }}
                                      color="blue"
                                      size="small"
                                     
                                    >
                                      Allocated
                                    </Label>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex align-items-center flex-wrap justify-content-between mt-2">
                                      <Autocomplete
                                        style={{ width: "48%" }}
                                        fullWidth
                                        options={rows}
                                        getOptionLabel={(option) =>
                                          option.row_name
                                        }
                                        value={product.selectedRow || null} // Pass full row object
                                        onChange={(e, newValue) => {
                                          handleRowChange(
                                            product.product_sku,
                                            newValue || null
                                          );
                                        }}
                                        loading={loading.rows}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            {...params}
                                            label="Row"
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />

                                      <Autocomplete
                                        style={{ width: "48%" }}
                                        fullWidth
                                        options={racks}
                                        getOptionLabel={(option) =>
                                          option.rack_name
                                        }
                                        value={product.selectedRack || null} // Pass full rack object
                                        onChange={(e, newValue) => {
                                          handleRackChange(
                                            product.product_sku,
                                            newValue || null
                                          );
                                        }}
                                        loading={loading.racks}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Rack"
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleAllocateProduct(product)
                                        }
                                        style={{ padding: "8px" }}
                                        className="w-100 mt-2"
                                      >
                                        Allocate &nbsp; <SiTicktick />
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAdjustmentModal(false)}
            variant="outlined"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewAdjustmentModal;
