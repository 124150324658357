import "./App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AddProducts from "./Pages/Products/AddProducts";
import EditProduct from "./Pages/Products/EditProduct";
import MasterProducts from "./Pages/Products/MasterProducts";
import Login from "./Authen/Login";
import AuthTokenVerify from "./Authen/AuthTokenVerify";
import Warehouses from "./Pages/Inventory/Warehouses";
import Purchases from "./Pages/Purchase/Purchases";
import AddPurchase from "./Pages/Purchase/AddPurchase";
import EditPurchase from "./Pages/Purchase/EditPurchase";
import Sales from "./Pages/Sales/Sales";
import AddSale from "./Pages/Sales/AddSales/AddCashSale";
import AddPartySale from "./Pages/Sales/AddSales/AddPartySale";

import BarcodePurchase from "./Pages/Purchase/BarcodePurchase";
import RowRackDetails from "./Pages/Inventory/RowRackDetails";
import StockTransfer from "./Pages/Inventory/StockTransfer/StockTransfer";
import AddStockTransfer from "./Pages/Inventory/StockTransfer/AddStockTransfer";
import Adjustments from "./Pages/Inventory/StockAdjustment/Adjustments";
import AddStockAdjustment from "./Pages/Inventory/StockAdjustment/AddStockAdjustment";
import ViewStockTransfer from "./Pages/Inventory/StockTransfer/ViewStockTransfer";
import EditWarehouse from "./Pages/Inventory/UpdateRows/EditWarehouse";
import ViewSaleDetail from "./Pages/Sales/ViewSaleDetail";
import Convert from "./Pages/Conversion/Convert";
import AddConversion from "./Pages/Conversion/AddConversion";
import UnallocatedProducts from "./Pages/Products/UnallocatedProducts";
import Allocate from "./Pages/Products/ProductAllocationScan/Allocate";
import axios from "axios";
import { apiKey, checkTokenValidity } from "./Api";
import { useEffect, useState } from "react";
import AllDraftPurchase from "./Pages/Purchase/AllDraftPurchase";
import AllVendor from "./Pages/OurClients/Vendor/AllVendor";
import UploadVendor from "./Pages/OurClients/Vendor/UploadVendor";
import AllocationHistory from "./Pages/Inventory/AllocationHistory";
import AllClients from "./Pages/OurClients/Clients/AllClients";
import ImportCustomer from "./Pages/OurClients/Clients/ImportCustomer";
import Layout from "./Layout";
import AllDispatch from "./Pages/Dispatch/AllDispatch";
import DispatchDetails from "./Pages/Dispatch/DispatchDetails";
import ProductDashboard from "./Pages/Products/ProductDashboard/ProductDashboard";
import { Helmet } from "react-helmet";
import Dashboard from "./Pages/Dashboard/Dashboard";
import EditSaleDetail from "./Pages/Sales/EditSaleDetail";
import ViewPurchase from "./Pages/Purchase/ViewPurchase";
import Requirements from "./Pages/RequirementList/Requirements";
import RequirementDetails from "./Pages/RequirementList/RequirementDetails";
import PickingRequirements from "./Pages/RequirementList/DispatchRequirementDetails";
import SingleDispatchDetail from "./Pages/Dispatch/SingleDispatchDetail";
import ConversionDetail from "./Pages/Conversion/ConversionDetail";
import Settings from "./Pages/Settings/Settings";
import DiscountAdjustment from "./Pages/Settings/DiscountAdjustment/DiscountAdjustment";
import ProductDetails from "./Pages/Products/ProductDetails";
import { useScreenWidth } from "./useScreenWidth";
import StockLedger from "./Pages/Inventory/StockLedger/StockLedger";
import PickUp from "./Pages/RequirementList/PickUp";
import RemoveChildParts from "./Pages/RequirementList/RemoveChildParts";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "./Firebase";
import NewCRMUpdateAlert from "./Messages/NewCRMUpdateAlert";
import FunModal from "./Components/FunModal";
import { setHours, setMinutes, setSeconds, isBefore } from "date-fns";
import PackRequirementProducts from "./Pages/RequirementList/PackRequirementProducts";
import PackedDetails from "./Pages/RequirementList/PackRequirement/PackedDetails";
import PackedDetailsProducts from "./Pages/RequirementList/PackRequirement/PackedDetailsProducts";
import PendingRequirementProducts from "./Pages/RequirementList/PendingRequirementProducts"

const firestoreDocPath = "update/cCwfTT2iTZzlOLkAQBMH";
const firestoreFieldName = "update";
const updateMsg = "msg";
const localStorageKey = "localStorageKey";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [userAvailable, setUserAvailable] = useState(false);
  const isWideScreen = useScreenWidth();
  const [redirectTo, setRedirectTo] = useState(null);
  const [redirected, setRedirected] = useState(false);

  // const [openFunModal, setOpenFunModal] = useState(false);
  // const [funstatus, setFunStatus] = useState("");

  // useEffect(() => {
  //   const setTimer = (hour, minute, status) => {
  //     const now = new Date();
  //     const targetTime = setSeconds(setMinutes(setHours(new Date(), hour), minute), 0);

  //     let timeoutDuration = targetTime - now;
  //     if (isBefore(targetTime, now)) {
  //       // If the target time is already past, set the timer for the next day's target time
  //       timeoutDuration += 24 * 60 * 60 * 1000;
  //     }

  //     return setTimeout(() => {
  //       setFunStatus(status);
  //       setOpenFunModal(true);
  //     }, timeoutDuration);
  //   };

  //   const timers = [
  //     setTimer(14, 0, 1), // 2:00 PM with funstatus 1
  //     setTimer(16, 0, 2), // 4:00 PM with funstatus 2

  //   ];

  //   return () => timers.forEach(timer => clearTimeout(timer)); // Clean up the timers on component unmount
  // }, []);

  const [openCRMAlert, setOpenAlert] = useState(false);
  const [firestoreValue, setFirestoreValue] = useState(null);
  const [updatemsg, setUpdateMsg] = useState(null);

  useEffect(() => {
    const docRef = doc(firestore, firestoreDocPath);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const value = docSnap.data()[firestoreFieldName];
        setUpdateMsg(docSnap.data()[updateMsg]);
        setFirestoreValue(value);

        // Check local storage and compare values
        let localStorageValue = localStorage.getItem(localStorageKey);
        if (!localStorageValue) {
          localStorage.setItem(localStorageKey, value);
          localStorageValue = value;
        }

        if (value == localStorageValue) {
          // console.log("");
        } else {
          // toast.error("New Update Available, Please Reload the CRM");
          setOpenAlert(true);
        }
      } else {
        console.log("No such document!");
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [firestoreDocPath, firestoreFieldName, localStorageKey]);

  // Update local storage value only on page refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (firestoreValue !== null) {
        localStorage.setItem(localStorageKey, firestoreValue);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [firestoreValue, localStorageKey]);

  window.onload = function () {
    localStorage.removeItem("CancelledUpdate");
  };

  useEffect(() => {
    // Function to set autoComplete="off"
    const addautoComplete = () => {
      const inputs = document.querySelectorAll("input");
      inputs.forEach((input) => {
        input.setAttribute("autoComplete", "off");
      });
    };

    addautoComplete();
    const observer = new MutationObserver(() => {
      addautoComplete(); // Reapply autoComplete for new inputs
    });

    // Observe changes in the body or another container as needed
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  // useEffect(() => {
  //   const validateUser = async () => {
  //     let userDetails;
  //     try {
  //       userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  //     } catch (error) {
  //       console.error("Error parsing user details:", error);
  //       navigate("/auth");
  //       setRedirectTo(location.pathname);
  //       setUserAvailable(false);
  //       return;
  //     }

  //     if (
  //       !userDetails ||
  //       !userDetails.token ||
  //       !userDetails.email ||
  //       !userDetails.name
  //     ) {
  //       navigate("/auth");
  //       setUserAvailable(false);
  //       return;
  //     }

  //     try {
  //       const headers = {
  //         "content-type": "application/json",
  //         "API-Key": apiKey,
  //       };

  //       const payload = { email: userDetails.email, token: userDetails.token };
  //       const response = await axios.post(checkTokenValidity, payload, {
  //         headers,
  //       });

  //       if (response.data.status === 1) {
  //         setUserAvailable(true);
  //       } else {
  //         navigate("/auth");
  //         setUserAvailable(false);
  //       }
  //     } catch (error) {
  //       console.error("Authentication error:", error);
  //       navigate("/auth");
  //       setUserAvailable(false);
  //     } finally {
  //       setIsLoading(false); // Always set loading to false at the end
  //     }
  //   };

  //   validateUser();
  // }, [navigate]);

  useEffect(() => {
    const validateUser = async () => {
      let userDetails;
      try {
        userDetails = JSON.parse(localStorage.getItem("ktt_users"));
      } catch (error) {
        console.error("Error parsing user details:", error);
        if (!redirected) {
          // Check if the user hasn't been redirected
          setRedirectTo(location.pathname); // Store current location before redirecting
          setRedirected(true); // Mark as redirected
          navigate("/auth");
        }
        setUserAvailable(false);
        setIsLoading(false);
        return;
      }

      if (
        !userDetails ||
        !userDetails.token ||
        !userDetails.email ||
        !userDetails.name
      ) {
        if (!redirected) {
          setRedirectTo(location.pathname); // Store current location before redirecting
          setRedirected(true); // Mark as redirected
          navigate("/auth");
        }
        setUserAvailable(false);
        setIsLoading(false);
        return;
      }

      try {
        const headers = {
          "content-type": "application/json",
          "API-Key": apiKey,
        };

        const payload = { email: userDetails.email, token: userDetails.token };
        const response = await axios.post(checkTokenValidity, payload, {
          headers,
        });

        if (response.data.status === 1) {
          setUserAvailable(true);
        } else {
          if (!redirected) {
            setRedirectTo(location.pathname); // Store current location before redirecting
            setRedirected(true); // Mark as redirected
            navigate("/auth");
          }
          setUserAvailable(false);
        }
      } catch (error) {
        console.error("Authentication error:", error);
        if (!redirected) {
          setRedirectTo(location.pathname); // Store current location before redirecting
          setRedirected(true); // Mark as redirected
          navigate("/auth");
        }
        setUserAvailable(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateUser();
  }, [navigate, location.pathname, redirected]);

  const [isSidebarActive, setIsSidebarActive] = useState(
    isWideScreen ? true : false
  );

  const toggleSidebar = () => {
    setIsSidebarActive((prev) => !prev);
  };

  return (
    <>
      {/* <FunModal setOpenFunModal={setOpenFunModal} openFunModal={openFunModal} funstatus={funstatus}/> */}

      <NewCRMUpdateAlert
        updatemsg={updatemsg}
        openCRMAlert={openCRMAlert}
        setOpenAlert={setOpenAlert}
      />
      <div className="App">
        <Helmet>
          <title>Kuber Turbo Tech</title>
        </Helmet>

        <Routes>
          <Route
            path="/auth"
            element={
              userAvailable ? (
                <Navigate to={redirectTo || "/"} />
              ) : (
                <Login redirectTo={redirectTo} />
              )
            }
          />

          {userAvailable && (
            <>
              <Route
                path="/"
                element={
                  <Layout
                    toggleSidebar={toggleSidebar}
                    isActive={isSidebarActive}
                  >
                    <Dashboard />
                  </Layout>
                }
              />

              {/* Products */}
              <Route
                path="/add_product"
                element={
                  <>
                    <Helmet>
                      <title>Add New Product - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add new products effortlessly with Kuber Turbo Tech's intuitive CRM interface."
                      />
                      <meta
                        name="keywords"
                        content="add product, Kuber Turbo Tech, CRM, product management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/update-product/:sku"
                element={
                  <>
                    <Helmet>
                      <title>Update Product - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Edit product details easily with Kuber Turbo Tech's user-friendly interface."
                      />
                      <meta
                        name="keywords"
                        content="update product, Kuber Turbo Tech, product editing"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <EditProduct />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/all_products"
                element={
                  <>
                    <Helmet>
                      <title>All Products - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Browse all products available in Kuber Turbo Tech's CRM for efficient management."
                      />
                      <meta
                        name="keywords"
                        content="all products, Kuber Turbo Tech, product catalog"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <MasterProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/product-dashboard"
                element={
                  <>
                    <Helmet>
                      <title>Product Dashboard - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Product dashboard effortlessly with Kuber Turbo Tech's intuitive CRM interface."
                      />
                      <meta
                        name="keywords"
                        content="add product, Kuber Turbo Tech, CRM, product management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ProductDashboard />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/product-details/:sku"
                element={
                  <>
                    <Helmet>
                      <title>Product - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Browse all products available in Kuber Turbo Tech's CRM for efficient management."
                      />
                      <meta
                        name="keywords"
                        content="all products, Kuber Turbo Tech, product catalog"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ProductDetails />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/unallocated-products"
                element={
                  <>
                    <Helmet>
                      <title>Unallocated Products - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View and manage unallocated products in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="unallocated products, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <UnallocatedProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/allocate-products-scan"
                element={
                  <>
                    <Helmet>
                      <title>Allocate Products - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Scan and allocate products efficiently using Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="allocate products, Kuber Turbo Tech, product scanning"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Allocate />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/allocation-history"
                element={
                  <>
                    <Helmet>
                      <title>Allocation History - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View the history of product allocations in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="allocation history, Kuber Turbo Tech, product management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AllocationHistory />
                    </Layout>
                  </>
                }
              />

              {/* Ware House */}

              <Route
                path="/warehouses"
                element={
                  <>
                    <Helmet>
                      <title>Warehouses - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage and view warehouses in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="warehouses, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Warehouses />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/row-rack/:id"
                element={
                  <>
                    <Helmet>
                      <title>Row Rack Details - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View details of the selected row rack in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="row rack, Kuber Turbo Tech, inventory details"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <RowRackDetails />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/update-row-rack/:id"
                element={
                  <>
                    <Helmet>
                      <title>Update Row Rack - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Update the details of a specific row rack in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="update row rack, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <EditWarehouse />
                    </Layout>
                  </>
                }
              />

              {/* Stock */}

              <Route
                path="/stock_adjustments"
                element={
                  <>
                    <Helmet>
                      <title>Stock Adjustments - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage stock adjustments in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="stock adjustments, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Adjustments />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-stock-adjustment"
                element={
                  <>
                    <Helmet>
                      <title>Add Stock Adjustment - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new stock adjustment in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add stock adjustment, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddStockAdjustment />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/stock_transfer"
                element={
                  <>
                    <Helmet>
                      <title>Stock Transfer - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Transfer stock between locations in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="stock transfer, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <StockTransfer />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/stock-ledger"
                element={
                  <>
                    <Helmet>
                      <title>Stock Ledger - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Ledger stock between locations in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="stock Ledger, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <StockLedger />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/transfer-history-detail/:pk"
                element={
                  <>
                    <Helmet>
                      <title>Transfer History Detail - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View details of a specific stock transfer in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="transfer history, Kuber Turbo Tech, stock transfer details"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ViewStockTransfer />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-stock-transfer"
                element={
                  <>
                    <Helmet>
                      <title>Add Stock Transfer - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new stock transfer in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add stock transfer, Kuber Turbo Tech, inventory management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddStockTransfer />
                    </Layout>
                  </>
                }
              />

              {/* Draft Purchase */}

              <Route
                path="/all-draft-purchase"
                element={
                  <>
                    <Helmet>
                      <title>All Draft Purchases - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View and manage all draft purchases in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="draft purchases, Kuber Turbo Tech, purchase management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AllDraftPurchase />
                    </Layout>
                  </>
                }
              />

              {/* Purchases */}

              <Route
                path="/all_purchases"
                element={
                  <>
                    <Helmet>
                      <title>All Purchases - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View all purchases made in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="purchases, Kuber Turbo Tech, purchase history"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Purchases />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-purchase"
                element={
                  <>
                    <Helmet>
                      <title>Add Purchase - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new purchase to Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add purchase, Kuber Turbo Tech, purchase management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddPurchase />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/update-purchase/:id"
                element={
                  <>
                    <Helmet>
                      <title>Update Purchase - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Update an existing purchase in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="update purchase, Kuber Turbo Tech, purchase management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <EditPurchase />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/view-purchase/:id"
                element={
                  <>
                    <Helmet>
                      <title>View Purchase - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View an existing purchase in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="View purchase, Kuber Turbo Tech, purchase management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ViewPurchase />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/barcode-purchase/:id"
                element={
                  <>
                    <Helmet>
                      <title>Barcode Purchase - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage barcode purchases in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="barcode purchase, Kuber Turbo Tech, purchase management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <BarcodePurchase />
                    </Layout>
                  </>
                }
              />

              {/* Sales */}

              <Route
                path="/all_sales"
                element={
                  <>
                    <Helmet>
                      <title>All Sales - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View all sales made in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="sales, Kuber Turbo Tech, sales history"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Sales />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-cash-sale"
                element={
                  <>
                    <Helmet>
                      <title>Add Cash Sale - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new cash sale to Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add cash sale, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddSale />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-party-sale"
                element={
                  <>
                    <Helmet>
                      <title>Add Party Sale - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new party sale to Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add party sale, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddPartySale />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/view-sale/:id"
                element={
                  <>
                    <Helmet>
                      <title>View Sale Detail - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View details of a specific sale in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="view sale, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ViewSaleDetail />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/edit-sale/:id"
                element={
                  <>
                    <Helmet>
                      <title>Edit Sale Detail - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Edit details of a specific sale in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="Edit sale, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <EditSaleDetail />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/dispatch-history"
                element={
                  <>
                    <Helmet>
                      <title>Dispatch Sales - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage all dispatch sales in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="dispatch sales, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AllDispatch />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/dispatch-details/:id"
                element={
                  <>
                    <Helmet>
                      <title>Dispatch Sales - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage all dispatch sales in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="dispatch sales, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <SingleDispatchDetail />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/dispatch-sale-scan/:id"
                element={
                  <>
                    <Helmet>
                      <title>Dispatch Sale Scan - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Scan and view details of a dispatch sale in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="dispatch sale scan, Kuber Turbo Tech, sales management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <DispatchDetails />
                    </Layout>
                  </>
                }
              />

              {/* Requirement List start */}
              <Route
                path="/requirements-list"
                element={
                  <>
                    <Helmet>
                      <title>Requirements List - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Manage your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Requirements />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/requirement-details/:id"
                element={
                  <>
                    <Helmet>
                      <title>Detail Requirements List - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <RequirementDetails />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/requirement-details-dispatch/:id"
                element={
                  <>
                    <Helmet>
                      <title>Packing Requirements - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PickingRequirements />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/remove-child-parts-requirement/:pk"
                element={
                  <>
                    <Helmet>
                      <title>Remove Child Parts - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <RemoveChildParts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/requirement-pick-up/:pk"
                element={
                  <>
                    <Helmet>
                      <title>Pickup Requirements - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PickUp />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/pack-requirement-products/:id"
                element={
                  <>
                    <Helmet>
                      <title>
                        Pack Requirement Products - Kuber Turbo Tech
                      </title>
                      <meta
                        name="description"
                        content="Pack your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PackRequirementProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/packed-details/:id"
                element={
                  <>
                    <Helmet>
                      <title>Packed Details - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Pack your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PackedDetails />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/view-requirement-items"
                element={
                  <>
                    <Helmet>
                      <title>Packed Details Products - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Pack your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PackedDetailsProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/pending-requirement-products/:id"
                element={
                  <>
                    <Helmet>
                      <title>
                        Pending Requirements Products - Kuber Turbo Tech
                      </title>
                      <meta
                        name="description"
                        content="Manage your requirements list in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="requirements, Kuber Turbo Tech, requirements management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <PendingRequirementProducts />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/conversions"
                element={
                  <>
                    <Helmet>
                      <title>Conversions - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View and manage conversions in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="conversions, Kuber Turbo Tech, conversion management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <Convert />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/conversion-detail/:id"
                element={
                  <>
                    <Helmet>
                      <title>Conversion Detail - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View and manage conversions in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="conversions, Kuber Turbo Tech, conversion management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <ConversionDetail />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/add-conversions"
                element={
                  <>
                    <Helmet>
                      <title>Add Conversion - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Add a new conversion to Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="add conversion, Kuber Turbo Tech, conversion management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AddConversion />
                    </Layout>
                  </>
                }
              />

              {/* Our Customers */}
              <Route
                path="/all-customers"
                element={
                  <>
                    <Helmet>
                      <title>All Customers - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View all customers in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="customers, Kuber Turbo Tech, customer management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <AllClients />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/import-customers"
                element={
                  <>
                    <Helmet>
                      <title>Import Customers - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Import customer data into Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="import customers, Kuber Turbo Tech, customer data"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <ImportCustomer />
                    </Layout>
                  </>
                }
              />

              {/* Our Vendors */}
              <Route
                path="/all-vendors"
                element={
                  <>
                    <Helmet>
                      <title>All Vendors - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="View all vendors in Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="vendors, Kuber Turbo Tech, vendor management"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <AllVendor />
                    </Layout>
                  </>
                }
              />

              <Route
                path="/upload-vendors"
                element={
                  <>
                    <Helmet>
                      <title>Upload Vendors - Kuber Turbo Tech</title>
                      <meta
                        name="description"
                        content="Upload vendor data into Kuber Turbo Tech's CRM."
                      />
                      <meta
                        name="keywords"
                        content="upload vendors, Kuber Turbo Tech, vendor data"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <UploadVendor />
                    </Layout>
                  </>
                }
              />

              {/* Settings */}
              <Route
                path="/settings"
                element={
                  <>
                    <Helmet>
                      <title>Settings - Kuber Turbo Tech</title>
                      <meta name="description" content="Settings" />
                      <meta
                        name="keywords"
                        content="Settings, Kuber Turbo Tech, vendor data"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <Settings />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/settings/discount-adjustment"
                element={
                  <>
                    <Helmet>
                      <title>Settings - Kuber Turbo Tech</title>
                      <meta name="description" content="Settings" />
                      <meta
                        name="keywords"
                        content="Settings, Kuber Turbo Tech, vendor data"
                      />
                    </Helmet>
                    <Layout
                      toggleSidebar={toggleSidebar}
                      isActive={isSidebarActive}
                    >
                      <AuthTokenVerify />
                      <DiscountAdjustment />
                    </Layout>
                  </>
                }
              />
            </>
          )}
        </Routes>
      </div>
    </>
  );
}

export default App;
