import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { apiKey, fetch_all_product } from '../../../Api'; // Adjust the import according to your file structure
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon, Input } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading spinner

function ProductDetailDashboard() {

    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);
    const [searchquery, setSearchQuery] = useState("");
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = (page = currentPage, query = searchquery) => {
        const data = {
            page,
            per_page: pageSize,
            search: query,
            selectedBrands: selectedBrands.length > 0 ? selectedBrands : null,
        };
        const headers = {
            "Content-Type": "application/json",
            "API-Key": apiKey,
        };

        setLoading(true); // Set loading to true before starting the fetch

        axios
            .post(fetch_all_product, data, { headers })
            .then((response) => {
                // console.log(response.data.data);
                setProducts(response.data.data.products);
                setTotalPages(response.data.num_pages);
                setLoading(false); // Set loading to false once data is fetched
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
                setLoading(false); // Set loading to false in case of error
            });
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setPageSize(25);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData(); 
    }, [currentPage, searchquery, selectedBrands, pageSize]);

    return (
        <>

        {/* {console.log("products :",products)} */}
            <div className="row">
                <div className="col-xxl-8 col-xl-8">
                    <div className="card custom-card position-relative rounded mb-2">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <div className="card-title mb-0">Products</div>
                            <Button variant='outlined' size='small' onClick={() => navigate(`/all_products`)}>View All </Button>
                        </div>
                        <div className="card-body p-3">
                            <div className="mb-2">
                                <Input
                                    icon
                                    placeholder="Search..."
                                    value={searchquery}
                                    className="searchInput w-100"
                                    onChange={handleSearchChange}
                                >
                                    <input />
                                    <Icon name="search" />
                                </Input>
                            </div>

                            {/* Loading Condition */}
                            {loading ? (
                                <div className="loading-container text-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="responsive-table1">
                                    <table className="ui table celled" id="c_table">
                                        <thead className="table-head">
                                            <tr>
                                                <th>SKU</th>
                                                <th>Part Number</th>
                                                <th>Product</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products && products.length > 0 ? (
                                                products.map((data) => (
                                                    <tr key={data.product_sku}>
                                                        <td>{data.product_sku}</td>
                                                        <td>{data.part_number}</td>
                                                        <td>{data.product_name}</td>
                                                        <td><Button variant='outlined' size='small'>Update</Button></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="notfound_img_div">
                                                            <div className="notfound_img"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetailDashboard;
