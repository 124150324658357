import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { adjustment_History_Detail_ById, apiKey } from "../../../Api";
import { useScreenWidth } from "../../../useScreenWidth";
import { Label } from "semantic-ui-react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "950px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function StockAdjusmentViewModal({ open, onClose, adjustmentId }) {
  const isWideScreen = useScreenWidth();
  const [loading, setLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [adjustmentDetail, setAdjustmentDetail] = useState();

  useEffect(() => {
    if (open && adjustmentId) {
      fetchAdjustment(adjustmentId);
    }
  }, [open, adjustmentId]);

  const fetchAdjustment = (id) => {
    const bodyData = {
      adjustment_pk: parseInt(id),
    };
    axios
      .post(adjustment_History_Detail_ById, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAdjustmentDetail(response.data.data);
        setSelectedProducts(response.data.data.adjustment_history_detail);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className="bg-white py-3 px-3">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <>
              <Typography variant="h5" component="h2">
                Adjustment Details <small>(#{adjustmentId})</small>
              </Typography>
              <div className="row my-2">
                <div className="col-md-4">
                  <Typography>
                    <strong>Warehouse: </strong>{" "}
                    {adjustmentDetail.warehouse.ware_house}
                  </Typography>
                </div>
                <div className="col-md-4 col-6">
                  <Typography>
                    <strong>Date: </strong>
                    {adjustmentDetail.date}
                  </Typography>
                </div>
                <div className="col-md-4 col-6">
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      color:
                        adjustmentDetail.action == "stock out"
                          ? "red"
                          : "green",
                    }}
                  >
                    <strong>Action: </strong> {adjustmentDetail.action}
                  </Typography>
                </div>
                
                <div className="col-md-4 col-6">
                  {adjustmentDetail.action == "stock out" ? null : (
                    <Typography>
                      <strong>Reason: </strong>{" "}
                      {adjustmentDetail.stock_in_reason}
                    </Typography>
                  )}
                </div>
                <div className="col-md-4 col-6">
                  <Typography>
                    <strong>Products: </strong>{" "}
                    {adjustmentDetail.total_products}
                  </Typography>
                </div>
                <div className="col-md-4 col-12">
                  <Typography>
                    <strong>Note: </strong> {adjustmentDetail.note}
                  </Typography>
                </div>
              </div>
              {isWideScreen ? (
                <>
                  <div
                    className="responsive-table1 "
                    style={{
                      maxHeight: "300px",
                      height: "max-content",
                      width: "100%",
                    }}
                  >
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th>Product</th>
                          <th>Part No</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts &&
                          selectedProducts.map((data, index) => (
                            <tr key={index}>
                              <td>{data.product_sku}</td>
                              <td>{data.product_name}</td>
                              <td>{data.part_number}</td>
                              <td>{data.quantity}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="card p-2 responsive-mobile-table"
                    style={{ maxHeight: "250px" }}
                  >
                    {selectedProducts.length > 0 ? (
                      <>
                        {selectedProducts &&
                          selectedProducts.map((data) => (
                            <>
                              <div className="product-card">
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_sku}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.product_name}
                                  </h5>
                                  <small>{data.part_number}</small>
                                  <Label
                                    color={"green"}
                                    style={{
                                      position: "absolute",
                                      bottom: "5%",
                                      right: "9px",
                                      fontSize: "11px",
                                      cursor: "pointer",
                                    }}
                                    className="product-card-mrp"
                                  >
                                    QTY {data.quantity}
                                  </Label>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {!loading ? (
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        ) : (
                          <div className="notfound_img_div">
                            <div className="loadingfound_img"></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ marginTop: "20px", float: "right" }}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default StockAdjusmentViewModal;
