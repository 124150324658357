import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import debounce from "lodash.debounce";
import CloseIcon from "@mui/icons-material/Close";
import { apiKey, fetch_all_product } from "../../Api";

const viewDispatchStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: { xs: "90%", sm: "80%", md: "550px" }, // Responsive width
  height: "600px",
  overflow: "auto",
};

function SelectDestinatedProduct({
  open,
  onClose,
  onProductSelect,
  warehouse,
  defaultSelectedProduct,
}) {
  const [searchDesignatedProduct, setSearchDesignatedProduct] = useState("");
  const [DesignatedProducts, setDesignatedProducts] = useState([]);
  const [destinatedDisplay, setDestinatedDisplay] = useState(false);

  const debounceFetchDestinatedProducts = useCallback(
    debounce(async (value) => {
      try {
        const body = {
          search: value,
          warehouse_pk: parseInt(warehouse),
        };
        const response = await axios.post(fetch_all_product, body, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        });

        const fetchedProducts = response.data.data.products || [];
        setDesignatedProducts(fetchedProducts);
        setDestinatedDisplay(true);
      } catch (error) {
        setDesignatedProducts([]);
        setDestinatedDisplay(false);
      }
    }, 500),
    [warehouse]
  );

  useEffect(() => {
    if (searchDesignatedProduct.trim().length >= 0) {
      debounceFetchDestinatedProducts(searchDesignatedProduct);
    } else {
      setDesignatedProducts([]);
      setDestinatedDisplay(false);
    }
  }, [searchDesignatedProduct, debounceFetchDestinatedProducts]);

  useEffect(() => {
    if (open) {
      debounceFetchDestinatedProducts("");
    }
  }, [open, debounceFetchDestinatedProducts]);

  const handleInputChangeDestinatedSearch = (event) => {
    setSearchDesignatedProduct(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={viewDispatchStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          Select Target Product
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <TextField
          // className="px-2 py-1 border border-secondary rounded"
          fullWidth
          placeholder="Search Product"
          onChange={handleInputChangeDestinatedSearch}
          value={searchDesignatedProduct}
          size="small"
        />
        <List style={{ maxHeight: "440px", overflow: "auto" }} className="mt-2">
          {DesignatedProducts.map((product, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onProductSelect(product)}
              selected={product.product_sku === defaultSelectedProduct}
              className="mb-1 border border-1 rounded p-1 hover-div"
            >
              <ListItemText
                primary={product.product_sku}
                secondary={product.product_name}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
}

export default SelectDestinatedProduct;
