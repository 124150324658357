import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Breadcrumbs, Typography, Card, CardContent, Button, Modal, Box, } from "@mui/material";
import { apiKey, product_report } from "../../../Api";
import CustomLoader from "../../../Components/Loader";
import { addDays, subDays, format } from "date-fns";
import { startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear, subYears, startOfQuarter, endOfQuarter, } from "date-fns";
import axios from "axios";
import PurchasesProductDashboard from "./PurchasesProductDashboard";
import SalesProductDashboard from "./SalesProductDashboard";
import CustomersProductDashboard from "./CustomersProductDashboard";
import { ImEnlarge } from "react-icons/im";
import { FaCalendarAlt } from "react-icons/fa";
import { DateRangePicker, defaultStaticRanges, createStaticRanges, } from "react-date-range";
import AdjustmentHistoryProductDashboard from "./AdjustmentHistoryProductDashboard";
import ConversionProductDashboard from "./ConversionProductDashboard";
import TransferProductDashboard from "./TransferProductDashboard";

function ProductDashboard() {
  const location = useLocation();
  const { productSku } = location.state || {};
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date().setMonth(new Date().getMonth() - 6)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [productData, setProductData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalType, setModalType] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    setEndDate(currentDate);

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    setStartDate(sixMonthsAgo);
  }, []);

  const fetchData = () => {
    setLoading(true);
    setShowDatePicker(false);

    const adjustedStartDate = subDays(startDate, 1);
    const adjustedEndDate = addDays(endDate, 1);

    const formattedData = {
      starting_date: format(adjustedStartDate, "yyyy-MM-dd"),
      ending_date: format(adjustedEndDate, "yyyy-MM-dd"),
      product_sku: productSku,
    };

    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(product_report, formattedData, { headers })
      .then((response) => {
        console.log(response.data.data);
        setProductData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (productSku) {
      fetchData();
    }
  }, [productSku]);


  const handleOpenModal = (type, content) => {
    setModalType(type);
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
    setModalType(null);
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "sales":
        return (
          <SalesProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      case "purchases":
        return (
          <PurchasesProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      case "customers":
        return (
          <CustomersProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      case "adjustment_history":
        return (
          <AdjustmentHistoryProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      case "conversion_transactions":
        return (
          <ConversionProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      case "transfer_transactions":
        return (
          <TransferProductDashboard
            data={modalContent}
            onClose={handleCloseModal}
          />
        );
      default:
        return null;
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  // Define custom ranges
  const customStaticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "6 Months",
      range: () => ({
        startDate: subMonths(new Date(), 6),
        endDate: new Date(),
      }),
    },
    // {
    //   label: 'Previous 6 Months',
    //   range: () => ({
    //     startDate: subMonths(subMonths(new Date(), 6), 6),
    //     endDate: subMonths(new Date(), 6),
    //   }),
    // },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
      }),
    },
    {
      label: "Previous Year",
      range: () => ({
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      }),
    },
  ]);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <>
      {modalOpen && renderModalContent()}

      <Toaster position="top-right" />
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <div className="d-flex">
              <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">Products</Typography>
                <Typography sx={{ color: "text.primary" }}>
                  Product Dashboard
                </Typography>
                <Typography>#{productSku}</Typography>
              </Breadcrumbs>
            </div>
          </div>

          <div className="bg-white p-2 my-2">
            <div className="row d-flex  align-items-center">
              <div className="col-md-4">
                <Typography>{productData.product_name}</Typography>
              </div>
              <div className="col-md-4"> </div>
              <div className="col-md-4 d-flex justify-content-end align-items-center">
                <div className="row">
                  <div className="col d-flex align-items-center gap-2 position-relative">
                    <Button
                      variant="outlined"
                      // size="small"
                      onClick={toggleDatePicker}
                    >
                      <div>
                        <FaCalendarAlt
                          style={{ marginTop: "-0.2rem" }}
                          className="me-1"
                        />{" "}
                        {`${format(startDate, "yyyy-MM-dd")} to ${format(
                          endDate,
                          "yyyy-MM-dd"
                        )} `}
                      </div>
                    </Button>
                    {showDatePicker && (
                      <div
                        className="row position-absolute"
                        style={{
                          zIndex: 10,
                          marginTop: "3.5rem",
                          width: "max-content",
                          right: "10%",
                          top: "10%",
                        }}
                      >
                        <div
                          className="bg-white"
                          style={{
                            zIndex: 10,
                            width: "max-content",
                          }}
                        >
                          <DateRangePicker
                            onChange={(item) => {
                              setStartDate(item.selection.startDate);
                              setEndDate(item.selection.endDate);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[{ startDate, endDate, key: "selection" }]}
                            direction="horizontal"
                            preventSnapRefocus={true}
                            staticRanges={customStaticRanges}
                          />
                          <div className="d-flex bg-white justify-content-end align-items-center mb-2">
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={toggleDatePicker}
                              className="ms-2"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={fetchData}
                              className="ms-2"
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row my-4">
            {productData && (
              <>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h6">Part Number</Typography>
                      <Typography variant="h6" style={{fontSize:"1rem"}}>
                        {productData?.part_number || "-"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h6">Brand</Typography>
                      <Typography variant="h6" style={{fontSize:"1rem"}}>
                        {productData?.product_brand || "-"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h6">Type</Typography>
                      <Typography variant="h6" style={{fontSize:"1rem"}}>
                        {productData?.product_type || "-"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h6">OEM Number</Typography>
                      <Typography variant="h6" style={{fontSize:"1rem"}}>
                        {productData?.oem_number || "-"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </>
            )}
          </div>


          <div className="row my-4">
            {productData && (
              <>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">Available Stock</Typography>
                      <Typography variant="h4">
                        {productData?.in_stock}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">Quantity Sold</Typography>
                      <Typography variant="h4">
                        {productData?.sold_quantity}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">Quantity Bought</Typography>
                      <Typography variant="h4">
                        {productData?.purchased_quantity}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("purchases", productData.n_purchases)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">No. of Sales</Typography>
                      <Typography variant="h4">
                        {productData?.n_sales?.length}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("sales", productData.n_sales)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">No. of Customers</Typography>
                      <Typography variant="h4">
                        {productData?.n_customer?.length}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("customers", productData.n_customer)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">No. of Adjustments</Typography>
                      <Typography variant="h4">
                        {productData?.adjustment_history?.length}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("adjustment_history", productData.adjustment_history)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">No. of Conversions</Typography>
                      <Typography variant="h4">
                        {productData?.conversion_transactions?.length}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("conversion_transactions", productData.conversion_transactions)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-3 mb-2">
                  <Card className="rounded text-start">
                    <CardContent className="position-relative">
                      <Typography variant="h5">No. of Transfers</Typography>
                      <Typography variant="h4">
                        {productData?.transfer_transactions?.length}
                      </Typography>
                      <div
                        className="enlarge-modal"
                        onClick={() =>
                          handleOpenModal("transfer_transactions", productData.transfer_transactions)
                        }
                      >
                        <ImEnlarge />
                      </div>
                    </CardContent>
                  </Card>
                </div>

              </>
            )}
          </div>

        </div>
      </div>

    </>
  );
}

export default ProductDashboard;
