import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import {
  apiKey,
  fetch_warehouse,
  allocateinventory_Stock_Out,
} from "../../../Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AssignStockModal({
  setOpenModal,
  openModal,
  unallocated,
  p_sku,
  warehouseId,
  fetchDataa,
  qty,
  inven,
  setInven,
  setStatus,
  status,
}) {
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [bins, setBins] = useState([]);
  const [alertqty, setAlertQty] = useState(0);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const [success, setSuccess] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [allocations, setAllocations] = useState([
    {
      warehouse_id: warehouseId,
      row: null,
      rack: null,
      bin: null,
      qty: "",
    },
  ]);

  useEffect(() => {
    if (openModal) {
      fetchWarehouses();
    }
  }, [openModal]);

  const fetchWarehouses = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        const filteredWarehouses = allWarehouses.filter(
          (warehouse) => warehouse.id === warehouseId
        );
        if (filteredWarehouses.length) {
          const selectedWarehouse = filteredWarehouses[0];
          setWarehouses(filteredWarehouses);
          setWarehouse(selectedWarehouse.ware_house);
          setRows(selectedWarehouse.rows);
          // console.log(selectedWarehouse.rows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowChange = (index, rowId) => {
    const selectedRow = rows.find((row) => row.id === rowId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      warehouse_id: warehouseId,
      row: selectedRow || [],
      rack: null,
      bin: null,
    };
    setAllocations(newAllocations);
    setRacks(selectedRow ? selectedRow.racks : []);
    setBins([]);
  };

  const handleRackChange = (index, rackId) => {
    const selectedRack = racks.find((rack) => rack.id === rackId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      rack: selectedRack,
      bin: null,
    };
    setAllocations(newAllocations);
    setBins(selectedRack ? selectedRack.bins : []);
  };

  const handleBinChange = (index, binId) => {
    const selectedBin = bins.find((bin) => bin.id === binId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      bin: selectedBin,
    };
    setAllocations(newAllocations);
  };

  const handleQtyChange = (index, qtyValue) => {
    const newQty = parseFloat(qtyValue) || "";

    const totalQty = allocations.reduce((total, alloc, idx) => {
      return idx === index
        ? total + newQty
        : total + (parseFloat(alloc.qty) || "");
    }, 0);

    const inventotalQty = inven.reduce((total, inv) => {
      return total + (parseFloat(inv.qty) || "");
    }, 0);

    let totalQuantity = totalQty + inventotalQty;
    if (totalQuantity > qty) {
      setErrorr("Total quantity exceeds.");

      setTimeout(() => {
        setErrorr("");
      }, 1000);
      const updatedAllocations = [...allocations];
      updatedAllocations[index] = {
        ...updatedAllocations[index],
        qty: "",
      };
      return;
    }

    const updatedAllocations = [...allocations];
    updatedAllocations[index] = {
      ...updatedAllocations[index],
      qty: newQty,
    };
    setAlertQty(totalQuantity);
    setAllocations(updatedAllocations);
    setErrorr("");
  };

  const addAllocationRow = () => {
    setAllocations([
      ...allocations,
      { row: null, rack: null, bin: null, qty: "" },
    ]);
  };

  const deleteAllocationRow = (index) => {
    const newAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(newAllocations);
  };

  // Function to filter inventory based on SKU
  const filterInventoryBySKU = (sku) => {
    try {
      // Get data from local storage
      const storedData = localStorage.getItem("BulkStock_In");
      const inventory = JSON.parse(storedData) || []; // Default to empty array if null

      // Filter inventory based on SKU
      const filteredInventory = inventory.filter((item) => item.sku === sku);

      // Update state with filtered inventory
      setInven(filteredInventory[0].inventoryDetails);
    } catch (error) {
      console.error("Error parsing inventory data:", error);
      setInven([]); // Set to empty array on parse error
    }
  };

  // Fetch filtered inventory when SKU changes
  useEffect(() => {
    if (p_sku) {
      filterInventoryBySKU(p_sku);
    }
  }, [p_sku]);

  const handleSubmit = () => {
    // Validate that all fields are filled out
    const isValid = allocations.every(
      (alloc) => alloc.row && alloc.rack && alloc.qty
    );

    if (!isValid) {
      setError("Please fill out all fields for each allocation.");
      return;
    }

    // Prepare the data to be sent
    const allocationsToSend = allocations.map((alloc) => ({
      warehouse_id: warehouseId,
      row_id: alloc.row.id,
      rack_id: alloc.rack.id,
      bin_id: alloc.bin ? alloc.bin.id : null, // Handle optional bin
      qty: alloc.qty,
    }));

    const data_Send = {
      sku: p_sku,

      inventoryDetails: allocationsToSend,
    };

    // Retrieve existing data from local storage
    const existingData = localStorage.getItem("BulkStock_In");
    let dataArray = existingData ? JSON.parse(existingData) : [];

    // Check if the SKU already exists in the array
    const existingIndex = dataArray.findIndex((item) => item.sku === p_sku);

    if (existingIndex !== -1) {
      // Append new allocations to existing inventoryDetails
      dataArray[existingIndex].inventoryDetails = [
        ...dataArray[existingIndex].inventoryDetails,
        ...allocationsToSend,
      ];
    } else {
      // Add the new data_Send object to the array
      dataArray.push(data_Send);
    }

    // Save the updated array back to local storage
    localStorage.setItem("BulkStock_In", JSON.stringify(dataArray));

    // Optionally, refetch the inventory data and reset form
    filterInventoryBySKU(p_sku);
    setAllocations([{ row: null, rack: null, bin: null, qty: "" }]);

    // Optionally handle success message
    setSuccess("Inventory saved successfully!");
    setOpenSuccess(true);
    setOpenModal(false);
  };

  const [skuExists, setSkuExists] = useState(false);

  useEffect(() => {
    // Check if the SKU exists in local storage whenever p_sku changes
    const existingData = localStorage.getItem("BulkStock_In");
    const dataArray = existingData ? JSON.parse(existingData) : [];

    // Check if the SKU already exists in the stored data
    const skuFound = dataArray.some((item) => item.sku === p_sku);
    setSkuExists(skuFound);
  }, [p_sku]);

  const handleSkipAllocation = () => {
    // Retrieve existing data from local storage
    const existingData = localStorage.getItem("BulkStock_In");
    let dataArray = existingData ? JSON.parse(existingData) : [];

    // Check if the SKU already exists in the stored data
    const skuFound = dataArray.some((item) => item.sku === p_sku);

    if (!skuFound) {
      // Prepare the data to be sent
      const allocationsToSend = [
        {
          warehouse_id: warehouseId,
          row_id: 0,
          rack_id: 0,
          bin_id: 0,
          qty: parseInt(qty),
        },
      ];

      const data_Send = {
        sku: p_sku,
        inventoryDetails: allocationsToSend,
      };

      dataArray.push(data_Send);

      // Save the updated array back to local storage
      localStorage.setItem("BulkStock_In", JSON.stringify(dataArray));

      // Optionally, refetch the inventory data and reset form
      filterInventoryBySKU(p_sku);
      setAllocations([{ row: null, rack: null, bin: null, qty: "" }]);

      // Optionally handle success message
      setSuccess("Allocation skipped!");
      setOpenSuccess(true);
      setOpenModal(false);
    } else {
      // Optionally handle the case where the SKU already exists
      setError(
        "Product already skipped allocation. You can allocate the product in Inventory!"
      );
      setOpenError(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    resetStates();
    setError(""); // Clear error on close
  };

  const resetStates = () => {
    setAllocations([{ row: null, rack: null, bin: null, qty: "" }]);

    setRows([]);
    setRacks([]);
    setBins([]);
    setError("");
    setSuccess("");
  };

  // Your component JSX here

  // console.log(rows[0].racks);

  const deleteAllocationRowInven = (index) => {
    // Remove the allocation row from local state
    const newAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(newAllocations);

    // Retrieve existing data from local storage
    const existingData = localStorage.getItem("BulkStock_In");
    let dataArray = existingData ? JSON.parse(existingData) : [];

    // Find the item with the current SKU
    const existingIndex = dataArray.findIndex((item) => item.sku === p_sku);

    if (existingIndex !== -1) {
      // Remove the specific allocation index from inventoryDetails
      const updatedInventoryDetails = dataArray[
        existingIndex
      ].inventoryDetails.filter((_, i) => i !== index);

      if (updatedInventoryDetails.length === 0) {
        // If inventoryDetails is empty, remove the SKU product
        dataArray = dataArray.filter((item) => item.sku !== p_sku);
      } else {
        // Update the item with the new inventoryDetails
        dataArray[existingIndex] = {
          ...dataArray[existingIndex],
          inventoryDetails: updatedInventoryDetails,
        };
      }

      // Save the updated array back to local storage
      localStorage.setItem("BulkStock_In", JSON.stringify(dataArray));

      // Refetch the inventory data after deletion
      filterInventoryBySKU(p_sku);
      resetStates();
      fetchWarehouses();
      setError(""); // Clear error on close
    }
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Allocate Inventory for <b style={{ color: "black" }}>{warehouse}</b>
          </Typography>
          <Typography variant="p" component="p" style={{ color: "red" }}>
            Entered Quantity: <b>{qty}</b>
          </Typography>

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
              marginTop: "10px",
              paddingTop: "10px",
            }}
          >
            {inven.length > 0 ? (
              <>
                {inven &&
                  inven.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{ marginBottom: "8px" }}
                        className="row"
                      >
                        {data.row_id == 0 && data.rack_id == 0 ? (
                          <>
                            <div
                              className="col-lg-9"
                              style={{
                                textAlign: "right",
                                color: "red",
                                marginTop: "2px",
                              }}
                            >
                              <h5>Allocation skipped</h5>
                              <p style={{ marginTop: "-10px" }}>
                                Delete this before allocating new inventory
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-lg-3">
                              <TextField
                                size="small"
                                fullWidth
                                label="Row"
                                variant="outlined"
                                value={
                                  rows.find((row) => row.id === data.row_id)
                                    ?.row_name || ""
                                }
                                style={{ marginRight: "8px" }}
                              />
                            </div>
                            <div className="col-lg-3">
                              <TextField
                                size="small"
                                fullWidth
                                label="Row"
                                variant="outlined"
                                value={
                                  rows
                                    .find((row) => row.id === data.row_id)
                                    ?.racks.find(
                                      (rack) => rack.id === data.rack_id
                                    )?.rack_name || ""
                                }
                                style={{ marginRight: "8px" }}
                              />
                            </div>
                            <div className="col-lg-3">
                              <TextField
                                size="small"
                                fullWidth
                                label="Row"
                                variant="outlined"
                                value={
                                  rows
                                    .find((row) => row.id === data.row_id)
                                    ?.racks.find(
                                      (rack) => rack.id === data.rack_id
                                    )
                                    ?.bins.find((bin) => bin.id === data.bin_id)
                                    ?.bin_name || ""
                                }
                                style={{ marginRight: "8px" }}
                              />
                            </div>
                          </>
                        )}

                        <div className="col-lg-2">
                          <TextField
                            size="small"
                            label="Quantity"
                            value={data.qty}
                            style={{ marginRight: "8px" }}
                            fullWidth
                          />
                        </div>

                        <div className="col-lg-1">
                          <Button
                            style={{ height: "40px" }}
                            color="error"
                            onClick={() => deleteAllocationRowInven(index)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : null}

            <hr />

            {allocations &&
              allocations.map((allocation, index) => (
                <div key={index} className="row my-1 add_warehouse_div">
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...rows.map((row) => ({
                          label: row.row_name,
                          value: row.id,
                        })),
                      ]}
                      value={
                        allocation.row
                          ? {
                              label: allocation.row.row_name,
                              value: allocation.row.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleRowChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      } // Add this line
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Row"
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...racks.map((rack) => ({
                          label: rack.rack_name,
                          value: rack.id,
                        })),
                      ]}
                      value={
                        allocation.rack
                          ? {
                              label: allocation.rack.rack_name,
                              value: allocation.rack.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleRackChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Rack"
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...bins.map((bin) => ({
                          label: bin.bin_name,
                          value: bin.id,
                        })),
                      ]}
                      value={
                        allocation.bin
                          ? {
                              label: allocation.bin.bin_name,
                              value: allocation.bin.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleBinChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Bin"
                        />
                      )}
                    />
                  </div>

                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      type="number"
                      label="Quantity"
                      value={allocation.qty}
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                      fullWidth
                      helperText={
                        errorr &&
                        (index === allocations.length - 1 ? errorr : "")
                      }
                    />
                  </div>

                  <div className="col-lg-1 ">
                    <IoIosAddCircle
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={addAllocationRow}
                    />
                    {allocations.length > 1 && (
                      <FaTrashAlt
                        onClick={() => deleteAllocationRow(index)}
                        style={{
                          fontSize: "18px",
                          marginLeft: "10px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              variant="outlined"
              color="warning"
              onClick={handleSkipAllocation}
            >
              Skip Allocation
            </Button>

            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              variant="outlined"
              color="primary"
              disabled={alertqty != qty}
              onClick={handleSubmit}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AssignStockModal;
