import React, { useState } from 'react';
import { Typography, Button, Modal, Box } from "@mui/material";
import { Label } from 'semantic-ui-react';
import StockAdjusmentViewModal from '../../Inventory/StockAdjustment/StockAdjusmentViewModal';
import { BiLinkExternal } from 'react-icons/bi';
import { useScreenWidth } from '../../../useScreenWidth';
import { TbExternalLink } from 'react-icons/tb';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "80%", md: "850px" },
    maxWidth: "950px",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

function AdjustmentHistoryProductDashboard({ data, onClose }) {

    const isWideScreen = useScreenWidth();

    const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
    const [selectedAdjustmentId, setSelectedAdjustmentId] = useState(null);


    const handleOpenDetailModal = (adjustment_pk) => {
        // console.log(adjustment_pk);
        setSelectedAdjustmentId(adjustment_pk);
        setAdjustmentModalOpen(true);
    };



    return (
        <>
            <StockAdjusmentViewModal
                open={adjustmentModalOpen}
                onClose={() => setAdjustmentModalOpen(false)}
                adjustmentId={selectedAdjustmentId}
            />

            <Modal open onClose={onClose}>
                <Box sx={modalStyle} className="bg-white py-3 px-3">
                    <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap"   >
                        <Typography variant="h5" component="h2">
                            Adjustment Details
                        </Typography>
                        <div className="">
                            Total : <Label>{data.length}</Label>
                        </div>

                    </div>

                    {isWideScreen ? (
                        <>
                            <div
                                className="responsive-table1"
                                style={{
                                    maxHeight: "300px",
                                    height: "max-content",
                                    width: "100%",
                                }}
                            >
                                <table className="ui table celled" id="c_table">
                                    <thead className="table-head">
                                        <tr>
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Warehouse</th>
                                            <th>Transaction Type</th>
                                            <th>Stock In</th>
                                            <th>Stock Out</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ cursor: "pointer" }} onClick={() => handleOpenDetailModal(item.adjustment_pk)} > <BiLinkExternal style={{ color: "#4183c4" }} />{" "}{item.adjustment_pk}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.warehouse?.warehouse_name}</td>
                                                    <td>{item.adjustment_type}</td>
                                                    <td>{item.stock_in}</td>
                                                    <td>{item.stock_out}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="card p-2 responsive-mobile-table">

                                {data && data.map((item, idx) => (
                                    <div className="product-card " key={idx} >
                                        <div className="py-1 px-2 w-100"  >
                                            <h5>{item.warehouse?.warehouse_name}</h5>
                                            <h5 className="sale-card-date">{item.date} | {item.quantity} QTY  </h5>
                                            <h5 className="mt-1">
                                                <Label className='py-1 px-2' color={ item.stock_in && item.stock_in > 0 ? "green" : item.stock_out && item.stock_out > 0 ? "red" : "grey" } >
                                                    {item.stock_in && item.stock_in > 0 ? `Stock In : ${item.stock_in}` : null}
                                                    {item.stock_out && item.stock_out > 0 ? `Stock Out : ${item.stock_out}` : null}
                                                </Label>
                                            </h5>
                                            <div
                                                onClick={() => handleOpenDetailModal(item.adjustment_pk)}
                                                className="navigate-other-mobile"
                                            >
                                                <TbExternalLink />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </>
                    )}

                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "20px", float: "right" }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

        </>
    );
}

export default AdjustmentHistoryProductDashboard;
