import { Box, Dialog, DialogContent } from "@mui/material";
import React from "react";

function SubmitLoader(openloader) {
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <Box
            className="submitLoader"
            display="flex"
            justifyContent="center"
            style={{ backgroundColor: "#e6e6e6", borderRadius: "10px" }}
            mt={1}
          >
            <div class="submitloading">
              <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
                <circle
                  class="pl__ring pl__ring--a"
                  cx="120"
                  cy="120"
                  r="105"
                  fill="none"
                  stroke="#000"
                  stroke-width="20"
                  stroke-dasharray="0 660"
                  stroke-dashoffset="-330"
                  stroke-linecap="round"
                ></circle>
                <circle
                  class="pl__ring pl__ring--b"
                  cx="120"
                  cy="120"
                  r="35"
                  fill="none"
                  stroke="#000"
                  stroke-width="20"
                  stroke-dasharray="0 220"
                  stroke-dashoffset="-110"
                  stroke-linecap="round"
                ></circle>
                <circle
                  class="pl__ring pl__ring--c"
                  cx="85"
                  cy="120"
                  r="70"
                  fill="none"
                  stroke="#000"
                  stroke-width="20"
                  stroke-dasharray="0 440"
                  stroke-linecap="round"
                ></circle>
                <circle
                  class="pl__ring pl__ring--d"
                  cx="155"
                  cy="120"
                  r="70"
                  fill="none"
                  stroke="#000"
                  stroke-width="20"
                  stroke-dasharray="0 440"
                  stroke-linecap="round"
                ></circle>
              </svg>
              <span className="submit-loading-gradient-text">
                Please wait...
              </span>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SubmitLoader;
