import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import { add_requirement_item, apiKey, fetch_all_product } from '../../Api';
import toast from 'react-hot-toast';

function AddProductInRequirementListModal({ onAddProducts, requirementPk, alreadyAddedProducts,fetchMainData }) {
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [quantityModalOpen, setQuantityModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchData = () => {
        setLoading(true);
        axios.post(fetch_all_product, {
            page: 1,
            per_page: 25,
            search: searchQuery || null
        }, {
            headers: { "Content-Type": "application/json", "API-Key": apiKey }
        })
        .then(response => {
            setProducts(response.data.data.products);
            setLoading(false);
        })
        .catch(error => {
            console.error("Error fetching products", error);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (open) fetchData();
    }, [open, searchQuery]);

    useEffect(() => {
        if (requirementPk) fetchData();
    }, [requirementPk]);

    const isProductAlreadyAdded = (sku) => {
        return alreadyAddedProducts.some(product => product.product_sku === sku);
    };

    const handleAddClick = (product) => {
        if (!isProductAlreadyAdded(product.product_sku)) {
            setSelectedProduct(product);
            setQuantity();
            setQuantityModalOpen(true);
        }
    };

    const handleSubmitQuantity = () => {

        if(!quantity){
            toast.error("Please add quantity");
            return
        }
        setSubmitLoading(true);
        const sendData = {
            requirement_pk: parseInt(requirementPk) || 0,
            product_sku: selectedProduct.product_sku,
            quantity: parseInt(quantity)
        };

        axios.post(add_requirement_item, sendData, {
            headers: { "Content-Type": "application/json", "API-Key": apiKey }
        })
        .then(response => {
            if (response.data.status === 1) {
                toast.success(`${selectedProduct.product_sku} added successfully`);
                setQuantityModalOpen(false);
                setSubmitLoading(false);
                fetchMainData()
            } else {
                toast.error(response.data.message);
                setSubmitLoading(false);
            }
        })
        .catch(error => {
            toast.error(error.response?.data?.message || "Something went wrong");
            setSubmitLoading(false);
        });
    };

    const style = {
        boxShadow: 24,
        borderRadius: 2,
        bgcolor: "background.paper",
        overflow: "auto",
        width: { xs: "90%", sm: "80%", md: "550px" },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: { xs: 2, sm: 2, md: 3 }
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Add Product
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" gutterBottom>Add Products</Typography>
                    <TextField
                        label="Search Product"
                        fullWidth
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ marginBottom: '20px' }}
                        size='small'
                        autoFocus
                    />
                    <div style={{ height: "60vh", overflow: "auto" }}>
                        {loading ? (
                            <Typography>Loading products...</Typography>
                        ) : (
                            products.length > 0 ? (
                                products.map((product) => (
                                    <Box key={product.product_sku} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                        <Typography>
                                            <small>#{product.product_sku}</small>
                                            <small className='d-block '>{product.product_name} ({product.part_number})</small>
                                        </Typography>
                                        {isProductAlreadyAdded(product.product_sku) ? (
                                            <Button variant="outlined" disabled size='small'>Added</Button>
                                        ) : (
                                            <Button variant="outlined" color="primary" onClick={() => handleAddClick(product)} size='small'>Add</Button>
                                        )}
                                    </Box>
                                ))
                            ) : (
                                <Typography className='text-center mt-3'>No products matched your search.</Typography>
                            )
                        )}
                    </div>
                    <div className='text-end mt-2'>
                        <Button onClick={handleClose} variant="outlined" color='error' sx={{ mr: 1 }}>Close</Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={quantityModalOpen} onClose={() => setQuantityModalOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2
                }}>
                    <Typography variant="h6" gutterBottom size='small'>
                        Enter Quantity for {selectedProduct?.product_sku}
                    </Typography>
                    <TextField
                        type="number"
                        label="Quantity"
                        fullWidth
                        variant="outlined"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        style={{ marginBottom: '20px' }}
                        autoFocus
                        size='small'
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setQuantityModalOpen(false)} variant="outlined" color='error' sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSubmitQuantity} variant="contained" color="primary" disabled={submitLoading}>
                            {submitLoading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default AddProductInRequirementListModal;
