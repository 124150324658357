import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, TransferHistoryDetails } from "../../../Api";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./InvenModal";
import { FaImage } from "react-icons/fa6";
import Error from "../../../Messages/Error";
import { Card } from "semantic-ui-react";
import { Pagination, Icon, Label } from "semantic-ui-react";
import { useScreenWidth } from "../../../useScreenWidth";
import { Button } from "@mui/material";
import CustomLoader from "../../../Components/Loader";

function ViewStockTransfer() {
  const isWideScreen = useScreenWidth();
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [notes, setNotes] = useState("");
  const [sourcewarehouse, setSourceWarehouse] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { pk } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(TransferHistoryDetails, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          history_pk: pk,
        },
      })
      .then((response) => {
        setProducts(response.data.data);
        setSourceWarehouse(response.data.obj.source_warehouse);
        setWarehouse(response.data.obj.target_warehouse);
        setNotes(response.data.obj.note);
        setLoading(false);
        // setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
        setLoading(false);
      });
  };

  const [data, setData] = useState([]);

  const handleInvenDetail = (data) => {
    setOpenModal(true);
    setData(data);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={data}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_transfer")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Stock Transfer</Typography>
              <Typography sx={{ color: "text.primary" }}>#{pk}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row" style={{ padding: "5px 2px 12px" }}>
            <div className="col-lg-5">
              <Card className="p-3" style={{ width: "100%" }}>
                <h4>
                  Warehouse: <b style={{ color: "black" }}>{warehouse}</b>
                </h4>

                <p>
                  Notes:{" "}
                  <b>
                    {notes ? notes : "No notes available for this transfer"}
                  </b>
                </p>
              </Card>
            </div>
          </div>

          {/* <div className="p-3">
            <DataGrid
              style={{ height: "70vh" }}
              slots={{ toolbar: CustomToolbar }}
              // checkboxSelection
              disableRowSelectionOnClick
              rows={products}
              columns={columns}
            />
          </div> */}

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Product</th>
                      <th style={{ textAlign: "center" }}>Stock</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.length > 0 ? (
                      <>
                        {products &&
                          products.map((data) => (
                            <>
                              <tr>
                                <td>{data.product_ref.product_sku || "N/A"}</td>

                                <td className="product_name_class"
                                  style={{
                                    maxWidth: "500px",
                                  
                                  }}
                                >
                                  <span style={{ cursor: "pointer" }}>
                                    {data.product_ref.product_name || "N/A"}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {data.quantity}
                                </td>

                                <td style={{ width: "100px" }}>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleInvenDetail(data.item_detail)
                                    }
                                    size="small"
                                  >
                                    Inventory
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={11}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {products.length > 0 ? (
                  <>
                    {products &&
                      products.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleInvenDetail(data.item_detail)}
                          >
                            <div>
                              <FaImage className="product-img" />
                            </div>
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                #{data.product_ref.product_sku || "N/A"}
                              </h5>
                              <h5 className="product-card-name">
                                {data.product_ref.product_name || "N/A"}
                              </h5>

                              <Label
                                style={{
                                  fontSize: "11px",
                                  marginTop: "5px",
                                }}
                                className="product-card-mrp"
                              >
                                QTY {data.quantity}
                              </Label>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewStockTransfer;
