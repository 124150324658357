import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import { apiKey, edit_sale_summary } from "../../Api";
import toast from "react-hot-toast";

function EditSaleSummaryPrices({
  sale_pk,
  fetchSales,
  totalamount,
  discountedprice,
  shipping,
  othercharge,
  onClose,
}) {
  const formatIndianRupee = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const [formData, setFormData] = useState({
    discountedprice,
    shipping,
    othercharge,
  });

  // Calculate Total Amount Dynamically
  const calculatedTotalAmount =
    totalamount -
    parseFloat(formData.discountedprice || 0) +
    parseFloat(formData.shipping || 0) +
    parseFloat(formData.othercharge || 0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const editedSalePriceSummary = {
      sale_pk: parseInt(sale_pk),
      discount_amount: parseFloat(formData.discountedprice) || 0,
      shipping_charge: parseFloat(formData.shipping) || 0,
      other_charge: parseFloat(formData.othercharge) || 0,
    };

    axios
      .post(edit_sale_summary, editedSalePriceSummary, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Sale Summary Updated Successfully!");
          fetchSales();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "Something went wrong!");
      });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <b>Edit Sale Summary</b>
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <div className="row mt-2">
            <div className="col-6 my-2">Sub-Total</div>
            <div className="col-6 my-2" style={{ textAlign: "right" }}>
              <h5 style={{ color: "black" }}>
                {formatIndianRupee(totalamount)}
              </h5>
            </div>
            <div className="col-6 my-2">Total Discount (-)</div>
            <div className="col-6 my-2" style={{ textAlign: "right" }}>
              <h5 style={{ color: discountedprice > 0 ? "black" : "grey" }}>
                <TextField
                  name="discountedprice"
                  value={formData.discountedprice}
                  onChange={handleChange}
                  type="number"
                  size="small"
                  style={{ maxWidth: "120px" }}
                />
              </h5>
            </div>
            <div className="col-6 my-2">Shipping Charge</div>
            <div className="col-6 my-2" style={{ textAlign: "right" }}>
              <h5 style={{ color: shipping > 0 ? "black" : "grey" }}>
                <TextField
                  name="shipping"
                  value={formData.shipping}
                  onChange={handleChange}
                  size="small"
                  type="number"
                  style={{ maxWidth: "120px" }}
                />
              </h5>
            </div>
            <div className="col-6 my-2">Other Charges</div>
            <div className="col-6 my-2" style={{ textAlign: "right" }}>
              <h5 style={{ color: othercharge > 0 ? "black" : "grey" }}>
                <TextField
                  name="othercharge"
                  value={formData.othercharge}
                  onChange={handleChange}
                  size="small"
                  type="number"
                  style={{ maxWidth: "120px" }}
                />
              </h5>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6 my-2">
              <strong>Total Amount</strong>
            </div>
            <div className="col-6 my-2" style={{ textAlign: "right" }}>
              <strong style={{ color: "red", fontSize: "1.3rem" }}>
                {formatIndianRupee(calculatedTotalAmount.toFixed(2))}
              </strong>
            </div>
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="outlined" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSaleSummaryPrices;
