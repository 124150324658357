import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_sale_item } from "../../Api";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";
import { InputAdornment, OutlinedInput, TextField } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "900px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditSaleDataModal({
  openEditModal,
  setOpenEditModal,
  p_data,
  fetchSales,
}) {
  const [discountedprice, setDiscountedPrice] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [productPrice, setProductPrice] = useState("");

  useEffect(() => {
    if (openEditModal) {
      setProductPrice(p_data.price);
      setDiscountPercentage(
        ((p_data.discount_amount / (p_data.mrp > 0 ? p_data.mrp : 1)) * 100).toFixed(2)
      );
      setDiscountedPrice(p_data.discount_amount.toFixed(0));
    }
  }, [openEditModal, p_data]);

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  const handleClose = () => {
    setOpenEditModal(false);
    setProductPrice("");
    setDiscountPercentage("");
    setDiscountedPrice("");
  };

  const handleDiscountPercentageChange = (e) => {
    
    const newDiscountPercentage = parseFloat(e.target.value) || 0;
    const newDiscountAmount = (p_data.mrp * newDiscountPercentage) / 100;
    const newProductPrice = p_data.mrp - newDiscountAmount;

 
    setDiscountPercentage(parseFloat(newDiscountPercentage));
    setDiscountedPrice(newDiscountAmount.toFixed(0));
    setProductPrice(newProductPrice.toFixed(2));
  };

  const handleDiscountAmountChange = (e) => {
    const newDiscountAmount = parseFloat(e.target.value) || 0;
    const newDiscountPercentage = (newDiscountAmount / (p_data.mrp > 0 ? p_data.mrp : 1)) * 100;
    const newProductPrice = p_data.mrp - newDiscountAmount;

    setDiscountedPrice(newDiscountAmount.toFixed(0));
    setDiscountPercentage(parseFloat(newDiscountPercentage));
    setProductPrice(newProductPrice.toFixed(0));
  };

  const handleProductPriceChange = (e) => {
    const newProductPrice = parseFloat(e.target.value) || 0;
    const newDiscountAmount = p_data.mrp - newProductPrice;
    const newDiscountPercentage = (newDiscountAmount / (p_data.mrp > 0 ? p_data.mrp : 1)) * 100;

    setProductPrice(newProductPrice.toFixed(0));
    setDiscountedPrice(newDiscountAmount.toFixed(0));
    setDiscountPercentage(parseFloat(newDiscountPercentage));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedData = {
      sale_item_pk: parseInt(p_data.id),
      discount_amount: parseFloat(discountedprice) || 0,
      price: parseFloat(productPrice) || 0,
    };

    axios
      .post(edit_sale_item, editedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          fetchSales();
          toast.success("Sale Details Updated Successfully!");
          handleClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        open={openEditModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <div style={modalStyle} className="bg-white py-3 px-3">
          <form onSubmit={handleSubmit}>
            <div className="row my-2">
              <Typography>
                {p_data.product_sku} / <strong>{p_data.product_name}</strong>
              </Typography>
            </div>

            <div className="row mt-4">
              <div className="col-md-3">
                <OutlinedInput
                  disabled
                  size="small"
                  fullWidth
                  value={formatIndianRupee(p_data.mrp)}
                  startAdornment={
                    <InputAdornment position="start">MRP</InputAdornment>
                  }
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Discount"
                  value={discountPercentage}
                  size="small"
                  type="number"
                  required
                  fullWidth
                  onChange={handleDiscountPercentageChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Discount Amount"
                  value={discountedprice}
                  size="small"
                  required
                  fullWidth
                  onChange={handleDiscountAmountChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Product Price"
                  value={productPrice}
                  size="small"
                  required
                  fullWidth
                  onChange={handleProductPriceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginTop: "20px", float: "right", marginLeft: "5px" }}
            >
              Update
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="error"
              style={{ marginTop: "20px", float: "right" }}
            >
              Close
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default EditSaleDataModal;
     